export interface ProfitChartPoint extends Partial<Record<ChartIndex, number>> {
  date: Date;
  portfolio: number;
}

export enum ChartIndex {
  IMOEX = 'imoex',
  // Временно скрыто по задаче https://jira.moscow.alfaintra.net/browse/ADIRWEB-4946
  // SP500 = 'sp500',
  // NASDAQ = 'nasdaq',
  GOLD = 'gold',
}
