import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getCommonInstrumentsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeChartConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHARTS_HOUR_CHARTIQ_SETTINGS,
  CHARTS_WEEK_CHARTIQ_SETTINGS,
  TRADE_CHARTIQ_SETTINGS,
  TRADE_CHARTIQ_SETTINGS_NO_VOLUME,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const swingLarge = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const greenLink = new Link({
      name: LinkName.GREEN,
      idFi: 144950,
      symbol: 'SBER',
    });

    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const positionsTabset = new TabSet(50);

    positionsTabset.addChildren([positionsTab]);

    const ordersTab = new Tab(Widget.ORDERS, getCommonOrdersConfig());
    const orderTabset = new TabSet(50);

    orderTabset.addChildren([ordersTab]);

    const leftRow = new Row(20);

    leftRow.addChildren([positionsTabset, orderTabset]);

    const instrumentLeftTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );
    const instrumentLeftTabset = new TabSet(50);

    instrumentLeftTabset.addChildren([instrumentLeftTab]);

    const orderbookLeftTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const orderbookLeftTabset = new TabSet(50);

    orderbookLeftTabset.addChildren([orderbookLeftTab]);

    const leftMiddleRow = new Row(10);

    leftMiddleRow.addChildren([instrumentLeftTabset, orderbookLeftTabset]);

    const instrumentsRightTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(greenLink.name, WatchlistIds.featuresList)
    );
    const instrumentRightTabset = new TabSet(50);

    instrumentRightTabset.addChildren([instrumentsRightTab]);

    const orderbookRightTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(greenLink.name)
    );
    const orderbookRightTabset = new TabSet(50);

    orderbookRightTabset.addChildren([orderbookRightTab]);

    const rightMiddleRow = new Row(10);

    rightMiddleRow.addChildren([instrumentRightTabset, orderbookRightTabset]);

    const chartiqTopTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      TRADE_CHARTIQ_SETTINGS
    );
    const chartiqTopTabSet = new TabSet(40);

    chartiqTopTabSet.addChildren([chartiqTopTab]);

    const chartiqBottomTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(greenLink.name),
      TRADE_CHARTIQ_SETTINGS
    );
    const chartiqBottomTabSet = new TabSet(40);

    chartiqBottomTabSet.addChildren([chartiqBottomTab]);

    const rightRow = new Row(40);

    rightRow.addChildren([chartiqTopTabSet, chartiqBottomTabSet]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, leftMiddleRow, rightMiddleRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink, greenLink]);
  };

  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const instrumentLeftTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig()
    );
    const instrumentLeftTabset = new TabSet(50);

    instrumentLeftTabset.addChildren([instrumentLeftTab]);

    const leftRow = new Row(20);

    leftRow.addChildren([instrumentLeftTabset]);

    const chartiqLeftTopTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      CHARTS_WEEK_CHARTIQ_SETTINGS
    );
    const chartiqLeftTopTabSet = new TabSet(40);

    chartiqLeftTopTabSet.addChildren([chartiqLeftTopTab]);

    const chartiqLeftBottomTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      CHARTS_HOUR_CHARTIQ_SETTINGS
    );
    const chartiqLeftBottomTabSet = new TabSet(40);

    chartiqLeftBottomTabSet.addChildren([chartiqLeftBottomTab]);

    const leftMiddleRow = new Row(40);

    leftMiddleRow.addChildren([chartiqLeftTopTabSet, chartiqLeftBottomTabSet]);

    const chartiqRightTopTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const chartiqRightTopTabSet = new TabSet(40);

    chartiqRightTopTabSet.addChildren([chartiqRightTopTab]);

    const chartiqRightBottomLeftTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(undefined, 144262, 'USD'),
      TRADE_CHARTIQ_SETTINGS_NO_VOLUME
    );
    const chartiqRightBottomLeftTabSet = new TabSet(40);

    chartiqRightBottomLeftTabSet.addChildren([chartiqRightBottomLeftTab]);

    const chartiqRightBottomRightTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(undefined, 108897, 'IMOEX'),
      TRADE_CHARTIQ_SETTINGS_NO_VOLUME
    );
    const chartiqRightBottomRightTabSet = new TabSet(40);

    chartiqRightBottomRightTabSet.addChildren([chartiqRightBottomRightTab]);

    const chertiqBottomRow = new Row(40);

    chertiqBottomRow.addChildren([
      chartiqRightBottomLeftTabSet,
      chartiqRightBottomRightTabSet,
    ]);

    const rightRow = new Row(60);

    rightRow.addChildren([chartiqRightTopTabSet, chertiqBottomRow]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftRow, leftMiddleRow, rightRow]);

    return new Layout('Графики', chartRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Среднесрочная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
