/* eslint-disable no-process-env */
import { LogLevelDesc } from 'loglevel';

import { fromStringToBoolean } from './lib/fromStringToBoolean';
import { toRequiredString } from './lib/toRequiredString';

/**
 * Флаг DEV окружения
 */
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';

/**
 * Версия приложения
 */
export const APP_VERSION = toRequiredString(process.env.REACT_APP_VERSION);

/**
 * Хеш коммита билда
 */
export const APP_BUILD_HASH =
  toRequiredString(process.env.REACT_APP_BUILD_HASH) || 'dev';

/**
 * Адрес на котором развернут инстанс, нужен для редиректа из паспорта
 */
export const APP_URI = toRequiredString(process.env.REACT_APP_URI);

// TODO понять почему PUBLIC_URL не равен APP_URI
export const PUBLIC_URL = toRequiredString(process.env.PUBLIC_URL);

/**
 * это PWA
 *
 * @deprecated по возможности не использовать, вместо этого использовать композицию
 */
export const IS_MOBILE_PWA = fromStringToBoolean(
  process.env.REACT_APP_IS_MOBILE
);

/**
 * это Альфа-Инвестиции, а не искусственный интеллект
 *
 * @deprecated по возможности не использовать, вместо этого использовать композицию
 */
export const IS_AI = fromStringToBoolean(process.env.REACT_APP_IS_AI);

/**
 * это Десктоп
 *
 * @deprecated по возможности не использовать, вместо этого использовать композицию
 */
export const IS_DESKTOP = !IS_MOBILE_PWA;

/**
 * это GoInvest
 *
 * @deprecated по возможности не использовать, вместо этого использовать композицию
 */
export const IS_GI = !IS_AI;

/**
 * URL для API мидлов
 */
export const MIDDLE_API_INVEST_URI = toRequiredString(
  process.env.REACT_APP_MIDDLE_INVEST_URI
);

/**
 * URL для API публичных мидлов
 */
export const MIDDLE_API_INVEST_PUBLIC_URI = toRequiredString(
  process.env.REACT_APP_MIDDLE_INVEST_PUBLIC_URI
);

/**
 * Кластер альфа-мобайла, для подключения к чату поддержки
 */
export const MOBILE_CLUSTER_URI = toRequiredString(
  process.env.REACT_APP_MOBILE_URI
);

/**
 * Имя базы в indexed db
 */
export const APP_DB_NAME =
  toRequiredString(process.env.REACT_APP_DB_NAME) || 'trading-terminal';

// ТОРГОВЫЕ СЕРВЕРА

/**
 * Код отправляется при логине в торговые сервисы
 */
export const TRADE_SERVICE_DEVELOPER_CODE = toRequiredString(
  process.env.REACT_APP_DEVELOPER_CODE
);

export const F1_URI = toRequiredString(process.env.REACT_APP_F1_URI);
export const F2_URI = toRequiredString(process.env.REACT_APP_F2_URI);
export const F3_URI = toRequiredString(process.env.REACT_APP_F3_URI);
export const F31_URI = toRequiredString(process.env.REACT_APP_F31_URI);
export const F4_URI = toRequiredString(process.env.REACT_APP_F4_URI);

// ПАСПОРТ

/**
 * Используется ли паспортная авторизация
 */
export const USE_PASSPORT_AUTH = fromStringToBoolean(
  process.env.REACT_APP_USE_PASSPORT_AUTH
);

/**
 * Адрес паспорта
 */
export const PASSPORT_URI = toRequiredString(
  process.env.REACT_APP_PASSPORT_URI
);

/**
 * CLIENT_ID паспорта
 */
export const PASSPORT_CLIENT_ID = toRequiredString(
  process.env.REACT_APP_PASSPORT_CLIENT_ID
);

/**
 * Использовать ли новую схему авторизации через церберус (только для PWA)
 */
export const USE_PASSPORT_CERBERUS_AUTH =
  IS_AI &&
  fromStringToBoolean(process.env.REACT_APP_USE_PASSPORT_CERBERUS_AUTH);

// ЛИЧНЫЙ КАБИНЕТ
/**
 * Включает интеграцию с ЛК
 */
export const ENABLE_LK_FEATURES = fromStringToBoolean(
  process.env.REACT_APP_ENABLE_LK_FEATURES
);
/**
 * Время в секундах до логаута при авторизации через ЛК, так как токен живет 15 минут
 * Проверка отключена, если используется паспорт
 */
export const LK_LOGOUT_TIMEOUT = USE_PASSPORT_AUTH
  ? 0
  : Number(process.env.REACT_APP_LK_LOGOUT_TIMEOUT) || 0;

/**
 * Включает работу с сертификатами (подпись/отзыв) через API ЛК
 */
export const LK_CERTIFICATES = fromStringToBoolean(
  process.env.REACT_APP_LK_CERTIFICATES
);

/**
 * Разрешает денежные выводы
 */
export const ENABLE_LK_MONEY_WITHDRAWAL = fromStringToBoolean(
  process.env.REACT_APP_ENABLE_LK_MONEY_WITHDRAWAL
);

/**
 * Client_Id для кроссавторизации с ЛК
 */
export const LK_CROSSAUTH_CLIENT_ID = toRequiredString(
  process.env.REACT_APP_LK_CROSSAUTH_CLIENT_ID
);

/**
 * Адрес API ЛК
 */
export const LK_URI = toRequiredString(process.env.REACT_APP_LK_URI);

/**
 * Адрес ЛК
 */
export const LK_WEB_URI = toRequiredString(process.env.REACT_APP_LK_WEB_URI);

// АНАЛИТИКА

/**
 * Включает аналитику
 */
export const IS_ANALYTICS_ENABLED = fromStringToBoolean(
  process.env.REACT_APP_ANALYTICS_ENABLED
);

/**
 * Использовать прод аналитику (без суфикса _dev)
 */
export const IS_PROD_ANALYTICS = fromStringToBoolean(
  process.env.REACT_APP_ANALYTICS_PRODUCTION
);

/**
 * APP_ID в аналитике
 */
export const ANALYTICS_APP_ID =
  toRequiredString(process.env.REACT_APP_ANALYTICS_APP_ID) +
  (IS_PROD_ANALYTICS ? '' : '_dev');

/**
 * Хост сервиса аналитики
 */
export const ANALYTICS_BASE_URL = toRequiredString(
  process.env.REACT_APP_ANALYTICS_BASE_URL
);

/**
 * URL на сервис аналитики (отправка событий на этот адрес)
 */
export const ANALYTICS_URL = toRequiredString(
  process.env.REACT_APP_ANALYTICS_URL
);

/**
 * Токен для сервиса аналитики
 */
export const ANALYTICS_TOKEN = toRequiredString(
  process.env.REACT_APP_ANALYTICS_TOKEN
);

// ФИЧИ (не добавляем сюда новых переменных, используем featureFlag)

/**
 * rubbles
 */
export const RUBBLES_ENABLED = fromStringToBoolean(
  process.env.REACT_APP_RUBBLES_ENABLED
);

// ОТЛАДКА

/**
 * Уровень логов
 */
export const DEV_LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL as LogLevelDesc;

/**
 * Включает devtools react-query
 */
export const DEV_IS_DEBUG_REACT_QUERY = fromStringToBoolean(
  process.env.REACT_APP_DEBUG_REACT_QUERY
);

/**
 * Если true в менюшке выбора виджета будут доступны виджеты статистики и сертификатов
 */
export const DEV_APP_DEVTOOLS = fromStringToBoolean(
  process.env.REACT_APP_DEVTOOLS
);

/**
 * Включает девтулзы для стора Zustand
 */
export const USE_DEV_TOOLS_FOR_STORE = fromStringToBoolean(
  process.env.REACT_APP_STORE_DEV_TOOLS
);

/**
 * Cразу логинит под следующими логином и паролем. Не работает с паспортом
 */
export const DEV_APP_AUTOLOGIN = fromStringToBoolean(
  process.env.REACT_APP_AUTOLOGIN
);

/**
 * Юзер при автологине
 */
export const DEV_APP_DEFAULT_USER_LOGIN =
  process.env.REACT_APP_DEFAULT_USER_LOGIN;

/**
 * Пароль при автологине
 */
export const DEV_APP_DEFAULT_USER_PASSWORD =
  process.env.REACT_APP_DEFAULT_USER_PASSWORD;

/**
 * Используется при разработке для проксирования запросов к ручкам
 */
export const USE_FETCH_PROXY = fromStringToBoolean(
  process.env.REACT_APP_USE_FETCH_PROXY
);

/**
 * Токен для remote config
 */
export const REMOTE_CONFIG_TOKEN = atob(
  toRequiredString(process.env.REACT_APP_REMOTE_CONFIG_TOKEN)
);

/**
 * Путь для remote config
 */
export const REMOTE_CONFIG_PATH = toRequiredString(
  process.env.REACT_APP_REMOTE_CONFIG_PATH
);

/**
 * Веб пуши
 */
export const WEB_PUSH = process.env.REACT_APP_WEB_PUSH;

/**
 * API c сайта alfadirect.ru
 */
export const ALFADIRECT_API_URI = toRequiredString(
  process.env.REACT_APP_ALFADIRECT_API_URI
);

/**
 * URL alfadirect.ru
 */
export const ALFADIRECT_URI = toRequiredString(
  process.env.REACT_APP_ALFADIRECT_URI || 'https://www.alfadirect.ru'
);
