import { ChartIndex } from '../model/types';

export const INDICES_CHIPSE = {
  [ChartIndex.IMOEX]: {
    name: 'IMOEX',
    idFi: 108897,
    color: '#3193fc',
    description:
      'Индекс Московской биржи — основной российский фондовый индекс, в который входит 40 акций крупнейших российских компаний, торгующихся на Московской бирже. Является ориентиром для оценки инвестиций в рублях.',
  },
  // Временно скрыто по задаче https://jira.moscow.alfaintra.net/browse/ADIRWEB-4946
  // [ChartIndex.SP500]: {
  //   name: 'S&P500',
  //   idFi: 1987262,
  //   color: '#fda136',
  //   description:
  //     'S&P500 — основной фондовый индекс США. Его называют индексом «широкого рынка», потому что в него входит 500 крупнейших американских компаний из разных отраслей. Рассчитывается в долларах.',
  // },
  // [ChartIndex.NASDAQ]: {
  //   name: 'NASDAQ',
  //   idFi: 1987263,
  //   color: '#6bd3e6',
  //   description:
  //     'NASDAQ — считается фондовым индексом высокотехнологичных и быстрорастущих компаний США, которые торгуются на бирже NASDAQ. Включает 3000 компаний и рассчитывается в долларах США.',
  // },
  [ChartIndex.GOLD]: {
    name: 'GOLD',
    idFi: 1992700,
    color: '#fc617c',
    description:
      'Цены золота на Лондонской бирже являются ориентиром (эталоном) для инвесторов и центральных банков по всему миру. Цены устанавливаются в долларах за тройскую унцию.',
  },
};
