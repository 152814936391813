import {
  addDays,
  format,
  isBefore,
  isSameDay,
  parse,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import {
  DocumentDepoAccount,
  DocumentReceivingType,
  DocumentTheme,
  ReportTheme,
  SignType,
} from '@terminal/lk-core';

export const useDocumentOrderForm = (
  theme: string,
  documentsTheme?: (DocumentTheme & SignType)[] | (ReportTheme & SignType)[],
  depoAccounts?: DocumentDepoAccount[]
) => {
  const defaultStartDate = format(
    startOfMonth(addDays(new Date(), -1)),
    DEFAULT_VIEW_DATE_FORMAT
  );
  const defaultEndDate = format(
    addDays(new Date(), -1),
    DEFAULT_VIEW_DATE_FORMAT
  );

  const [periodStart, setPeriodStart] = useState<string>(defaultStartDate);
  const [periodEnd, setPeriodEnd] = useState<string>(defaultEndDate);
  const [year, setYear] = useState<string>('');
  const [selectedDepoAccount, setSelectedDepoAccount] = useState<string>('');
  const [obtaining, setObtaining] = useState<DocumentReceivingType>(
    DocumentReceivingType.Online
  );
  const [city, setCity] = useState<string>('');
  const [office, setOffice] = useState<string>('');
  const [count, setCount] = useState<string>('1');
  const [comment, setComment] = useState<string>('');
  const [depoTouched, setDepoTouched] = useState(false);

  const selectedDocumentTheme = useMemo<DocumentTheme | undefined>(() => {
    if (documentsTheme && theme) {
      const selected = documentsTheme.filter(
        (doc) => doc.id.toString() === theme
      );

      return selected.length > 0 ? selected[0] : undefined;
    }

    return undefined;
  }, [documentsTheme, theme]);

  const depoaccOptions = useMemo(() => {
    if (depoAccounts) {
      return depoAccounts.map((account) => ({
        key: account.depoAccount,
        content: `${account.depoAccount}, ${account.placeName}`,
      }));
    }

    return [];
  }, [depoAccounts]);

  useEffect(() => {
    if (selectedDocumentTheme?.startDate) {
      const themeStartDate = parseISO(selectedDocumentTheme.startDate);

      const effectiveStartDate = isBefore(
        themeStartDate,
        parse(defaultStartDate, DEFAULT_VIEW_DATE_FORMAT, new Date())
      )
        ? defaultStartDate
        : format(themeStartDate, DEFAULT_VIEW_DATE_FORMAT);

      setPeriodStart(effectiveStartDate);
    }

    const selectedDocumentThemeEndDate =
      selectedDocumentTheme?.endDate && parseISO(selectedDocumentTheme.endDate);

    if (
      selectedDocumentTheme?.dateType === 'period' &&
      selectedDocumentThemeEndDate &&
      isSameDay(addDays(selectedDocumentThemeEndDate, -1), new Date())
    ) {
      setPeriodEnd(format(new Date(), DEFAULT_VIEW_DATE_FORMAT));
    }
  }, [selectedDocumentTheme, defaultStartDate]);

  useEffect(() => {
    if (depoAccounts && depoTouched) {
      const filtered = depoAccounts.filter(
        (acc) => acc.depoAccount === selectedDepoAccount
      );
      const depoAccountsList = filtered.length > 0 ? filtered : depoAccounts;

      setSelectedDepoAccount(depoAccountsList[0].depoAccount);
    }
  }, [depoAccounts, depoTouched, selectedDepoAccount]);

  return {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    year,
    setYear,
    selectedDepoAccount,
    setSelectedDepoAccount,
    obtaining,
    setObtaining,
    city,
    setCity,
    office,
    setOffice,
    count,
    setCount,
    comment,
    setComment,
    depoTouched,
    setDepoTouched,
    selectedDocumentTheme: selectedDocumentTheme as
      | ((DocumentTheme | ReportTheme) & SignType)
      | undefined,
    depoaccOptions,
  };
};
