import { orderTypeAbbrMap } from '@terminal/core/constants/orders';
import { DetailedOrderParams } from '@terminal/core/lib/analytics/model/order';
import { QuantityType } from '@terminal/core/lib/client/entities';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { SendOrderParams } from '@terminal/core/types/trading';

import { OrderSource } from '../const';

export const mapOrderParamsToAnalytics = (
  source: OrderSource,
  params: SendOrderParams,
  fullFi: ExtendedFI,
  sum?: number,
  errorText?: string
): DetailedOrderParams => {
  const {
    buy,
    idOrderType,
    quantityType,
    linkedOrderId,
    idLifeTime,
    idActivationTime,
    idFIActivate,
    priceControlType,
    activationPriceDirection,
    openQuantity,
    bracket,
  } = params;

  let idQuantityType = quantityType ?? QuantityType.QTY;

  if (linkedOrderId) {
    idQuantityType = QuantityType.OTO;
  }

  const getAdditionalInstructions = () => {
    if (idFIActivate || priceControlType || activationPriceDirection) {
      return 'Если выполнится условие';
    } else if (linkedOrderId) {
      return 'Связанная заявка';
    } else if (openQuantity) {
      return 'Айсберг';
    } else if (bracket?.idOrderType) {
      return 'Стоп лосс и Тейк профит';
    } else {
      return;
    }
  };

  return {
    orderDirection: buy ? 'Buy' : 'Sell',
    orderSource: source,
    ticker: fullFi.symbolObject,
    productTitle: fullFi.nameObject,
    productGroup: fullFi.idObjectType.name,
    orderType: orderTypeAbbrMap[idOrderType],
    orderSum: sum ?? 0,
    quantityType: idQuantityType,
    activationDate: idActivationTime,
    maturityDate: idLifeTime,
    additionalInstructions: getAdditionalInstructions(),
    isin: fullFi.isin,
    errorText,
  };
};
