import cn from 'classnames';
import range from 'lodash/range';
import { useCallback, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { Spinner } from '@terminal/common/components/Spinner';
import {
  convertPostfixToChar,
  getRoundedBigSum,
} from '@terminal/core/lib/format';
import { IssuerFinancials } from '@terminal/core/types/issuerFinancials';

import styles from './Chart.module.css';

export interface ChartProps {
  hasDimesion?: boolean;
  data: IssuerFinancials;
}

const margin = {
  top: 5,
  right: 0,
  left: 0,
  bottom: 5,
};

const CustomYAxisTick = ({
  y,
  payload,
}: {
  y?: number;
  payload?: { value: number };
}) => {
  const [roundedValue, valuePostfix] = getRoundedBigSum(
    payload?.value ? payload.value * 1000000 : 0n
  );

  return (
    <g transform={`translate(${0},${(y || 10) + 3})`}>
      <text x={0} y={0} textAnchor="start" fill="#666">
        {roundedValue} {convertPostfixToChar(valuePostfix) || ''}
      </text>
    </g>
  );
};

export const Chart = ({ data, hasDimesion = true }: ChartProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [dimension, setDimension] = useState<'year' | 'quarter'>('year');
  const getYears = (name: string) =>
    data.historicalInfo.find((item) => item.name === name)?.years || [];
  const getLastYear = (name: string) =>
    Math.max(...getYears(name).map((item) => item.year));
  const getHasHistory = (name: string) =>
    data.historicalInfo.some((item) => item.name === name);
  const getChartData = (name: string, year: number, quarter?: number) =>
    (getYears(name).find((item) => item.year === year)?.periods || []).find(
      (item) => {
        if (quarter) {
          return (
            item.fiscalPeriod.periodType === 'Interim' &&
            item.fiscalPeriod.quarter === quarter
          );
        }

        return item.fiscalPeriod.periodType === 'Annual';
      }
    )?.value || 0;
  const hasRevenue = getHasHistory('Revenue');
  const hasProfit = getHasHistory('Net_Income');

  const handleContainerRef = useCallback((container: HTMLDivElement | null) => {
    setContainerWidth(container?.clientWidth || 0);
  }, []);

  if (!hasRevenue && !hasProfit) {
    return null;
  }

  const lastYear = Math.max(
    hasRevenue ? getLastYear('Revenue') : 0,
    hasProfit ? getLastYear('Net_Income') : 0
  );

  return (
    <>
      {hasDimesion && (
        <div className={styles.header}>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
            className={styles.title}
          >
            Выручка и прибыль
          </Typography.TitleMobile>
          <Button
            onClick={() =>
              setDimension(dimension === 'quarter' ? 'year' : 'quarter')
            }
            view="ghost"
          >
            <Typography.TitleMobile
              view="xsmall"
              tag="div"
              weight="bold"
              className={cn(styles.title, styles.link)}
            >
              По {dimension === 'quarter' ? 'кварталам' : 'годам'}
            </Typography.TitleMobile>
          </Button>
        </div>
      )}
      <div ref={handleContainerRef} className={styles.chart}>
        {!containerWidth ? (
          <Spinner size="36" color="var(--text-color-secondary)" />
        ) : (
          <BarChart
            width={containerWidth}
            height={300}
            data={range(4, -1).map((index) => {
              const year =
                dimension === 'year'
                  ? lastYear - index
                  : lastYear - (index > 3 ? 1 : 0);
              const quarter =
                dimension === 'quarter'
                  ? 4 - (index > 3 ? 0 : index)
                  : undefined;

              return {
                name: dimension === 'year' ? year : `${year}, ${quarter} кв`,
                rv: getChartData('Revenue', year, quarter),
                pr: getChartData('Net_Income', year, quarter),
              };
            })}
            margin={margin}
          >
            <CartesianGrid strokeDasharray="1" vertical={false} />
            <XAxis axisLine={false} tickLine={false} dataKey="name" />
            <YAxis
              tick={<CustomYAxisTick />}
              axisLine={false}
              tickLine={false}
            />
            <Legend iconSize={5} />
            <Bar
              legendType={hasRevenue ? 'circle' : 'none'}
              dataKey="rv"
              name="Выручка"
              fill="#7938E0"
            />
            <Bar
              legendType={hasProfit ? 'circle' : 'none'}
              dataKey="pr"
              name="Прибыль"
              fill="#CB830F"
            />
          </BarChart>
        )}
      </div>
    </>
  );
};
