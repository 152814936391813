import { FC, useRef } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChartRingMIcon } from '@alfalab/icons-glyph/ChartRingMIcon';

import {
  Feature,
  FeatureOnboardingPopover,
  useOneTimeShowing,
} from '../FeatureOnboarding';

import styles from './PortfolioAnalyticsButton.module.css';

interface Props {
  onClick: () => void;
}

export const PortfolioAnalyticsButton: FC<Props> = ({ onClick }) => {
  const portfolioAnalyticsButtonRef = useRef<HTMLButtonElement>(null);

  const { isVisible, onClose } = useOneTimeShowing(Feature.PORTFOLIO_ANALYTICS);

  return (
    <>
      <IconButton
        ref={portfolioAnalyticsButtonRef}
        size="xs"
        view="secondary"
        icon={ChartRingMIcon}
        style={
          isVisible
            ? {
                color: 'var(--color-light-text-primary)',
              }
            : {}
        }
        onClick={onClick}
      />

      <FeatureOnboardingPopover
        isOpen={isVisible}
        anchorElement={portfolioAnalyticsButtonRef.current}
        onClose={onClose}
        className={styles.popover}
      >
        <Typography.Text
          tag="div"
          view="primary-small"
          color="primary"
          weight="bold"
        >
          Доступна новая аналитика портфеля
        </Typography.Text>

        <Gap size="xs" />

        <Typography.Text tag="div" view="secondary-large" color="primary">
          Теперь можно посмотреть детальные
          <br />
          отчеты о составе, доходах и расходах
          <br />
          портфеля или конкретного инструмента.
        </Typography.Text>

        <Gap size="m" />

        <ButtonDesktop
          size="xs"
          view="primary"
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          Открыть
        </ButtonDesktop>

        <Gap size="2xs" />
      </FeatureOnboardingPopover>
    </>
  );
};
