import { VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import { Result } from '@terminal/lk/shared';

import styles from './OrderFormApplied.module.css';

interface FormAppliedProps {
  title?: string;
  caption: string;
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
}

export const OrderFormApplied: VFC<FormAppliedProps> = ({
  title = 'Документ успешно заказан',
  caption,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => (
  <Result
    title={title}
    text={caption}
    icon={
      <SuperEllipse size={48}>
        <CheckmarkMIcon />
      </SuperEllipse>
    }
    maxWidth={360}
  >
    <div className={styles.buttonGroup}>
      <ButtonDesktop
        size="xs"
        view="accent"
        onClick={onPrimaryButtonClick}
        className={styles.actionButton}
      >
        {primaryButtonLabel}
      </ButtonDesktop>
      <ButtonDesktop
        size="xs"
        view="tertiary"
        onClick={onSecondaryButtonClick}
        className={styles.actionButton}
      >
        {secondaryButtonLabel}
      </ButtonDesktop>
    </div>
  </Result>
);
