import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import groupBy from 'lodash/groupBy';
import { useCallback, useState } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { DocumentsHistoryItem } from '@terminal/lk-core';

import { HistoryItem } from '../HistoryItem';
import { HistoryItemDetails } from '../HistoryItemDetails';

import styles from './HistoryList.module.css';

interface HistoryListProps {
  history: DocumentsHistoryItem[] | null;
  treaty: number;
  emailConfirmed?: boolean;
}

export const HistoryList = ({
  history,
  treaty,
  emailConfirmed,
}: HistoryListProps) => {
  const [selectedItem, setSelectedItem] = useState<DocumentsHistoryItem>();

  const dateGroups = groupBy(history, (item) => {
    return format(parseISO(item.docDate), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  const handleDetailsOpen = (item: DocumentsHistoryItem) =>
    setSelectedItem(item);

  const handleDetailsClose = () => setSelectedItem(undefined);

  return (
    <div className={styles.container}>
      {Object.keys(dateGroups).map((key) => (
        <div className={styles.group} key={key}>
          <div className={styles.groupTitle}>{getKeyTitle(key)}</div>
          {dateGroups[key].map((item, index) => (
            <HistoryItem
              key={`document-history-item-${item.docDate}-${index}`}
              item={item}
              treaty={treaty}
              odd={(index + 1) % 2 !== 0}
              onClick={handleDetailsOpen}
            />
          ))}
        </div>
      ))}

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={Boolean(selectedItem)}
          backLabel="История"
          onClose={handleDetailsClose}
        >
          <HistoryItemDetails
            item={selectedItem}
            onClose={handleDetailsClose}
            emailConfirmed={emailConfirmed}
            treaty={treaty}
          />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
