import { useMemo } from 'react';
import { ArrowDownLineDownMIcon } from '@alfalab/icons-glyph/ArrowDownLineDownMIcon';

import { PortfolioAnalyticsResult } from '@terminal/core/types/account';

import { mapOperationChapterKeyToName } from '../lib/mapOperationChapterKeyToName';
import { OperationChapterType } from '../model/FinResult';
import { InputOutputTableRow } from '../model/FinResultTable';

export const useInputsOutputsTable = (
  analytics?: PortfolioAnalyticsResult
): InputOutputTableRow[] => {
  return useMemo(() => {
    if (!analytics) {
      return [];
    }

    return [
      {
        key: 'inputs' as OperationChapterType,
        Icon: ArrowDownLineDownMIcon,
        name: mapOperationChapterKeyToName('inputs'),
        income: analytics.inputs?.value || 0,
        currency: analytics.inputs?.currency || 'RUR',
        byCurrency: {
          all:
            analytics?.moneyInputsTotal?.value ?? analytics?.inputs?.value ?? 0,
          rub: analytics?.moneyInputsRUB?.value ?? 0,
          usd: analytics?.moneyInputsUSD?.value ?? 0,
          eur: analytics?.moneyInputsEUR?.value ?? 0,
          cny: analytics?.moneyInputsCNY?.value ?? 0,
          other: analytics?.moneyInputsOther?.value ?? 0,
          papers: analytics?.inputsPapers?.value ?? 0,
        },
      },
      {
        key: 'outputs' as OperationChapterType,
        Icon: ArrowDownLineDownMIcon,
        name: mapOperationChapterKeyToName('outputs'),
        income: analytics.outputs?.value || 0,
        currency: analytics.outputs?.currency || 'RUR',
        byCurrency: {
          all: analytics?.outputs?.value ?? 0,
          rub: analytics?.moneyOutputsRUB?.value ?? 0,
          usd: analytics?.moneyOutputsUSD?.value ?? 0,
          eur: analytics?.moneyOutputsEUR?.value ?? 0,
          cny: analytics?.moneyOutputsCNY?.value ?? 0,
          other: analytics?.moneyOutputsOther?.value ?? 0,
          papers: analytics?.outputsPapers?.value ?? 0,
        },
      },
    ].filter((row) => row.income !== 0);
  }, [analytics]);
};
