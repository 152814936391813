import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetMultiplyReportsStatusDTO } from './types/GetMultiplyReportsStatusDTO';
import { GetMultiplyReportsStatusParams } from './types/GetMultiplyReportsStatusParams';

export async function getMultiplyReportsStatus({
  id,
}: GetMultiplyReportsStatusParams): Promise<GetMultiplyReportsStatusDTO> {
  const result = await fetchLkResult<GetMultiplyReportsStatusDTO & LKResult>(
    `/api/reports/multiple/status/${id}`
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
