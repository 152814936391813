import { IS_AI, LK_URI, LK_WEB_URI } from '@terminal/core/env';
import { normalizeLkAPIURIS } from '@terminal/core/lib/rest/normalizeLkAPIURI';

export const LK_PAGES = {
  CONTACTS: `${LK_URI}/settings/contacts`,
  TESTING: `${LK_URI}/testing`,
};

export const LK_API = normalizeLkAPIURIS({
  REQUISITES_BROKER_PDF: `${LK_URI}/api/requisites/broker/pdf`,
  REQUISITES_BROKER_QR: `${LK_URI}/api/requisites/broker/qr`,
  REQUISITES_DEPO_PDF: `${LK_URI}/api/requisites/depo/pdf`,

  MONEY_HISTORY_ECXEL: `${LK_URI}/api/transfers/money/history/excel`,
  MONEY_DEPOSIT_COMPLETE: `${LK_WEB_URI}/api/transfers/money/deposit/complete`,

  DOCUMENT_ACCOUNT_BROKER: `${LK_URI}/api/client/documents/broker`,
  DOCUMENT_ACCOUNT_DEPO: `${LK_URI}/api/client/documents/depo`,
  DOCUMENT_SCAN: `${LK_URI}/api/client/documents/scan`,

  REPORT_FILE_BASE: `${LK_URI}/api/reports`,
  REPORTS_MULTIPLY_DOWNLOAD_BASE: `${LK_URI}/api/reports/multiple/download`,

  SECURITIES_INSTRUCTION_REQUISITES: `${LK_URI}/api/files/public/b9c8a0cb-3309-4bd0-8fec-1c9d116dec33/Реквизиты GoInvest.pdf`,
  SECURITIES_INSTRUCTION_DOCUMENTS: `${LK_URI}/api/files/public/f4909554-44b5-474f-a093-6087ad804a78/Расходные документы.pdf`,
  SECURITIES_INSTRUCTION_TRANSFER: `${LK_URI}/api/files/public/d2ac8322-14e9-4e1f-9953-309b878180b9/Как зачислить ценные бумаги.pdf`,
  SECURITIES_HISTORY_DETAIL_FILE_BASE: `${LK_URI}/api/v2/transfers/securities/history`,
  SECURITIES_HISTORY_DETAIL_BASE: `${LK_URI}/api/v2/transfers/securities/history/details/pdf`,

  FATCA_PDF_BASE: `${LK_URI}/api/client/documents/fatca`, // К url нужно добавить `/{treaty}/fatca.pdf`
});

export const EXTERNAL_LINKS = IS_AI
  ? {
      ABOUT_TARIFF: 'https://alfabank.ru/make-money/investments/tariffs/',
      REQUIRED_SECURITY:
        'https://alfabank.ru/make-money/investments/help/trebuemoe-obespechenie/',
      OFICES: 'https://alfabank.ru/office/',
      TAX_STATUS: 'https://www.alfadirect.ru/faq#Ty4MMC6Rh6zcTcsIxKvTG-9.9',

      MARGINAL_ABOUT:
        'https://www.alfadirect.ru/faq/trebuemoe-obespechenie#qwTUUM_4s9NQb9wKHPcck',

      SECURITIES_DEPO_TARIFFS: 'https://www.alfadirect.ru/o-kompanii/documenty',
      FATCA_ABOUT: '',
    }
  : {
      ABOUT_TARIFF: '#',
      REQUIRED_SECURITY: 'http://goinvest.ru/margintrading',
      OFICES: '#',
      TAX_STATUS: '#',

      MARGINAL_ABOUT: '#',

      SECURITIES_DEPO_TARIFFS:
        'https://cdncl.goinvest.ru/files/docs/20-tarify_go.pdf',
      FATCA_ABOUT: '',
    };

export const OPEN_ACCOUNT =
  'https://alfabank.ru/make-money/investments/anketa/terminal/?utm_source=trade&utm_medium=referral&utm_campaign=auth&platformId=trade_referral_auth';
