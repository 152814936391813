import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { OrderDocumentsParams as OrderDocumentsRequest } from '../model/OrderDocumentsParams';

export interface OrderDocumentsParams {
  params: OrderDocumentsRequest;
  signOptions?: LkSignOptions;
}

export async function orderDocuments(data: OrderDocumentsParams) {
  return await signOperation(
    '/api/client/documents/order-create',
    'POST',
    data.params,
    data.signOptions
  );
}
