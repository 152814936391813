import { useQuery } from 'react-query';

import { getTransferableAccounts } from '@terminal/core/lib/rest/investApi';

export const useTransferableAccounts = () => {
  return useQuery({
    queryKey: ['lk', 'transfer', 'transferableAccounts'],
    queryFn: getTransferableAccounts,
  });
};
