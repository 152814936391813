import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getCloudConfig } from '../api/getCloudConfig';

export function useGetCloudConfigMutation() {
  const addNotification = useNotification();

  return useMutation({
    mutationFn: getCloudConfig,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При запросе конфигурации произошла ошибка',
      });
    },
  });
}
