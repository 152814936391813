import { WidgetsDefaultConfigMap } from '@terminal/core/constants/Layout';
import { LinkName, Widget } from '@terminal/core/types/layout';

import { ConfigTemplate } from '../../../../index';
import {
  getBalancesOperationsConfig,
  getBaseChartConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import { getWatchListConfig, TRADE_CHARTIQ } from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

// Веса для боковых панелей, центральная панель занимает оставшееся место из 100
type Options = {
  tradeLeftWeight: number;
  tradeRightWeight: number;
  newsLeftWeight: number;
  newsRightWeight: number;
};

export const createBeginnerConfig = (options: Options) => {
  const { tradeLeftWeight, tradeRightWeight, newsLeftWeight, newsRightWeight } =
    options;

  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const middleWeight = 100 - tradeLeftWeight - tradeRightWeight;
    const leftRow = new Row(tradeLeftWeight);
    const middleRow = new Row(middleWeight);
    const rightRow = new Row(tradeRightWeight);

    // Список инструментов слева
    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getWatchListConfig(blueLink.name)
    );

    const instrumentsTabSet = new TabSet(50);

    instrumentsTabSet.addChildren([leftInstrumentsTab]);

    leftRow.addChildren([instrumentsTabSet]);

    // Чарт сверху посередине
    const middleChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_CHARTIQ
    );
    const middleChartTabSet = new TabSet(66);

    middleChartTabSet.addChildren([middleChartTab]);

    // Инфо под чартом
    const middleInfoTabSet = new TabSet(33);
    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const operationsTab = new Tab(
      Widget.OPERATIONS,
      getBalancesOperationsConfig(blueLink.name)
    );
    const ordersTab = new Tab(
      Widget.ORDERS,
      getCommonOrdersConfig(blueLink.name)
    );

    middleInfoTabSet.addChildren([positionsTab, ordersTab, operationsTab]);

    middleRow.addChildren([middleChartTabSet, middleInfoTabSet]);

    // Стакан справа
    const orderBookTab = new Tab(Widget.ORDERBOOK, {
      ...getTradeOrderbookConfig(blueLink.name),
      hideForm: true,
    });
    const orderBookTabSet = new TabSet(10);

    orderBookTabSet.addChildren([orderBookTab]);

    rightRow.addChildren([orderBookTabSet]);

    // Итог
    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, middleRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink]);
  };
  /**
   * Возвращает layout Подборки и новости
   * */
  const makeNewsLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const middleWeight = 100 - newsLeftWeight - newsRightWeight;
    const leftRow = new Row(newsLeftWeight);
    const middleRow = new Row(middleWeight);
    const rightRow = new Row(newsRightWeight);

    // Список инструментов слева
    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getWatchListConfig(blueLink.name)
    );

    const instrumentsTabSet = new TabSet(100);

    instrumentsTabSet.addChildren([leftInstrumentsTab]);

    leftRow.addChildren([instrumentsTabSet]);

    // Чарт посередине
    const middleChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_CHARTIQ
    );
    const middleChartTabSet = new TabSet(100);

    middleChartTabSet.addChildren([middleChartTab]);

    middleRow.addChildren([middleChartTabSet]);

    // Стакан справа
    const newsTab = new Tab(Widget.NEWS, {
      ...WidgetsDefaultConfigMap.get(Widget.NEWS),
      link: blueLink.name,
    });
    const newsTabSet = new TabSet(10);

    newsTabSet.addChildren([newsTab]);

    rightRow.addChildren([newsTabSet]);

    // Итог
    const newsRow = new Row(100);

    newsRow.addChildren([leftRow, middleRow, rightRow]);

    return new Layout('Подборки и новости', newsRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Базовая конфигурация');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeNewsLayout());

  return configuration as unknown as ConfigTemplate;
};
