import { isPast } from 'date-fns';

import { APP_URI, LK_CROSSAUTH_CLIENT_ID, LK_URI } from '../../env';
import { makeGetParams } from '../url';
import { fetchRetry } from './helpers/fetchRetry';
import { getRefreshToken } from './investApi';

interface CrossauthTokens {
  access: string;
  refresh: string;
  expires: number;
}

/**
 * Класс получения токенов кроссавторизации с ЛК.
 * Чтобы иметь возможность запрашивать api ЛК после авторизации в паспорте, необходимо пройти кроссавторизацию
 * и получить токены доступа для приложения 'adirect-web'.
 *
 * Для этого:
 *
 * 1. Получить код кроссавторизации, используя 'refresh_token' с помощью прокси-метода ЛК:
 * [GET] /api/passport/crossauthbyrefresh?refresh_token=refresh_token&redirect_uri=redirect_uri&crossauth_client_id=crossauth_client_id, где
 *     refresh_token - рефреш-токен, полученный после авторизации в паспорте;
 *     redirect_uri - абсолютный URL-адрес, на который произойдет переадресация после успешной кроссавторизации;
 *     crossauth_client_id - идентификато клиента паспорта, в который требуется кроссавторизоваться ('adirect-web').
 *
 * 2. Получить токены доступа. Для этого отправить 'code', полученный на 1-м шаге в метод ЛК:
 * [GET] /api/passport/token?code=code
 * В ответе будет JSON, содержащий: 'access_token', 'refresh_token', 'expires_in'
 *
 * Полученный access_token нужно добавлять как header в запросы к api ЛК.
 * expires_in - указывает время его жизни.
 *
 * По наступлению expires_in, нужно обновить токены доступа, используя refresh_token:
 * [GET] /api/passport/token?refresh_token=refresh_token
 *
 */
export class LKCrossauthTokens {
  private tokensPromise?: Promise<CrossauthTokens>;

  public async refreshTokens(): Promise<CrossauthTokens> {
    return this.refreshWebTokens();
  }

  public async getTokens() {
    if (!this.tokensPromise) {
      this.tokensPromise = this.getAdirwebTokens();
    }

    return this.tokensPromise.then((tokens) =>
      isPast(tokens.expires)
        ? this.requestTokens({ refreshToken: tokens.refresh })
        : tokens
    );
  }

  private async parseTokenResponse(res: Response): Promise<CrossauthTokens> {
    if (res.ok) {
      try {
        const tokens = await res.json();

        return {
          access: tokens.access_token,
          refresh: tokens.refresh_token,
          expires: tokens.expires_in,
        };
      } catch (error) {
        throw error;
      }
    } else {
      this.tokensPromise = undefined;

      throw new Error('Ошибка получения токенов ЛК');
    }
  }

  private async requestCrossauthCode(): Promise<string> {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      throw new Error('Ошибка кроссавторизации');
    }

    const redirectUri = APP_URI;
    const crossauthClientId = LK_CROSSAUTH_CLIENT_ID;
    const data = {
      refresh_token: refreshToken,
      redirect_uri: redirectUri,
      crossauth_client_id: crossauthClientId,
    };
    const request = makeGetParams(data);
    const url = `/api/passport/crossauthbyrefresh?${request}`;

    const response = await fetchRetry(3, LK_URI + url, {
      headers: { 'Cache-Control': 'no-cache' },
    });

    if (!response.ok) {
      throw new Error('Ошибка запроса кроссавторизации в ЛК');
    }

    let code: string | null = null;

    // Сейчас возможны 2 варианта:
    // 1. Ответ редиректом, тогда code содержится в url редиректа - А-инвестиции
    // 2. Обычный ответ json - GoInvest
    // TODO: В будущем должен остаться только вариант с json,
    // тогда обработку редиректа можно будет выпилить
    if (response.redirected) {
      const redirectUrl = response.url;
      const redirectUrlObj = new URL(redirectUrl);

      code = redirectUrlObj.searchParams.get('code');
    } else {
      try {
        const result = await response.json();

        code = result.code;
      } catch (error) {
        throw error;
      }
    }

    if (!code) {
      throw new Error('Ошибка запроса кроссавторизации в ЛК');
    }

    return code;
  }

  private async requestTokens(params: {
    code?: string;
    refreshToken?: string;
  }): Promise<CrossauthTokens> {
    if (!params.code && !params.refreshToken) {
      throw new Error('Не заданы параметры обмена токенов ЛК');
    }

    const request = makeGetParams({
      code: params.code,
      refresh_token: params.refreshToken,
    });

    const url = `/api/passport/token?${request}`;
    const response = await fetchRetry(3, LK_URI + url);

    return await this.parseTokenResponse(response);
  }

  private async getAdirwebTokens(): Promise<CrossauthTokens> {
    const code = await this.requestCrossauthCode();
    const tokens = await this.requestTokens({ code });

    return tokens;
  }

  private async refreshWebTokens(): Promise<CrossauthTokens> {
    const tokens = await this.getTokens();

    if (tokens.refresh) {
      this.tokensPromise = this.requestTokens({ refreshToken: tokens.refresh });

      return this.tokensPromise;
    } else {
      this.tokensPromise = undefined;

      return this.getTokens();
    }
  }
}
