import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';

import { BalanceChartPoint } from '../../model/types';

import styles from './BalanceChartTooltip.module.css';

interface Props {
  active?: boolean;
  payload?: [{ payload: BalanceChartPoint }];
}

export const BalanceChartTooltip = ({ active, payload }: Props) => {
  if (!active || !payload?.length) {
    return null;
  }

  const price = payload[0].payload.balance;
  const date = payload[0].payload.date;
  const symbol = payload[0].payload.symbol;
  const decimalsNumber = countDecimals(price);
  const minority = Math.pow(10, decimalsNumber);

  const formatted = formatAmount({
    value: price * minority,
    currency: symbol,
    minority,
    view: 'withZeroMinorPart',
  }).formattedWithCurrency;

  return (
    <div className={styles.popover}>
      <Typography.Text
        view="secondary-small"
        weight="bold"
        className={styles.quaternary}
      >
        {formatted}
      </Typography.Text>
      <Gap size="2xs" />
      <Typography.Text
        view="secondary-small"
        weight="medium"
        className={styles.tertiary}
      >
        {format(date, 'dd MMMM yyyy', { locale: ru })}
      </Typography.Text>
    </div>
  );
};
