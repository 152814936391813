import { useCallback } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

import {
  ClientCancelOrderRsp,
  ClientEnterOrderRsp,
} from '../../client/entities';

import { NewOrderType, OrderItem } from '../../../types/order';
import { SendOrderParams } from '../../../types/trading';

export type SendOrderFn = (params: SendOrderParams) => void;
export type ReplaceOrderFn = (
  idFi: number,
  prevOrder: OrderItem,
  newOrder: NewOrderType
) => void;
export type CancelOrderFn = (order: OrderItem) => void;

export const useTradingService = (
  onError: (message: string, params?: SendOrderParams) => void,
  onResponse?: (
    result: ClientEnterOrderRsp | ClientCancelOrderRsp[] | undefined,
    params?: SendOrderParams
  ) => void
) => {
  const { TradingService: tradingService } = useAlfaDirectContext();

  const sendOrder = useCallback(
    (params: SendOrderParams) => {
      tradingService.sendOrder(params).then(
        (response) => onResponse?.(response, params),
        (err) => onError(err?.message, params)
      );
    },
    [tradingService, onError, onResponse]
  );

  const replaceOrder = useCallback(
    (idFi: number, prevOrder: OrderItem, newOrder: NewOrderType) => {
      tradingService
        .replaceOrder(idFi, prevOrder, newOrder)
        .then(onResponse, (err) => onError(err?.message));
    },
    [tradingService, onError, onResponse]
  );

  const cancelOrder = useCallback(
    (order: OrderItem) => {
      tradingService
        .cancelOrder(order)
        .then(onResponse, (err) => onError(err?.message));
    },
    [tradingService, onError, onResponse]
  );

  return {
    sendOrder,
    replaceOrder,
    cancelOrder,
  };
};
