import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { NEW_USERS_DISABLED_ONBOARDING_FEATURES } from '../../lib/const';

import { Feature } from '../types';

interface FeatureOnboardingStore {
  features: Partial<Record<Feature, boolean>>;
}

interface FeatureOnboardingStoreActions {
  setFeatureViewed: (feature: Feature) => void;
  disableOnboarding: () => void;
}

const STORAGE_KEY = 'feature_onboarding';

export const useFeatureOnboarding = createWithEqualityFn<
  FeatureOnboardingStore & FeatureOnboardingStoreActions
>()(
  persist(
    (set, get) => ({
      features: {},
      setFeatureViewed: (feature: Feature) => {
        set({
          features: {
            ...get().features,
            [feature]: true,
          },
        });
      },
      disableOnboarding: () => {
        set({
          features: {
            ...get().features,
            ...NEW_USERS_DISABLED_ONBOARDING_FEATURES.reduce(
              (acc, feature) => ({
                ...acc,
                [feature]: true,
              }),
              {}
            ),
          },
        });
      },
    }),
    {
      name: STORAGE_KEY,
      getStorage: () => localStorage,
    }
  ),
  shallow
);
