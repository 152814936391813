import { FC } from 'react';

import { ObjectTypeCategory } from '@terminal/core/types/objectTypes';

import { InstrumentCoupons } from './InstrumentCoupons';
import { InstrumentDividends } from './InstrumentDividends';

import { useInstrumentInfo } from '../../hooks/useInstrumentInfo';

export const InstrumentEventsTab: FC<{ idFi: number }> = ({ idFi }) => {
  const { fullFI, objectTypeCategory, finInfoExt, isin } =
    useInstrumentInfo(idFi);
  const isBond = objectTypeCategory === ObjectTypeCategory.Bond;

  return (
    <div>
      {isBond ? (
        <InstrumentCoupons
          isin={isin}
          finInfoExt={finInfoExt}
          fullFI={fullFI}
        />
      ) : (
        <InstrumentDividends
          isin={isin}
          idFi={fullFI.idFI}
          idObject={fullFI.idObject}
        />
      )}
    </div>
  );
};
