import { GetState } from 'zustand';

import { RootStore, StoreSet } from '../useStore';

import { UserCredentials } from '../../types/user';

export interface UserSlice {
  user: UserCredentials | null;
  setUser: (user: UserCredentials) => void;
  credentials: UserCredentials | null;
  setCredentials: (credentials: UserCredentials) => void;
  isUserWithoutBrokerageAccount: boolean;
  setUserWithoutBrokerageAccount: (param: boolean) => void;
}

export const createUserSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): UserSlice => ({
  user: null,
  setUser: (user: UserCredentials) =>
    set((state) => {
      // eslint-disable-next-line no-restricted-syntax
      state.user = user;
    }),
  credentials: null,
  isUserWithoutBrokerageAccount: false,
  setCredentials: (credentials: UserCredentials) =>
    set((state) => {
      state.credentials = credentials;
    }),
  setUserWithoutBrokerageAccount: (param: boolean) =>
    set((state) => {
      state.isUserWithoutBrokerageAccount = param;
    }),
});
