import React from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import {
  DailyLimits,
  MoneyAmountInput,
  MoneyAmountInputProps,
} from '@terminal/lk-desktop-common';

import styles from './MoneyInCards.module.css';

interface Props {
  amount: number | null;
  amountMinority: number;
  onAmountChange: MoneyAmountInputProps['onChange'];
  amountError: boolean;
}

export const MoneyInCardsParameters = ({
  amount,
  amountMinority,
  onAmountChange,
  amountError,
}: Props) => (
  <div>
    <Space fullWidth={true} direction="vertical">
      <Row gutter={16}>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <MoneyAmountInput
            autoFocus
            value={amount}
            minority={amountMinority}
            onChange={onAmountChange}
            error={amountError ? 'Лимит пополнения: 200 000 ₽' : undefined}
          />
        </Col>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <div className={styles.plate}>
            <Typography.Text view="secondary-large" color="secondary">
              <span className={styles.feeInputContent}>
                Комиссия
                <TooltipDesktop
                  position="top"
                  content="Возможна комиссия Банка, выпустившего карту"
                >
                  <InformationCircleLineMIcon
                    className={styles.tooltipIcon}
                    width={15}
                    height={15}
                  />
                </TooltipDesktop>
              </span>
            </Typography.Text>

            <Amount
              minority={0}
              value={0}
              view="withZeroMinorPart"
              currency="RUR"
              transparentMinor
            />
          </div>
        </Col>
        <Col width={{ mobile: 6, tablet: 6, desktop: 6 }}>
          <Gap size="2xs" />
          <DailyLimits />
        </Col>
      </Row>
    </Space>
  </div>
);
