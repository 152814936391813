import { featureFlags } from '@terminal/core/featureFlags';
import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getBaseChartConfig,
  getCommonInstrumentsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHART_RIGHT_CHARTIQ_SETTINGS,
  TRADE_CHART_MOEX,
  TRADE_LEFT_CHART_SETTINGS,
  TRADE_RIGHT_CHART_SETTINGS,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const scalpMedium = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const trades1Tab = new Tab(Widget.TRADES, {
      link: blueLink.name,
      timer: 20,
      minimum: 0,
      timerActive: true,
      highlight: {},
      tableProps: {},
    });
    const trades2Tab = new Tab(Widget.TRADES, { link: blueLink.name });
    const tradesTopTabSet = new TabSet(20);

    tradesTopTabSet.addChildren([trades1Tab, trades2Tab]);

    const tradesTopRow = new Row(100);

    tradesTopRow.addChildren([tradesTopTabSet]);

    const trades3Tab = new Tab(Widget.TRADES, {
      link: blueLink.name,
      timer: 300,
      minimum: 0,
      timerActive: true,
      highlight: {},
      tableProps: {},
    });
    const trades4Tab = new Tab(Widget.TRADES, { link: blueLink.name });
    const tradesBottomTabSet = new TabSet(20);

    tradesBottomTabSet.addChildren([trades3Tab, trades4Tab]);

    const tradesBottomRow = new Row(100);

    tradesBottomRow.addChildren([tradesBottomTabSet]);

    const leftRow = new Row(10);

    leftRow.addChildren([tradesTopRow, tradesBottomRow]);

    const orderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const orderbookTabset = new TabSet(10);

    orderbookTabset.addChildren([orderbookTab]);

    const leftMiddleRow = new Row(10);

    leftMiddleRow.addChildren([orderbookTabset]);

    const leftChartTab = new Tab(
      Widget.CHART,
      TRADE_CHART_MOEX,
      TRADE_LEFT_CHART_SETTINGS
    );
    const leftChartTabset = new TabSet(50);

    leftChartTabset.addChildren([leftChartTab]);

    const instrumentsChartTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig()
    );
    const instrumentChartTabset = new TabSet(20);

    instrumentChartTabset.addChildren([instrumentsChartTab]);

    const rightMiddleRow = new Row(50);

    rightMiddleRow.addChildren([leftChartTabset, instrumentChartTabset]);

    const rightChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_RIGHT_CHART_SETTINGS
    );
    const rightChartTabset = new TabSet(50);

    rightChartTabset.addChildren([rightChartTab]);

    const ordersTab = new Tab(Widget.ORDERS, getCommonOrdersConfig());
    const positionsTab = new Tab(Widget.POSITIONS, getPositionsCommonConfig());
    const operationTab = new Tab(Widget.OPERATIONS, {});

    const rightBottomTabSet = new TabSet(20);

    rightBottomTabSet.addChildren([ordersTab, positionsTab, operationTab]);

    const rightRow = new Row(50);

    rightRow.addChildren([rightChartTabset, rightBottomTabSet]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, leftMiddleRow, rightMiddleRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink]);
  };
  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });
    const baseChartConfig = {
      symbol: 'SBER',
      idFi: 144950,
      link: blueLink.name,
      tableProps: {},
    } as const;
    const leftChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftChartTabset = new TabSet(50);

    leftChartTabset.addChildren([leftChartTab]);

    const watchLists: Tab[] = [];

    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.MOEX10List)
      )
    );

    if (featureFlags.AMERICAN_INDICIES_ENABLED) {
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.NDXList)
        )
      );
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.DJIList)
        )
      );
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.SP500List)
        )
      );
    }

    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.RGBITRList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.RUCBITRList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.featuresList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.currenciesList)
      )
    );

    const chartWatchListTabSet = new TabSet(60);

    chartWatchListTabSet.addChildren(watchLists);

    const orderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const orderbookTabset = new TabSet(40);

    orderbookTabset.addChildren([orderbookTab]);

    const chartLeftBottomRow = new Row(40);

    chartLeftBottomRow.addChildren([chartWatchListTabSet, orderbookTabset]);

    const leftRow = new Row(50);

    leftRow.addChildren([leftChartTabset, chartLeftBottomRow]);

    const rightChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(100);

    rightChartTabset.addChildren([rightChartTab]);

    const rightRow = new Row(50);

    rightRow.addChildren([rightChartTabset]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftRow, rightRow]);

    return new Layout('Графики', chartRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Скальпинг');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
