import { differenceInDays, format } from 'date-fns';
import React, { FC, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop/Component.desktop';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';

import { PortfolioAnalyticsWarningContainer } from '@terminal/widgets/shared';

import {
  PortfolioChart,
  PortfolioChartNameMap,
  usePortfolioReportsMetrics,
} from '../../../../entities/PortfolioReport';
import { PortfolioBalanceChart } from '../../../PortfolioBalanceChart';
import { PortfolioProfitChart } from '../../../PortfolioProfitChart';
import { thinPortfolioGraph } from '../../lib/thinPortfolioGraph';

import { usePortfolioGraph } from '../../hooks/usePortfolioGraph';

import styles from './PortfolioCharts.module.css';

interface Props {
  accountId: number;
  dateFrom: number;
  dateTo: number;
  isPortfolioSuccess: boolean;
  isEveryThingLoaded: boolean;
}

const MIN_AVAILABLE_DOTS = 11;
const MAX_AVAILABLE_DOTS = 200;

export const PortfolioCharts: FC<Props> = ({
  accountId,
  dateFrom,
  dateTo,
  isPortfolioSuccess,
  isEveryThingLoaded,
}) => {
  const [selected, setSelected] = useState<PortfolioChart>(
    PortfolioChart.BALANCE
  );

  const { trackClickPortfolioReportGraphChip } = usePortfolioReportsMetrics();

  const handleChangeChart = (chartType: PortfolioChart) => {
    setSelected(chartType);
    trackClickPortfolioReportGraphChip(chartType);
  };

  // Прибавляем единицу, потому что считаем кол-во дней в интервале, а не разницу
  const daysInInterval = Math.abs(differenceInDays(dateFrom, dateTo)) + 1;
  const isChartsAvailable =
    daysInInterval >= MIN_AVAILABLE_DOTS &&
    daysInInterval <= MAX_AVAILABLE_DOTS;

  const { data: portfolioGraph } = usePortfolioGraph(
    {
      accountId,
      dateFrom: format(dateFrom, 'yyyy-MM-dd'),
      dateTo: format(dateTo, 'yyyy-MM-dd'),
    },
    isChartsAvailable
  );

  if (!isPortfolioSuccess) {
    return null;
  }

  if (!isChartsAvailable) {
    return (
      <>
        <PortfolioAnalyticsWarningContainer
          Icon={ClockMIcon}
          title="Измените даты"
          description={
            <>
              Чтобы увидеть график, выберите
              <br />
              период от 11 до 200 дней
            </>
          }
        />
        <Gap size="4xl" />
      </>
    );
  }

  if (!portfolioGraph || !isEveryThingLoaded) {
    return null;
  }

  const graph = thinPortfolioGraph(portfolioGraph);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {[PortfolioChart.BALANCE, PortfolioChart.PROFIT].map((chartType) => (
          <Tag
            key={chartType}
            checked={selected === chartType}
            size="xs"
            view="filled"
            onClick={() => {
              handleChangeChart(chartType);
            }}
            className={styles.tag}
          >
            {PortfolioChartNameMap[chartType]}
          </Tag>
        ))}
      </div>

      <Gap size="2xs" />

      {selected === PortfolioChart.BALANCE ? (
        <PortfolioBalanceChart portfolioGraph={graph} />
      ) : (
        <PortfolioProfitChart
          portfolioGraph={graph}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}

      <Gap size="m" />
    </div>
  );
};
