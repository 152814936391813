import { Button } from '@alfalab/core-components/button/modern';
import { Typography } from '@alfalab/core-components/typography';

import { Spinner } from '@terminal/common/components/Spinner';
import { useLoginWithCerberus } from '@terminal/lk-core';

import { formatLoginError } from '../../entities/Login';

import styles from './LoginWithPassport.module.css';

// Если надо подебажить этот способ, то можно пойти в ПУРМС
// Там открыть https://adir-p-cerberus.moscow.alfaintra.net залогинится
// После этого выполнить в консоли браузера fetch() токена
// И подставить его в файл setupProxy.js
function LoginWithCerberus() {
  const { hideButton, connectionError } = useLoginWithCerberus();

  return (
    <div className={styles.container}>
      {!hideButton ? (
        <Button
          view="accent"
          type="submit"
          size="m"
          onClick={() => window.location.reload()}
        >
          Войти в терминал
        </Button>
      ) : (
        <Spinner size="m" />
      )}
      {connectionError && (
        <Typography.Text view="primary-small" color="negative">
          {formatLoginError(connectionError)}
        </Typography.Text>
      )}
    </div>
  );
}

export { LoginWithCerberus };
