import { OrderStatus, OrderType } from './client/entities';

import { OrderItem } from '../types/order';

/**
 * @returns `true`, если требуется отображать уведомление о смене статуса заявки, иначе — `false`
 * */
export function shouldOrderStatusChangeBeNotified(
  prevOrder: Partial<OrderItem>,
  order: Partial<OrderItem>
): boolean {
  const { idOrderStatus: prevStatus } = prevOrder;
  const { idOrderStatus: status, idOrderType: orderType } = order;

  if (!prevStatus || !status) {
    return false;
  }

  // https://jira.moscow.alfaintra.net/browse/ADIRWEB-1267
  const isTRLEdit =
    orderType === OrderType.TRL &&
    (prevStatus === OrderStatus.Edit || status === OrderStatus.Edit);

  // https://jira.moscow.alfaintra.net/browse/ADIRMT-5709
  const isPreparedForCancel = status === OrderStatus.ToBeCancelled;

  return prevStatus !== status && !isTRLEdit && !isPreparedForCancel;
}
