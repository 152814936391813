import { useMutation, useQueryClient } from 'react-query';

import { addBankCard } from '@terminal/core/lib/rest/lkBankCard';

export const useCardAdd = () => {
  const client = useQueryClient();

  return useMutation(addBankCard, {
    onSettled: () => {
      client.invalidateQueries(['lk', 'transfer', 'cardList']);
    },
  });
};
