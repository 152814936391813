import { WidgetsDefaultConfigMap } from '@terminal/core/constants/Layout';
import {
  defaultBalanceColumnSetting,
  getDefaultOperationsColumnSetting,
  getDefaultOrderTableColumnSetting,
  getDefaultPositionColumnSetting,
} from '@terminal/core/constants/tableProps';
import { addKeyIfExists } from '@terminal/core/lib/addKeyIfExists';
import {
  LinkName,
  TradingLimitsShowMode,
  Widget,
} from '@terminal/core/types/layout';

export const getCommonInstrumentsConfig = (
  link?: LinkName,
  selectedListId: string = 'default'
) => ({
  ...WidgetsDefaultConfigMap.get(Widget.WATCHLIST),
  link,
  selectedListId,
});

export const getPositionsCommonConfig = (link?: LinkName) => {
  return {
    symbol: 'SBER',
    idFi: 144950,
    tableProps: {
      columns: getDefaultPositionColumnSetting(),
    },
    ...addKeyIfExists('link', link),
  };
};

export const getBalancesBalancesConfig = () => {
  return {
    columns: defaultBalanceColumnSetting,
  };
};

export const getBalancesOperationsConfig = (link?: LinkName) => {
  return {
    tableProps: { columns: getDefaultOperationsColumnSetting() },
    ...addKeyIfExists('link', link),
  };
};

export const getCommonOrdersConfig = (link?: LinkName) => {
  return {
    tableProps: {
      columns: getDefaultOrderTableColumnSetting(),
    },
    ...addKeyIfExists('link', link),
  };
};

export const getTradeOrderbookConfig = (link?: LinkName) => {
  return {
    symbol: 'SBER',
    id: 144950,
    limitsProps: {
      showLimits: TradingLimitsShowMode.NONE,
      showNPU: TradingLimitsShowMode.MONEY,
      showPosition: true,
    },
    tableProps: {},
    idFi: 144950,
    ...addKeyIfExists('link', link),
  };
};

export const getTradeHybridOrderConfig = (link?: LinkName) => {
  return {
    symbol: 'SBER',
    idFi: 144950,
    tableProps: {},
    ...addKeyIfExists('link', link),
  };
};

export const getBaseChartConfig = (link?: LinkName) => {
  return {
    symbol: 'SBER',
    idFi: 144950,
    tableProps: {},
    ...addKeyIfExists('link', link),
  };
};

export const getTradeChartConfig = (
  link?: LinkName,
  id?: number,
  symbol?: string
) => {
  return {
    symbol: symbol || 'SBER',
    id: id || 144950,
    idFi: id || 144950,
    tableProps: {},
    ...addKeyIfExists('link', link),
  };
};
