import { ActGroupType } from '../model/FinResult';

const MESSAGES = {
  [ActGroupType.METALLS]: 'Металлы',
  [ActGroupType.STOCKS]: 'Акции и дивиденды',
  [ActGroupType.BONDS]: 'Облигации и купоны',
  [ActGroupType.CURRENCY]: 'Валюта',
  [ActGroupType.FUNDS]: 'Фонды',
  [ActGroupType.FUTURES]: 'Фьючерсы',
};

export const mapActGroupToBanchmarkName = (actGroup: ActGroupType): string => {
  return MESSAGES[actGroup];
};
