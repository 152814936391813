import omit from 'lodash/omit';

import { WatchLists } from '@terminal/core/lib/db/watchLists';
import { findAllWatchlistIds } from '@terminal/core/lib/workspaceConfiguration/findAllWatchlistIds';
import { useStore } from '@terminal/core/store';
import { WorkspaceConfiguration } from '@terminal/core/store/slices/workspaceConfigurationsSlice';

import { CloudConfig } from '../model/types/cloudConfig';

export async function prepareConfigForCloud(
  configuration: WorkspaceConfiguration
): Promise<CloudConfig> {
  const idbWatchlists = await WatchLists.get();

  const configurationWatchlists = findAllWatchlistIds(configuration);
  const watchLists = (idbWatchlists?.lists || []).filter((w) =>
    configurationWatchlists.includes(w.id)
  );

  const { settings } = useStore.getState();

  return {
    settings,
    watchLists,
    workspaceConfiguration: omit(configuration.lastAutoSavedConfig, 'models'),
  };
}
