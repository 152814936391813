import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GenerateMultiplyReportsDTO } from './types/GenerateMultiplyReportsDTO';
import { GenerateMultiplyReportsParams } from './types/GenerateMultiplyReportsParams';

export async function generateMultiplyReports(
  data: GenerateMultiplyReportsParams
): Promise<GenerateMultiplyReportsDTO> {
  const result = await fetchLkResult<GenerateMultiplyReportsDTO & LKResult>(
    '/api/reports/multiple/generate',
    'POST',
    data
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
