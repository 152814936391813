import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getRiskDeclarationText } from '../api/getRiskDeclarationText';

export const useRiskDeclarationText = (
  treaty: number | undefined,
  enabled: boolean = false
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'documents/risk-declaration-text'],
    async () => {
      if (!treaty) {
        return;
      }

      return await getRiskDeclarationText({ treaty });
    },
    {
      enabled: Boolean(treaty) && enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса соглашения о рисках',
          text: error?.message || '',
        });
      },
    }
  );
};
