import React, { FC, ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import {
  IS_GI,
  IS_MOBILE_PWA,
  LK_URI,
  USE_PASSPORT_CERBERUS_AUTH,
} from '@terminal/core/env';
import { useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';
import { isPassportPath } from '@terminal/lk-core';

import { Footer } from './Footer';
import { ROUTE } from './routes';

import styles from './Login.module.css';

export type FormContainerProps = {
  title?: ReactNode;
  appLogo: JSX.Element | null;
  copyright: string;
};

export const FormContainer: FC<FormContainerProps> = (props) => {
  const { children, title, appLogo, copyright } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const clientState = useStore((state) => state.appState);

  const isToLogin = location.pathname === ROUTE.RESTORE;

  const restoreClick = useCallback(() => {
    navigate(ROUTE.RESTORE);
  }, [navigate]);

  const toLoginClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const showMobileRestore =
    clientState !== AppState.AUTHORIZING_PASSPORT && !isPassportPath(location);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>{appLogo}</div>
      {title && (
        <Typography.Title
          view="small"
          weight="medium"
          tag="div"
          color="primary"
          className={styles.title}
        >
          {title}
        </Typography.Title>
      )}
      <div className={styles.body}>{children}</div>
      {isToLogin && (
        <Footer copyright={copyright}>
          <Link view="secondary" onClick={toLoginClick}>
            Войти в систему
          </Link>
        </Footer>
      )}
      {!isToLogin && (
        <Footer copyright={copyright}>
          {IS_MOBILE_PWA &&
            showMobileRestore &&
            !USE_PASSPORT_CERBERUS_AUTH && (
              <Link
                view="secondary"
                target="_blank"
                rel="noopener"
                href={`${LK_URI}/password-restore`}
              >
                Восстановление логина и пароля
              </Link>
            )}
          {IS_GI && (
            <Link view="secondary" onClick={restoreClick}>
              Восстановление логина и пароля
            </Link>
          )}
        </Footer>
      )}
    </div>
  );
};
