import { ReportChapterType } from '../model/FinResult';

const MESSAGES: Record<ReportChapterType, string> = {
  dividends: 'Дивиденды',
  couponRevenue: 'Купоны',
  taxes: 'Налоги',
  commissions: 'Комиссии',
  marginTrade: 'Торговля с плечом',
};

export const mapReportChapterKeyToName = (key: ReportChapterType): string => {
  return MESSAGES[key];
};
