import cn from 'classnames';
import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Link } from '@alfalab/core-components/link';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { BottomSheet } from '@terminal/common/components/BottomSheet';
import {
  Confirmation,
  ConfirmationScreen,
  ConfirmationState,
  useConfirmation,
} from '@terminal/common/components/Confirmation';
import { SmsSigningInit } from '@terminal/core/lib/rest/lkSmsSignOperation';

import { FormatedText } from '../../../shared';
import { AssignmentTextModal } from './AssignmentTextModal';

import styles from './SmsSignDialog.module.css';
import stylesMobile from './SmsSignDialogMobile.module.css';

interface SmsSignDialogProps {
  open: boolean;
  isMobile?: boolean;
  codeSending: boolean;
  error: any;
  isError: boolean;
  isSuccess: boolean;
  title?: string;
  assignment?: SmsSigningInit | null;
  onCancel: () => void;
  onResend: () => Promise<void>;
  signCheck: (code: string) => void;
  onComplete: () => void;
}

export const SmsSignDialog = ({
  isSuccess,
  onComplete,
  title = 'Подтверждение',
  ...props
}: SmsSignDialogProps) => {
  const [isTextOpen, toggleIsTextOpen] = useToggle(false);
  const {
    confirmationState,
    confirmationScreen,
    setConfirmationState,
    setConfirmationScreen,
  } = useConfirmation();

  useEffect(() => {
    if (props.open) {
      setConfirmationState('INITIAL' as ConfirmationState);
      setConfirmationScreen('INITIAL' as ConfirmationScreen);
      toggleIsTextOpen(false);
    }
  }, [
    props.open,
    setConfirmationState,
    setConfirmationScreen,
    toggleIsTextOpen,
  ]);

  useEffect(() => {
    if (isSuccess) {
      setConfirmationState('INITIAL' as ConfirmationState);
      setConfirmationScreen('INITIAL' as ConfirmationScreen);
      onComplete();
    }
  }, [isSuccess, onComplete, setConfirmationState, setConfirmationScreen]);

  useEffect(() => {
    if (props.error) {
      if (props.error.code === 1) {
        setConfirmationState('CODE_ERROR' as ConfirmationState);
      }
    }
  }, [props.error, setConfirmationState, setConfirmationScreen]);

  const checkSmsCode = async (code: string) => {
    props.signCheck(code);
  };

  const resendCodeSms = async () => {
    setConfirmationState('CODE_SENDING' as ConfirmationState);
    await props.onResend();
    setConfirmationState('INITIAL' as ConfirmationState);
  };

  const refresh = () => {
    setConfirmationState('INITIAL' as ConfirmationState);
  };

  const close = () => {
    refresh();
    toggleIsTextOpen(false);
    props.onCancel();
  };

  if (props.isMobile) {
    return (
      <ModalMobile
        open={props.open}
        onClose={close}
        className={stylesMobile.root}
        transitionProps={{ timeout: 0 }}
      >
        <ModalMobile.Header
          hasCloser={false}
          className={stylesMobile.header}
          sticky
        >
          <div className={stylesMobile.headerContent}>
            <CrossMIcon
              onClick={close}
              className={stylesMobile.headerCrossIcon}
              role="button"
            />
            <Typography.TitleResponsive
              tag="h2"
              view="xsmall"
              className={stylesMobile.headerTitle}
            >
              {title}
            </Typography.TitleResponsive>
          </div>
        </ModalMobile.Header>
        <ModalMobile.Content>
          {props.assignment && (
            <div
              className={cn(
                stylesMobile.contentSection,
                stylesMobile.textSection
              )}
            >
              <Typography.Text view="secondary-large">
                <Link
                  view="secondary"
                  leftAddons={
                    <DocumentLinesMIcon className={styles.textGlyph} />
                  }
                  onClick={toggleIsTextOpen}
                >
                  Текст поручения
                </Link>
              </Typography.Text>
              <BottomSheet
                open={isTextOpen}
                onClose={toggleIsTextOpen}
                title="Текст поручения"
                stickyHeader
                hasCloser
              >
                <FormatedText text={props.assignment.opText} />
              </BottomSheet>
            </div>
          )}
          <div className={stylesMobile.contentSection}>
            <Confirmation
              className={stylesMobile.confirmation}
              screen={confirmationScreen}
              state={confirmationState}
              onChangeState={setConfirmationState}
              onChangeScreen={setConfirmationScreen}
              requiredCharAmount={5}
              alignContent="center"
              countdownDuration={30000}
              texts={{ codeError: props.error?.message }}
              onInputFinished={checkSmsCode}
              onSmsRetryClick={resendCodeSms}
              phone="ваш номер телефона"
            />
          </div>
        </ModalMobile.Content>
      </ModalMobile>
    );
  }

  return (
    <BaseModal
      open={props.open}
      onClose={props.onCancel}
      className={styles.modal}
      contentClassName={styles.content}
    >
      <div className={styles.header}>
        <CrossMIcon
          className={styles.closeIcon}
          width={18}
          height={18}
          onClick={close}
        />
      </div>
      <div className={styles.container}>
        <Confirmation
          className={styles.confirmation}
          screen={confirmationScreen}
          state={confirmationState}
          onChangeState={setConfirmationState}
          onChangeScreen={setConfirmationScreen}
          requiredCharAmount={5}
          alignContent="center"
          countdownDuration={30000}
          texts={{ codeError: props.error?.message }}
          onInputFinished={checkSmsCode}
          onSmsRetryClick={resendCodeSms}
          phone="номер телефона, указанный при регистрации"
        />
        {Boolean(props.assignment?.opText) && (
          <div className={styles.text}>
            <Link
              view="secondary"
              leftAddons={<DocumentLinesMIcon className={styles.textGlyph} />}
              onClick={toggleIsTextOpen}
            >
              Текст поручения
            </Link>
          </div>
        )}
      </div>
      {props.assignment?.opText ? (
        <div className={styles.drawer} id="sms-confirmation-drawer">
          <AssignmentTextModal open={isTextOpen} onClose={toggleIsTextOpen}>
            <FormatedText text={props.assignment.opText} />
          </AssignmentTextModal>
        </div>
      ) : null}
    </BaseModal>
  );
};
