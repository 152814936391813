import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { memo, useCallback, useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { MarginCallInfo } from '@terminal/core/types/marginCall';

import { getTreatyKey } from '../../lib/getTreatyKey';

import styles from './SelectTreaty.module.css';

export type BaseSelectTreatyProps = {
  hideLabel?: boolean;
  zeros?: boolean;
  selectedAccountId?: number;
  isDisplayRequirements?: boolean;
  treatyFilter?: (acc: Treaty) => boolean;
  hint?: React.ReactNode;
  onSelect?: (treaty: Treaty) => void;
  label?: string;
  labelClassname?: string;
};

type SelectTreatyProps = BaseSelectTreatyProps & MemoSelectProps;

export const SelectTreaty = memo((props: SelectTreatyProps) => {
  const { hideLabel, label, labelClassname, ...restProps } = props;

  return (
    <>
      {!hideLabel && (
        <Typography.Text
          view="primary-small"
          weight="bold"
          tag="p"
          className={labelClassname}
        >
          {label ?? 'Счёт'}
        </Typography.Text>
      )}
      <MemoSelect {...restProps} />
    </>
  );
});

interface MemoSelectProps {
  treaty: Treaty | null;
  treaties?: Treaty[];
  setTreaty: (treaty: Treaty | null) => void;
  pending: boolean;
  marginCall: MarginCallInfo;
}

const MemoSelect = React.memo(
  ({
    zeros,
    treatyFilter,
    hint,
    onSelect,
    treaties,
    setTreaty,
    pending,
    treaty,
    marginCall,
    isDisplayRequirements = false,
  }: SelectTreatyProps & MemoSelectProps) => {
    const getRequirementBySubAccount = useCallback(
      (code: string) => {
        if (!isEmpty(marginCall) && isDisplayRequirements) {
          const marginCallForAcc = marginCall[code];

          if (marginCallForAcc) {
            if (marginCallForAcc.immediateRequirements !== 0) {
              return 'immediateRequirements';
            } else if (marginCallForAcc.requirements !== 0) {
              return 'requirements';
            }
          }
        }
      },
      [marginCall, isDisplayRequirements]
    );

    const requirementForSelected = useMemo(() => {
      if (treaty) {
        return getRequirementBySubAccount(treaty.name);
      }
    }, [getRequirementBySubAccount, treaty]);

    const hintContent = useMemo(() => {
      if (requirementForSelected && treaty) {
        return (
          <Typography.Text
            view="secondary-small"
            color={
              (requirementForSelected === 'immediateRequirements' &&
                'negative') ||
              (requirementForSelected === 'requirements' && 'attention') ||
              undefined
            }
          >
            {requirementForSelected === 'immediateRequirements'
              ? 'Срочное требование: внести'
              : 'Требование: внести'}
            &nbsp;
            <Amount.Pure
              value={marginCall[treaty.name][requirementForSelected] * 100}
              minority={100}
              view="withZeroMinorPart"
              currency="RUB"
            />
          </Typography.Text>
        );
      } else {
        return hint;
      }
    }, [hint, marginCall, requirementForSelected, treaty]);

    const options = useMemo(() => {
      if (!treaties) {
        return [];
      }

      return treaties
        .filter(treatyFilter ? treatyFilter : () => true)
        .map((t) => {
          const hasRequirement = getRequirementBySubAccount(t.name);
          const content = zeros ? `${t.treaty} - 000` : String(t.treaty);

          return {
            key: getTreatyKey(t),
            code: t.name,
            content: (
              <div className={styles.option}>
                {hasRequirement && (
                  <ExclamationCircleMIcon
                    className={styles.requirementIcon}
                    width={18}
                    height={18}
                    color={
                      hasRequirement === 'immediateRequirements'
                        ? 'var(--color-light-text-negative)'
                        : 'var(--color-light-text-attention)'
                    }
                  />
                )}
                <Typography.Text view="secondary-large" weight="medium">
                  {content}
                </Typography.Text>
                {t.isIis ? <span className={styles.iisLabel}>ИИС</span> : ''}
              </div>
            ),
          };
        });
    }, [treaties, treatyFilter, getRequirementBySubAccount, zeros]);

    const onChange: SelectDesktopProps['onChange'] = ({ selected }) => {
      const treaty = treaties?.find((t) => getTreatyKey(t) === selected?.key);

      if (treaty) {
        setTreaty(treaty);
        onSelect && onSelect(treaty);
      }
    };

    const CustomField = (props: FieldProps) => {
      const hasRequirement =
        //@ts-expect-error
        Boolean(props.selected?.code) &&
        getRequirementBySubAccount(
          //@ts-expect-error
          props.selected.code
        );

      return (
        <Field
          {...props}
          fieldClassName={cn(styles.field, props.className)}
          leftAddons={
            (pending && <Loader className={styles.loader} />) ||
            (props.selected && !hasRequirement && (
              <BriefcaseMIcon className={styles.icon} />
            ))
          }
        />
      );
    };

    return (
      <SelectDesktop
        size="s"
        block
        disabled={pending}
        options={options}
        selected={treaty ? getTreatyKey(treaty) : []}
        onChange={onChange}
        Field={CustomField}
        hint={hintContent}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);
