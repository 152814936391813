import { Amount } from '@alfalab/core-components/amount';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { DotMediumMIcon } from '@alfalab/icons-glyph/DotMediumMIcon';

import { MINORITY } from '@terminal/core/constants/ui';

import styles from './HistogramSegment.module.css';

interface Props {
  totalSum: number;
  segmentSum: number;
  color: string;
  name: string;
}

export const HistogramSegment = ({
  totalSum,
  segmentSum,
  color,
  name,
}: Props) => {
  const percents = (segmentSum / totalSum) * 100;
  const percentsLabel = Math.round(percents);

  return (
    <div
      className={styles.bar}
      style={{ background: color, flexBasis: `${percents}%` }}
    >
      <TooltipDesktop
        content={
          <div className={styles.tooltipContent}>
            <DotMediumMIcon width={12} height={12} color={color} />
            <Typography.Text view="secondary-small" color="primary">
              {name}
            </Typography.Text>{' '}
            <Typography.Text view="secondary-small">
              <Amount
                value={segmentSum * MINORITY}
                minority={MINORITY}
                currency="RUB"
                bold="none"
              />
            </Typography.Text>
          </div>
        }
        targetClassName={styles.tooltipTarget}
        position="top"
        view="hint"
        offset={[0, 8]}
      >
        <Typography.Text
          view="secondary-small"
          weight="medium"
          className={styles.label}
        >
          {`${percentsLabel}%`}
        </Typography.Text>
      </TooltipDesktop>
    </div>
  );
};
