import { DocumentReceivingType } from '@terminal/lk-core';
import { ActiveFormType } from '@terminal/lk-desktop-common';

export function getObtainHint(
  type: DocumentReceivingType,
  formType?: ActiveFormType
): string | undefined {
  if (type === DocumentReceivingType.ByMail && formType === 'depoReport') {
    return 'Подготовим в течение 30 дней. Срок доставки зависит от почтовой службы.';
  }

  switch (type) {
    case DocumentReceivingType.InBank:
      return 'Срок изготовления: до 20 дней';
    case DocumentReceivingType.InLK:
      return 'Справка будет добавлена в раздел Налоговая информация';
    case DocumentReceivingType.ByMail:
      return 'Срок изготовления: до 20 дней';
    case DocumentReceivingType.Electronic:
    default:
      return undefined;
  }
}
