import cn from 'classnames';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import styles from './DocumentItem.module.css';

interface DocumentItemProps {
  name: React.ReactNode;
  count?: React.ReactNode;
  hint?: React.ReactNode;
  tooltip?: React.ReactNode;
  view?: 'even' | 'odd';
  onClick?: () => void;
}

export const DocumentItem = ({
  name,
  hint,
  count,
  tooltip,
  view,
  onClick,
}: DocumentItemProps) => {
  return (
    <div
      className={cn(styles.container, { [styles.odd]: view === 'odd' })}
      onClick={onClick}
    >
      <div className={styles.icon}>
        <DocumentLinesMIcon width={18} height={18} />
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          <div className={styles.name}>{name}</div>
        </div>
        {Boolean(hint) && <div className={styles.hint}>{hint}</div>}
      </div>
      {Boolean(tooltip) && <div className={styles.tooltip}>{tooltip}</div>}
      {Boolean(count) && <div className={styles.count}>{count}</div>}
    </div>
  );
};
