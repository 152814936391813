// текущий список - https://jira.moscow.alfaintra.net/browse/ADIRWEB-4666 от 09.08.2024

export const floatersList = [
  'RU000A1077V4',
  'RU000A106P97',
  'RU000A106Z46',
  'RU000A108GR8',
  'RU000A107ET1',
  'RU000A1087J8',
  'RU000A107084',
  'RU000A108DE3',
  'RU000A106375',
  'RU000A107HR8',
  'RU000A106565',
  'RU000A107456',
  'RU000A108KT6',
  'RU000A108GL1',
  'RU000A107AG6',
  'RU000A108Q94',
  'RU000A108P46',
  'RU000A107W55',
  'RU000A1075S4',
  'RU000A108U72',
  'RU000A108447',
  'RU000A107B35',
  'RU000A106TM6',
  'RU000A108WY1',
  'RU000A106K43',
  'RU000A106ZL5',
  'RU000A107S10',
  'RU000A107TT9',
  'RU000A0JXE06',
  'RU000A107EC7',
  'RU000A1068R1',
  'RU000A109098',
  'RU000A1075J3',
  'RU000A108TU5',
  'RU000A108P61',
  'RU000A1083W0',
  'RU000A108ZT4',
  'RU000A1083U4',
  'RU000A108777',
  'RU000A107HG1',
  'RU000A108Y86',
  'RU000A108Z85',
  'RU000A108RP9',
  'RU000A108ZH9',
  'RU000A108G05',
  'RU000A108GN7',
  'RU000A107UW1',
  'RU000A108VW7',
  'RU000A108JV4',
  'RU000A1083A6',
  'RU000A104V00',
  'RU000A107W71',
  'RU000A1090H6',
  'RU000A1078S8',
  'RU000A106VV3',
  'RU000A1038M5',
  'RU000A107F49',
  'RU000A106LL5',
  'RU000A1079S6',
  'RU000A107605',
  'RU000A107050',
  'RU000A106VT7',
  'RU000A108LN7',
  'RU000A107SM6',
  'RU000A108FC2',
  'RU000A1074E7',
  'RU000A1042W6',
  'RU000A107GX8',
  'RU000A108462',
  'RU000A1049Y7',
  'RU000A108VL0',
  'RU000A1080Y2',
  'RU000A107LU4',
  'RU000A106037',
  'RU000A108Z77',
  'RU000A107DP1',
  'RU000A107BP5',
  'RU000A108PC1',
  'RU000A107TK8',
  'RU000A103XP8',
  'RU000A1090K0',
  'RU000A107SG8',
  'RU000A107QL2',
  'RU000A105YH8',
  'RU000A1087G4',
  'RU000A107BR1',
  'RU000A1008V9',
  'RU000A107UX9',
  'RU000A108F63',
  'RU000A107GJ7',
  'RU000A107E81',
  'RU000A107WL0',
  'RU000A107CG2',
  'RU000A108QA3',
  'RU000A107HH9',
  'RU000A105HH3',
  'RU000A106ZU6',
  'RU000A1069N8',
  'RU000A1075E4',
  'RU000A107X96',
  'RU000A108991',
  'RU000A104EP6',
  'RU000A105MN1',
  'RU000A104SJ9',
  'RU000A107AU7',
  'RU000A0ZZ885',
  'RU000A107C00',
  'RU000A106J38',
  'RU000A107CJ6',
  'RU000A0ZZ877',
  'RU000A0ZZGT5',
  'RU000A1041Q0',
  'RU000A107S85',
  'RU000A108LF3',
  'RU000A1006C3',
  'RU000A107R29',
  'RU000A104TM1',
  'RU000A106FM5',
  'RU000A1067H4',
  'RU000A104UV0',
  'RU000A101947',
  'RU000A101H84',
  'RU000A100725',
  'RU000A100YD8',
  'RU000A106169',
  'RU000A104V26',
  'RU000A107GC2',
  'RU000A108LU2',
  'RU000A105NZ3',
  'RU000A0ZZTL5',
  'RU000A104BX6',
  'RU000A105NN9',
  'RU000A0JS9X1',
  'RU000A104XJ9',
  'RU000A1078X8',
  'RU000A0ZZ893',
  'RU000A0ZZ1M2',
  'RU000A106W49',
  'RU000A0ZYAP9',
  'RU000A107T27',
  'RU000A0JVT43',
  'RU000A0JVVK6',
  'RU000A0JVVP5',
  'RU000A0JV0T3',
  'RU000A0JVVT7',
  'RU000A0JVZA8',
  'RU000A0JWTV5',
  'RU000A0JXQQ9',
  'RU000A0JXXD3',
  'RU000A0JU9K4',
  'RU000A0ZZ7G1',
  'RU000A0ZZ7H9',
  'RU000A0ZZCX6',
  'RU000A0ZZRY2',
  'RU000A0ZZZ25',
  'RU000A0ZZZY5',
  'RU000A100030',
  'RU000A1007U3',
  'RU000A100ME1',
  'RU000A1013N6',
  'RU000A0ZZ2W9',
  'RU000A0ZYSE5',
  'RU000A0ZYG37',
  'RU000A0ZYCP5',
  'RU000A0JXXE1',
  'RU000A104X32',
  'RU000A104ZV9',
  'RU000A1058R2',
  'RU000A105JF3',
  'RU000A105N58',
  'RU000A105RW1',
  'RU000A105VQ5',
  'RU000A105W08',
  'RU000A106CL4',
  'RU000A106RR9',
  'RU000A106RS7',
  'RU000A106RT5',
  'RU000A106S29',
  'RU000A106S37',
  'RU000A106S52',
  'RU000A106SM8',
  'RU000A106SQ9',
  'RU000A107AX1',
  'RU000A106WW9',
  'RU000A107G55',
  'RU000A107GB4',
  'RU000A107GW0',
  'RU000A107JV6',
  'RU000A107TL6',
  'RU000A108BN8',
  'RU000A108LD8',
  'RU000A108MZ9',
  'RU000A108RM6',
  'RU000A109080',
  'RU000A104US6',
  'RU000A104KW9',
  'RU000A101T72',
  'RU000A102192',
  'RU000A1021A6',
  'RU000A102887',
  'RU000A102HX7',
  'RU000A102L95',
  'RU000A102ZC3',
  'RU000A103W26',
  'RU000A104CK1',
  'RU000A104DZ7',
  'RU000A101HP5',
  'RU000A101EW8',
  'RU000A101D54',
  'RU000A1018N5',
  'RU000A1018G9',
  'RU000A1015T8',
];
