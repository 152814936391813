import { UNICODE_CHARS } from '@terminal/core/constants/unicodeChars';

import { ActGroupType } from '../model/FinResult';

const MESSAGES = {
  [ActGroupType.METALLS]: 'В этом разделе отображаются закрытые позиции',
  [ActGroupType.STOCKS]: `В этом разделе отображаются закрытые позиции${UNICODE_CHARS.NL}и полученные дивиденды`,
  [ActGroupType.BONDS]: `В этом разделе отображаются закрытые позиции${UNICODE_CHARS.NL}и полученные купоны`,
  [ActGroupType.CURRENCY]: 'В этом разделе отображаются закрытые позиции',
  [ActGroupType.FUNDS]: 'В этом разделе отображаются закрытые позиции',
  [ActGroupType.FUTURES]:
    'В этом разделе отображается полученная вариационная маржа',
};

export const mapActGroupToIncomeDescription = (
  actGroup: ActGroupType
): string => {
  return MESSAGES[actGroup];
};
