import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, Fragment, useRef, useState } from 'react';
import { useLocalStorage, useUnmount } from 'react-use';
import {
  Area,
  AreaChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Gap } from '@alfalab/core-components/gap';

import { NetValuePoint } from '@terminal/core/lib/rest/investApi';

import { CurrentPriceLabel } from '../../../../shared';
import { calculateProfitChartLabels } from '../../lib/calculateProfitChartLabels';
import { INDICES_CHIPSE } from '../../lib/const';
import { IndicesChipse } from '../IndicesChipse';
import { ProfitChartTooltip } from '../ProfitChartTooltip';

import { useIndicesData } from '../../hooks/useIndicesData';
import { useProfitChartDots } from '../../hooks/useProfitChartDots';

import { ChartIndex } from '../../model/types';

import styles from './PortfolioProfitChart.module.css';

interface Props {
  portfolioGraph: NetValuePoint[];
  dateFrom: number;
  dateTo: number;
}

const Y_AXIS_WIDTH = 48;

export const PortfolioProfitChart: FC<Props> = ({
  portfolioGraph,
  dateFrom,
  dateTo,
}) => {
  const [persistedIndices, persistIndices] = useLocalStorage<ChartIndex[]>(
    'portfolio-analytics-indices',
    []
  );
  const existIndices = Object.values(ChartIndex);
  const defaultIndices = (persistedIndices || []).filter((p) =>
    existIndices.includes(p)
  );
  const [selectedIndices, setSelectedIndices] =
    useState<ChartIndex[]>(defaultIndices);

  useUnmount(() => {
    persistIndices(selectedIndices);
  });

  const areaRef = useRef<Area | null>(null);

  const indicesData = useIndicesData(selectedIndices, dateFrom, dateTo);

  const loadedIndices = selectedIndices.filter((index) => index in indicesData);

  const dots = useProfitChartDots(portfolioGraph, indicesData);

  const { labels, minMaxTicks } = calculateProfitChartLabels(
    dots,
    loadedIndices,
    areaRef.current?.props.height || 200
  );

  const lastTick = dots.at(-1);

  return (
    <div>
      <ResponsiveContainer width="100%" height={242}>
        {/* @ts-expect-error cursor=pointer валидно */}
        <AreaChart cursor="pointer" data={dots} className={styles.chart}>
          {labels.map((label) => (
            // Здесь мы доверяем факту что для уровней 0 и портфеля y === price, и ценовой уровень отрисуется на правильной высоте
            // Если логика меняется, можно разбить на две ReferenceLine - одна для лейбла, другая для уровня
            <ReferenceLine
              key={`${label.type}-line`}
              y={label.y}
              stroke={label.stroke}
              label={
                label.hideLabel ? undefined : (
                  <CurrentPriceLabel
                    price={label.price}
                    suffix={label.type === 'zero' ? undefined : '%'}
                    fill={label.color}
                    yAxisWidth={Y_AXIS_WIDTH}
                  />
                )
              }
            />
          ))}

          {minMaxTicks.map((label) => (
            <ReferenceLine
              key={`minmax-${label.price}`}
              y={label.y}
              stroke="none"
              label={
                <CurrentPriceLabel
                  price={label.price}
                  suffix="%"
                  fill="var(--color-light-text-secondary)"
                  yAxisWidth={Y_AXIS_WIDTH}
                />
              }
            />
          ))}

          {loadedIndices.map((index) => (
            <Fragment key={`${index}-area-dot`}>
              <Area
                isAnimationActive={false}
                dot={false}
                activeDot={false}
                type="linear"
                dataKey={index}
                fill="none"
                strokeLinejoin="round"
                strokeLinecap="round"
                stroke={INDICES_CHIPSE[index].color}
                strokeWidth={1}
              />
              <ReferenceDot
                x={lastTick?.date?.getTime()}
                y={lastTick?.[index]}
                r={3}
                fill={INDICES_CHIPSE[index].color}
                stroke="none"
              />
            </Fragment>
          ))}
          <Area
            ref={(area) => {
              areaRef.current = area;
            }}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            type="linear"
            dataKey="portfolio"
            fill="none"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="var(--color-light-text-primary)"
            strokeWidth={2}
          />
          <ReferenceDot
            x={lastTick?.date?.getTime()}
            y={lastTick?.portfolio}
            r={3}
            fill="var(--color-light-text-primary)"
            stroke="none"
          />

          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: 'var(--color-light-text-secondary)',
              fontSize: 11,
            }}
            tickFormatter={(timestamp) =>
              format(timestamp, 'dd MMM', { locale: ru })
            }
          />

          <YAxis
            axisLine={false}
            tickLine={false}
            orientation="right"
            width={Y_AXIS_WIDTH}
            tick={<div />}
            ticks={[lastTick?.portfolio || 0]}
            domain={([min, max]) => {
              return [min - (max - min) / 10, max + (max - min) / 10];
            }}
          />
          <Tooltip
            content={<ProfitChartTooltip selectedIndices={selectedIndices} />}
          />
        </AreaChart>
      </ResponsiveContainer>

      <Gap size="xs" />

      <IndicesChipse selected={selectedIndices} onChange={setSelectedIndices} />
    </div>
  );
};
