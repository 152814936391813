import { endOfYear, format, setYear, startOfYear } from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';

export function getPeriodFromYear(
  year: string,
  dateFormat: string = DEFAULT_VIEW_DATE_FORMAT
) {
  const date = setYear(new Date(), parseInt(year));

  return {
    periodStart: format(startOfYear(date), dateFormat),
    periodEnd: format(endOfYear(date), dateFormat),
  };
}
