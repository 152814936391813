import sumBy from 'lodash/sumBy';

import { ASSETS_COLORS } from '../../lib/const';
import { HistogramSegment } from '../HistogramSegment';

import { Asset } from '../../model/types/PortfolioComposition';

import styles from './PortfolioHistogram.module.css';

interface Props {
  assets: Asset[];
  isFetching: boolean;
}

const HIDDEN_LESS_THEN_PART = 0.05;

export const PortfolioHistogram = ({ assets, isFetching }: Props) => {
  if (!assets.length || isFetching) {
    return null;
  }

  const totalSum = sumBy(assets, (asset) => asset.price);

  return (
    <div className={styles.container}>
      {assets
        .filter((asset) => asset.price / totalSum >= HIDDEN_LESS_THEN_PART)
        .map(({ id, price, name, color }, index) => (
          <HistogramSegment
            key={id}
            segmentSum={price}
            totalSum={totalSum}
            color={color ?? ASSETS_COLORS[index]}
            name={name}
          />
        ))}
    </div>
  );
};
