import { endOfDay, format, startOfMonth } from 'date-fns';
import { FC, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
// eslint-disable-next-line no-restricted-imports
import { CalendarInputProps } from '@alfalab/core-components/calendar-input';
import { CalendarRange } from '@alfalab/core-components/calendar-range';
import { Popover } from '@alfalab/core-components/popover';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop/Component.desktop';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ChevronUpMIcon } from '@alfalab/icons-glyph/ChevronUpMIcon';

import { UNICODE_CHARS } from '@terminal/core/constants/unicodeChars';
import {
  DateFilterType,
  DateFilterValue,
} from '@terminal/core/types/operation';

import {
  FiltersNameMap,
  usePortfolioReportsMetrics,
} from '../../../../entities/PortfolioReport';
import { calculateFilterDates } from '../../lib/calculateFilterDates';
import { START_ANALYTICS_DATE } from '../../lib/const';

import styles from './DateFilters.module.css';

interface Props {
  value: DateFilterValue;
  onChange: (value: DateFilterValue) => void;
}

export const DateFilters: FC<Props> = ({ value, onChange }) => {
  const [calendarVisible, toggleCalendarVisible] = useToggle(false);

  const calendarChipRef = useRef<HTMLButtonElement>(null);
  const calendarPopoverRef = useRef<HTMLDivElement>(null);

  const {
    trackApplyPortfolioReportCustomDate,
    trackClickPortfolioReportDateChip,
  } = usePortfolioReportsMetrics();

  const handleChange = (
    type: DateFilterType,
    dateFrom?: number | null,
    dateTo?: number | null,
    from?: string,
    to?: string
  ) => {
    const newDates = calculateFilterDates(type, dateFrom, dateTo, from, to);

    if (newDates) {
      onChange(newDates);
    }

    if (type === DateFilterType.RANGE) {
      trackApplyPortfolioReportCustomDate(
        newDates?.valueFrom?.value || '',
        newDates?.valueTo?.value || ''
      );
    } else {
      trackClickPortfolioReportDateChip(type);
    }
  };

  useClickAway(calendarPopoverRef, (event) => {
    if (
      calendarChipRef.current === event.target ||
      calendarChipRef.current?.contains(event.target as Node)
    ) {
      return;
    }

    toggleCalendarVisible(false);
  });

  return (
    <div className={styles.row}>
      {[
        DateFilterType['30DAYS'],
        DateFilterType['3MONTHS'],
        DateFilterType.YEAR,
        DateFilterType.THIS_YEAR,
        DateFilterType.NONE,
      ].map((type) => {
        const checked = type === value?.type;

        return (
          <Tag
            key={type}
            checked={checked}
            size="xs"
            view="filled"
            onClick={() => {
              if (checked) {
                handleChange(DateFilterType.NONE);
              } else {
                handleChange(type);
              }
            }}
            className={styles.tag}
          >
            {FiltersNameMap.get(type)}
          </Tag>
        );
      })}

      <Tag
        ref={calendarChipRef}
        checked={value?.type === DateFilterType.RANGE}
        size="xs"
        view="filled"
        onClick={() => {
          toggleCalendarVisible();
        }}
        rightAddons={
          calendarVisible ? (
            <ChevronUpMIcon width={16} height={16} />
          ) : (
            <ChevronDownMIcon width={16} height={16} />
          )
        }
        className={styles.tag}
      >
        {value?.type === DateFilterType.RANGE
          ? getRangeText(value?.valueFrom?.date, value?.valueTo?.date)
          : FiltersNameMap.get(DateFilterType.RANGE)}
      </Tag>

      <Popover
        ref={calendarPopoverRef}
        open={calendarVisible}
        anchorElement={calendarChipRef.current}
        position="bottom"
        popperClassName={styles.popover}
      >
        <CalendarRange
          valueTo={value?.valueTo?.value}
          valueFrom={value?.valueFrom?.value}
          defaultMonth={startOfMonth(new Date()).getTime()}
          calendarPosition="static"
          onChange={({ dateFrom, dateTo, valueFrom, valueTo }) => {
            if (
              dateFrom &&
              dateTo &&
              !(
                value?.valueFrom?.value === valueFrom &&
                value?.valueTo?.value === valueTo
              )
            ) {
              handleChange(
                DateFilterType.RANGE,
                dateFrom,
                dateTo,
                valueFrom,
                valueTo
              );
            }

            if (dateFrom && dateTo) {
              toggleCalendarVisible();
            }
          }}
          inputFromProps={getInputProps(
            'from',
            value?.valueFrom?.date,
            value?.valueTo?.date
          )}
          inputToProps={getInputProps(
            'to',
            value?.valueFrom?.date,
            value?.valueTo?.date
          )}
          minDate={START_ANALYTICS_DATE.getTime()}
          maxDate={endOfDay(new Date()).getTime()}
        />
      </Popover>
    </div>
  );
};

function getInputProps(
  inputType: 'from' | 'to',
  from?: number,
  to?: number
): CalendarInputProps {
  const TODAY = endOfDay(new Date()).getTime();

  return {
    size: 's',
    calendarProps: {
      minDate: inputType === 'to' ? from : undefined,
      maxDate: inputType === 'to' ? TODAY : to ?? TODAY,
      selectedFrom: from,
      selectedTo: to,
    },
    minDate: START_ANALYTICS_DATE.getTime(),
    maxDate: TODAY,
  };
}

function getRangeText(from?: number, to?: number): string {
  if (!from || !to) {
    return 'Даты';
  }

  return `${format(from, 'dd.MM.yyyy')}${UNICODE_CHARS.NBSP}-${
    UNICODE_CHARS.NBSP
  }${format(to, 'dd.MM.yyyy')}`;
}
