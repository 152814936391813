import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetScansListDTO } from './types/GetScansListDTO';
import { GetScansListParams } from './types/GetScansListParams';

export async function getScansList(
  data: GetScansListParams
): Promise<GetScansListDTO> {
  const result = await fetchLkResult<GetScansListDTO | LKResult>(
    qs.stringifyUrl({
      url: '/api/client/documents/scans',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
