import { FC, useCallback } from 'react';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowUpLineDownMIcon } from '@alfalab/icons-glyph/ArrowUpLineDownMIcon';
import { CloudMIcon } from '@alfalab/icons-glyph/CloudMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { MenuGroup } from '@terminal/common/components/Menu';
import { WorkspaceConfiguration } from '@terminal/core/store/slices/workspaceConfigurationsSlice';

import { exportConfiguration } from '../lib/exportConfiguration';

import { useWorkspaceConfigurationStore } from '../model/store/useWorkspaceConfigurationStore';

import { WorkspaceConfigurationScreen } from '../model/types';

import styles from './CommonSettingHooks.module.css';

interface ExportGroupOptions {
  onCloudSelect?: () => void;
  GroupLabel?: FC<{ className?: string }>;
}

export function useConfigurationExportGroup(options?: ExportGroupOptions) {
  const { onCloudSelect, GroupLabel } = options ?? {};

  const [setCloudSelectedConfiguration, setScreen] =
    useWorkspaceConfigurationStore((state) => [
      state.setCloudSelectedConfiguration,
      state.setScreen,
    ]);

  return useCallback(
    (configuration: WorkspaceConfiguration) => {
      return {
        key: 'export',
        label: GroupLabel ? (
          <GroupLabel className={styles.groupLabel} />
        ) : (
          <Typography.Text
            view="secondary-large"
            weight="bold"
            color="secondary"
            className={styles.groupLabel}
          >
            Для другого устройства
            <TooltipDesktop
              position="top"
              content={
                <>
                  Вы сможете загрузить <br />
                  конфигурацию на другом <br />
                  устройстве или в другом <br />
                  браузере.
                </>
              }
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-text-secondary)"
                className={styles.icon}
              />
            </TooltipDesktop>
          </Typography.Text>
        ),
        items: [
          {
            key: 'to-file',
            type: 'button',
            label: 'Выгрузить в файл',
            Icon: ArrowUpLineDownMIcon,
            hideMenu: true,
            onClick: () => exportConfiguration(configuration),
          },
          {
            key: 'to-cloud',
            type: 'button',
            label: 'Сохранить в облаке',
            Icon: CloudMIcon,
            hideMenu: true,
            onClick: () => {
              setCloudSelectedConfiguration(configuration);
              setScreen(WorkspaceConfigurationScreen.Cloud);
              onCloudSelect?.();
            },
          },
        ],
      } satisfies MenuGroup;
    },
    [GroupLabel, setCloudSelectedConfiguration, setScreen, onCloudSelect]
  );
}
