import { Typography } from '@alfalab/core-components/typography';

export const DailyLimits = () => {
  return (
    <Typography.Text view="secondary-small" color="secondary">
      С одной карты: до 200 000 ₽ в день
      <br />
      По всем картам: до 600 000 ₽ в день
    </Typography.Text>
  );
};
