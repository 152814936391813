import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { useSelectWithLoading } from '@alfalab/core-components/select/presets';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { Security } from '@terminal/lk-core';

import { getSecurityDescription } from '../../lib/getSecurityDescription';
import { SelectSecurityItem } from '../SelectSecurityItem';

import styles from './SelectSecurity.module.css';

const ALL_OPTION_KEY = 'all';

const allOption = {
  key: ALL_OPTION_KEY,
  content: <SelectSecurityItem value="Все бумаги" icon={BriefcaseMIcon} />,
};

export type SelectSecurityOption =
  | { key: typeof ALL_OPTION_KEY; value: undefined }
  | { key: string; value: Security };

interface SecuritySelectProps {
  loading: boolean;
  onSearch: (value: string) => void;
  onChange: (value: OptionShape) => void;
  selected?: OptionShape;
  options: SelectSecurityOption[];
  disabled: boolean;
}

export const SelectSecurity: React.FC<SecuritySelectProps> = ({
  loading,
  onSearch,
  selected,
  onChange,
  options,
  disabled,
}) => {
  const [searchText, setSearchText] = useState('');
  const searchStarted = useRef<boolean>(false);

  const debouncedSearch = useDebouncedCallback((searchText: string) => {
    onSearch(searchText);

    searchStarted.current = Boolean(searchText);
  }, 500);

  useEffect(() => {
    debouncedSearch(searchText);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, debouncedSearch]);

  const loadingProps = useSelectWithLoading({
    loading,
    visibleOptions: 6,
  });

  const mapOptionToSelectItem = useCallback(
    (option: SelectSecurityOption): OptionShape => ({
      key: option?.key,
      content: (
        <SelectSecurityItem
          value={getSecurityDescription(option?.value) ?? ''}
        />
      ),
      value: option?.value,
    }),
    []
  );

  const searchOptions = useMemo(
    () => options.map(mapOptionToSelectItem),
    [options, mapOptionToSelectItem]
  );

  const securitiesOptions = useMemo<OptionShape[]>(() => {
    if (!searchStarted.current) {
      const selectedOption =
        selected?.key !== ALL_OPTION_KEY
          ? [mapOptionToSelectItem(selected as SelectSecurityOption)]
          : [];

      return [
        allOption,
        ...selectedOption,
        ...searchOptions.filter((option) => option?.key !== selected?.key),
      ];
    }

    return searchOptions;
  }, [searchOptions, selected, mapOptionToSelectItem]);

  const handleChange: SelectDesktopProps['onChange'] = useCallback(
    ({ selected }) => {
      if (selected) {
        onChange(selected);
        setSearchText('');
        searchStarted.current = false;
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (!selected && !loading) {
      onChange(securitiesOptions[0]);
    }
  }, [loading, onChange, securitiesOptions, selected]);

  return (
    <SelectDesktop
      popperClassName={styles.popover}
      popoverPosition="bottom-end"
      showSearch={true}
      size="s"
      options={securitiesOptions}
      label="Ценные бумаги"
      placeholder="Выбрать"
      labelView="outer"
      onChange={handleChange}
      closeOnSelect
      disabled={disabled}
      selected={selected}
      block
      optionsListClassName={styles.optionsList}
      optionsListWidth="content"
      searchProps={{
        value: searchText,
        onChange: setSearchText,
        accessor: () => searchText,
        componentProps: {
          className: styles.searchInput,
          placeholder: 'Наименование, ISIN, номер гос. регистрации или тикер',
          block: true,
          leftAddons: <MagnifierMIcon className={styles.searchIcon} />,
        },
      }}
      {...loadingProps}
    />
  );
};
