import { useMemo } from 'react';
import { Link } from '@alfalab/core-components/link';
import { Notification } from '@alfalab/core-components/notification';
import { NotificationManager as Manager } from '@alfalab/core-components/notification-manager';

import { shallow, useStore } from '@terminal/core/store';

export const PwaNotificationManager = () => {
  const [notifications, removeNotification] = useStore(
    (state) => [state.notifications, state.removeNotification],
    shallow
  );

  const notificationElements = useMemo(() => {
    return notifications.map((props) => (
      <Notification
        {...props}
        id={props.id}
        key={props.id}
        autoCloseDelay={props.link ? 10000 : 3000}
        badge={props.badge === 'chat' ? 'attention' : props.badge}
        colors={props.colors ?? 'inverted'}
        children={
          props.link ? (
            <>
              {props.text}
              <br />
              <Link
                href={props.link}
                view="default"
                rel="noopener"
                target="_blank"
              >
                Пройти тест
              </Link>
            </>
          ) : (
            props.text
          )
        }
      />
    ));
  }, [notifications]);

  return (
    <Manager
      notifications={notificationElements}
      onRemoveNotification={(id) => removeNotification(id)}
    />
  );
};
