import { BorderNode, Layout as FlexLayout, TabSetNode } from 'flexlayout-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';
// пока полностью не перешли на новую архитектуру нужно использовать такой импорт, не из индекса
import { useAlfaDirectProps } from '@terminal/alfadirect/provider/react/useAlfaDirectProps';
import { SupportService } from '@terminal/chat/api/chatService';
import { Chat } from '@terminal/chat/Chat';
import { BrokerRecommendationService } from '@terminal/chat/services/broker';
import { DesktopNotificationManager } from '@terminal/common/features-DEPRECATED/NotificationManager';
import {
  WidgetsDefaultConfigMap,
  WidgetsNameMap,
} from '@terminal/core/constants/Layout';
import { ENABLE_LK_FEATURES, USE_PASSPORT_AUTH } from '@terminal/core/env';
import { featureFlags } from '@terminal/core/featureFlags';
import { useEventListener } from '@terminal/core/hooks';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import {
  logAuthorizationPerformance,
  logProblemFrames,
} from '@terminal/core/lib/analytics/performance';
import { WorkspaceConfigurations } from '@terminal/core/lib/db/workspaceConfiguration';
import { useStore } from '@terminal/core/store';
import { ElementType, Widget } from '@terminal/core/types/layout';
import { UserCredentials } from '@terminal/core/types/user';
import { SigningDialog } from '@terminal/lk/legacy/components/SigningDialog';
import { LoginProlongation } from '@terminal/lk/legacy/login/LoginProlongation';
import {
  Provider as WidgetsApiProvider,
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '@terminal/widgets';
import { useFeatureOnboarding } from '@terminal/widgets/features/FeatureOnboarding';

import { Layout } from '../../app/ui/Layout';
import { getOnboardingStateFromLocaleStorage } from '../../entities/OnboardingConfig';
import { MarginCall } from '../../features/MarginCall';
import { SrEfrBanner } from '../../features/SrEfrBanner';
import { Alerts } from '../../legacy/components/Alerts';
// eslint-disable-next-line no-restricted-imports
import { SingletonModalProvider } from '../../legacy/widgets/certificates/SingletonModalProvider/SingletonModalProvider';
// eslint-disable-next-line no-restricted-imports
import { Header } from '../../widgets/Header/ui/Header';
import { OnboardingConfig } from '../../widgets/OnboardingConfig';

import { useWidgetProps } from '../../shared/hooks/useWidgetProps';

import styles from './Workspace.module.css';

const authPerformanceLogger = logAuthorizationPerformance();

export interface Props {
  user: UserCredentials;
}

enum ConfigurationState {
  UNKNOWN,
  CONFIGURED,
  EMPTY,
}

export function Workspace({ user }: Props): JSX.Element {
  const problemFramesPerformance = useMemo(() => logProblemFrames(), []);

  useEffect(() => {
    problemFramesPerformance.start();

    return () => {
      problemFramesPerformance.stop();
    };
  }, [problemFramesPerformance]);

  const workspacesIsInitialized = useStore(
    (state) => state.workspacesIsInitialized
  );

  const layoutRef = useRef<FlexLayout>(null);
  const [onboardingCongif, setOnboardingConfig] = useState<ConfigurationState>(
    ConfigurationState.UNKNOWN
  );

  const { supportChat, supportPhone, supportEmail } = useAppContext();

  useEffect(() => {
    authPerformanceLogger.workspaceLoaded();
  }, []);

  const onAddFromMenu = (key: Widget) => {
    layoutRef.current?.addTabToActiveTabSet({
      type: ElementType.TAB,
      name: WidgetsNameMap.get(key),
      component: key,
      config: WidgetsDefaultConfigMap.get(key),
    });
  };

  const onAddFromTabSetButton = (
    node: TabSetNode | BorderNode,
    key: Widget
  ) => {
    layoutRef.current?.addTabToTabSet(node.getId(), {
      type: ElementType.TAB,
      name: WidgetsNameMap.get(key),
      component: key,
      config: WidgetsDefaultConfigMap.get(key),
    });
  };

  useEffect(() => BrokerRecommendationService.subscribe(), []);

  useEventListener('visibilitychange', () => {
    SupportService.togglePolling(document.visibilityState === 'visible');
  });

  const disableNewFeaturesOnboarding = useFeatureOnboarding(
    (store) => store.disableOnboarding
  );

  const closeOnboardingConfigMenu = useCallback(() => {
    setOnboardingConfig(ConfigurationState.CONFIGURED);
  }, []);

  useEffect(() => {
    if (workspacesIsInitialized) {
      // клиент проходил онбординг ранее
      const isPassed = getOnboardingStateFromLocaleStorage('passed');
      const isOnboarded = WorkspaceConfigurations.isOnboarded;

      if (!isPassed && !isOnboarded) {
        disableNewFeaturesOnboarding();
        setOnboardingConfig(ConfigurationState.EMPTY);
      } else {
        setOnboardingConfig(ConfigurationState.CONFIGURED);
      }
    }
  }, [
    setOnboardingConfig,
    workspacesIsInitialized,
    disableNewFeaturesOnboarding,
  ]);

  const widgetProps = useWidgetProps();
  const alfaDirectProps = useAlfaDirectProps();

  if (onboardingCongif === ConfigurationState.UNKNOWN) {
    return <div />;
  }

  if (onboardingCongif === ConfigurationState.EMPTY) {
    return (
      <AlfaDirectProvider value={alfaDirectProps}>
        <WidgetsApiProvider value={widgetProps}>
          <OnboardingConfig
            closeOnboardingConfigMenu={closeOnboardingConfigMenu}
          />
        </WidgetsApiProvider>
        <DesktopNotificationManager />
      </AlfaDirectProvider>
    );
  }

  return (
    <AlfaDirectProvider value={alfaDirectProps}>
      <WidgetsApiProvider value={widgetProps}>
        <div className={styles.container}>
          <Header user={user} onAddFromMenu={onAddFromMenu} />
          <WidgetServiceNotification
            variant="line"
            code={TECH_MESSAGE_CODE.MAIN}
            closable
          />
          {featureFlags.SR_EFR_ACCOUNTS_MIGRATION_BANNER && <SrEfrBanner />}
          <MarginCall />
          <Alerts />
          <Layout
            ref={layoutRef}
            onAddFromTabSetButton={onAddFromTabSetButton}
          />
          <Chat
            supportChat={supportChat}
            supportEmail={supportEmail}
            supportPhone={supportPhone}
            feedbackEmail=""
          />
          <SingletonModalProvider />
          <DesktopNotificationManager />
          {ENABLE_LK_FEATURES && (
            <>
              {!USE_PASSPORT_AUTH && <LoginProlongation />}
              <SigningDialog />
            </>
          )}
        </div>
      </WidgetsApiProvider>
    </AlfaDirectProvider>
  );
}
