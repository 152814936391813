import React, { FC, useEffect, useState } from 'react';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { ENABLE_LK_MONEY_WITHDRAWAL } from '@terminal/core/env';
import { RazdelGroupType } from '@terminal/core/lib/client/entities';
import { useStore } from '@terminal/core/store';
import { TransferStatus } from '@terminal/lk-desktop-common';

import {
  TransferCompleteParams,
  TransferResult,
} from '../../../../entities/TransferGoinvest';
import {
  MoneyInternal,
  MoneyToOther,
} from '../../../../features/TransferGoinvest';
import { MoneyIn } from '../../../../legacy/transfergoinvest/MoneyIn';
import { MoneyHistory } from '../../../../legacy/transfergoinvest/tabs/MoneyHistory';
import { Result } from '../../../../shared/ui/Result';

import styles from './MoneyTransfer.module.css';

interface Props {
  defaultSelectedAccountId?: number;
  defaultTopUpSum?: number;
}

export const MoneyTransfer: FC<Props> = React.memo(
  ({ defaultSelectedAccountId, defaultTopUpSum }) => {
    const marginCallAccount = useStore((state) => state.marginCallAccount);
    const [selectedId, setSelectedId] = useState('in');
    const [success, setSuccess] = useState<TransferCompleteParams | null>(null);

    const handleChange: TabsDesktopProps['onChange'] = (
      event,
      { selectedId }
    ) => setSelectedId(selectedId as string);

    useEffect(() => {
      if (marginCallAccount) {
        const { idRazdelGroup } = marginCallAccount;

        idRazdelGroup !== RazdelGroupType.StocksAndBondsMarket &&
          setSelectedId('move');
      }
    }, [marginCallAccount]);

    const handleSuccess = (data: TransferCompleteParams) => {
      setSuccess(data);
    };

    const isCurrency = Boolean(
      success && success.currency !== 'RUB' && success.currency !== 'RUR'
    );

    if (success && !isCurrency) {
      const transferAmount = formatAmount({
        value: success.amount || 0,
        currency: success.currency as CurrencyCodes,
        minority: MINORITY,
        view: 'withZeroMinorPart',
      });

      return (
        <Result
          title="Перевод отправлен"
          icon={<CheckmarkMIcon className={styles.glyph} />}
          ellipseColor="var(--color-light-graphic-positive)"
        >
          <TransferResult
            onAction={() => setSuccess(null)}
            actionText="Сделать новый перевод"
          >
            <>
              Перевод на сумму {transferAmount.formattedWithCurrency} со счёта{' '}
              {success.fromAccount} отправлен.
              {success.saveTemplate && (
                <>
                  <br /> Реквизиты сохранены как «{success.templateName}».
                </>
              )}
            </>
          </TransferResult>
        </Result>
      );
    }

    if (success && isCurrency) {
      return (
        <Result
          title="Поручение передано в обработку"
          icon={<CheckmarkMIcon />}
        >
          <TransferResult
            onAction={() => setSuccess(null)}
            orderStatus={success.status}
          >
            <>
              Обработка поручений осуществляется только в&nbsp;рабочие дни
              до&nbsp;18:00 по&nbsp;МСК. По&nbsp;факту исполнения поручения
              денежный перевод на&nbsp;вывод валюты появится в&nbsp;разделе
              Пополнения и&nbsp;переводы&nbsp;– История.
            </>
          </TransferResult>
        </Result>
      );
    }

    return (
      <>
        <TabsDesktop
          selectedId={selectedId}
          onChange={handleChange}
          scrollable
          size="s"
        >
          <Tab title="Пополнение" id="in" className={styles.tab}>
            <MoneyIn
              defaultSelectedAccountId={defaultSelectedAccountId}
              defaultTopUpSum={defaultTopUpSum}
            />
          </Tab>
          <Tab
            title="Вывод"
            id="bank"
            hidden={!ENABLE_LK_MONEY_WITHDRAWAL}
            className={styles.tab}
          >
            <MoneyToOther onSuccess={handleSuccess} />
          </Tab>
          <Tab title="Внутренние" id="move" className={styles.tab}>
            <MoneyInternal />
          </Tab>
          <Tab title="История" id="history" className={styles.tab}>
            <MoneyHistory />
          </Tab>
        </TabsDesktop>
        <TransferStatus />
      </>
    );
  }
);
