import { useEffect, useMemo, useState } from 'react';

import {
  getDocumentsThemesAI,
  getDocumentsThemesGI,
  getReportsThemesAI,
  getReportsThemesGI,
  ReportTheme,
  Treaty,
  useDocumentsTheme,
  useReportsTheme,
} from '@terminal/lk-core';

import { DEPO_REPORT_TYPES } from '../lib/const/depoReportTypes';
import { ActiveFormType } from '../model';

const REPORT_DOCUMENTS_ID = '-1';

type DocumentsThemesFetcher =
  | typeof getDocumentsThemesAI
  | typeof getDocumentsThemesGI;
type ReportsThemesFetcher =
  | typeof getReportsThemesAI
  | typeof getReportsThemesGI;

export const useDocumentsFormSelect = (
  treaty: Treaty | null,
  documentsThemesFetcher: DocumentsThemesFetcher,
  reportsThemesFetcher: ReportsThemesFetcher
) => {
  const [selectedDocument, setSelectedDocument] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<string>('');

  const { isFetching: documentsThemesPending, data: documentsThemes } =
    useDocumentsTheme(treaty?.treaty, documentsThemesFetcher);
  const { isFetching: reportsThemesPending, data: reportsThemes } =
    useReportsTheme(treaty?.treaty, reportsThemesFetcher);

  useEffect(() => {
    if (selectedDocument !== REPORT_DOCUMENTS_ID) {
      setSelectedReport('');
    }
  }, [selectedDocument]);

  const documentOptions = useMemo(() => {
    let options = [
      {
        key: REPORT_DOCUMENTS_ID,
        content: 'Отчёт',
      },
    ];

    if (documentsThemes?.themes) {
      options = options.concat(
        documentsThemes.themes.map((doc) => ({
          key: doc.id.toString(),
          content: doc.theme,
        }))
      );
    }

    return options;
  }, [documentsThemes]);

  const selectedDocumentTheme = useMemo(() => {
    if (documentsThemes?.themes && selectedDocument) {
      const selected = documentsThemes.themes.filter(
        (doc) => doc.id.toString() === selectedDocument
      );

      return selected.length > 0 ? selected[0] : undefined;
    }

    return undefined;
  }, [documentsThemes, selectedDocument]);

  const reportOptions = useMemo(() => {
    if (reportsThemes?.themes) {
      return reportsThemes.themes.map((theme) => ({
        key: theme.id.toString(),
        content: theme.theme,
      }));
    }

    return [];
  }, [reportsThemes?.themes]);

  const selectedReportTheme = useMemo<ReportTheme | undefined>(() => {
    if (reportsThemes?.themes && selectedReport) {
      const selected = reportsThemes?.themes.filter(
        (report) => report.id.toString() === selectedReport
      );

      return selected.length > 0 ? selected[0] : undefined;
    }

    return undefined;
  }, [reportsThemes?.themes, selectedReport]);

  const activeForm: ActiveFormType = useMemo(() => {
    const isReportSelected = selectedDocument === REPORT_DOCUMENTS_ID;
    const isDepoReport = DEPO_REPORT_TYPES.includes(parseInt(selectedReport));

    if (selectedDocument && isReportSelected) {
      return isDepoReport ? 'depoReport' : 'report';
    } else if (selectedDocument) {
      return 'document';
    }

    return undefined;
  }, [selectedDocument, selectedReport]);

  return {
    documents: {
      selectedId: selectedDocument,
      selectedTheme: selectedDocumentTheme,
      setSelectedId: setSelectedDocument,
      loading: documentsThemesPending,
      rawData: documentsThemes,
      options: documentOptions,
    },
    reports: {
      selectedId: selectedReport,
      selectedTheme: selectedReportTheme,
      setSelectedId: setSelectedReport,
      loading: reportsThemesPending,
      rawData: reportsThemes,
      options: reportOptions,
    },
    activeForm,
    shouldDisplayReportSelector: selectedDocument === REPORT_DOCUMENTS_ID,
  };
};
