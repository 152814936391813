import { WorkspaceConfigurationLS } from '@terminal/core/store/slices/workspaceConfigurationsSlice';
import { WatchList } from '@terminal/core/types/watchList';

export enum WorkspaceConfigurationScreen {
  List = 'List',
  Export = 'Export',
  Add = 'Add',
  Cloud = 'Cloud',
}

export interface WorkspaceConfigurationExport {
  configuration: WorkspaceConfigurationLS;
  watchLists: WatchList[];
}
