import flatten from 'lodash/flatten';
import isNumber from 'lodash/isNumber';

import { roundPrice } from '@terminal/core/lib/format';

import { INDICES_CHIPSE } from './const';

import { ChartIndex, ProfitChartPoint } from '../model/types';

const LABEL_HEIGHT_PX = 16;

const getPointActivePrices = (
  tick: ProfitChartPoint,
  selected: ChartIndex[]
): number[] => {
  return [tick.portfolio, ...selected.map((index) => tick[index])].filter(
    (tick) => isNumber(tick)
  ) as number[];
};

type ChartLabelType = ChartIndex | 'portfolio' | 'zero';
interface LabelProps {
  type: ChartLabelType;
  price: number;
  y: number;
  stroke?: string;
  color?: string;
  hideLabel: boolean;
}

interface MinMaxLabelProps {
  price: number;
  y: number;
}

/**
 * Функция подсчитывает на каких уровнях расставить маркеры цен, чтобы они не пересекались визуально
 * Расчет идет по принципу отталкивания остальных маркеров от маркера портфельной цены.
 * Маркер нулевого уровня не двигается м скрывается если на него наезжает другой маркер
 */
export const calculateProfitChartLabels = (
  data: ProfitChartPoint[],
  loadedIndices: ChartIndex[],
  chartHeight: number
) => {
  const lastTick = data.at(-1);

  if (!lastTick) {
    return {
      labels: [],
      minMaxTicks: [],
    };
  }

  const allChartDots = flatten<number>(
    data.map((tick) => getPointActivePrices(tick, loadedIndices))
  );
  const minDotPrice = Math.min(...allChartDots);
  const maxDotPrice = Math.max(...allChartDots);
  const currentPrice = lastTick.portfolio || 0;

  const chartHeightPriceDiff = maxDotPrice - minDotPrice;
  const percentPerPx = chartHeightPriceDiff / chartHeight;
  const labelsDiffPercent = roundPrice(LABEL_HEIGHT_PX * percentPerPx);

  const lastMarkers = [
    ...['portfolio', ...loadedIndices].map((type) => ({
      type,
      price: lastTick[type as ChartIndex],
      y: lastTick[type as ChartIndex],
      stroke:
        type === 'portfolio' ? 'var(--color-light-border-primary)' : 'none',
      color: INDICES_CHIPSE[type]?.color || 'var(--color-light-text-primary)',
      hideLabel: false,
    })),
  ];

  // Есть отрицательные значения
  if (minDotPrice < 0) {
    lastMarkers.push({
      type: 'zero' as ChartLabelType,
      price: 0,
      y: 0,
      stroke: 'var(--color-light-border-primary)',
      color: 'var(--color-light-text-secondary)',
      hideLabel: false,
    });
  }

  const labels = lastMarkers.sort(
    (a, b) => (a.price || 0) - (b.price || 0)
  ) as LabelProps[];

  const portfolioIndex = labels.findIndex((l) => l.type === 'portfolio');

  // Отодвигаем маркеры снизу скрывая 0 если нужно
  for (let i = portfolioIndex - 1; i >= 0; i--) {
    const prev = labels[i + 1];

    if (
      labels[i].type === 'zero' &&
      prev.y - labels[i].price < labelsDiffPercent
    ) {
      labels[i].hideLabel = true;
      continue;
    }

    let prevIndex = i + 1;

    while (labels[prevIndex].hideLabel) {
      prevIndex++;
    }

    if (labels[prevIndex]) {
      labels[i].y =
        prev.y - Math.max(labelsDiffPercent, prev.y - labels[i].price);
    }
  }

  // Отодвигаем маркеры сверху скрывая 0 если нужно
  for (let j = portfolioIndex + 1; j < labels.length; j++) {
    const prev = labels[j - 1];

    if (
      labels[j].type === 'zero' &&
      labels[j].price - prev.y < labelsDiffPercent
    ) {
      labels[j].hideLabel = true;
      continue;
    }

    let prevIndex = j - 1;

    while (labels[prevIndex]?.hideLabel) {
      prevIndex--;
    }

    if (labels[prevIndex]) {
      labels[j].y =
        prev.y + Math.max(labelsDiffPercent, labels[j].price - prev.y);
    }
  }

  const minMaxTicks: MinMaxLabelProps[] = [];

  if (!loadedIndices.length || !lastTick) {
    if (minDotPrice !== currentPrice && minDotPrice !== 0) {
      const closest = Math.min(currentPrice, 0);

      minMaxTicks.push({
        price: minDotPrice,
        y:
          minDotPrice -
          (closest - minDotPrice > labelsDiffPercent
            ? 0
            : labelsDiffPercent - closest + minDotPrice),
      });
    }

    if (maxDotPrice !== currentPrice && maxDotPrice !== 0) {
      const closest = Math.max(currentPrice, 0);

      minMaxTicks.push({
        price: maxDotPrice,
        y:
          maxDotPrice +
          (maxDotPrice - closest > labelsDiffPercent
            ? 0
            : labelsDiffPercent - maxDotPrice + closest),
      });
    }
  }

  return {
    labels,
    minMaxTicks,
  };
};
