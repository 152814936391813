import cn from 'classnames';
import React, { ElementType, ReactElement } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './SelectSecurityItem.module.css';

const renderIcon = (
  Icon: MoneyAccountItemProps['icon'],
  iconClassName: string
) => {
  if (React.isValidElement<{ className?: string }>(Icon)) {
    return React.cloneElement(Icon as ReactElement, {
      className: cn(iconClassName, Icon.props.className),
    });
  }

  if (Icon) {
    const IconComponent = Icon as ElementType<{ className?: string }>;

    return <IconComponent className={iconClassName} />;
  }

  return null;
};

interface MoneyAccountItemProps {
  value: string;
  caption?: string;
  className?: string;
  icon?: ElementType<{ className?: string }> | ReactElement;
}

export const SelectSecurityItem = ({
  value,
  caption,
  className,
  icon: Icon,
}: MoneyAccountItemProps) => {
  return (
    <div className={cn(styles.container, className)}>
      {Icon && (
        <span className={styles.iconWrapper}>
          {renderIcon(Icon, styles.icon)}
        </span>
      )}
      <Typography.Text view="primary-small">{value}</Typography.Text>
      {caption && (
        <Typography.Text
          view="secondary-large"
          weight="medium"
          color="secondary"
        >
          {caption}
        </Typography.Text>
      )}
    </div>
  );
};
