import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { InputDesktop } from '@alfalab/core-components/input/desktop';

interface ReceiveInOfficeProps {
  city: string;
  onCityChange: (city: string) => void;
  office: string;
  onOfficeChange: (office: string) => void;
}

export const ReceiveInOffice = ({
  city,
  onCityChange,
  office,
  onOfficeChange,
}: ReceiveInOfficeProps) => {
  return (
    <Row>
      <Col width={6}>
        <InputDesktop
          label="Город"
          labelView="outer"
          block
          size="s"
          value={city}
          onChange={(_, { value }) => onCityChange(value)}
        />
      </Col>
      <Col width={6}>
        <InputDesktop
          label="Офис"
          labelView="outer"
          block
          size="s"
          value={office}
          onChange={(_, { value }) => onOfficeChange(value)}
        />
      </Col>
    </Row>
  );
};
