import { LifeTime, Messages, OrderType } from '../lib/client/entities';

import { OrderBookLine } from '../types/orderBook';

export interface SetOrderParams {
  idOrderType: OrderType;
  buy: boolean;
  bestOffer?: boolean;
  quantity: number;
  stopPrice?: number;
  inputPrice: number;
}

export enum ActivationTime {
  NOW = 1,
  HOUR = 2,
  DAY = 3,
  WEEK = 4,
}

export interface LifeTimeOptionShape {
  key: string;
  content: string;
  value: LifeTime;
  description?: string;
  // Этот тип заявки нельзя выбирать в селекторах
  isHidden?: boolean;
}

export interface ActivationOptionShape {
  key: string;
  content: string;
  value: ActivationTime;
}

//Опции выбора Срока исполнения в форме Торгового приказа
export const lifeTimeOptions: LifeTimeOptionShape[] = [
  {
    key: 'D30',
    content: 'DAY 30',
    value: LifeTime.D30,
    description: 'Заявка, действующая 30 дней',
  },
  {
    key: 'GTC',
    content: 'GTC',
    value: LifeTime.GTD,
    description: 'Заявка, действующая до отмены',
  },
  {
    key: 'DAY',
    content: 'DAY',
    value: LifeTime.DAY,
    description: 'Заявка, действующая в течение дня выставления',
  },
  {
    key: 'GTD',
    content: 'GTD',
    value: LifeTime.GTD,
    description: 'Заявка, действующая до определенной даты',
    isHidden: true,
  },
  {
    key: 'GTT',
    content: 'GTT',
    value: LifeTime.GTT,
    description: 'Заявка, действующая до определенной даты и времени',
    isHidden: true,
  },
  {
    key: 'IMM',
    content: 'IMM',
    value: LifeTime.IMM,
    description: 'Заявка, исполняемая сразу или отменяемая',
    isHidden: true,
  },
  // {
  //   key: 'MOD',
  //   content: 'MOD',
  // },
  // {
  //   key: 'FOK',
  //   content: 'FOK',
  // },
];

//Опции выбора Активации в форме Торгового приказа
export const activationOptions: ActivationOptionShape[] = [
  {
    key: 'NOW',
    content: 'Сразу',
    value: ActivationTime.NOW,
  },
  {
    key: 'HOUR',
    content: 'Через 1 час',
    value: ActivationTime.HOUR,
  },
  {
    key: 'DAY',
    content: 'Через 1 день',
    value: ActivationTime.DAY,
  },
  {
    key: 'WEEK',
    content: 'Через 1 неделю',
    value: ActivationTime.WEEK,
  },
];

export const activationOptionsMap: Record<string, ActivationOptionShape> =
  activationOptions.reduce(
    (acc, option) => ({
      ...acc,
      [option.value]: option,
    }),
    {}
  );

export interface OrderTypeOption {
  key: string;
  content: string;
  value: OrderType;
  description: string;
}

export const orderTypeOptions: OrderTypeOption[] = [
  {
    key: String(OrderType.LMT),
    content: 'Лимитная',
    value: OrderType.LMT,
    description:
      'Заявка, которая будет исполнена по указанной или лучшей цене, когда появится соответствующая встречная заявка',
  },
  {
    key: String(OrderType.MKT),
    content: 'Рыночная',
    value: OrderType.MKT,
    description: 'Заявка, которая будет исполнена по рыночной цене',
  },
  {
    key: String(OrderType.STL),
    content: 'Стоп-лимит',
    value: OrderType.STL,
    description:
      'Лимитная заявка, которая будет выставлена по стоп-цене + проскок (для покупок) или стоп-цене - проскок (для продаж), если на рынке пройдет сделка по указанной стоп-цене',
  },
  {
    key: String(OrderType.STP),
    content: 'Стоп-маркет',
    value: OrderType.STP,
    description:
      'Заявка, которая будет исполнена по любой доступной в моменте цене, если на рынке пройдет сделка по указанной стоп-цене',
  },
  {
    key: String(OrderType.TRS),
    content: 'Трейлинг стоп-маркет',
    value: OrderType.TRS,
    description:
      'Стоп заявка, которая будет исполнена по любой доступной в моменте цене, если в рынке пройдет сделка по указанной стоп-цене. При этом указанная стоп-цена следует за ценой рынка на фиксированном в момент выставления расстоянии. Заявка двигается, если расстояние между ценой и заявкой увеличивается и не меняется, если расстояние уменьшается',
  },
  {
    key: String(OrderType.BRS),
    content: 'Стоп-маркет и Тейк-профит',
    value: OrderType.BRS,
    description:
      'Парная заявка, при исполнении одной стороны противоположная заявка снимается. Заявка Stop market будет исполнена по любой доступной в моменте цене, если в рынке пройдет сделка по указанной стоп-цене. Заявка Take Profit будет исполнена по указанной или лучшей цене, когда появится соответствующая встречная заявка.',
  },
  {
    key: String(OrderType.TBRS),
    content: 'Трейлинг стоп-маркет и Тейк-профит',
    value: OrderType.TBRS,
    description:
      'Пара связанных заявок. Первая (трейлинг стоп-маркет) выставляется для минимизации потерь, если цена инструмента начала движение в убыточном направлении с изменяемым значением стоп цены. Вторая (Тейк-профит) выставляется для фиксации прибыли при достижении цены финансового инструмента прогнозируемого уровня. Для этого выставляется лимит-заявка с ценой, соответствующей прогнозируемому уровню.',
  },
  // Типы заявок ниже будут добавлены позже
  // {
  //   key: OrderType.STL,
  //   content: 'Стоп лимит',
  //   value: OrderType.STL,
  //   description:
  //     'Лимитная заявка, которая будет выставлена по стоп-цене + проскок (для покупок) или стоп-цене - проскок (для продаж), если на рынке пройдет сделка по указанной стоп-цене.',
  // },
  // {
  //   key: OrderType.TRL,
  //   content: 'Трейлинг лимит',
  //   value: OrderType.TRL,
  //   description:
  //     'Лимитная заявка, которая следует за ценой рынка на фиксированном в момент выставления расстоянии. Заявка двигается, если расстояние между ценой и заявкой увеличивается и не меняется, если расстояние уменьшается.',
  // },
  // {
  //   key: OrderType.TSL,
  //   content: 'Трейлинг стоп лимит',
  //   value: OrderType.TSL,
  //   description:
  //     'Лимитная заявка, которая будет выставлена по стоп-цене + проскок (для покупок) или стоп-цене - проскок(для продаж), если в рынке пройдет сделка по указанной стоп цене. При этом указанная стоп-цена следует за ценой рынка на фиксированном в момент выставления расстоянии.  Заявка двигается, если расстояние между ценой и заявкой увеличивается и не меняется, если расстояние уменьшается.',
  // },
  // {
  //   key: OrderType.BSL,
  //   content: 'Стоп лимит и тейк профит',
  //   value: OrderType.BSL,
  //   description:
  //     'Парная заявка, при исполнении одной стороны противоположная заявка снимается. Заявка Stop limit будет выставлена по стоп-цене + проскок(для покупок) или стоп-цене - проскок(для продаж), если на рынке пройдет сделка по указанной стоп-цене. Заявка Take-profit будет исполнена по указанной или лучшей цене, когда появится соответствующая встречная заявка.',
  // },
];

export const TestRequiredCodes = [
  Messages.OrderRejected_TestRequired_REPO, // Не пройден тест по договорам РЕПО. https://lk.alfadirect.ru/testing/1
  Messages.OrderRejected_TestRequired_MarginTrading, // Не пройден тест по маржинальной торговле. https://lk.alfadirect.ru/testing/2
  Messages.OrderRejected_TestRequired_DerivativesTrading, // Не пройден тест по ПФИ. https://lk.alfadirect.ru/testing/3
  Messages.OrderRejected_TestRequired_ForeignSharesTrading, // Для данной операции нужно пройти тест по иностранным акциям или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/6
  Messages.OrderRejected_TestRequired_ETFTrading, // Для данной операции нужно пройти тест по ETF или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/10
  Messages.OrderRejected_TestRequired_UnlistedSharesTrading, // Для данной операции нужно пройти тест по акциям, не включенным в котировальные списки, или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/4
  Messages.OrderRejected_TestRequired_NoRatingRussianBondsTrading, // Для данной операции нужно пройти тест по российским облигациям без необходимого рейтинга или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/8
  Messages.OrderRejected_TestRequired_NoRatingForeignBondsTrading, // Для данной операции нужно пройти тест по иностранным облигациям без необходимого рейтинга или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/7
  Messages.OrderRejected_TestRequired_StructuredBondsTrading, // Для данной операции нужно пройти тест по структурным облигациям или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/11
  Messages.OrderRejected_TestRequired_StructuredIncomeBondsTrading, // Для данной операции нужно пройти тест по облигациям со структурным доходом или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/9
  Messages.OrderRejected_TestRequired_CEMFTrading, // Для данной операции нужно пройти тест по паям ЗПИФов или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/5

  // Generic сообщения что нужен статус квала без конкретного теста
  Messages.OrderRejected_QualStatusRequired, // Заявка #{0} отклонена. Требуется получение статуса квалифицированного инвестора.
  Messages.OrderRejected_QualStatusRequired_StockExchg, // Заявка #{0} отклонена. Спасибо, что получили статус квалифицированного инвестора. Торговля инструментов будет доступна, начиная со следующего дня.
];

export const TestLinksMap = new Map<number, string>([
  [
    Messages.OrderRejected_TestRequired_REPO,
    'https://lk.alfadirect.ru/testing/1',
  ],
  [
    Messages.OrderRejected_TestRequired_MarginTrading,
    'https://lk.alfadirect.ru/testing/2',
  ],
  [
    Messages.OrderRejected_TestRequired_DerivativesTrading,
    'https://lk.alfadirect.ru/testing/3',
  ],
  [
    Messages.OrderRejected_TestRequired_ForeignSharesTrading,
    'https://lk.alfadirect.ru/testing/6',
  ],
  [
    Messages.OrderRejected_TestRequired_ETFTrading,
    'https://lk.alfadirect.ru/testing/10',
  ],
  [
    Messages.OrderRejected_TestRequired_UnlistedSharesTrading,
    'https://lk.alfadirect.ru/testing/4',
  ],
  [
    Messages.OrderRejected_TestRequired_NoRatingRussianBondsTrading,
    'https://lk.alfadirect.ru/testing/8',
  ],
  [
    Messages.OrderRejected_TestRequired_NoRatingForeignBondsTrading,
    'https://lk.alfadirect.ru/testing/7',
  ],
  [
    Messages.OrderRejected_TestRequired_StructuredBondsTrading,
    'https://lk.alfadirect.ru/testing/11',
  ],
  [
    Messages.OrderRejected_TestRequired_StructuredIncomeBondsTrading,
    ' https://lk.alfadirect.ru/testing/9',
  ],
  [
    Messages.OrderRejected_TestRequired_CEMFTrading,
    'https://lk.alfadirect.ru/testing/5',
  ],
]);

export const MIN_LINES_LENGTH = 40;

export const DEFAULT_LINE: OrderBookLine = {
  Price: 0,
  BuyQty: 0,
  SellQty: 0,
  Yield: 0,
  LineId: 0,
  Revision: 0n,
};

export const DEFAULT_LINES = new Array<OrderBookLine>(MIN_LINES_LENGTH).fill(
  DEFAULT_LINE
);
