import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { useWidgetContext } from '../../shared';
import { ContextMenuProviderProps } from './ContextMenuProvider';

export function useContextMenuProviderProps(): ContextMenuProviderProps {
  const widgetApi = useWidgetContext();
  const alfaDirectApi = useAlfaDirectContext();

  return { widgetApi, alfaDirectApi };
}
