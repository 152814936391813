import cn from 'classnames';
import { Tag } from '@alfalab/core-components/tag';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { ObjectGroup } from '@terminal/core/lib/client/entities';

import { Dropdown } from '../../../components/Dropdown';

import styles from './TypeOptions.module.css';

const filtersByType = [
  {
    name: 'Акции',
    idObjectGroup: [ObjectGroup.Stocks, ObjectGroup.Receipts],
  },
  {
    name: 'Облигации',
    idObjectGroup: [ObjectGroup.Bonds],
  },
  {
    name: 'Фьючерсы',
    idObjectGroup: [ObjectGroup.Futures],
  },
  {
    name: 'Фонды',
    idObjectGroup: [ObjectGroup.EquityParticipations],
  },
  {
    name: 'Валюты',
    idObjectGroup: [ObjectGroup.Currency],
  },
  {
    name: 'Металлы',
    idObjectGroup: [ObjectGroup.Metals],
  },
];

interface TypeOptionsProps {
  isShowChips: boolean;
  selectedTypes: ObjectGroup[];
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeOptions = ({
  isShowChips,
  selectedTypes,
  saveTypes,
}: TypeOptionsProps) =>
  isShowChips ? (
    <>
      {filtersByType.map(({ name, idObjectGroup }) => {
        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Tag
            checked={checked}
            key={name}
            size="xxs"
            onClick={() => {
              if (checked) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
            className={cn(
              styles.statusTag,
              !isShowChips && styles.orderStatusFiltersFilterInSelect,
              checked && styles.orderStatusFilterChecked
            )}
          >
            <Typography.Text view="secondary-small">{name}</Typography.Text>
          </Tag>
        );
      })}
    </>
  ) : (
    <>
      {filtersByType.map(({ name, idObjectGroup }) => {
        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Dropdown.Item
            key={name}
            leftIcon={
              checked ? (
                <CheckmarkOnCircleMIcon
                  color="#7938e0"
                  height={18}
                  width={18}
                />
              ) : (
                <SelectionMaskMIcon color="#8D8D93" height={18} width={18} />
              )
            }
            content={
              <Typography.Text view="secondary-large">{name}</Typography.Text>
            }
            onClick={() => {
              if (checked && selectedTypes.length !== 0) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
          />
        );
      })}
    </>
  );
