import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import { ObjectGroup } from '@terminal/core/lib/client/entities';

import { Dropdown } from '../../../components/Dropdown';
import { TypeOptions } from '../TypeOptions';

import styles from './TypeFilters.module.css';

const filtersByType = [
  {
    name: 'Акции',
    idObjectGroup: [ObjectGroup.Stocks, ObjectGroup.Receipts],
  },
  {
    name: 'Облигации',
    idObjectGroup: [ObjectGroup.Bonds],
  },
  {
    name: 'Фьючерсы',
    idObjectGroup: [ObjectGroup.Futures],
  },
  {
    name: 'Фонды',
    idObjectGroup: [ObjectGroup.EquityParticipations],
  },
  {
    name: 'Валюты',
    idObjectGroup: [ObjectGroup.Currency],
  },
  {
    name: 'Металлы',
    idObjectGroup: [ObjectGroup.Metals],
  },
];

const allTypes = [
  ObjectGroup.Stocks,
  ObjectGroup.Receipts,
  ObjectGroup.Bonds,
  ObjectGroup.Futures,
  ObjectGroup.EquityParticipations,
  ObjectGroup.Currency,
];

interface TypeFiltersProps {
  selectedTypes: ObjectGroup[];
  isShowChips: boolean;
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeFilters = ({
  selectedTypes,
  isShowChips,
  saveTypes,
}: TypeFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const isEverySelected = useMemo(
    () =>
      !selectedTypes.length ||
      allTypes.every((idObjectGroup) => selectedTypes.includes(idObjectGroup)),
    [selectedTypes]
  );

  const filtersCount = useMemo(() => {
    if (selectedTypes.length) {
      const firstType = selectedTypes[0];
      const firstName = filtersByType.find(({ idObjectGroup }) =>
        idObjectGroup.includes(firstType)
      )?.name;

      const restTypes =
        selectedTypes.length -
        1 -
        (selectedTypes.includes(ObjectGroup.Receipts) ? 1 : 0);

      if (restTypes) {
        return `${firstName} + ${restTypes}`;
      } else {
        return firstName ?? '';
      }
    } else {
      return '';
    }
  }, [selectedTypes]);

  return isShowChips ? (
    <TypeOptions
      isShowChips={isShowChips}
      selectedTypes={selectedTypes}
      saveTypes={saveTypes}
    />
  ) : (
    <>
      <div
        ref={filtersButtonRef}
        className={cn(
          styles.orderStatusFiltersButton,
          Boolean(selectedTypes.length) && styles.activeFilterButton
        )}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {isEverySelected ? 'Все инструменты' : filtersCount}
        <ChevronDownCompactMIcon
          height={16}
          width={16}
          color="currentColor"
          className={cn(styles.chevron, isOpen && styles.chevronUp)}
        />
      </div>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={styles.positionTypeSelectPoppover}
      >
        <TypeOptions
          isShowChips={isShowChips}
          selectedTypes={selectedTypes}
          saveTypes={saveTypes}
        />
      </Dropdown>
    </>
  );
};
