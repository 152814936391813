import uniqBy from 'lodash/uniqBy';
import { InfiniteData } from 'react-query';

import { LKResult } from '@terminal/core/lib/rest/lkApi';
import { ClientAccounts, TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import { getTradeAccountKey } from './getTradeAccountKey';

export function getAllBrokerAccountsFrom(
  result: InfiniteData<ClientAccounts & LKResult>
): TradeAccount[] {
  return uniqBy(
    result.pages.flatMap((page) => page.brokerAccounts.from),
    (acc: TradeAccount) => {
      return getTradeAccountKey(acc);
    }
  );
}
