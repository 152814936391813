import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetDocumentsHistoryDTO } from './types/GetDocumentsHistoryDTO';
import { GetDocumentsHistoryParams } from './types/GetDocumentsHistoryParams';

export async function getDocumentsHistory(
  data: GetDocumentsHistoryParams
): Promise<GetDocumentsHistoryDTO> {
  const result = await fetchLkResult<GetDocumentsHistoryDTO | LKResult>(
    qs.stringifyUrl(
      {
        url: '/api/client/documents/history',
        query: { ...data },
      },
      { arrayFormat: 'bracket' }
    )
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
