import { DocumentReceiving } from '@terminal/lk-core';

import { obtainOrder } from './const/obtainOrder';

export function sortObtain(a: DocumentReceiving, b: DocumentReceiving) {
  if (obtainOrder.indexOf(a.type) < 0 && obtainOrder.indexOf(b.type) >= 0) {
    return 1;
  } else if (
    obtainOrder.indexOf(a.type) >= 0 &&
    obtainOrder.indexOf(b.type) < 0
  ) {
    return -1;
  }

  return obtainOrder.indexOf(a.type) - obtainOrder.indexOf(b.type);
}
