export const RU_PHONE_MASK = [
  '+',
  '7',
  ' ',
  /([0-6]|[8-9])/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

export const BY_PHONE_MASK = [
  '+',
  '3',
  '7',
  '5',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

export const BY_PHONE_MASK_12 = [
  '+',
  '3',
  '7',
  '5',
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  // Тут последняя единица - хак для возможности введения 13 знаков
  /\d/,
];

export const INIT_PHONE_MASK = ['+', /\d/, /\d/, /\d/, /\d/, /\d/];

export function phoneUnformat(phone: string) {
  return String(phone).replace(/\D/g, '');
}

export function getPhoneMask(str: string) {
  const phone = phoneUnformat(str);

  if (phone.startsWith('375')) {
    // Белорусский телефон может быть в двух форматах
    if (phone.length < 13) {
      return BY_PHONE_MASK_12;
    } else {
      return BY_PHONE_MASK;
    }
  }

  if ((!phone.startsWith('3') && phone.length > 0) || phone.length > 4) {
    return RU_PHONE_MASK;
  }

  return INIT_PHONE_MASK;
}

export const phonePattern = /^(7[0-9]{10})|(375[0-9]{9,10})$/;

export function isPhoneValid(phone: string) {
  return phonePattern.test(phoneUnformat(phone));
}
