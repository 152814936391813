import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { MINORITY } from '@terminal/core/constants/ui';
import { usePrevious } from '@terminal/core/hooks';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import {
  PortfolioAnalyticsWarningContainer,
  Spinner,
} from '../../../../shared';
import { Drawer } from '../../../Drawer';
import { SimpleTable } from '../../../SimpleTable';
import { mapActGroupToBanchmarkName } from '../../lib/mapActGroupToBenchmarkName';
import { mapActGroupToIncomeDescription } from '../../lib/mapActGroupToIncomeDescription';
import { ActGroupType } from '../../model/FinResult';
import { FinResultByInstrumentRecord } from '../../model/FinResultByInstrument';
import { DateFilters } from '../DateFilters';
import { IncomeByFI } from '../IncomeByFI';

import { useIncomeByInstrumentsTable } from '../../hooks/useIncomeByInstrumentsTable';

import styles from './IncomeByInstruments.module.css';

enum Column {
  SymbolObject = 'symbolObject',
  Income = 'finResultTotal',
  Actions = 'actions',
}

const COLUMN_SETTINGS = [
  {
    key: Column.SymbolObject,
    width: '1fr',
  },
  {
    key: Column.Income,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Actions,
  },
];

interface Props {
  actGroup?: ActGroupType;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  selectedAccountId: number;
}

export const IncomeByInstruments: FC<Props> = ({
  actGroup,
  dateFilter,
  setDateFilter,
  selectedAccountId,
}) => {
  const [drawerProps, setDrawerProps] = useState<Pick<
    FinResultByInstrumentRecord,
    'actId'
  > | null>(null);

  const { trackClickPortfolioReportElement, trackOpenPortfolioReportDetails } =
    usePortfolioReportsMetrics();

  const drawer = (
    <Drawer isOpen={Boolean(drawerProps)} onClose={() => setDrawerProps(null)}>
      {drawerProps ? (
        <IncomeByFI
          actId={drawerProps.actId}
          actGroup={actGroup || ActGroupType.STOCKS}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          selectedAccountId={selectedAccountId}
        />
      ) : null}
    </Drawer>
  );

  const handleOpenDrawer = useCallback(
    (row: FinResultByInstrumentRecord) => {
      setDrawerProps({ actId: row.actId });
      trackClickPortfolioReportElement(
        actGroup ? mapActGroupToBanchmarkName(actGroup) : '',
        row.ticker || row.name
      );
    },
    [actGroup, trackClickPortfolioReportElement]
  );

  const prevActGroup = usePrevious(actGroup);

  useEffect(() => {
    if (!prevActGroup && actGroup && prevActGroup !== actGroup) {
      trackOpenPortfolioReportDetails(mapActGroupToBanchmarkName(actGroup));
    }
  }, [prevActGroup, actGroup, trackOpenPortfolioReportDetails]);

  const { data, isLoading } = useIncomeByInstrumentsTable({
    actGroup,
    accountId: selectedAccountId,
    dateFrom: format(dateFilter.valueFrom.date, 'yyyy-MM-dd'),
    dateTo: format(dateFilter.valueTo.date, 'yyyy-MM-dd'),
  });

  const cellRender = useCallback(
    (columnKey, _, row) => {
      switch (columnKey) {
        case Column.SymbolObject:
          return (
            <SymbolCell
              idObject={row.idObject}
              symbolObject={row.symbolObject}
            />
          );
        case Column.Income:
          return (
            <Typography.Text view="secondary-large">
              {row.finResultTotal >= 0 ? '+' : '-'}&nbsp;
              {
                formatAmount({
                  value: Math.abs(row.finResultTotal) * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formatted
              }
              &nbsp;
              <Typography.Text
                tag="span"
                view="secondary-large"
                color="secondary"
              >
                {getSymbolByCurrency(row.currency)}
              </Typography.Text>
            </Typography.Text>
          );
        case Column.Actions:
          return (
            <IconButton
              size="xs"
              view="secondary"
              icon={ChevronRightMIcon}
              onClick={() => handleOpenDrawer(row)}
            />
          );
      }
    },
    [handleOpenDrawer]
  );

  return (
    <>
      <div className={styles.container}>
        <DateFilters value={dateFilter} onChange={setDateFilter} />

        <Gap size="l" />

        <Typography.Text
          view="primary-medium"
          weight="bold"
          className={styles.title}
        >
          {mapActGroupToBanchmarkName(actGroup || ActGroupType.STOCKS)}
        </Typography.Text>

        <Gap size="2xs" />

        <Typography.Text view="secondary-large" tag="div" color="secondary">
          {mapActGroupToIncomeDescription(actGroup || ActGroupType.STOCKS)}
        </Typography.Text>
        <Gap size="m" />
        {!isLoading && data && !data.length ? (
          <PortfolioAnalyticsWarningContainer
            Icon={ClockMIcon}
            title="Измените даты"
            description={
              <>
                Нет закрытых позиций за этот период
                <br />
                Попробуйте изменить даты
              </>
            }
          />
        ) : null}
        {isLoading ? (
          <Spinner />
        ) : (
          <SimpleTable<FinResultByInstrumentRecord & { key: string }, Column>
            columns={COLUMN_SETTINGS}
            data={data}
            cellRender={cellRender}
            onRowClick={(_, row) => handleOpenDrawer(row)}
            className={styles.table}
          />
        )}
      </div>

      <div />

      {drawer}
    </>
  );
};
