import noop from 'lodash/noop';
import { useCallback } from 'react';

import { DIMENSIONS_SCHEMAS } from '@terminal/core/constants/analytics';

import { AnalyticsTrack, useWidgetContext } from '../../../shared';
import { CompositionType } from '../model';
import { CompositionTabsNameMap } from './const';

const CATEGORY_NAME = 'Portfolio analytics';

const enum EVENT_NAME {
  OPEN = 'open',
  CLICK = 'click',
}

const enum EVENT_LABELS {
  CHIPS = 'Chips',
  ELEMENT = 'Element',
  TAB = 'Tab',
}

export const usePortfolioCompositionMetrics = () => {
  const {
    analytics: { track = noop as AnalyticsTrack } = {
      track: noop as AnalyticsTrack,
    },
  } = useWidgetContext();

  return {
    trackOpenPortfolioComposition: useCallback(() => {
      track(CATEGORY_NAME, EVENT_NAME.OPEN, EVENT_LABELS.TAB, null, null);
    }, [track]),

    trackClickPortfolioCompositionAssetChip: useCallback(
      (assetType: CompositionType) => {
        track(CATEGORY_NAME, EVENT_NAME.CLICK, EVENT_LABELS.CHIPS, null, null, [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              1: CompositionTabsNameMap[assetType],
            },
          },
        ]);
      },
      [track]
    ),

    trackClickPortfolioCompositionElement: useCallback(
      (assetType: CompositionType, elementName: string) => {
        track(
          CATEGORY_NAME,
          EVENT_NAME.CLICK,
          EVENT_LABELS.ELEMENT,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                8: CompositionTabsNameMap[assetType],
                9: elementName,
              },
            },
          ]
        );
      },
      [track]
    ),
  };
};
