import { isBefore } from 'date-fns';
import { useMemo } from 'react';

import { roundPrice } from '@terminal/core/lib/format';
import { NetValuePoint } from '@terminal/core/lib/rest/investApi';
import { lbinsearch } from '@terminal/core/lib/search';
import { ArchiveCandle } from '@terminal/core/types/chart';

import { ChartIndex, ProfitChartPoint } from '../model/types';

export const useProfitChartDots = (
  graph: NetValuePoint[],
  indicesData: Record<ChartIndex, ArchiveCandle[]>
): ProfitChartPoint[] => {
  return useMemo(() => {
    const points = graph.map(({ date, returnOnAssets }) => ({
      date: new Date(date),
      portfolio: returnOnAssets.value,
    }));

    for (let index in indicesData) {
      let firstPrice;

      for (let i = 0; i < points.length; i++) {
        const day = points[i];

        const indexClosestDotIndex = lbinsearch<Date>(
          0,
          indicesData[index].length,
          (m, date) => !isBefore(indicesData[index][m].DT!, date),
          day.date
        );

        const close =
          indicesData[index][
            Math.min(indexClosestDotIndex, indicesData[index].length - 1)
          ]?.Close ?? 0;

        if (!firstPrice) {
          firstPrice = close;
        }

        day[index] = roundPrice(
          ((close - firstPrice) / firstPrice) * 100,
          0.01
        );
      }
    }

    return points.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [graph, indicesData]);
};
