import trimEnd from 'lodash/trimEnd';

export const formatTextBeforeDisplay = (str: string): string =>
  [
    // Приводим значение в нижний регистр
    (text: string) => text.toLocaleLowerCase(),
    // Заменяет все тире и дефисы на стандартное тире
    (text: string) => text.replace(/[\u2013\u2014\u2212-]/g, '-'),
    // Удаляет последовательности тире
    (text: string) => text.replace(/-{2,}/g, '-'),
    // Удаляет пробелы и спец символы кроме тире
    (text: string) => text.replace(/[^\p{L}-]|[\s_]/gu, ''),
    // Преобразует первую букву каждого слова в заглавную, разделённых тире
    (text: string) =>
      text.replace(
        /(^|\-)([a-zA-Zа-яА-ЯёЁ])/g,
        (match, p1, p2) => p1 + p2.toUpperCase()
      ),
  ].reduce((text, fn) => fn(text), str);

export const formatTextOnBlur = (str: string): string => trimEnd(str, '-');
