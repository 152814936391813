import { defaultWatchList } from '../../../constants/WatchList';
import { IS_AI, IS_GI } from '../../../env';
import { featureFlags } from '../../../featureFlags';
import { createIndices } from '../../arrayIndex';
import {
  clientChoice,
  currenciesList,
  currencyIncomeBonds,
  DJIList,
  featuresList,
  floatersList,
  iMOEXList,
  mixedBPIF,
  MOEX10List,
  MOEXBMIList,
  NDXList,
  oilAndGasStocks,
  reliableBonds,
  replacementBondsList,
  RGBITRList,
  RUCBITRList,
  SP500List,
  taxFreeIncome,
} from './constants';

import { useStore } from '../../../store';
import { marketCodeFilter } from '../../../store/selectors';

import { MarketBoard, ObjectItem } from '../../../types/core';
import {
  WatchList,
  WatchlistIds,
  WatchListItem,
  WatchListType,
} from '../../../types/watchList';
import { SplitedFeatures, WatchListDescription } from './constants/types';

const getObjectsIndex = createIndices<
  ObjectItem,
  'idObject' | 'symbolObject' | 'isin'
>(['idObject', 'symbolObject', 'isin']);

/**
 * idMarketBoard - Если указан, то в лист будет добавляться конкретный idFI с этим рынком.
 * Если не указан, то будет добавляться первый найденный для доступного рынка
 * (подходит, когда в листте могут быть разные типы инструментов)
 */
export const makeWatchlist = (secList: WatchListDescription) => {
  const objects = useStore.getState().objects;
  const objectsIndex = getObjectsIndex(objects);

  const finInstruments = useStore.getState().finInstruments;
  const instruments = secList.fis
    .map((fi) => {
      const searchedId = typeof fi === 'string' ? fi : fi.name;
      const objectFi =
        objectsIndex.bySymbolObject.get(searchedId) ||
        objectsIndex.byIsin.get(searchedId);
      const idObject = objectFi?.idObject;

      // Условие предиката специально разбито на разные этапы для оптимизации поиска по массиву
      const FI = finInstruments.find((f) => {
        if (f.idObject !== idObject) {
          return false;
        }

        if (secList.idMarketBoard !== undefined) {
          return secList.idMarketBoard === f.idMarketBoard;
        }

        const codeMarketBoard = MarketBoard[f.idMarketBoard];
        const isAllowedMarketBoard = marketCodeFilter[codeMarketBoard];

        return isAllowedMarketBoard;
      });

      if (!(idObject && FI)) {
        return null;
      }

      return {
        idObject,
        codeMarketBoard: FI.idMarketBoard,
        symbolObject: typeof fi === 'string' ? fi : fi.name,
        idFI: FI.idFI,
        calculated: typeof fi === 'string' ? false : fi.calculated,
      };
    })
    .filter(Boolean);

  const watchList: WatchList = {
    type: WatchListType.GOINVEST,
    id: secList.id,
    name: secList.listName,
    instruments: instruments as unknown as WatchListItem[],
  };

  return watchList;
};

export const makeFeaturesList = (instruments?: WatchListItem[]) => {
  const objects = useStore.getState().objects;
  // выбираем фьючерсы с датой экспирации болше или равно сегодня
  const filterDate = (matDate: Date) => {
    const today = new Date();
    const expirationDate = new Date(matDate);

    return expirationDate >= today;
  };
  const objectsIndex = getObjectsIndex(objects);
  // если есть сохраненный список, разделяем его на добавленные пользователем и дефолтные,
  // требующие проверки дат экспирации (calculated), для дефолтных получаем базовый актив
  const splitedInstruments: SplitedFeatures = instruments
    ? (instruments?.reduce(
        (acc, feature) => {
          const featureObject = objectsIndex.byIdObject.get(feature.idObject);

          if (feature.calculated) {
            return featureObject
              ? {
                  ...acc,
                  default: [...acc.default, featureObject.idObjectBase],
                }
              : acc;
          }

          return featureObject
            ? {
                ...acc,
                custom: [...acc.custom, featureObject.nameObject],
              }
            : acc;
        },
        { default: [], custom: [] } as SplitedFeatures
      ) as unknown as SplitedFeatures)
    : { default: [], custom: [] };
  // избавляемся от дублирования базовых активов
  const deduplicatedList = [...new Set(splitedInstruments.default)];
  // если есть сохраненные списки, берем их, иначе - дефолтный
  const calculatedFeatures = splitedInstruments.default.length
    ? deduplicatedList
    : featuresList;
  const list: { name: string; calculated: boolean }[] = [];

  // для каждого базового актива находим подходящие фьючи (не пользовательские)
  calculatedFeatures.forEach((baseId) => {
    const featuresWithBaseId = objects.filter(
      (obj) =>
        // может быть расчетный фьюч (17) или поставочный (18)
        (obj.idObjectType.value === 17 || obj.idObjectType.value === 18) &&
        obj.idObjectBase === baseId &&
        filterDate(obj.matDateObject)
    );
    // сортируем по дате экспрации и берем idObject первыx двух
    const closestFeatures = featuresWithBaseId
      .sort((a, b) => {
        return new Date(a.matDateObject) > new Date(b.matDateObject) ? 1 : -1;
      })
      .slice(0, 2);

    closestFeatures.forEach((feature) =>
      list.push({ name: feature.nameObject, calculated: true })
    );
  });

  return splitedInstruments?.custom.length
    ? [...list, ...splitedInstruments.custom]
    : list;
};

export const makeBondsList = (
  userList: WatchListItem[] = [],
  defaultList: string[]
) => {
  const objects = useStore.getState().objects;
  const isExpiredDate = (matDate: Date) => {
    const today = new Date();
    const expirationDate = new Date(matDate);

    return expirationDate <= today;
  };

  // Список актуальных дефолтных облигаций
  const validBonds = objects
    .filter((obj) => defaultList.includes(obj?.isin || ''))
    .filter((obj) => !isExpiredDate(obj.matDateObject))
    .map((obj) => obj.isin)
    .filter((obj) => obj !== undefined) as string[];

  // Список добавленных пользователем объектов
  const uniqUserItems = userList
    ?.map((obj) => {
      const object = objects.find((o) => o.idObject === obj.idObject);

      return object?.isin;
    })
    ?.filter((isin) => !defaultList.includes(isin || ''))
    ?.filter((obj) => obj !== undefined) as string[];

  return [...uniqUserItems, ...validBonds];
};

export const createFuturesItem = (
  props: Partial<WatchListDescription> | void
) => {
  const { fis } = props || {};

  return makeWatchlist({
    fis: fis || makeFeaturesList(),
    idMarketBoard: 56,
    listName: 'Фьючерсы',
    id: WatchlistIds.featuresList,
  });
};

export const createReplacementBondsList = (watchListItem?: WatchListItem[]) => {
  return makeWatchlist({
    fis: makeBondsList(watchListItem, replacementBondsList),
    idMarketBoard: MarketBoard.TQXXT1,
    listName: 'Замещающие облигации',
    id: WatchlistIds.replacementBondsList,
  });
};

export const createReliableBondsList = (watchListItem?: WatchListItem[]) => {
  return makeWatchlist({
    fis: makeBondsList(watchListItem, reliableBonds),
    idMarketBoard: MarketBoard.TQXXT1,
    listName: 'Надежные облигации',
    id: WatchlistIds.reliableBonds,
  });
};

export const createCurrencyIncomeBondsList = (
  watchListItem?: WatchListItem[]
) => {
  return makeWatchlist({
    fis: makeBondsList(watchListItem, currencyIncomeBonds),
    idMarketBoard: MarketBoard.TQXXT1,
    listName: 'Валютный доход',
    id: WatchlistIds.currencyIncomeBonds,
  });
};

export const refreshItemInstruments = (
  source: WatchListItem[]
): WatchListItem[] => {
  const objects = useStore.getState().objects;
  const { byIdObject } = getObjectsIndex(objects);
  const now = new Date();

  return source.filter((item) => {
    const object = byIdObject.get(item.idObject);

    return object?.matDateObject ? object.matDateObject > now : true;
  });
};

export const createDefaultWatchLists = () => {
  let result = [
    defaultWatchList,
    makeWatchlist({
      fis: iMOEXList,
      idMarketBoard: 92,
      listName: 'Акции iMOEX',
      id: WatchlistIds.iMOEXList,
    }),
    makeWatchlist({
      fis: MOEX10List,
      idMarketBoard: 92,
      listName: 'Акции MOEX10',
      id: WatchlistIds.MOEX10List,
    }),
    makeWatchlist({
      fis: MOEXBMIList,
      idMarketBoard: 92,
      listName: 'Акции MOEXBMI',
      id: WatchlistIds.MOEXBMIList,
    }),
  ];

  if (IS_GI && featureFlags.AMERICAN_INDICIES_ENABLED) {
    result = [
      ...result,
      makeWatchlist({
        fis: DJIList,
        idMarketBoard: 119,
        listName: 'Акции DJIA',
        id: WatchlistIds.DJIList,
      }),
      makeWatchlist({
        fis: NDXList,
        idMarketBoard: 119,
        listName: 'Акции NDX',
        id: WatchlistIds.NDXList,
      }),
      makeWatchlist({
        fis: SP500List,
        idMarketBoard: 119,
        listName: 'Акции S&P500',
        id: WatchlistIds.SP500List,
      }),
    ];
  }

  result = [
    ...result,
    makeWatchlist({
      fis: RGBITRList,
      idMarketBoard: 150,
      listName: 'Облигации RGBITR',
      id: WatchlistIds.RGBITRList,
    }),
    makeWatchlist({
      fis: RUCBITRList,
      idMarketBoard: 150,
      listName: 'Облигации RUCBITR ',
      id: WatchlistIds.RUCBITRList,
    }),
    makeWatchlist({
      fis: floatersList,
      idMarketBoard: 150,
      listName: 'Облигации - флоатеры',
      id: WatchlistIds.floatersList,
    }),
  ];

  if (IS_AI) {
    result = [
      ...result,
      makeWatchlist({
        fis: mixedBPIF,
        idMarketBoard: 92,
        listName: 'Смешанные БПИФ',
        id: WatchlistIds.mixedBPIF,
      }),
      makeWatchlist({
        fis: oilAndGasStocks,
        idMarketBoard: 92,
        listName: 'Аĸции нефти и газа',
        id: WatchlistIds.oilAndGasStocks,
      }),
      makeWatchlist({
        fis: taxFreeIncome,
        listName: 'Доход без налогов',
        id: WatchlistIds.taxFreeIncome,
      }),
      makeWatchlist({
        fis: clientChoice,
        listName: 'Выбор 85% ĸлиентов',
        id: WatchlistIds.clientChoice,
      }),
    ];
    result.push(createReliableBondsList());
    result.push(createCurrencyIncomeBondsList());
  }

  if (IS_GI) {
    result = [
      ...result,
      makeWatchlist({
        fis: currenciesList,
        idMarketBoard: 114,
        listName: 'Валюта',
        id: WatchlistIds.currenciesList,
      }),
    ];
    result.push(createReplacementBondsList());
  }

  result.push(createFuturesItem());

  return result;
};

// если есть текущая конфигурация - обновляем фьючерсы и добавляем новые списки из дефолтного
// или берем дефолтный список
export const createWatchLists = (watchLists?: WatchList[]) => {
  let currentWatchLists = watchLists || [];
  const defaultWatchLists = createDefaultWatchLists();

  // последствия ошибки в ADIRWEB-1632
  if (IS_AI || !featureFlags.AMERICAN_INDICIES_ENABLED) {
    const listsToRemove = [
      WatchlistIds.DJIList,
      WatchlistIds.SP500List,
      WatchlistIds.NDXList,
    ];

    currentWatchLists = currentWatchLists.filter(
      (item) => !listsToRemove.includes(item.id as WatchlistIds)
    );
  }

  // Ошибки в названиях или устаревшие листы
  const listsToRemove = ['MOEXMBIList'];

  currentWatchLists = currentWatchLists.filter(
    (item) => !listsToRemove.includes(item.id as WatchlistIds)
  );

  const currentListIndex = currentWatchLists.reduce((result, item) => {
    result[item.id] = item;

    return result;
  }, {} as Record<string, WatchList>);

  return [
    ...(currentWatchLists
      ? currentWatchLists.map((item) => {
          // пропускаем все списки юзера
          if (item.type === WatchListType.CUSTOM) {
            return item;
          }

          if (item.type === WatchListType.DEFAULT) {
            return item;
          }

          if (item.id === WatchlistIds.featuresList) {
            // обновление фьючерсов
            const fis = makeFeaturesList(item.instruments);

            return createFuturesItem({ fis });
          } else if (
            item.id === WatchlistIds.RGBITRList ||
            item.id === WatchlistIds.RUCBITRList ||
            item.id === WatchlistIds.floatersList
          ) {
            item.instruments = refreshItemInstruments(item.instruments);
          } else if (item.id === WatchlistIds.replacementBondsList) {
            return createReplacementBondsList(item.instruments);
          } else if (item.id === WatchlistIds.reliableBonds) {
            return createReliableBondsList(item.instruments);
          } else if (item.id === WatchlistIds.currencyIncomeBonds) {
            return createCurrencyIncomeBondsList(item.instruments);
          }

          const newDefaultList =
            defaultWatchLists.find((id) => item.id === id.id)?.instruments ||
            [];

          item['instruments'] = newDefaultList;

          return item;
        })
      : []),
    ...defaultWatchLists.filter((item) => !currentListIndex[item.id]),
  ];
};

export const mergeDuplicates = (watchLists: WatchList[]) => {
  const res = watchLists?.reduce((acc, cur) => {
    const foundIdx = acc.findIndex((item) => item.name === cur.name);

    if (foundIdx !== -1) {
      const uniqInstrumentsMap = [
        ...acc[foundIdx].instruments,
        ...cur.instruments,
      ].reduce((map, item) => {
        map.set(item.idObject, item);

        return map;
      }, new Map<number, WatchListItem>());

      acc[foundIdx].instruments = Array.from(uniqInstrumentsMap.values());

      return acc;
    }

    return [...acc, cur];
  }, [] as WatchList[]);

  return res;
};
