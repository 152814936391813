import { DocumentReceiving, DocumentReceivingType } from '@terminal/lk-core';

export function obtainRename(obtain: DocumentReceiving): DocumentReceiving {
  if (obtain.type === DocumentReceivingType.Electronic) {
    return {
      ...obtain,
      description: 'На электронную почту',
    };
  }

  return obtain;
}
