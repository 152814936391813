import { ReactNode } from 'react';
import type { CurrencyCodes } from '@alfalab/utils';

import { ActivationTime } from '../constants/orderBook';
import {
  CommandType,
  DocumentType,
  ExecutionType,
  LifeTime,
  Messages,
  ObjectGroup,
  Operation,
  OrderDirection,
  OrderStatus,
  OrderStopPriceDirection,
  OrderType,
  PriceControlType,
  PriceType,
  QuantityType,
} from '../lib/client/entities';
import { MarketBoardItem, ObjectItem } from './core';
import { SubAccountRazdelItem } from './subAccountRazdel';
import { BracketOrderParams } from './trading';

export interface OrderItem {
  idAccount: number;
  idSubAccount: number;
  orderStatusRevision: number;
  rest: number;
  price: number;
  extSessId: number;
  extOrderNum: string;
  orderUpdateTime: string;
  brokerComment: string;
  bATime: string;
  confirmedQuantity: number;
  killNumEDocument: string;
  sentTime: string;
  sendCompletedTime: string;
  killTime: string;
  terminatedTime: string;
  exchangeTime: string;
  exchangeTerminatedTime: string;
  repoDate: string;
  clientNumStrategy: number;
  idOrderType: OrderType;
  idQuantityType: QuantityType;
  idPriceType: PriceType;
  idLifeTime: LifeTime;
  idExecutionType: ExecutionType;
  idPriceControlType: PriceControlType;
  idSettleCode: number;
  idObject: number;
  idMarketBoard: number;
  idOrderStatus: OrderStatus;
  idRazdel: number;
  limitPrice: number;
  stopPrice: number;
  limitLevelAlternative: number;
  signTime: Date;
  buySell: OrderDirection;
  quantity: number;
  openQuantity: number;
  activationTime: Date;
  oCOGroup: string;
  withdrawTime: Date;
  idFIActivate: number;
  activationPriceDirection: OrderStopPriceDirection;
  value: number;
  tradeNo: string;
  idCommandType: CommandType;
  idDocumentType: DocumentType;
  login: string;
  clientOrderNum: number;
  clientNumEDocument: string;
  numEDocument: string;
  acceptTime: Date;
  numEDocumentBase: string;
  operation: Operation;
  idFi: number;
  entryRest?: number;
  sessionFilled?: number;
  filledPrice?: number;
  brokerErrorCode?: Messages;
  contragent?: string;
  contragentRef?: string;
  reason?: string;
  accountContragent?: string;
  idAccountTypeContragent?: number;
  refundRate?: number;
  sDiscount?: number;
  lowerDiscount?: number;
  upperDiscount?: number;
  bIC?: string;
  comment?: string;
}

export type NewOrderType = Omit<
  OrderItem,
  'stopPrice' | 'limitPrice' | 'activationTime'
> & {
  bracket?: BracketOrderParams;
  orderType?: string;
  idActivationTime?: ActivationTime;
  activationTime?: Date;
  stopPrice?: number;
  limitPrice?: number;
};

export const OrderTypeMap = new Map<number, string>([
  [OrderType.MKT, 'MKT'],
  [OrderType.LMT, 'LMT'],
  [OrderType.LWA, 'LWA'],
  [OrderType.NOS, 'NOS'],
  [OrderType.MIT, 'MIT'],
  [OrderType.LIT, 'LIT'],
  [OrderType.STP, 'STP'],
  [OrderType.STL, 'STL'],
  [OrderType.TRL, 'TRL'],
  [OrderType.TRS, 'TRS'],
  [OrderType.TSL, 'TSL'],
  [OrderType.BRS, 'BRS'],
  [OrderType.BSL, 'BSL'],
  [OrderType.RPS, 'RPS'],
  [OrderType.INT, 'INT'],
  [OrderType.SMU, 'SMU'],
  [OrderType.SMX, 'SMX'],
  [OrderType.VDP, 'VDP'],
  [OrderType.MDP, 'MDP'],
  [OrderType.REES, 'REES'],
  [OrderType.CLMON, 'CLMON'],
  [OrderType.DUMON, 'DUMON'],
  [OrderType.TechRPS, 'TechRPS'],
  [OrderType.SWAP, 'SWAP'],
  [OrderType.TechSWAP, 'TechSWAP'],
  [OrderType.REPO, 'REPO'],
  [OrderType.TechREPO, 'TechREPO'],
  [OrderType.TBRS, 'TBRS'],
]);

export const LifeTimeMap = new Map<number, string>([
  [LifeTime.IMM, 'IMM'],
  [LifeTime.CLS, 'CLS'],
  [LifeTime.OPN, 'OPN'],
  [LifeTime.AU, 'AU'],
  [LifeTime.DAY, 'DAY'],
  [LifeTime.GTD, 'GTD'],
  [LifeTime.GTT, 'GTT'],
  [LifeTime.FIN, 'FIN'],
  [LifeTime.D30, 'D30'],
]);

export const OrderDirectionMap = new Map<OrderDirection, string>([
  [OrderDirection.Sell, 'Продажа'],
  [OrderDirection.Undefined, 'НЕИЗВЕСТНО'],
  [OrderDirection.Buy, 'Покупка'],
]);

// Таблица с маппингом тут - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1055240476
export const OrderFullStatusMap = new Map<OrderStatus, string>([
  [
    OrderStatus.Parked,
    'Ожидание электронной подписи и отправки заявки брокеру',
  ],
  [OrderStatus.New, 'Ожидание приема заявки брокером'],
  [
    OrderStatus.Offer,
    'Ожидание отправки встречного ордера или отклонения заявки',
  ],
  [
    OrderStatus.Sign,
    'Ожидание подписания второй электронной подписью перед исполнением',
  ],
  [OrderStatus.HiddenTime, 'Ожидание активации по времени'],
  [OrderStatus.HiddenOrder, 'Ожидание исполнения другого ордера'],
  [OrderStatus.HiddenB, 'Ожидание пробоя стоп цены '],
  [
    OrderStatus.HiddenPrice,
    'Ожидание сделки с ценой больше/меньше/равной указанной',
  ],
  [OrderStatus.CheckLimits, 'Ожидание завершения контроля лимитов '],
  [OrderStatus.Accepted, 'Ожидание передачи заявки'],
  [OrderStatus.PendingEnter, 'Отправка текущей заявки на биржу'],
  [OrderStatus.HiddenPeriod, 'Ожидание наступления заданного периода торгов'],
  [OrderStatus.HiddenZ, 'Ожидание изменения ценового коридора'],
  [OrderStatus.Working, 'Заявка принята'],
  [OrderStatus.ToBeCancelled, 'Помечена для удаления'],
  [OrderStatus.PendingCancel, 'Ожидание подтверждения отмены заявки'],
  [OrderStatus.ExchangeCancelled, 'Текущая заявка удалена с биржи'],
  [OrderStatus.Rejected, 'Заявка отклонена брокером'],
  [OrderStatus.ExchangeFilled, 'Заявка исполнена биржей'],
  [OrderStatus.Filled, 'Подтверждено полное исполнение заявки'],
  [OrderStatus.Cancelled, 'Заявка отменена'],
  [
    OrderStatus.Verify,
    'Исполненный, в том числе частично, приказ ожидает письменного/электронного подтверждения клиента',
  ],
  [OrderStatus.Edit, 'Помечена к удалению для последующего редактирования'],
  [
    OrderStatus.Moving,
    'Ожидание подтверждения удаления от торговой системы перед редактированием',
  ],
  [OrderStatus.ExchangeRejected, 'Удалена с биржи до попадания в стакан'],
  [
    OrderStatus.ExchangeRemoved,
    'Удалена с биржи после попадания в стакан из-за изменившихся параметров',
  ],
]);

// Таблица с маппингом тут - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1055240476
export const OrderStatusMap = new Map<OrderStatus, string>([
  [OrderStatus.Parked, 'Выставляется'],
  [OrderStatus.New, 'Новая'],
  [OrderStatus.Offer, 'Оферта'],
  [OrderStatus.Sign, 'НаПодписи'],
  [OrderStatus.HiddenTime, 'СкрытаяВремя'],
  [OrderStatus.HiddenOrder, 'СкрытаяСоСвязью'],
  [OrderStatus.HiddenB, 'СкрытаяПробой'],
  [OrderStatus.HiddenPrice, 'СкрытаяЦена'],
  [OrderStatus.CheckLimits, 'КонтрольЛимита'],
  [OrderStatus.Accepted, 'Принята'],
  [OrderStatus.PendingEnter, 'ОтправкаБиржа'],
  [OrderStatus.HiddenPeriod, 'СкрытаяТоргПериод'],
  [OrderStatus.HiddenZ, 'СкрытаяЦеновойКоридор'],
  [OrderStatus.Working, 'Активная'],
  [OrderStatus.ToBeCancelled, 'НаОтмену'],
  [OrderStatus.PendingCancel, 'ОжиданиеОтмены'],
  [OrderStatus.ExchangeCancelled, 'БиржаОтмена'],
  [OrderStatus.Rejected, 'ОтклоненаБрокером'],
  [OrderStatus.ExchangeFilled, 'ИсполненаБиржей'],
  [OrderStatus.Filled, 'Исполнена'],
  [OrderStatus.Cancelled, 'Отменена'],
  [OrderStatus.Verify, 'ОжиданиеПодтверждения'],
  [OrderStatus.Edit, 'НаИзменение'],
  [OrderStatus.Moving, 'НаИзменение2'],
  [OrderStatus.ExchangeRejected, 'ОтклоненБиржей'],
  [OrderStatus.ExchangeRemoved, 'УдаленаБиржей'],
]);

export interface IFilteredOrder {
  idFi: number;
  order: OrderItem;
  object: ObjectItem;
  market: MarketBoardItem;
  direction?: string;
  subAccountRazdel?: SubAccountRazdelItem;
  currency?: CurrencyCodes;
  lifeTime: ReactNode;
  operation: string;
  objectGroup?: ObjectGroup;
  priceStep?: number;
  executed: number;
  displayPrice: number;
  type: string;
  withdrawTime: Date;
  status: string;
  acceptTime: Date;
  acceptDate: Date;
}
