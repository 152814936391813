import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { GetDocumentsThemesParams } from '../api/types/GetDocumentsThemesParams';

export const useDocumentsTheme = <R>(
  treaty: number | undefined,
  fetcher: (params: GetDocumentsThemesParams) => Promise<R>
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'documents/documents-themes', treaty],
    async () => {
      if (!treaty) {
        return;
      }

      return await fetcher({ treaty });
    },
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка документов',
          text: error?.message || '',
        });
      },
    }
  );
};
