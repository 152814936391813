import { ReportType } from '../model/ReportType';

export function getReportType(theme: ReportType) {
  switch (theme) {
    case ReportType.MyPortfolio:
      return 'MyPortfolio';
    case ReportType.MyBroker:
      return 'MyBroker';
    case ReportType.Taxes:
      return 'Taxes';
    case ReportType.TaxAppendix:
      return 'TaxAppendix';
    case ReportType.MyMoneyMove:
      return 'MyMoneyMove';
    case ReportType.IncomePaymentsReportOnSecurities:
      return 'IncomePaymentsReportOnSecurities';
    case ReportType.SecuritiesMovementStatementDepoAccount:
      return 'SecuritiesMovementStatementDepoAccount';
    case ReportType.DepoAccountStatusStatement:
      return 'DepoAccountStatusStatement';
  }
}
