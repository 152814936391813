import cn from 'classnames';
import { compareDesc } from 'date-fns';
import groupBy from 'lodash/groupBy';
import React, {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { usePrevious } from '@terminal/core/hooks';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection, Spinner } from '../../../../shared';
import { SimpleTable } from '../../../SimpleTable';
import { formatOperationDate } from '../../lib/formatOperationDate';
import { mapOperationHistoryTypeToName } from '../../lib/makeOperationHistoryTypeToName';
import { mapReportChapterKeyToName } from '../../lib/mapReportChapterKeyToName';
import { ReportChapterType } from '../../model/FinResult';
import { DateFilters } from '../DateFilters';

import { useChapterDetailsTable } from '../../hooks/useChapterDetailsTable';

import styles from './ChapterDetails.module.css';

enum Column {
  Name = 'name',
  Income = 'income',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.Income,
    justifyContent: 'flex-end',
  },
];

interface Props {
  chapter: ReportChapterType;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  selectedAccountId: number;
}

export const ChapterDetails: FC<Props> = ({
  chapter,
  dateFilter,
  setDateFilter,
  selectedAccountId,
}) => {
  const { trackOpenPortfolioReportDetails } = usePortfolioReportsMetrics();

  const prevChapter = usePrevious(chapter);

  useEffect(() => {
    if (!prevChapter && chapter && prevChapter !== chapter) {
      trackOpenPortfolioReportDetails(mapReportChapterKeyToName(chapter));
    }
  }, [prevChapter, chapter, trackOpenPortfolioReportDetails]);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useChapterDetailsTable({
      selectedAccountId,
      dateFilter,
      chapter,
    });

  /**
   * Элемент для дозагрузки истории
   */
  const bottomObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();
  const pageBottomRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (bottomObserver.current) {
        bottomObserver.current.disconnect();
      }

      if (fetchNextPage) {
        bottomObserver.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !isFetching && hasNextPage) {
            fetchNextPage();
          }
        });

        if (node) {
          bottomObserver.current.observe(node);
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage]
  );

  // Группировка по дате выполнения операции
  const groupedOperations = groupBy(data, 'operationDate');
  //Отсортированный список дат операций
  const operationDates = Object.keys(groupedOperations)
    .map((date) => new Date(date))
    .sort(compareDesc)
    .map((date) => date.toDateString());

  const cellRender = useCallback((columnKey, _, row) => {
    switch (columnKey) {
      case Column.Name:
        return (
          <Typography.Text view="secondary-large" className={styles.itemName}>
            {row.object ? (
              <SymbolCell
                onlyIcon
                idObject={row.object.idObject}
                symbolObject={row.object.idObject}
              />
            ) : null}
            {mapOperationHistoryTypeToName(
              row.operation.type,
              row.operation.name ?? '',
              row.operation.direction
            )}
          </Typography.Text>
        );
      case Column.Income:
        return (
          <Typography.Text view="secondary-large">
            {row.operation.direction > 0 ? '+' : '-'}
            {String(row.operation.value).replace('.', ',')}&nbsp;
            <Typography.Text
              tag="span"
              view="secondary-large"
              color="secondary"
            >
              {getSymbolByCurrency('RUB')}
            </Typography.Text>
          </Typography.Text>
        );
    }
  }, []);

  return (
    <div>
      <DateFilters value={dateFilter} onChange={setDateFilter} />

      <Gap size="l" />

      <PortfolioSection title={mapReportChapterKeyToName(chapter)}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={styles.operationsContainer}>
            {operationDates.map((date) => {
              const displayedDate = formatOperationDate(date);

              const sortedOperations = groupedOperations[date]?.sort(
                (left, right) =>
                  compareDesc(
                    new Date(left.operation.timeOperation),
                    new Date(right.operation.timeOperation)
                  )
              );

              return (
                <Fragment key={displayedDate}>
                  <Typography.Text
                    view="secondary-large"
                    color="secondary"
                    weight="bold"
                  >
                    {displayedDate}
                  </Typography.Text>
                  <Gap size="xs" />
                  <SimpleTable
                    columns={COLUMN_SETTINGS}
                    data={sortedOperations}
                    cellRender={cellRender}
                    className={styles.table}
                  />
                  <Gap size="m" />
                </Fragment>
              );
            })}

            <div
              ref={pageBottomRef}
              className={cn(styles.bottomBlock, {
                [styles.bottomBlockInvisible]: data.length < 20,
              })}
            />
          </div>
        )}

        {isFetching && !isLoading ? <Spinner /> : null}
      </PortfolioSection>
    </div>
  );
};
