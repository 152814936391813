import { useMemo } from 'react';

import {
  lifeTimeOptions as lifeTimeOptionsOriginal,
  orderTypeOptions as orderTypeOptionsOriginal,
} from '@terminal/core/constants/orderBook';
import { LifeTime, OrderType } from '@terminal/core/lib/client/entities';
import { MarketBoard } from '@terminal/core/types/core';

export const useFormOptions = (idMarketBoard?: MarketBoard) => {
  // Рынок МБ ВР
  const isMarketMB_VR = useMemo(() => {
    return idMarketBoard === MarketBoard.CETS_TOM_USD_TMS;
  }, [idMarketBoard]);

  const orderTypeOptions = useMemo(() => {
    // Для МБ ВР доступен только тип заявки "Рыночная"
    if (isMarketMB_VR) {
      return orderTypeOptionsOriginal.filter(
        (option) => option.value === OrderType.MKT
      );
    } else {
      return orderTypeOptionsOriginal;
    }
  }, [isMarketMB_VR]);

  const lifeTimeOptions = useMemo(() => {
    // Для МБ ВР доступен только тип LifeTime.IMM
    if (isMarketMB_VR) {
      return lifeTimeOptionsOriginal
        .filter((option) => option.value === LifeTime.IMM)
        .map((item) => ({
          ...item,
          isHidden: false,
        }));
    } else {
      return lifeTimeOptionsOriginal;
    }
  }, [isMarketMB_VR]);

  const defaultLifeTime = useMemo(() => {
    return !isMarketMB_VR
      ? lifeTimeOptions.find((opt) => opt.value === LifeTime.D30)!
      : lifeTimeOptions[0];
  }, [isMarketMB_VR, lifeTimeOptions]);

  return {
    isMarketMB_VR,
    lifeTimeOptions,
    defaultLifeTime,
    orderTypeOptions,
  };
};
