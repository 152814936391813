import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { confirmRefillIOperation } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useConfirmRefillFromAlfa = (
  reference: string | undefined,
  CUS: string | undefined
) => {
  const addNotification = useNotification();

  return useMutation(
    // TODO: не понятно, зачем этот ключ, попробовать удалить
    [`confirm-refill-operation${reference}`],
    async () => confirmRefillIOperation(reference, CUS),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка пополнения',
          text: error?.message || '',
        });
      },
    }
  );
};
