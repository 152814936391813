import { roundPrice } from '@terminal/core/lib/format';

import { BalanceChartPoint } from '../model/types';

const LABEL_HEIGHT_PX = 16;

type ChartLabelType = 'portfolio' | 'zero';
interface LabelProps {
  type: ChartLabelType;
  price: number;
  y: number;
  stroke?: string;
  color?: string;
  hideLabel: boolean;
}

interface MinMaxLabelProps {
  price: number;
  y: number;
}

export const calculateBalanceChartLabels = (
  data: BalanceChartPoint[],
  chartHeight: number
) => {
  const lastTick = data.at(-1);

  if (!lastTick) {
    return {
      labels: [],
      minMaxTicks: [],
    };
  }

  const allChartDots = data.map((tick) => tick.balance);
  const minPrice = Math.min(...allChartDots);
  const maxPrice = Math.max(...allChartDots);
  const chartHeightPriceDiff = maxPrice - minPrice;
  const pricePerPx = chartHeightPriceDiff / chartHeight;
  const labelsDiffPrice = roundPrice(LABEL_HEIGHT_PX * pricePerPx);

  const labels: LabelProps[] = [
    {
      type: 'portfolio' as ChartLabelType,
      price: lastTick.balance,
      y: lastTick.balance,
      stroke: 'var(--color-light-border-primary)',
      color: 'var(--color-light-text-primary)',
      hideLabel: false,
    },
    {
      type: 'zero' as ChartLabelType,
      price: 0,
      y: 0,
      stroke: 'var(--color-light-border-primary)',
      color: 'var(--color-light-text-secondary)',
      hideLabel: Math.abs(lastTick.balance) < labelsDiffPrice,
    },
  ];

  const currentPrice = data.at(-1)?.balance || 0;

  const minMaxTicks: MinMaxLabelProps[] = [];

  if (minPrice !== currentPrice && minPrice !== 0) {
    minMaxTicks.push({
      price: minPrice,
      y:
        minPrice -
        (currentPrice - minPrice > labelsDiffPrice
          ? 0
          : labelsDiffPrice - currentPrice + minPrice),
    });
  }

  if (maxPrice !== currentPrice && maxPrice !== 0) {
    minMaxTicks.push({
      price: maxPrice,
      y:
        maxPrice +
        (maxPrice - currentPrice > labelsDiffPrice
          ? 0
          : labelsDiffPrice - maxPrice + currentPrice),
    });
  }

  return {
    labels,
    minMaxTicks,
  };
};
