import qs from 'query-string';

import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { CloudConfigUploadParams } from '../model/types/cloudConfigUpload';

export async function uploadConfigToCloud(params: CloudConfigUploadParams) {
  const { name, config } = params;

  return fetchLkResult(
    qs.stringifyUrl({
      url: '/api/terminal-settings/webterminal/add',
      query: { configName: name },
    }),
    'POST',
    config
  );
}
