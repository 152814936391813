import {
  IssuerIconIdentifierType,
  IssuerLogotype,
} from '../model/types/IssuerLogotype';

export const buildIssuerLogoUrl = (item: IssuerLogotype): string | null => {
  if (!item.hasIcon || item.iconType === null) {
    return null;
  }

  const baseUrl = '/api/logotypes/emitents/download';

  const identifierMap: Record<
    IssuerIconIdentifierType,
    { path: string; value: string | number | undefined }
  > = {
    [IssuerIconIdentifierType.IdObject]: {
      path: 'idobject',
      value: item.idObject,
    },
    [IssuerIconIdentifierType.IdObjectBase]: {
      path: 'idobject',
      value: item.idObjectBase,
    },
    [IssuerIconIdentifierType.Isin]: { path: 'isin', value: item.isin },
    [IssuerIconIdentifierType.EmCode]: { path: 'emcode', value: item.emCode },
  };

  const { path, value } = identifierMap?.[item.iconType];

  if (value === undefined) {
    return null;
  }

  return `${baseUrl}/${path}/${value}.png`;
};
