import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import DotMediumMIcon from '@alfalab/icons-glyph/DotMediumMIcon';

import { INDICES_CHIPSE } from '../../lib/const';

import { ChartIndex, ProfitChartPoint } from '../../model/types';

import styles from './ProfitChartTooltip.module.css';

interface Props {
  active?: boolean;
  payload?: [{ payload: ProfitChartPoint }];
  selectedIndices: ChartIndex[];
}

export const ProfitChartTooltip = ({
  active,
  payload,
  selectedIndices,
}: Props) => {
  if (!active || !payload?.length) {
    return null;
  }

  const last = payload[0].payload;
  const date = last.date;

  return (
    <div className={styles.popover}>
      <Row name="Портфель" percent={last.portfolio} color="#c1c1c3" />
      {Object.values(ChartIndex)
        .filter((index) => selectedIndices.includes(index) && index in last)
        .map((index) => (
          <Row
            key={index}
            name={INDICES_CHIPSE[index].name}
            percent={last[index]}
            color={INDICES_CHIPSE[index].color}
          />
        ))}

      <Gap size="2xs" />

      <Typography.Text
        view="secondary-small"
        weight="medium"
        className={styles.tertiary}
      >
        {format(date, 'dd MMMM yyyy', { locale: ru })}
      </Typography.Text>
    </div>
  );
};

function Row({ name, percent, color }) {
  return (
    <Typography.Text
      tag="div"
      view="secondary-small"
      weight="medium"
      className={cn(styles.percent, styles.quaternary)}
    >
      <DotMediumMIcon width={12} height={12} color={color} />
      &nbsp;{name}&nbsp;
      <Typography.Text view="secondary-small" color="secondary">
        {String(percent).replaceAll('.', ',')}%
      </Typography.Text>
    </Typography.Text>
  );
}
