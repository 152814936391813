import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getCommonInstrumentsConfig,
  getPositionsCommonConfig,
  getTradeHybridOrderConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHART_MIDDLE_CHRTIQ_SETTINGS,
  CHARTS__10MIN_CHARTIQ_SETTINGS,
  CHARTS__HOUR_CHARTIQ_SETTINGS,
  TRADE_BOTTOM_CHART_SETTING,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const daytradeSmall = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const baseChartConfig = {
      symbol: 'SBER',
      idFi: 144950,
      link: blueLink.name,
      tableProps: {},
    } as const;

    const leftRow = new Row(30);
    const middleRow = new Row(20);
    const rightRow = new Row(50);

    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );

    const tradesTab = new Tab(Widget.TRADES, { link: blueLink.name });

    const instrumentsTabSet = new TabSet(50);

    instrumentsTabSet.addChildren([leftInstrumentsTab, tradesTab]);

    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const operationTab = new Tab(Widget.OPERATIONS, {});

    const leftBottomTabSet = new TabSet(50);

    leftBottomTabSet.addChildren([positionsTab, operationTab]);

    leftRow.addChildren([instrumentsTabSet, leftBottomTabSet]);

    const orderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const orderbookTabset = new TabSet(20);
    const tradeTab = new Tab(
      Widget.HYBRIDORDER,
      getTradeHybridOrderConfig(blueLink.name)
    );

    orderbookTabset.addChildren([orderbookTab, tradeTab]);

    middleRow.addChildren([orderbookTabset]);

    const rightChartTab1 = new Tab(
      Widget.CHART,
      baseChartConfig,
      TRADE_BOTTOM_CHART_SETTING
    );
    const rightChartTab2 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__HOUR_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(100);

    rightChartTabset.addChildren([rightChartTab1, rightChartTab2]);

    rightRow.addChildren([rightChartTabset]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, middleRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink]);
  };

  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const baseChartConfig = {
      symbol: 'SBER',
      idFi: 144950,
      link: blueLink.name,
      tableProps: {},
    } as const;

    const leftChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__10MIN_CHARTIQ_SETTINGS
    );
    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );
    const leftChartTabset = new TabSet(50);

    leftChartTabset.addChildren([leftChartTab, leftInstrumentsTab]);

    const rightChartTab1 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_MIDDLE_CHRTIQ_SETTINGS
    );
    const rightChartTab2 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__HOUR_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(50);

    rightChartTabset.addChildren([rightChartTab1, rightChartTab2]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftChartTabset, rightChartTabset]);

    return new Layout('Графики', chartRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Дневная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
