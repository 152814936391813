import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ChevronUpCompactMIcon } from '@alfalab/icons-glyph/ChevronUpCompactMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { plural } from '@terminal/core/lib/plural';

import styles from './AccountPicker.module.css';

interface Props {
  multiple?: boolean;
  selectedAccountsIds?: number[];
  onSelect: (accountIds: number[]) => void;
}

export const AccountPicker: FC<Props> = ({
  multiple = false,
  selectedAccountsIds,
  onSelect,
}) => {
  const { accounts } = useAlfaDirectContext();

  const [selectedAccounts, setSelectedAccounts] = useState<number[]>(
    selectedAccountsIds || (accounts[0] ? [accounts[0]?.idAccount] : [])
  );

  useEffect(() => {
    if (selectedAccountsIds) {
      setSelectedAccounts(selectedAccountsIds);
    }
  }, [selectedAccountsIds]);

  const [searchText, setSearchText] = useState('');

  const handleChange = (accountId: number) => {
    if (multiple) {
      setSelectedAccounts((prev) => {
        const next = prev.includes(accountId)
          ? prev.filter((id) => id !== accountId)
          : [...prev, accountId];

        onSelect(next);

        return next;
      });
    } else {
      setSelectedAccounts([accountId]);
      onSelect([accountId]);
    }

    setSearchText('');
  };

  const selectedText = (() => {
    if (!multiple || selectedAccounts.length === 1) {
      return selectedAccounts[0];
    }

    if (selectedAccounts.length === accounts.length) {
      return 'Все счета';
    }

    return `${selectedAccounts.length} ${plural(
      ['счёт', 'счёта', 'счетов'],
      selectedAccounts.length
    )}`;
  })();

  return (
    <DropdownMenu
      popoverProps={{
        position: 'bottom-start',
        useAnchorWidth: true,
        popperClassName: styles.popover,
      }}
      groups={[
        {
          key: '1',
          items: [
            {
              key: '1.1',
              type: 'element',
              label: (
                <>
                  <InputDesktop
                    size="s"
                    block
                    clear
                    onClear={() => {
                      setSearchText('');
                    }}
                    leftAddons={
                      <MagnifierMIcon
                        width={16}
                        height={16}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    placeholder="Номер счета"
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;

                      setSearchText(value);
                    }}
                    fieldClassName={styles.field}
                    addonsClassName={styles.addons}
                    inputClassName={styles.input}
                  />
                  <Dropdown.Divider size="s" />
                </>
              ),
              hideMenu: true,
            },
          ],
        },
        {
          key: '2',
          items: accounts
            .filter((account) => String(account.idAccount).includes(searchText))
            .map((account) => ({
              key: account.idAccount,
              type: 'button',
              label: account.idAccount,
              hideMenu: true,
              onClick: () => handleChange(account.idAccount),
              checked: selectedAccounts.includes(account.idAccount),
            })),
        },
      ]}
      className={styles.menu}
    >
      {(ref, isOpen) => {
        const Icon = isOpen ? ChevronUpCompactMIcon : ChevronDownCompactMIcon;

        return (
          <div ref={ref} className={styles.picker}>
            <BriefcaseMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
            <Typography.Text
              tag="div"
              weight="medium"
              view="secondary-large"
              className={styles.accountName}
            >
              {selectedText}
            </Typography.Text>
            <Icon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={cn(styles.chevron, isOpen && styles.chevronUp)}
            />
          </div>
        );
      }}
    </DropdownMenu>
  );
};
