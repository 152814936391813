import { format } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection } from '../../../../shared';
import { Drawer } from '../../../Drawer';
import { SimpleTable } from '../../../SimpleTable';
import { START_ANALYTICS_DATE } from '../../lib/const';
import { PaymentsAndExpensesTableRow } from '../../model/FinResultTable';
import { ChapterDetails } from '../ChapterDetails';

import { usePaymentsAndExpensesTable } from '../../hooks/usePaymentsAndExpensesTable';

import styles from './PaymentsAndExpenses.module.css';

enum Column {
  Name = 'name',
  Income = 'income',
  Actions = 'actions',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.Income,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Actions,
  },
];

const TABLE_TITLE = 'Выплаты и расходы';

interface Props {
  analytics?: PortfolioAnalyticsResult;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  selectedAccountId: number;
}

export const PaymentsAndExpenses: FC<Props> = ({
  analytics,
  dateFilter,
  setDateFilter,
  selectedAccountId,
}) => {
  const [drawerProps, setDrawerProps] = useState<Pick<
    PaymentsAndExpensesTableRow,
    'key'
  > | null>(null);

  const { trackClickPortfolioReportElement, trackHoverPortfolioReportTooltip } =
    usePortfolioReportsMetrics();

  const drawer = (
    <Drawer isOpen={Boolean(drawerProps)} onClose={() => setDrawerProps(null)}>
      {drawerProps ? (
        <ChapterDetails
          chapter={drawerProps.key}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          selectedAccountId={selectedAccountId}
        />
      ) : null}
    </Drawer>
  );

  const handleOpenDrawer = useCallback(
    (row: PaymentsAndExpensesTableRow) => {
      if (row.hasHistory) {
        setDrawerProps({ key: row.key });
        trackClickPortfolioReportElement(TABLE_TITLE, row.name);
      }
    },
    [trackClickPortfolioReportElement]
  );

  const data = usePaymentsAndExpensesTable(analytics);

  const cellRender = useCallback(
    (columnKey, prop, row) => {
      switch (columnKey) {
        case Column.Name:
          const Icon = row.Icon;

          return (
            <Typography.Text
              view="secondary-large"
              className={styles.chapterName}
            >
              <Icon
                width={16}
                height={16}
                color="var(--color-light-text-secondary)"
              />
              {prop}
            </Typography.Text>
          );
        case Column.Income:
          return (
            <Typography.Text view="secondary-large">
              {row.income >= 0 ? '+' : '-'}&nbsp;
              {
                formatAmount({
                  value: Math.abs(row.income) * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formatted
              }
              &nbsp;
              <Typography.Text
                tag="span"
                view="secondary-large"
                color="secondary"
              >
                {getSymbolByCurrency(row.currency)}
              </Typography.Text>
            </Typography.Text>
          );
        case Column.Actions:
          return row.hasHistory ? (
            <IconButton
              size="xs"
              view="secondary"
              icon={ChevronRightMIcon}
              onClick={() => handleOpenDrawer(row.key)}
            />
          ) : null;
      }
    },
    [handleOpenDrawer]
  );

  return (
    <>
      {analytics && data.length ? (
        <>
          <PortfolioSection
            title={TABLE_TITLE}
            tooltip={
              <>
                Выплаты учитывают выплаченные дивиденды и купоны.
                <br />
                <br />
                Расходы учитывают комиссии и налоги. Комиссии могут отражаться
                позже, так как они списываются через 1-2 дня после сделки.
                <br />
                <br />
                Данные доступны с {format(START_ANALYTICS_DATE, 'dd.MM.yyyy')}
              </>
            }
            onTooltipOpen={() => {
              trackHoverPortfolioReportTooltip(TABLE_TITLE);
            }}
          >
            <SimpleTable<PaymentsAndExpensesTableRow, Column>
              columns={COLUMN_SETTINGS}
              data={data}
              cellRender={cellRender}
              onRowClick={(_, row) => handleOpenDrawer(row)}
              className={styles.table}
            />
          </PortfolioSection>
          <Gap size="2xl" />
        </>
      ) : null}

      {drawer}
    </>
  );
};
