import { useEffect } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsCwMIcon } from '@alfalab/icons-glyph/ArrowsCwMIcon';
import { ArrowsRightLeftCurvedMediumMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMediumMIcon';
import { CheckmarkCircleMIcon } from '@alfalab/icons-glyph/CheckmarkCircleMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';
import { InternetOffMIcon } from '@alfalab/icons-glyph/InternetOffMIcon';

import { StatusPanel } from '../StatusPanel';

import { useOfflineTransferStatus } from '../../hooks/useOfflineTransferStatus';

import { useTransferStatusStore } from '../../model/store/useTransferStatusStore';

import { TransferStatusEnum } from '../../model/types/transferStatus';

import styles from './TransferStatus.module.css';

export const TransferStatus = () => {
  const [status, description, setStatus, reset] = useTransferStatusStore(
    (store) => [store.status, store.description, store.setStatus, store.reset]
  );

  useOfflineTransferStatus();

  useEffect(() => {
    return () => reset();
  }, [reset]);

  if (status === TransferStatusEnum.None) {
    return null;
  }

  return (
    <div className={styles.root}>
      {status === TransferStatusEnum.Loading && <Spinner visible size="m" />}
      {status === TransferStatusEnum.LoadingError && (
        <StatusPanel
          title="Ошибка загрузки"
          description={
            <>
              Не удалось получить данные. <br />
              Попробуйте обновить страницу
            </>
          }
          buttonLabel="Обновить"
          onClick={() => window.location.reload()}
          ButtonIcon={ArrowsRightLeftCurvedMediumMIcon}
          Icon={ArrowsCwMIcon}
        />
      )}
      {status === TransferStatusEnum.NoNetwork && (
        <StatusPanel
          title="Нет сети"
          description={
            <>
              Проверьте подключение <br />и попробуйте обновить страницу
            </>
          }
          buttonLabel="Обновить"
          onClick={() => window.location.reload()}
          ButtonIcon={ArrowsRightLeftCurvedMediumMIcon}
          Icon={InternetOffMIcon}
        />
      )}
      {status === TransferStatusEnum.MoneyTransferSuccess && (
        <StatusPanel
          title="Брокерский счёт пополнен"
          description={description}
          buttonLabel="Продолжить"
          onClick={() => setStatus(TransferStatusEnum.None)}
          Icon={CheckmarkCircleMIcon}
          iconColor="var(--color-light-graphic-positive)"
        />
      )}
      {status === TransferStatusEnum.MoneyTransferError && (
        <StatusPanel
          title="Что-то пошло не так"
          description={description}
          buttonLabel="Повторить"
          onClick={() => setStatus(TransferStatusEnum.None)}
          Icon={ExclamationCircleMIcon}
          iconColor="#FDA136"
        />
      )}
      {status === TransferStatusEnum.PaymentProcessing && (
        <div className={styles.paymentProcessing}>
          <Spinner visible size="m" />
          <Typography.Text view="secondary-large">
            Платёж в обработке. <br />
            Пожалуйста, подождите...
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
