import { useMemo } from 'react';

import { roundPrice } from '@terminal/core/lib/format';
import { NetValuePoint } from '@terminal/core/lib/rest/investApi';

import { BalanceChartPoint } from '../model/types';

export const useBalanceChartDots = (
  graph: NetValuePoint[]
): BalanceChartPoint[] => {
  return useMemo(() => {
    return graph.map(({ date, netAssets }) => ({
      date: new Date(date),
      symbol: netAssets.currency,
      balance: roundPrice(
        netAssets.value / (netAssets.minorUnits || 1),
        1 / netAssets.minorUnits
      ),
    }));
  }, [graph]);
};
