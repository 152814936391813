import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { BankCard } from '@terminal/core/lib/rest/lkBankCard';
import { NotificationType } from '@terminal/core/types/ui';

import { unformatCardNumber } from '../lib/unformatCardNumber';
import { unformatCardTerm } from '../lib/unformatCardTerm';
import { useCardAdd } from './useCardAdd';
import { useCardTransactionCreate } from './useCardTransactionCreate';

interface CardAddAndTransferParams {
  treaty?: number;
  card: BankCard | null;
  cardNumber: string;
  cardTerm: string;
  cardName: string;
  cardCvc: string;
  sum: number;
}

export const useCardAddAndTransfer = () => {
  const addNotification = useNotification();

  const { mutateAsync: cardAddRequest } = useCardAdd();
  const { mutateAsync: createCardTransaction } = useCardTransactionCreate();

  return useMutation(
    async (params: CardAddAndTransferParams) => {
      if (!params.treaty) {
        throw new Error('Не выбран счёт');
      }

      // Добавление новой карты
      let cardID: string = params.card?.id || '';

      if (!params.card) {
        const res = await cardAddRequest({
          treaty: params.treaty,
          number: unformatCardNumber(params.cardNumber),
          expirationDate: unformatCardTerm(params.cardTerm),
          clientCardName: params.cardName,
        });

        cardID = res.id;
      }

      if (!cardID) {
        // Что-то пошло не так
        throw new Error('Не выбрана карта');
      }

      const { protocol, host } = window.location;
      const successUrl = `${protocol}//${host}/money-in-result/complete`; // Обратный адрес результата операции (в случае ThreeDS)

      return await createCardTransaction({
        treaty: params.treaty,
        cardId: cardID,
        cvv: params.cardCvc,
        sum: params.sum,
        redirectUri: successUrl,
      });
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Ошибка при создании транзакции',
        });
      },
    }
  );
};
