import { OperationHistoryType } from '@terminal/core/types/operation';

import { ReportChapterType } from '../model/FinResult';

export const makeOperationHistoryTypes = (
  type: ReportChapterType
): OperationHistoryType[] => {
  if (type === 'dividends') {
    return [OperationHistoryType.DP, OperationHistoryType.DE];
  }

  if (type === 'couponRevenue') {
    return [OperationHistoryType.CE];
  }

  if (type === 'commissions') {
    return [
      OperationHistoryType.CP,
      OperationHistoryType.CS,
      OperationHistoryType.CT,
    ];
  }

  if (type === 'taxes') {
    return [OperationHistoryType.TP];
  }

  return [];
};
