import { MIDDLE_API_INVEST_URI } from '../../env';
import { fetchThroughProxy } from '../url';
import { getAuthorizationHeader } from './investApi';

type IndustryProps = {
  code: string;
  text: string;
};

export interface IndustryResponse {
  industry: IndustryProps;
  isin: string;
}

export async function getIndustry(isin: string): Promise<IndustryResponse> {
  const path = `${MIDDLE_API_INVEST_URI}/invest/api/v1/issuers?isin=${isin}`;
  const response = await fetchThroughProxy(path, {
    method: 'GET',
    headers: {
      ...getAuthorizationHeader(),
    },
  });

  if (response.ok) {
    const industry = await response.json();

    return { isin, ...industry };
  } else {
    if (response.status === 419) {
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface Sector {
  sectorId: number;
  isins: string[];
  sector: string;
}

export interface IndustriesResponse {
  sectors: Sector[];
}

export async function getIndustries(
  isins: string[],
  signal
): Promise<IndustriesResponse> {
  const path = `${MIDDLE_API_INVEST_URI}/mobile/api/v1/issuers/industries`;
  const response = await fetchThroughProxy(path, {
    signal,
    method: 'POST',
    headers: {
      ...getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isins,
    }),
  });

  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 419) {
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}
