import { Typography } from '@alfalab/core-components/typography';

import { isFeatureHidden, useFeatureSettings } from '../../../../shared';
import { INSTRUCTION_FEATURE_TOGGLE_KEY } from '../../../Education/lib/const/featureToggles';
import instr from './img/instructions.png';
import instr2x from './img/instructions@2x.png';

import styles from './InstructionSupportBanner.module.css';

interface InstructionSupportBannerProps {
  onClick: () => void;
}

export const InstructionSupportBanner = ({
  onClick,
}: InstructionSupportBannerProps) => {
  const { data: featureSettings } = useFeatureSettings();
  const manualOff = isFeatureHidden(
    INSTRUCTION_FEATURE_TOGGLE_KEY,
    featureSettings
  );

  return !manualOff ? (
    <div className={styles.container} role="button" onClick={onClick}>
      <div className={styles.body}>
        <Typography.Text tag="div">Как устроен веб-терминал</Typography.Text>
        <Typography.Text view="secondary-small" color="secondary">
          Руководство по использованию
        </Typography.Text>
      </div>
      <img
        className={styles.image}
        src={instr}
        srcSet={`${instr2x} 2x`}
        alt=""
      />
    </div>
  ) : null;
};
