import noop from 'lodash/noop';
import { useCallback } from 'react';

import {
  ANALYTICS,
  DIMENSIONS_SCHEMAS,
} from '@terminal/core/constants/analytics';
import {
  LimitsDisplayMap,
  NPUDisplayMap,
} from '@terminal/core/constants/tradeLimits';
import { TradingLimitsShowMode } from '@terminal/core/types/layout';

import { AnalyticsTrack, useWidgetContext } from '../../../shared';

interface OrderBookSettings {
  hideForm?: boolean;
  sendTrallingOrders?: boolean;
  showSpread?: boolean;
  showEmptyPrice?: boolean;
  showPosition: boolean;
  showLimits: TradingLimitsShowMode;
  showNPU: TradingLimitsShowMode;
  showBestBuy?: boolean;
  showMarketBuy?: boolean;
  enableQuantity?: boolean;
}

export const useOrderBookAnalyticsMetrics = () => {
  const {
    analytics: { track = noop as AnalyticsTrack } = {
      track: noop as AnalyticsTrack,
    },
  } = useWidgetContext();

  return {
    trackOrderBookSettings: useCallback(
      ({
        hideForm,
        sendTrallingOrders,
        showSpread,
        showEmptyPrice,
        showPosition,
        showLimits,
        showNPU,
        showBestBuy,
        showMarketBuy,
        enableQuantity,
      }: OrderBookSettings) => {
        track(
          ANALYTICS.CATEGORY.DOM_WIDGET,
          ANALYTICS.ACTION.IMPRESSION,
          ANALYTICS.LABEL.DOM,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                1: hideForm ? 'Выкл' : 'Вкл', // Положение тоггла по отображению торговой формы в настройках пользователя
                2: sendTrallingOrders ? 'Вкл' : 'Выкл', // Положение тоггла по отображению трейлинга по умолчанию в настройках пользователя
                3: showSpread ? 'Вкл' : 'Выкл', // Положение тоггла по отображению спреда в настройках пользователя
                4: showEmptyPrice ? 'Вкл' : 'Выкл', // Положение тоггла по отображению прореженного стакана в настройках пользователя
                5: showPosition ? 'Вкл' : 'Выкл', // Положение тоггла по отображению позиции в информационной стироке в настройках пользователя
                6: showLimits > 0 ? 'Вкл' : 'Выкл', // Положение тоггла по отображению лимитов в информационной стироке в настройках пользователя
                7: LimitsDisplayMap[showLimits], // Настройки отображения лимитов в информационной строке
                8: showNPU > 0 ? 'Вкл' : 'Выкл', // Положение тоггла по отображению результата в информационной стироке в настройках пользователя
                9: NPUDisplayMap[showNPU], // Настройки отображения результатов в информационной строке
                10: showBestBuy ? 'Вкл' : 'Выкл', // Положение тоггла по отображению лучшей цены в контекстном меню в настройках пользователя
                11: showMarketBuy ? 'Вкл' : 'Выкл', // Положение тоггла по отображению рыночной цены в контекстном меню в настройках пользователя
                12: enableQuantity ? 'Вкл' : 'Выкл', // Положение тоггла по отображению объёмов по умолчнаию в настройках пользователя
              },
            },
          ]
        );
      },
      [track]
    ),

    trackOrderBookAutoCenter: useCallback(() => {
      track(
        ANALYTICS.CATEGORY.DOM_WIDGET,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.CENTER,
        null,
        null
      );
    }, [track]),
  };
};
