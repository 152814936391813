import { useQuery } from 'react-query';

import { checkTransferableAccounts } from '@terminal/core/lib/rest/investApi';

export const useCheckTransferableAccounts = (
  source: string,
  destination: string
) => {
  return useQuery({
    queryKey: [
      'lk',
      'transfer',
      'checkTransferableAccounts',
      source,
      destination,
    ],
    queryFn: () => checkTransferableAccounts(source, destination),
    enabled: Boolean(source) && Boolean(destination),
  });
};
