import { OrderDirection } from '@terminal/core/lib/client/entities';
import { OperationHistoryType } from '@terminal/core/types/operation';

export const mapOperationHistoryTypeToName = (
  type: OperationHistoryType,
  name: string,
  direction = OrderDirection.Undefined
): string => {
  if ([OperationHistoryType.DE, OperationHistoryType.CE].includes(type)) {
    return `Выплата ${name}`;
  }

  if (type === OperationHistoryType.DP) {
    return `Компенсация ${name}`;
  }

  if (type === OperationHistoryType.CP) {
    return 'Комиссия брокера';
  }

  if (type === OperationHistoryType.TP) {
    return direction === OrderDirection.Buy
      ? 'Возврат переплаты по НДФЛ '
      : 'Удержание НДФЛ';
  }

  return name;
};
