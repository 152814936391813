import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLatest } from 'react-use';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { AmountInputProps } from '@alfalab/core-components/amount-input';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { formatAmount } from '@alfalab/utils';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { OrderType } from '@terminal/core/lib/client/entities';
import {
  getMinority,
  roundPrice,
  roundToStep,
} from '@terminal/core/lib/format';
import { plural } from '@terminal/core/lib/plural';
import { OrderSide } from '@terminal/core/types/trading';
import { BracketPriceType } from '@terminal/core/types/ui';

import { PriceInputComponent } from '../../PriceInput';
import { getBracketPrice } from '../lib/getBracketPrice';

import styles from './BracketPrice.module.css';

const bracketPriceTypeMap = {
  [BracketPriceType.PRICE]: () => 'Цена',
  [BracketPriceType.PERCENT]: (isSmall: boolean) =>
    isSmall ? '%' : 'Проценты',
  [BracketPriceType.TICK]: () => 'Тики',
};

interface Props extends Pick<AmountInputProps, 'onBlur' | 'error'> {
  disabled?: boolean;
  isSmall?: boolean;
  side: OrderSide;
  brsSide: 'sl' | 'tp';
  price: number;
  priceStep?: number;
  type?: BracketPriceType;
  value?: number;
  symbol?: AmountInputProps['currency'];
  onTypeChange?: (type: BracketPriceType) => void;
  onChange: (price: number) => void;
  containerClassName?: string;
}

export const BracketPrice: FC<Props> = ({
  disabled = false,
  isSmall = false,
  side,
  brsSide,
  price,
  priceStep = 0.01,
  type,
  value,
  symbol,
  onTypeChange,
  onChange,
  onBlur,
  error,
  containerClassName,
}) => {
  const [localType, setLocalType] = useState<BracketPriceType>(
    type || BracketPriceType.PRICE
  );
  const [localValue, setLocalValue] = useState<number>(value || 0);

  useEffect(() => {
    type && setLocalType(type);
  }, [type]);

  useEffect(() => {
    value && setLocalValue(value);
  }, [value]);

  const [priceTypeMenuVisible, setPriceTypeMenuVisible] = useState(false);

  const priceTypeRef = useRef<HTMLDivElement>(null);
  const priceTypeRefButton = useRef<HTMLDivElement>(null);

  useOnClickOutside([priceTypeRef, priceTypeRefButton], () =>
    setPriceTypeMenuVisible(false)
  );

  const sideMultiplicator =
    (side === OrderSide.BUY) === (brsSide === 'tp') ? 1 : -1;

  const handleChangeType = (newType: BracketPriceType) => {
    setLocalType(newType);
    onTypeChange?.(newType);

    switch (true) {
      case localType === BracketPriceType.PRICE &&
        newType === BracketPriceType.PERCENT: {
        return setLocalValue((prevPrice) => {
          if (!price) {
            return 0.1;
          }

          return roundToStep(
            (sideMultiplicator * (prevPrice - price) * 100) / price,
            0.01
          );
        });
      }
      case localType === BracketPriceType.PRICE &&
        newType === BracketPriceType.TICK: {
        return setLocalValue((prevPrice) => {
          if (!price) {
            return 1;
          }

          return (sideMultiplicator * (prevPrice - price)) / priceStep;
        });
      }
      case localType === BracketPriceType.TICK &&
        newType === BracketPriceType.PRICE: {
        return setLocalValue((tick) => {
          if (!price) {
            return 0;
          }

          return price + sideMultiplicator * tick * priceStep;
        });
      }
      case localType === BracketPriceType.TICK &&
        newType === BracketPriceType.PERCENT: {
        return setLocalValue((tick) => {
          if (!price) {
            return 0.1;
          }

          return (tick * priceStep * 100) / price;
        });
      }
      case localType === BracketPriceType.PERCENT &&
        newType === BracketPriceType.PRICE: {
        return setLocalValue((percent) => {
          if (!price) {
            return 0;
          }

          return price * (1 + (sideMultiplicator * percent) / 100);
        });
      }
      case localType === BracketPriceType.PERCENT &&
        newType === BracketPriceType.TICK: {
        return setLocalValue((percent) => {
          if (!price) {
            return 1;
          }

          return (price * percent) / (100 * priceStep);
        });
      }
    }
  };

  const onChangeLatest = useLatest(onChange);
  const prevLocalValue = usePrevious(localValue);

  useEffect(() => {
    if (isUndefined(prevLocalValue) || prevLocalValue === localValue) {
      return;
    }

    let step;

    if (localType === BracketPriceType.PRICE) {
      step = priceStep;
    } else if (localType === BracketPriceType.PERCENT) {
      step = 0.01;
    } else {
      step = 1;
    }

    onChangeLatest.current?.(roundPrice(localValue, step));
  }, [priceStep, localType, prevLocalValue, localValue, onChangeLatest]);

  const priceDecimals = getMinority(priceStep);
  const bracketPriceMinority = (() => {
    switch (localType) {
      case BracketPriceType.TICK:
        return 1;
      case BracketPriceType.PERCENT:
        return 100;
      case BracketPriceType.PRICE:
      default:
        return priceDecimals;
    }
  })();

  return (
    <div className={cn(containerClassName)}>
      <div
        ref={priceTypeRefButton}
        className={cn(styles.dropdownRef, disabled && styles.disabled)}
      >
        <div
          className={styles.dropDownContent}
          onClick={() => !disabled && setPriceTypeMenuVisible((prev) => !prev)}
        >
          <Typography.Text view="secondary-large" className={styles.text}>
            {bracketPriceTypeMap[localType](isSmall)}
          </Typography.Text>
          <ChevronDownCompactMIcon
            height={16}
            width={16}
            className={cn(
              styles.chevron,
              priceTypeMenuVisible && styles.chevronUp
            )}
          />
        </div>
        <Dropdown
          ref={priceTypeRef}
          useAnchorWidth
          anchorElement={priceTypeRefButton.current}
          open={priceTypeMenuVisible}
          position="bottom-start"
          offset={[0, 4]}
          popperClassName={cn(isSmall ? styles.popperSmall : styles.popper)}
        >
          {Object.values(BracketPriceType).map((priceType) => (
            <Dropdown.Item
              key={priceType}
              rightIcon={
                priceType === localType && (
                  <CheckmarkMIcon height={16} width={16} />
                )
              }
              content={
                <div className={styles.dropDownItemContent}>
                  <Typography.Text
                    view="secondary-large"
                    className={styles.graphic}
                  >
                    {bracketPriceTypeMap[priceType](false)}
                  </Typography.Text>
                </div>
              }
              onClick={() => {
                handleChangeType(priceType);
                setPriceTypeMenuVisible(false);
              }}
            />
          ))}
        </Dropdown>
      </div>

      <div>
        <PriceInputComponent
          isChart={false}
          withStepper={!isSmall}
          disabled={disabled}
          error={error}
          onBlur={(e) => {
            if (e.target.value === '') {
              setLocalValue(0);
            }

            onBlur?.(e);
          }}
          currency={symbol}
          suffix={getSuffix(localType, localValue, isSmall, symbol)}
          orderType={OrderType.LMT}
          side="sell"
          value={localValue * bracketPriceMinority}
          minority={bracketPriceMinority}
          onChange={(_, { value }) => {
            if (typeof value === 'number') {
              setLocalValue(value / bracketPriceMinority);
            }
          }}
          onAdd={() => {
            if (localType === BracketPriceType.PRICE) {
              setLocalValue((prev) => prev + priceStep);
            } else if (localType === BracketPriceType.PERCENT) {
              setLocalValue((prev) => prev + 0.01);
            } else if (localType === BracketPriceType.TICK) {
              setLocalValue((prev) => prev + 1);
            }
          }}
          onSub={() => {
            if (localType === BracketPriceType.PRICE) {
              setLocalValue((prev) => Math.max(0, prev - priceStep));
            } else if (localType === BracketPriceType.PERCENT) {
              setLocalValue((prev) => Math.max(0, prev - 0.01));
            } else if (localType === BracketPriceType.TICK) {
              setLocalValue((prev) => Math.max(0, prev - 1));
            }
          }}
          allowStreamPrice={false}
          isPriceStreamed={false}
          setPriceStreamed={noop}
        />
        <Typography.Text
          view="secondary-small"
          tag="div"
          className={styles.graphic}
        >
          {
            formatAmount({
              value:
                getBracketPrice(
                  price,
                  localType,
                  localValue,
                  (side === OrderSide.BUY) === (brsSide === 'sl') ? -1 : 1,
                  priceStep
                ) * priceDecimals,
              minority: priceDecimals,
              currency: symbol,
              view: 'withZeroMinorPart',
            }).formattedWithCurrency
          }
        </Typography.Text>
      </div>
    </div>
  );
};

function getSuffix(
  type: BracketPriceType,
  value: number,
  isSmall: boolean,
  symbol?: CurrencyCodes
): string | undefined {
  if (type === BracketPriceType.PRICE) {
    return symbol;
  }

  if (type === BracketPriceType.TICK) {
    return isSmall ? 'т' : plural(['тик', 'тика', 'тиков'], value);
  }

  return '%';
}
