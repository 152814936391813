import { Gap } from '@alfalab/core-components/gap';

import {
  ActiveCertificate,
  OtherCertificates,
} from '../../../../widgets/Certificates';

import styles from './Certificates.module.css';

export const Certificates = () => (
  <div className={styles.root}>
    <ActiveCertificate />
    <Gap size="2xl" />
    <OtherCertificates />
  </div>
);
