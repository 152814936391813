import cn from 'classnames';
import { useState } from 'react';
import { BellMIcon } from '@alfalab/icons-glyph/BellMIcon';
import { NavigationMarketplaceMIcon } from '@alfalab/icons-glyph/NavigationMarketplaceMIcon';
import { ScalesMIcon } from '@alfalab/icons-glyph/ScalesMIcon';
import { ScrewdriverPaintBrushMIcon } from '@alfalab/icons-glyph/ScrewdriverPaintBrushMIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import {
  MainMenu,
  MainMenuItem,
} from '@terminal/common/components/MainMenu/MainMenu';
import { DefaultValues } from '@terminal/common/features-DEPRECATED/DefaultValues';

import { Design } from '../Design';
import { Notifications } from '../Notifications';
import { WorkspaceConfiguration } from '../WorkspaceConfiguration';

import { CommonSettingMenuType } from '../../model/types';

import styles from './CommonSettingsModal.module.css';

interface Props {
  defaultSelectedMenuType?: CommonSettingMenuType | null;
  onClose: () => void;
}

export const CommonSettingsModal = ({
  defaultSelectedMenuType,
  onClose,
}: Props) => {
  const [selected, setSelected] = useState<CommonSettingMenuType>(
    defaultSelectedMenuType || CommonSettingMenuType.Design
  );

  const menuItems: MainMenuItem<CommonSettingMenuType>[] = [
    {
      name: 'Оформление',
      icon: <ScrewdriverPaintBrushMIcon />,
      type: CommonSettingMenuType.Design,
    },
    {
      name: 'Нотификации',
      icon: (
        <BellMIcon
          width={18}
          height={18}
          fill={
            selected === CommonSettingMenuType.Notifications
              ? 'var(--color-light-graphic-primary)'
              : 'var(--color-light-graphic-secondary)'
          }
        />
      ),
      type: CommonSettingMenuType.Notifications,
    },
    {
      name: 'Конфигурации',
      icon: <NavigationMarketplaceMIcon />,
      type: CommonSettingMenuType.WorkspaceConfiguration,
    },
    {
      name: 'Дефолтные значения',
      icon: <ScalesMIcon />,
      type: CommonSettingMenuType.DefaultValues,
    },
  ];

  return (
    <FunctionalModal
      title="Общие настройки"
      width={812}
      height={520}
      onClose={onClose}
      drawerId="common-settings-modals"
      sidebar={
        <MainMenu
          items={menuItems}
          selected={selected}
          onItemSelect={(item) =>
            setSelected(item.type as CommonSettingMenuType)
          }
        />
      }
      innerClassname={cn({
        [styles.workspaceInner]:
          selected === CommonSettingMenuType.WorkspaceConfiguration,
      })}
    >
      {(() => {
        switch (selected) {
          case CommonSettingMenuType.Notifications:
            return <Notifications />;
          case CommonSettingMenuType.Design:
            return <Design />;
          case CommonSettingMenuType.WorkspaceConfiguration:
            return <WorkspaceConfiguration />;

          case CommonSettingMenuType.DefaultValues:
            return <DefaultValues />;

          default:
            return null;
        }
      })()}
    </FunctionalModal>
  );
};
