import { useQuery } from 'react-query';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { getCloudConfigurations } from '../api/getCloudConfigurations';

export function useCloudConfigList() {
  return useQuery({
    queryKey: [LkQueryKeys.CloudConfigList],
    queryFn: getCloudConfigurations,
  });
}
