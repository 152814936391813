import keyBy from 'lodash/keyBy';

import { LinkName } from '@terminal/core/types/layout';

import { Link } from '../model/types/layoutLinks';
import { Row } from '../model/types/row';
import { Tab } from '../model/types/tab';
import { TabSet } from '../model/types/tabSet';

/**
 * Рекурсивно обходит конфигурацию и собирает линковку из элементов Tab,
 * возвращает объект для вставки в конфигурацию (layoutLinks)
 * @param element
 * @param links
 * */
export const getLinks = (
  element: Row,
  links: Link[]
): Record<Exclude<LinkName, LinkName.DEFAULT>, Link> => {
  const idsByLinks: Array<{ link: LinkName; id: string }> = [];
  const collectLinks = (element: Tab | TabSet | Row): void => {
    if (element instanceof Tab) {
      if (element.config.link) {
        idsByLinks.push({ link: element.config.link, id: element.id });
      }
    } else {
      element.children.forEach((child) => {
        collectLinks(child);
      });
    }
  };

  collectLinks(element);

  return idsByLinks.reduce<Record<Exclude<LinkName, LinkName.DEFAULT>, Link>>(
    (acc, el) => {
      acc[el.link].nodeIds.push(el.id);

      return acc;
    },
    {
      [LinkName.BLUE]: { name: LinkName.BLUE, nodeIds: [] },
      [LinkName.GREEN]: { name: LinkName.GREEN, nodeIds: [] },
      [LinkName.RED]: { name: LinkName.RED, nodeIds: [] },
      [LinkName.YELLOW]: { name: LinkName.YELLOW, nodeIds: [] },
      ...keyBy<Link>(links, 'name'),
    }
  );
};
