import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { CopyLineMIcon } from '@alfalab/icons-glyph/CopyLineMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';

// eslint-disable-next-line no-restricted-imports
import formstyles from '../../../styles/forms.module.css';
import styles from './MoneyRequisitesActions.module.css';

interface MoneyRequisitesActionsProps {
  onPdfDownload: () => void;
  onCopyClick: () => void;
  pdfLoading: boolean;
  pdfFile: DownloadedFile | null;
}

export const MoneyRequisitesActions = ({
  onPdfDownload,
  pdfLoading,
  pdfFile,
  onCopyClick,
}: MoneyRequisitesActionsProps) => {
  return (
    <div className={styles.actions}>
      <Row gutter={16}>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <ButtonDesktop
            className={formstyles.buttonSmall}
            size="s"
            view="tertiary"
            block
            onClick={onCopyClick}
            leftAddons={
              <CopyLineMIcon
                width={18}
                height={18}
                className={formstyles.icon}
              />
            }
          >
            Скопировать
          </ButtonDesktop>
        </Col>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <ButtonDesktop
            className={formstyles.buttonSmall}
            size="s"
            view="tertiary"
            block
            onClick={pdfFile ? undefined : onPdfDownload}
            href={pdfFile?.url}
            download={pdfFile?.filename}
            target={pdfFile ? '_blank' : undefined}
            loading={pdfLoading}
            leftAddons={
              <OutsideMIcon
                width={18}
                height={18}
                className={formstyles.icon}
              />
            }
          >
            {pdfFile ? 'Открыть в PDF' : 'Скачать PDF'}
          </ButtonDesktop>
        </Col>
      </Row>
    </div>
  );
};
