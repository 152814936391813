import { LinkName, TableProps } from '@terminal/core/types/layout';

export class Link {
  name: LinkName;
  nodeIds: string[];
  idFi?: number;
  symbol?: string;
  tableProps?: TableProps;

  constructor(props: {
    name: LinkName;
    nodeIds?: string[];
    idFi: number;
    symbol: string;
    tableProps?: TableProps;
  }) {
    Object.assign(this, props);

    if (!this.nodeIds) {
      this.nodeIds = [];
    }
  }
}

export interface LayoutLinks {
  blue: Link;
  green: Link;
  red: Link;
  yellow: Link;
}
