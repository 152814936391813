import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetFatcaTextDTO } from './types/GetFatcaTextDTO';
import { GetFatcaTextParams } from './types/GetFatcaTextParams';

export async function getFatcaText({
  treaty,
}: GetFatcaTextParams): Promise<GetFatcaTextDTO> {
  const result = await fetchLkResult<GetFatcaTextDTO & LKResult>(
    `/api/documents/fatca-text/${treaty}`
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
