// Функция для поиска ключа key со значением value в многоуровневом массиве/объекте container
export const deepSearch = (
  container: Record<any, any> | any[],
  key: string | number,
  value: any
): Record<any, any> | null => {
  let result: Record<any, any> | null = null;

  if (container instanceof Array) {
    for (let i = 0; i < container.length; i++) {
      result = deepSearch(container[i], key, value);

      if (result) break;
    }
  } else {
    for (let prop in container) {
      if (prop === key) {
        if (container[prop] === value) return container;
      }

      if (
        container[prop] instanceof Object ||
        container[prop] instanceof Array
      ) {
        result = deepSearch(container[prop], key, value);

        if (result) break;
      }
    }
  }

  return result;
};

export const lbinsearch = <T>(
  l: number,
  r: number,
  check: (m: number, params: T) => boolean,
  checkParams: T
) => {
  while (l < r) {
    const m = Math.floor((l + r) / 2);
    if (check(m, checkParams)) {
      r = m;
    } else {
      l = m + 1;
    }
  }

  return l;
};
