import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getCommonInstrumentsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeChartConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHART_RIGHT_CHARTIQ_SETTINGS,
  TRADE_RIGHT_CHART_SETTINGS,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const scalpingLarge = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });
    const greenLink = new Link({
      name: LinkName.GREEN,
      idFi: 144950,
      symbol: 'SBER',
    });
    const leftRow = new Row(50);
    const rightRow = new Row(50);

    const leftTopRow = new Row(50);
    const leftBottomRow = new Row(50);

    const leftOrdersTab = new Tab(
      Widget.ORDERS,
      getCommonOrdersConfig(blueLink.name)
    );
    const leftPositionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );

    const leftOrdersTabset = new TabSet(40);

    leftOrdersTabset.addChildren([leftOrdersTab, leftPositionsTab]);

    const leftChartiq = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      TRADE_RIGHT_CHART_SETTINGS
    );
    const leftChartiqTabSet = new TabSet(60);

    leftChartiqTabSet.addChildren([leftChartiq]);

    leftTopRow.addChildren([leftOrdersTabset, leftChartiqTabSet]);

    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );

    const instrumentsTabSet = new TabSet(40);

    instrumentsTabSet.addChildren([leftInstrumentsTab]);

    const leftTradesTab = new Tab(Widget.TRADES, { link: blueLink.name });
    const leftTradesTabSet = new TabSet(30);

    leftTradesTabSet.addChildren([leftTradesTab]);

    const leftOrderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const leftOrderbookTabset = new TabSet(30);

    leftOrderbookTabset.addChildren([leftOrderbookTab]);

    leftBottomRow.addChildren([
      instrumentsTabSet,
      leftTradesTabSet,
      leftOrderbookTabset,
    ]);

    leftRow.addChildren([leftTopRow, leftBottomRow]);

    const rightTopRow = new Row(50);
    const rightBottomRow = new Row(50);

    const rightOrdersTab = new Tab(
      Widget.ORDERS,
      getCommonOrdersConfig(greenLink.name)
    );
    const rightPositionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(greenLink.name)
    );

    const rightOrdersTabset = new TabSet(40);

    rightOrdersTabset.addChildren([rightOrdersTab, rightPositionsTab]);

    const rightChartiq = new Tab(
      Widget.CHART,
      getTradeChartConfig(greenLink.name),
      TRADE_RIGHT_CHART_SETTINGS
    );
    const rightChartiqTabSet = new TabSet(60);

    rightChartiqTabSet.addChildren([rightChartiq]);

    rightTopRow.addChildren([rightChartiqTabSet, rightOrdersTabset]);

    const rightInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(greenLink.name, WatchlistIds.iMOEXList)
    );

    const rightinstrumentsTabSet = new TabSet(40);

    rightinstrumentsTabSet.addChildren([rightInstrumentsTab]);

    const rightTradesTab = new Tab(Widget.TRADES, { link: greenLink.name });
    const rightTradesTabSet = new TabSet(30);

    rightTradesTabSet.addChildren([rightTradesTab]);

    const rightOrderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(greenLink.name)
    );
    const rightOrderbookTabset = new TabSet(30);

    rightOrderbookTabset.addChildren([rightOrderbookTab]);

    rightBottomRow.addChildren([
      rightOrderbookTabset,
      rightTradesTabSet,
      rightinstrumentsTabSet,
    ]);

    rightRow.addChildren([rightTopRow, rightBottomRow]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink, greenLink]);
  };
  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });
    const greenLink = new Link({
      name: LinkName.GREEN,
      idFi: 144950,
      symbol: 'SBER',
    });
    const baseChartConfig = {
      symbol: 'SBER',
      idFi: 144950,
      link: blueLink.name,
      tableProps: {},
    } as const;
    const mainRow = new Row(100);
    const topRow = new Row(50);
    const bottomRow = new Row(50);

    const topInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );

    const topInstrumentsTabSet = new TabSet(20);

    topInstrumentsTabSet.addChildren([topInstrumentsTab]);

    const leftTopChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftTopChartTabSet = new TabSet(40);

    leftTopChartTabSet.addChildren([leftTopChartTab]);

    const rightTopChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightTopChartTabSet = new TabSet(40);

    rightTopChartTabSet.addChildren([rightTopChartTab]);

    topRow.addChildren([
      topInstrumentsTabSet,
      leftTopChartTabSet,
      rightTopChartTabSet,
    ]);

    const bottomInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(greenLink.name, WatchlistIds.iMOEXList)
    );

    const bottomInstrumentsTabSet = new TabSet(20);

    bottomInstrumentsTabSet.addChildren([bottomInstrumentsTab]);

    const leftBottomChartTab = new Tab(
      Widget.CHART,
      { ...baseChartConfig, link: greenLink.name },
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftBottomChartTabSet = new TabSet(40);

    leftBottomChartTabSet.addChildren([leftBottomChartTab]);

    const rightBottomChartTab = new Tab(
      Widget.CHART,
      { ...baseChartConfig, link: greenLink.name },
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightBottomChartTabSet = new TabSet(40);

    rightBottomChartTabSet.addChildren([rightBottomChartTab]);

    bottomRow.addChildren([
      bottomInstrumentsTabSet,
      leftBottomChartTabSet,
      rightBottomChartTabSet,
    ]);

    mainRow.addChildren([topRow, bottomRow]);

    const chartRow = new Row(100);

    chartRow.addChildren([mainRow]);

    return new Layout('Графики', chartRow, [blueLink, greenLink]);
  };

  const configuration = new DefaultConfiguration('Скальпинг');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
