import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { requestMoneyBankTemplates } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

export const useBankTemplates = (treaty: number, enabled: boolean = false) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'transfer', 'bankTemplates', treaty],
    () => requestMoneyBankTemplates(treaty),
    {
      enabled: Boolean(treaty) && enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе счетов произошла ошибка',
        });
      },
    }
  );
};
