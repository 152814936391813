import React, { FC, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { RocketMIcon } from '@alfalab/icons-glyph/RocketMIcon';

import { PortfolioAnalyticsResult } from '@terminal/core/types/account';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { Drawer } from '../../../Drawer';
import { MarginTradeDrawer } from '../MarginTradeDrawer';

import styles from './MarginTradeBanner.module.css';

interface Props {
  isMarginTradeTestPassed: boolean;
  analytics?: PortfolioAnalyticsResult;
  onClose: () => void;
}

export const MarginTradeBanner: FC<Props> = ({
  isMarginTradeTestPassed,
  analytics,
  onClose,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { trackClickPortfolioReportMarginalBanner } =
    usePortfolioReportsMetrics();

  const drawer = (
    <Drawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
      <MarginTradeDrawer
        isMarginTradeTestPassed={isMarginTradeTestPassed}
        onClose={onClose}
      />
    </Drawer>
  );

  const handleOpenBanner = () => {
    setDrawerOpen(true);
    trackClickPortfolioReportMarginalBanner();
  };

  const beginBalance = analytics?.beginNAV?.value || 0;
  const balance = analytics?.endNAV?.value || 0;
  const profit = balance - beginBalance;

  if (!balance || profit <= 0) {
    return null;
  }

  return (
    <>
      <div className={styles.banner} onClick={handleOpenBanner}>
        <RocketMIcon
          width={26}
          height={24}
          color="var(--color-light-text-secondary)"
        />
        <Typography.Text tag="div" view="secondary-large">
          С плечом можно заработать
          <br />
          ещё больше
        </Typography.Text>
        <ChevronRightMIcon
          width={16}
          height={16}
          color="var(--color-light-text-secondary)"
        />
      </div>

      <Gap size="2xl" />

      {drawer}
    </>
  );
};
