import EventEmitter from 'eventemitter3';
import log from 'loglevel';

import { AuthService, AuthServiceEvents } from '../../lib/services/auth';
import { trackApplicationError } from '../analytics';
import { trackClientMessage } from '../services/monitoring';
import {
  ClientMessageEntity,
  FrontEndType,
  Message,
  Messages,
  MessageUnitedType,
} from './entities';
import { EntityType } from './entityTypes';
import { initDataViewExtensions } from './extensions';
import { isObjectMessage } from './helpers';
import AdirClientService from './service';
import {
  ADSocket,
  AuthTimeoutError,
  SocketEvent,
  SocketStatus,
} from './socket';

import { AdirApiEvent, AdirClientOptions } from './types';

// Надо вызвать в самом начале работы
initDataViewExtensions(); // добавить специфичные функции в прототип DataView

/**
 * Коды сообщений после которых не будет производиться попытка реконнекта
 */
const noReconnectAllowedMessages = [
  Messages.FrontEndAuthenticationFailed,
  Messages.FrontEndDisconnectBecauseOfNewLogin,
  Messages.FrontEndSessionCommandLimitsOverflow,
  Messages.FrontEndDisconectFrequencyLimitsOverflow,
  Messages.FrontEndDisconnectBecauseOfClientInactivity,
  Messages.FrontEndWrondDeveloperKey,
  Messages.FrontEndAuthenticationFailedNoExtNum,
  Messages.FrontEndNotSupportedProtocolVersion,
  Messages.FrontEndDemoClientsAreNotSupported,
];

const FRONT_END_TYPE_LIST = [
  FrontEndType.AuthAndOperInitServer,
  FrontEndType.OperServer,
  FrontEndType.RealTimeBirzInfoServer,
  FrontEndType.RealTimeBirzInfoDelayedServer,
  FrontEndType.BirzArchAndMediaServer,
];

export class AdirAPI extends EventEmitter<EntityType | AdirApiEvent> {
  readonly sockets: Map<FrontEndType, ADSocket>;
  readonly services: AdirClientService[] = [];
  events = AdirApiEvent;
  initialized: Boolean = false;
  feUriMap: Map<FrontEndType, string>;
  #settings: AdirClientOptions;

  idDevice: string = '';
  private skipClientInactivity = true;

  constructor() {
    super();

    this.sockets = new Map<FrontEndType, ADSocket>();

    this.addListener(EntityType.ClientMessageEntity, this.checkErrorMessage);
  }

  set settings(data: AdirClientOptions) {
    this.#settings = data;

    const { FE1, FE2, FE3, FE4, FE31 } = this.settings.feURI;

    this.feUriMap = new Map([
      [FrontEndType.AuthAndOperInitServer, FE1],
      [FrontEndType.OperServer, FE2],
      [FrontEndType.RealTimeBirzInfoServer, FE3],
      [FrontEndType.RealTimeBirzInfoDelayedServer, FE31],
      [FrontEndType.BirzArchAndMediaServer, FE4],
    ]);
  }

  get settings() {
    return this.#settings;
  }

  registerService<T extends AdirClientService>(service: T) {
    this.services.push(service);
  }

  init(idDevice: string) {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this.idDevice = idDevice;
    this.createWebSockets();

    for (let service of this.services) {
      service.setClient(this);
      service.init();
    }

    AuthService.addListener(
      AuthServiceEvents.AUTH_ATTEMPT,
      (attemptsRemain) => {
        this.skipClientInactivity = attemptsRemain > 0;
      }
    );
  }

  async createWebSocket(frontEndType: FrontEndType) {
    const feUri = this.feUriMap.get(frontEndType);

    if (!feUri) {
      throw new Error(`No URI for frontEndType=${frontEndType}`);
    }

    if (!this.idDevice) {
      throw new Error('No idDevice');
    }

    const currentSocket = this.sockets.get(frontEndType);

    if (currentSocket) {
      await currentSocket.close();
      currentSocket.resetCredentials();

      return currentSocket;
    }

    const socket = new ADSocket(
      feUri,
      frontEndType,
      this.settings.lostConnectionTimeout,
      this.settings.heartBeatInterval,
      this.idDevice
    );

    this.sockets.set(frontEndType, socket);

    try {
      socket.addListener(
        SocketEvent.Ready,
        this.updateConnectionStatus.bind(this)
      );
      socket.addListener(SocketEvent.Close, this.onClose.bind(this));
      socket.addListener(SocketEvent.Message, this.onMessage.bind(this));
      socket.addListener(SocketEvent.Error, this.onError.bind(this));
    } catch (e) {
      log.warn(e);
    }

    return socket;
  }

  createWebSockets() {
    return Promise.all(
      FRONT_END_TYPE_LIST.map((frontEndType) => {
        return this.createWebSocket(frontEndType);
      })
    );
  }

  updateConnectionStatus(data: { frontEndType: FrontEndType }) {
    let socketsReadyCount = 0;

    this.sockets.forEach((socket) => {
      if (socket.isWebSocketReady() && socket.status === SocketStatus.Ready) {
        socketsReadyCount++;
      }
    });

    this.emit(AdirApiEvent.SOCKET_READY, data);

    if (socketsReadyCount === this.sockets.size) {
      this.emit(AdirApiEvent.SOCKETS_READY);
    }
  }

  subscribe(
    frontEndType: FrontEndType,
    entityType: EntityType,
    flowKeys?: number[],
    version?: BigInt
  ) {
    this.sockets.get(frontEndType)?.subscribe(entityType, flowKeys, version);
  }
  unsubscribe(
    frontEndType: FrontEndType,
    entityType: EntityType,
    flowKeys?: number[]
  ) {
    this.sockets.get(frontEndType)?.unsubscribe(entityType, flowKeys);
  }

  onMessage(message: Message) {
    if (
      isObjectMessage(message.payload) &&
      message.payload.type === EntityType.HeartbeatEntity
    ) {
      log.trace(message);
    } else {
      log.debug(message);
    }

    this.notify(message);
  }

  async onError(e: Error, socket: ADSocket) {
    log.error(`SOCKET ERROR  FE=${socket.fe}`);

    try {
      if (e instanceof AuthTimeoutError && this.skipClientInactivity) {
        await AuthService.connectPassport();
      } else {
        this.emit(AdirApiEvent.SOCKET_ERROR, e.message);
      }
    } catch (e) {
      if (e instanceof Error) {
        this.emit(AdirApiEvent.SOCKET_ERROR, e.message);
      } else {
        log.error(e);
        this.emit(AdirApiEvent.SOCKET_ERROR, 'Ошибка соединения');
      }
    }
  }

  onClose(e: CloseEvent, socket: ADSocket) {
    log.warn(
      `SOCKET CLOSED FE=${socket.fe} code=${e.code} reason=${e.reason} wasClean=${e.wasClean}`
    );
    this.emit(AdirApiEvent.SOCKET_CLOSE, e, socket);
  }

  notify(message: Message) {
    // TODO надо сделать какой-нибудь лукап словарь вместо списка
    // тк на каждый месседж делать цикл не очень идея
    if (!Array.isArray(message.payload)) {
      this.emit(message.payload.type as EntityType, {
        ...message.payload,
        data: [message.payload.data],
        frontend: message.frontend,
      });
    } else if (Array.isArray(message.payload)) {
      message.payload.forEach((element) => {
        this.emit(element.type as EntityType, element);
      });
    }

    trackClientMessage(message);
  }

  send(message: Message) {
    const socket = this.sockets.get(message.frontend);

    if (!socket) {
      log.error(`unknown frontend ${message.frontend}`);

      return;
    }

    if (socket.isWebSocketReady()) {
      socket.send(message);
    } else {
      log.debug(`Trying to send message to closed socket ${socket.fe}`);
    }
  }

  appplyToSockets(fn: (socket: ADSocket | undefined) => void) {
    for (const [, value] of this.sockets) {
      fn(value);
    }
  }

  private checkErrorMessage = (message: MessageUnitedType) => {
    const messages = message.data as ClientMessageEntity[];

    if (!message.frontend) {
      return;
    }

    for (const message of messages) {
      // на мобилках возникает проблема с авторизацией из-за проблем сети и производительности
      // скипаем обработку ошибки о таймауте подключения
      if (
        message.MessageId ===
          Messages.FrontEndDisconnectBecauseOfClientInactivity &&
        this.skipClientInactivity
      ) {
        continue;
      }

      const isBlockReconnection = noReconnectAllowedMessages.includes(
        message.MessageId
      );

      if (isBlockReconnection) {
        log.warn(`cancel reconnection, code: ${message.MessageId}`);

        // Выводим ошибку на экран
        const fe = message.frontend ?? FrontEndType.Unknown;
        const errorMessage = `Сервер прервал соединение. Код ошибки FE${fe}/${message.MessageId}`;

        trackApplicationError(errorMessage);

        this.emit(AdirApiEvent.SOCKET_ERROR, errorMessage, {
          blockedReconnection: true,
        });

        // убиваем все соединения т.к. понимаем что переподключится нельзя
        this.resetConnections();

        break;
      }
    }
  };

  resetConnections() {
    this.appplyToSockets((socket) => {
      socket?.terminate();
    });
  }
}

export const alfaDirectClient = new AdirAPI();
