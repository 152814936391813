export const enum IssuerIconIdentifierType {
  IdObject = 'IdObject',
  IdObjectBase = 'IdObjectBase',
  Isin = 'Isin',
  EmCode = 'EmCode',
}

export interface IssuerLogotype {
  hasIcon: boolean;
  iconType: IssuerIconIdentifierType | null;
  idObject?: number;
  idObjectBase?: number;
  isin?: string;
  emCode?: string;
}
