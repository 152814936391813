import { format, parseISO } from 'date-fns';
import { memo, useCallback, useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownBoldContainerMIcon } from '@alfalab/icons-glyph/ArrowDownBoldContainerMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import {
  BACKEND_DATE_FORMAT,
  DownloadedFile,
} from '@terminal/core/lib/rest/lkApi';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { LK_API } from '@terminal/lk/legacy/const/externalUrls';
import { useDownloadFile } from '@terminal/lk/legacy/hooks';
import { Result, StaticField } from '@terminal/lk/shared';
import {
  DocumentsHistoryItem,
  ReportType,
  useAutomaticDocumentOrder,
} from '@terminal/lk-core';

import styles from './HistoryItemDetails.module.css';

interface HistoryItemDetailsProps {
  item?: DocumentsHistoryItem;
  onClose?: () => void;
  emailConfirmed?: boolean;
  treaty: number;
}

export const HistoryItemDetails = memo(
  ({ item, onClose, emailConfirmed, treaty }: HistoryItemDetailsProps) => {
    const [file, setFile] = useState<DownloadedFile | null>(null);
    const [formApplied, setFormApplied] = useState(false);

    const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

    const { mutateAsync: downloadFile, isLoading: filePending } =
      useDownloadFile();

    const {
      mutateAsync: orderDocument,
      isLoading: orderDocumentPending,
      isSuccess,
    } = useAutomaticDocumentOrder();

    useEffect(() => {
      if (isSuccess) {
        setFormApplied(true);
      }
    }, [isSuccess]);

    useEffect(() => {
      setFormApplied(false);
    }, [item]);

    const date = item?.docDate ? parseISO(item.docDate) : undefined;
    const periodStartDate = item?.data?.dateFrom
      ? parseISO(item?.data?.dateFrom)
      : undefined;
    const periodEndDate = item?.data?.dateTo
      ? parseISO(item?.data?.dateTo)
      : undefined;

    const onRequestFile = useCallback(async () => {
      if (item?.scan && !file && !filePending) {
        const url = `${LK_API.DOCUMENT_SCAN}/${item.scan?.scanId}/${item.scan?.filename}`;
        const file = await downloadFile(url);

        setFile(file);
      }
    }, [downloadFile, file, filePending, item?.scan]);

    const handleChangeEmail = useCallback(() => {
      setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
    }, [setHeaderMenuOpen]);

    const handleBack = useCallback(() => {
      onClose?.();
      setFormApplied(false);
    }, [onClose]);

    const orderAutomaticDocument = useCallback(() => {
      if (
        treaty &&
        item?.data?.depoAccount &&
        item.data?.dateFrom &&
        item.data?.dateTo
      ) {
        const dateFrom = format(
          parseISO(item.data?.dateFrom),
          BACKEND_DATE_FORMAT
        );
        const dateTo = format(parseISO(item.data?.dateTo), BACKEND_DATE_FORMAT);

        orderDocument({
          params: {
            depoAccount: item.data.depoAccount,
            startDate: dateFrom,
            endDate: dateTo,
            themeId: ReportType.SecuritiesMovementStatementDepoAccount,
            treaty,
          },
        });
      }
    }, [item, orderDocument, treaty]);

    if (formApplied) {
      return (
        <Result
          title="Ваш запрос принят"
          text={
            <>
              Отправим выписку на вашу электронную почту.
              <br />
              Если вы сегодня изменили адрес почты,
              <br />
              мы обновим информацию и вышлем документ
              <br />в течение одного рабочего дня
            </>
          }
          icon={
            <SuperEllipse size={48}>
              <CheckmarkMIcon />
            </SuperEllipse>
          }
          maxWidth={360}
        >
          <ButtonDesktop size="xs" view="accent" onClick={handleBack}>
            Перейти в историю
          </ButtonDesktop>
        </Result>
      );
    }

    if (!item) {
      return null;
    }

    return (
      <div className={styles.detailsContainer}>
        <Typography.Text
          view="primary-small"
          weight="bold"
          color="primary"
          tag="p"
          className={styles.detailsTitle}
        >
          {item.theme}
        </Typography.Text>
        <Space size="m" fullWidth>
          <StaticField label="Статус">{item.status}</StaticField>
          {item.data && item.data.lkNumber && (
            <StaticField label="Номер заявки">
              {item.data?.lkNumber}
            </StaticField>
          )}
          {date && (
            <StaticField label="Дата и время заявки">
              {format(date, 'dd.MM.yyyy HH:mm:ss')}
            </StaticField>
          )}
          {periodStartDate && (
            <StaticField label="Начало периода">
              {format(periodStartDate, 'dd.MM.yyyy')}
            </StaticField>
          )}
          {periodEndDate && (
            <StaticField label="Конец периода">
              {format(periodEndDate, 'dd.MM.yyyy')}
            </StaticField>
          )}
          {item.comment && (
            <StaticField label="Комментарий">{item.comment}</StaticField>
          )}
          {item.scan && (
            <>
              <Divider className={styles.divider} />
              <ButtonDesktop
                className={styles.downloadButton}
                size="xs"
                view="accent"
                block
                onClick={file ? undefined : onRequestFile}
                href={file?.url}
                download={file?.filename}
                target={file ? '_blank' : undefined}
                loading={filePending}
                leftAddons={
                  file ? (
                    <OutsideMIcon
                      width={18}
                      height={18}
                      className={styles.glyph}
                    />
                  ) : (
                    <ArrowDownBoldContainerMIcon
                      width={18}
                      height={18}
                      className={styles.glyph}
                    />
                  )
                }
              >
                {file ? 'Открыть' : 'Скачать'}
              </ButtonDesktop>
            </>
          )}
          {Boolean(item?.data?.isAutomatic) && (
            <>
              <Divider className={styles.divider} />
              {emailConfirmed ? (
                <ButtonDesktop
                  size="xs"
                  view="accent"
                  onClick={orderAutomaticDocument}
                  loading={orderDocumentPending}
                >
                  Получить выписку
                </ButtonDesktop>
              ) : (
                <div className={styles.emailContainer}>
                  <ButtonDesktop
                    size="xs"
                    view="accent"
                    onClick={handleChangeEmail}
                  >
                    Указать адрес
                  </ButtonDesktop>
                  <Typography.Text color="secondary" view="secondary-small">
                    Укажите или подтвердите адрес электронной почты. Мы отправим
                    на него выписку в течение следующего рабочего дня
                  </Typography.Text>
                </div>
              )}
            </>
          )}
        </Space>
      </div>
    );
  }
);
