import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import ru from 'date-fns/locale/ru';

export const formatOperationDate = (operationDate: string): string => {
  const date = new Date(operationDate);

  if (isToday(date)) {
    return 'Сегодня';
  }

  if (isYesterday(date)) {
    return 'Вчера';
  }

  return format(date, 'dd MMMM yyyy', {
    locale: ru,
  });
};
