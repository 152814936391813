import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';
import { NavigationMarketplaceMIcon } from '@alfalab/icons-glyph/NavigationMarketplaceMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { RepeatOffMIcon } from '@alfalab/icons-glyph/RepeatOffMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { inactiveConfigurationsSelector } from '@terminal/core/store/selectors/workspaceConfigurations/inactiveConfigurationsSelector';

import { PickerButton } from '../../../../shared/ui/PickerButton';
import { formatDateTime } from '../../lib/formatDateTime';
import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';
import { WorkspaceRenameModal } from '../WorkspaceConfigurationModal/WorkspaceRenameModal';
import { WorkspaceConfigurationsTabs } from '../WorkspaceConfigurationsTabs';

import { useConfigurationExportGroup } from '../../hooks/useConfigurationExportGroup';

import { useWorkspaceConfigurationStore } from '../../model/store/useWorkspaceConfigurationStore';

import { WorkspaceConfigurationScreen } from '../../model/types';

import styles from './WorkspaceConfigurationList.module.css';

export const WorkspaceConfigurationList = () => {
  const [renameModalConfig, setRenameModalConfig] = useState({
    isOpen: false,
    value: '',
  });
  const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);

  const {
    workspaceConfigurationActiveId,
    manualSaveConfiguration,
    resetConfigurationById,
    renameConfiguration,
  } = useStore(
    (state) => ({
      workspaceConfigurationActiveId: state.workspaceConfigurationActiveId,
      manualSaveConfiguration: state.manualSaveConfiguration,
      resetConfigurationById: state.resetConfigurationById,
      renameConfiguration: state.renameConfiguration,
    }),
    shallow
  );
  const setScreen = useWorkspaceConfigurationStore((state) => state.setScreen);

  const activeConfiguration = useStore(activeConfigurationSelector);
  const otherConfigurations = useStore(inactiveConfigurationsSelector);

  const autoSavedDateStr = formatDateTime(
    new Date(activeConfiguration.lastManualSavedConfig.timeStamp)
  );

  const isChanged = useMemo(
    () =>
      activeConfiguration.lastManualSavedConfig.timeStamp !==
      activeConfiguration.lastAutoSavedConfig.timeStamp,
    [activeConfiguration]
  );

  const changedDate = useMemo(
    () =>
      formatDateTime(
        new Date(activeConfiguration.lastAutoSavedConfig.timeStamp)
      ),
    [activeConfiguration]
  );

  const onClickManualSaveConfiguration = useCallback(() => {
    manualSaveConfiguration(workspaceConfigurationActiveId);
  }, [manualSaveConfiguration, workspaceConfigurationActiveId]);

  const onClickResetConfiguration = useCallback(
    (id: string) => {
      resetConfigurationById(id);
    },
    [resetConfigurationById]
  );

  const onClickSaveNewConfiguration = useCallback(() => {
    setScreen(WorkspaceConfigurationScreen.Add);
  }, [setScreen]);

  const activeConfigExportGroup = useConfigurationExportGroup();

  return (
    <div>
      <Typography.Text view="secondary-large" color="secondary">
        Конфигурация — сохраненный набор виджетов и их настроек на вашем рабочем
        столе. Позволяет быстро изменять рабочие столы при работе с различными
        торговыми стратегиями.
      </Typography.Text>

      <Gap size="m" />

      {activeConfiguration && (
        <>
          <Typography.Text
            view="primary-small"
            color="primary"
            tag="div"
            weight="bold"
            className={styles.graphic}
          >
            Активная конфигурация
          </Typography.Text>

          <Typography.Text view="secondary-large" color="secondary" tag="div">
            Только для этого устройства и браузера.&nbsp;
            <span
              onClick={() => setScreen(WorkspaceConfigurationScreen.Export)}
              className={styles.hintText}
            >
              Как открыть на другом устройстве?
            </span>
          </Typography.Text>

          <Gap size="s" />

          <div className={styles.activeConfiguration}>
            <SuperEllipse size={48}>
              <NavigationMarketplaceMIcon />
            </SuperEllipse>

            <div>
              <Gap size="xs" />

              <Typography.Text
                view="secondary-large"
                color="primary"
                weight="bold"
                tag="div"
                className={styles.graphic}
              >
                {activeConfiguration.name}
              </Typography.Text>
              <Typography.Text
                view="secondary-small"
                color="secondary"
                tag="div"
                weight="medium"
              >
                Сохранено {autoSavedDateStr}
                {isChanged && changedDate && <> • Изменено {changedDate}</>}
              </Typography.Text>

              <Gap size="m" />

              <div className={styles.buttons}>
                <DropdownMenu
                  groups={[
                    {
                      key: 'save-group',
                      items: [
                        {
                          key: 'save',
                          type: 'button',
                          label: 'Сохранить текущую конфигурацию',
                          hideMenu: true,
                          onClick: onClickManualSaveConfiguration,
                          className: styles.menuItem,
                        },
                        {
                          key: 'save-as',
                          type: 'button',
                          label: 'Сохранить конфигурацию как ...',
                          hideMenu: true,
                          onClick: onClickSaveNewConfiguration,
                          className: styles.menuItem,
                        },
                      ],
                    },
                    activeConfigExportGroup(activeConfiguration),
                  ]}
                >
                  {(ref) => (
                    <PickerButton ref={ref} size="xs" className={styles.button}>
                      Сохранить изменения...
                    </PickerButton>
                  )}
                </DropdownMenu>

                <DropdownMenu
                  groups={[
                    {
                      key: 'other',
                      items: [
                        {
                          key: 'rename',
                          type: 'button',
                          label: 'Переименовать',
                          Icon: PencilMIcon,
                          hideMenu: true,
                          onClick: () =>
                            setRenameModalConfig({
                              value: activeConfiguration.name,
                              isOpen: true,
                            }),
                        },
                        {
                          key: 'reset',
                          type: 'button',
                          label: 'Сбросить изменения',
                          hint: 'Конфигурация будет сброшена к последнему состоянию, сохраненному вами',
                          Icon: RepeatOffMIcon,
                          hideMenu: true,
                          onClick: () => setResetModalVisible(true),
                        },
                      ],
                    },
                  ]}
                >
                  {(ref) => (
                    <PickerButton
                      ref={ref}
                      size="xs"
                      className={cn(styles.button, styles.moreButton)}
                    >
                      <IconButton
                        size="xs"
                        view="secondary"
                        icon={DotsHorizontalMIcon}
                      />
                    </PickerButton>
                  )}
                </DropdownMenu>
              </div>
            </div>
          </div>
        </>
      )}

      <Gap size="2xl" />

      <Typography.Text
        view="primary-small"
        color="primary"
        tag="div"
        weight="bold"
        className={styles.graphic}
      >
        Другие конфигурации
      </Typography.Text>

      <Gap size="m" />

      <WorkspaceConfigurationsTabs otherConfigurations={otherConfigurations} />

      <WorkspaceRenameModal
        open={renameModalConfig.isOpen}
        value={renameModalConfig.value}
        onChange={(value) =>
          setRenameModalConfig({ ...renameModalConfig, value })
        }
        onClose={() => setRenameModalConfig({ value: '', isOpen: false })}
        onSubmit={() =>
          renameConfiguration(activeConfiguration.id, renameModalConfig.value)
        }
      />

      <WorkspaceConfigurationModal
        open={resetModalVisible}
        onClose={() => setResetModalVisible(false)}
        title="Сбросить изменения?"
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: () => setResetModalVisible(false),
          },
          {
            children: 'Сбросить',
            view: 'secondary',
            onClick: () => {
              onClickResetConfiguration(activeConfiguration.id);
              setResetModalVisible(false);
            },
          },
        ]}
        className={styles.resetModal}
      >
        Вы действительно хотите сбросить все несохраненные изменения текущей
        конфигурации?
      </WorkspaceConfigurationModal>
    </div>
  );
};
