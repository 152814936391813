import { format, parseISO } from 'date-fns';

import { FatcaState } from '@terminal/core/lib/rest/lkAccountInfo';
import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';

export const getFatcaStateText = (fatca: FatcaState) => {
  switch (fatca.state) {
    case 'Registred': {
      const expirationDate = fatca.expirationDate
        ? format(parseISO(fatca.expirationDate), DEFAULT_VIEW_DATE_FORMAT)
        : format(new Date(), DEFAULT_VIEW_DATE_FORMAT);

      return `Срок действия — до ${expirationDate}`;
    }
    case 'InProgress': {
      const signTime = fatca.signTime
        ? format(parseISO(fatca.signTime), DEFAULT_VIEW_DATE_FORMAT)
        : format(new Date(), DEFAULT_VIEW_DATE_FORMAT);

      return `Принята к рассмотрению ${signTime}. Срок — до 5 рабочих дней.`;
    }
    case 'Declined':
      return `W-8BEN не может быть подписана`;
    case 'NotRegistred':
      return 'Не подписана';
    default:
      return '';
  }
};
