import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getDocumentsHistory } from '../api/getDocumentsHistory';

import { GetDocumentsHistoryParams } from '../api/types/GetDocumentsHistoryParams';

export const useDocumentsHistory = (params: GetDocumentsHistoryParams) => {
  const { page, ...otherParams } = params;

  const addNotification = useNotification();

  const fetchHistory = ({ pageParam = page }: QueryFunctionContext) => {
    return getDocumentsHistory({
      ...otherParams,
      page: pageParam,
    });
  };

  return useInfiniteQuery(['lk', 'documents/history', params], fetchHistory, {
    getNextPageParam: (lastPage, pages) => {
      const pageNum = pages.length;

      if (pageNum < lastPage.pageCount) {
        return pageNum + 1;
      }
    },
    enabled: Boolean(otherParams.treaty),
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при запросе истории',
      });
    },
  });
};
