import noop from 'lodash/noop';
import { useCallback } from 'react';

import { DIMENSIONS_SCHEMAS } from '@terminal/core/constants/analytics';

import { AnalyticsTrack, useWidgetContext } from '../../../shared';
import { AnalyticsTab } from '../model';
import { AnalyticsTabMap } from './const';

const CATEGORY_NAME = 'Analytics';

const enum EVENT_NAME {
  OPEN = 'open',
  CLOSE = 'close',
  CLICK = 'click',
}

const enum EVENT_LABELS {
  WINDOW = 'Window',
  TAB = 'Tab',
}

export const usePortfolioAnalyticsMetrics = () => {
  const {
    analytics: { track = noop as AnalyticsTrack } = {
      track: noop as AnalyticsTrack,
    },
  } = useWidgetContext();

  return {
    trackOpenPortfolioAnalytics: useCallback(() => {
      track(CATEGORY_NAME, EVENT_NAME.OPEN, EVENT_LABELS.WINDOW, null, null);
    }, [track]),
    trackClickPortfolioAnalyticsTab: useCallback(
      (tab: AnalyticsTab) => {
        track(CATEGORY_NAME, EVENT_NAME.CLICK, EVENT_LABELS.TAB, null, null, [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              7: AnalyticsTabMap[tab],
            },
          },
        ]);
      },
      [track]
    ),
    trackClosePortfolioAnalytics: useCallback(() => {
      track(CATEGORY_NAME, EVENT_NAME.CLOSE, EVENT_LABELS.WINDOW, null, null);
    }, [track]),
  };
};
