import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import { useMemo } from 'react';

import { useFilteredSubAccounts } from '@terminal/alfadirect/hooks';
import {
  AccountOption,
  SubAccountOption,
} from '@terminal/common/model/types/account';
import { formatFullNameWithInitials } from '@terminal/common/shared/lib/formatFullNameWithInitials';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { sortObjKeys } from '@terminal/core/lib/sortObjKeys';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';

import { useWidgetContext } from '../../../shared';
import { isIisAccount } from '../lib';

import { useWidgetRequirement } from '../../../shared/hooks/useWidgetRequirement';

type AccountsTree = Record<string, SubAccountRazdelItem[]>;

export const useAccountFilter = ({
  isShouldBeVisible,
  isOrderBookVersion,
  selectedSubAccounts,
  searchText,
  onSave,
  subAccounts,
}) => {
  const { useTreaties } = useWidgetContext();
  const { data: treaties } = useTreaties();
  const filteredSubAccounts = useFilteredSubAccounts();

  const isEverySubAccountSelected =
    selectedSubAccounts.length === subAccounts.length;

  const onSelect = (option: AccountOption | SubAccountOption) => {
    const { value, isAccount } = option;
    let selected = selectedSubAccounts.slice();

    // Логика версии для стакана. Можно выбрать только субсчет и только 1
    if (isOrderBookVersion) {
      if (isAccount) {
        return;
      }

      onSave([value]);

      return;
    }

    // Если выбрали счет
    if (isAccount) {
      const subAccountsOfAccount = accountsTree[value].map(
        (subAccount) => subAccount.codeSubAccount
      );
      const subAccountsInSelected = selected.filter((subAccount) =>
        subAccountsOfAccount.includes(subAccount)
      );
      const isEverySubAccountSelected =
        subAccountsOfAccount.length === subAccountsInSelected.length;

      // Удаляем счет из выбранных
      selected = selected.filter((item) => item !== value);

      // Если все субсчета этого счета выбраны, удаляем все. Иначе добавляем оставшиеся
      if (isEverySubAccountSelected) {
        selected = difference(selected, subAccountsOfAccount);
      } else {
        selected = uniq([...selected, ...subAccountsOfAccount]);
      }
    }
    // Если выбрали субсчет
    else {
      const isOptionSelected = selected.includes(value);

      if (isOptionSelected) {
        selected = selected.filter((option) => option !== value);
      } else {
        selected.push(value);
      }
    }

    onSave(selected);
  };

  const onSelectAll = () => {
    if (isEverySubAccountSelected) {
      onSave([]);
    } else {
      onSave(subAccounts);
    }
  };

  const marginCall = useWidgetRequirement();

  const hasRequirements = useMemo(
    () =>
      Object.values(marginCall).some(
        ({ immediateRequirements, requirements }) =>
          immediateRequirements || requirements
      ),
    [marginCall]
  );
  const selectedAccRequirement = useMemo(() => {
    if (isOrderBookVersion) {
      return getRequirementBySubAccount(marginCall, selectedSubAccounts[0]);
    }
  }, [marginCall, isOrderBookVersion, selectedSubAccounts]);

  const accountsTree = useMemo(() => {
    if (!isShouldBeVisible) {
      return {};
    }

    return createAccountsTree(filteredSubAccounts);
  }, [isShouldBeVisible, filteredSubAccounts]);

  const isDifferentAccountOwners = useMemo(() => {
    const allClientNames = Object.values(accountsTree).flatMap((subAccounts) =>
      subAccounts.map((subAccount) => subAccount.nameClient)
    );

    return uniq(allClientNames).length > 1;
  }, [accountsTree]);

  const options = useMemo(() => {
    if (!isShouldBeVisible) {
      return [];
    }

    return createOptionsForSelect(
      accountsTree,
      selectedSubAccounts,
      searchText,
      marginCall,
      isDifferentAccountOwners,
      treaties
    );
  }, [
    isShouldBeVisible,
    accountsTree,
    selectedSubAccounts,
    searchText,
    marginCall,
    treaties,
    isDifferentAccountOwners,
  ]);

  return {
    options,
    selected: selectedSubAccounts,
    onSelect,
    onSelectAll,
    hasRequirements,
    selectedAccRequirement,
  };
};

function getRequirementBySubAccount(marginCall, code: string) {
  const marginCallForAcc = marginCall[code];

  if (marginCallForAcc) {
    if (marginCallForAcc.immediateRequirements !== 0) {
      return 'immediateRequirements';
    } else if (marginCallForAcc.requirements !== 0) {
      return 'requirements';
    }
  }
}

function createAccountsTree(accountsMap: AccountsTree) {
  let accountsTree: AccountsTree = {};

  // У каждого счета оставляем только уникальные субсчета и сортируем их
  Object.keys(accountsMap).forEach((accountKey: string) => {
    const currentSubAccounts: SubAccountRazdelItem[] = accountsMap[accountKey];

    accountsTree[accountKey] = uniqWith(
      currentSubAccounts,
      (a: SubAccountRazdelItem, b: SubAccountRazdelItem) =>
        a.idSubAccount === b.idSubAccount
    ).sort((a: SubAccountRazdelItem, b: SubAccountRazdelItem) =>
      a.codeSubAccount.localeCompare(b.codeSubAccount)
    );
  });

  // Сортируем счета в дереве
  accountsTree = sortObjKeys(accountsTree);

  return accountsTree;
}

function createOptionsForSelect(
  accountsTree: AccountsTree,
  selectedSubAccounts,
  searchText,
  marginCall,
  withClientName: boolean,
  treaties?: Treaty[]
) {
  const accountsOptions: AccountOption[] = [];

  Object.keys(accountsTree).forEach((accountKey: string) => {
    const currentSubAccounts: SubAccountRazdelItem[] = accountsTree[accountKey];
    const subAccountsOfAccount = accountsTree[accountKey].map(
      (subAccount) => subAccount.codeSubAccount
    );
    const subAccountsInSelected = selectedSubAccounts.filter((subAccount) =>
      subAccountsOfAccount.includes(subAccount)
    );
    const isEverySubAccountSelected =
      subAccountsOfAccount.length === subAccountsInSelected.length;
    const isAnySubAccountSearchedFor = subAccountsOfAccount.find((subAccount) =>
      subAccount.includes(searchText)
    );
    const isAccountSearchedFor = !searchText || accountKey.includes(searchText);

    if (isAccountSearchedFor || isAnySubAccountSearchedFor) {
      const subAccountsOptions: SubAccountOption[] = [];

      currentSubAccounts.forEach((subAccount: SubAccountRazdelItem) => {
        const { codeSubAccount } = subAccount;
        const isSelected = selectedSubAccounts.includes(codeSubAccount);
        const isSubAccountSearchedFor =
          !searchText || codeSubAccount.includes(searchText);

        const requirement = getRequirementBySubAccount(
          marginCall,
          codeSubAccount
        );

        if (isSubAccountSearchedFor) {
          subAccountsOptions.push({
            isAccount: false,
            key: codeSubAccount,
            value: codeSubAccount,
            requirement,
            isSelected,
          });
        }
      });

      const accountTreaty = treaties?.find(
        ({ treaty }) => treaty.toString() === accountKey.split('-')[0]
      );

      accountsOptions.push({
        isAccount: true,
        key: accountKey,
        value: accountKey,
        isEverySubAccountSelected,
        subAccounts: subAccountsOptions,
        ownerName: withClientName
          ? formatFullNameWithInitials(currentSubAccounts[0].nameClient)
          : '',
        isIis: isIisAccount(accountTreaty),
      });
    }
  });

  return accountsOptions;
}

export const isOwnSubAccount = (
  selectedSubAccounts: string[],
  subAccounts: string[]
) => selectedSubAccounts.every((acc) => subAccounts.includes(acc));
