import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetRiskDeclarationTextDTO } from './types/GetRiskDeclarationTextDTO';
import { GetRiskDeclarationTextParams } from './types/GetRiskDeclarationTextParams';

export async function getRiskDeclarationText({
  treaty,
}: GetRiskDeclarationTextParams): Promise<GetRiskDeclarationTextDTO> {
  const result = await fetchLkResult<GetRiskDeclarationTextDTO & LKResult>(
    `/api/documents/risk-declaration-text/${treaty}`
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
