import { useEffect, useMemo, useState } from 'react';

import { OrderDirection, OrderStatus, OrderType } from '../lib/client/entities';
import { makeOrderKey } from '../lib/domain/makeOrderKey';
import { isPosActualQuantity } from '../lib/isPosActualQuantity';
import { useObjectByIdMap } from './domain/useObjectByIdMap';
import { useOrdersMap } from './useOrdersMap';
import { usePrevious } from './usePrevious';

import { useStore } from '../store';

import { PositionItem } from '../types/position';

export const usePositionCancelable = (position?: PositionItem): boolean => {
  const orders = useStore((store) => store.orders);
  const ordersMap = useOrdersMap(orders);
  const objectByIdMap = useObjectByIdMap();

  const [availableTorgPos, setAvailableTorgPos] = useState(position?.torgPos);

  // Существует промежуток между выполнением заявки по инструменту и обновлением
  // данных по позиции, что позволяет юзеру жать кнопки закрытия у позиции, которая уже может быть закрыта
  // Для этого нам нужна дополнительная проверка - берем выполненные и частично-выполненные ордера по инструменту,
  // и суммируем их кол-во лотов, которое не должно равняться torgPos в позиции
  // Работает с ненулевой позицией
  const isActualQuantity = useMemo(
    () =>
      position
        ? isPosActualQuantity(
            orders,
            position.position.idObject,
            position.torgPos
          )
        : false,
    [orders, position]
  );

  useEffect(() => {
    setAvailableTorgPos(position?.torgPos);
  }, [position?.torgPos]);

  /** заявки на покупку */
  const orderLong = useMemo(() => {
    return position?.position
      ? ordersMap[makeOrderKey(position.position, OrderDirection.Buy)] ?? []
      : [];
  }, [ordersMap, position?.position]);
  /** заявки на продажу */
  const orderShort = useMemo(() => {
    return position?.position
      ? ordersMap[makeOrderKey(position.position, OrderDirection.Sell)] ?? []
      : [];
  }, [ordersMap, position?.position]);

  //Отсеиваем позицию рубля и сумма лонга и шорта которых равно 0 (не можем ни продать ни купить, позиция считается уже закрытой)
  //Также смотрим, есть ли уже заявка на закрытие позиции
  const cancelOrder = useMemo(() => {
    if (!position) {
      return;
    }

    return (position.torgPos > 0 ? orderShort : orderLong).find(
      (order) =>
        order.idOrderType === OrderType.MKT &&
        order.rest === Math.abs(position.torgPos) &&
        ![
          OrderStatus.Filled,
          OrderStatus.Cancelled,
          OrderStatus.Rejected,
        ].includes(order.idOrderStatus)
    );
  }, [orderLong, orderShort, position]);

  const prevCancelOrder = usePrevious(cancelOrder);

  // После выполнения заявки есть ещё один гэп пока не придёт обновления по позициям.
  // В этот момент смотрим что заявка на закрытие была, но стала undefined,
  // проверяем что эта заявка перешла в статус "Исполнено" и локально обнуляем позицию
  useEffect(() => {
    if (
      typeof cancelOrder === 'undefined' &&
      prevCancelOrder &&
      position?.torgPos
    ) {
      const isCancelOrderSuccess = (
        position.torgPos > 0 ? orderShort : orderLong
      ).some(
        (order) =>
          order.clientOrderNum === prevCancelOrder.clientOrderNum &&
          order.idOrderStatus === OrderStatus.Filled
      );

      if (isCancelOrderSuccess) {
        setAvailableTorgPos(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelOrder]);

  if (!position) {
    return false;
  }

  return (
    availableTorgPos !== 0 &&
    isActualQuantity &&
    objectByIdMap.get(position.position.idObject)?.idObject !== 174368 &&
    !cancelOrder
  );
};
