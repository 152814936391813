import React, { FC, useCallback } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ChartPieLineMIcon } from '@alfalab/icons-glyph/ChartPieLineMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { MINORITY } from '@terminal/core/constants/ui';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { plural } from '@terminal/core/lib/plural';
import { useStore } from '@terminal/core/store';
import { orderDimensionSelector } from '@terminal/core/store/selectors/settings/orderDimensionSelector';
import { PositionItem } from '@terminal/core/types/position';
import { OrderDimensionEnum } from '@terminal/core/types/ui';
import { PortfolioAnalyticsWarningContainer } from '@terminal/widgets/shared';

import { CompositionType } from '../../../../entities/PortfolioComposition';
import { SimpleTable } from '../../../SimpleTable';
import { CustomAssetId } from '../../lib/const';

import { useAssetDetailsTable } from '../../hooks/useAssetDetailsTable';

import { Asset } from '../../model/types/PortfolioComposition';
import { AssetDetailsTableRow } from '../../model/types/PortfolioCompositionTable';

import styles from './AssetDetails.module.css';

enum Column {
  Name = 'name',
  Price = 'price',
  Percent = 'percent',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.Price,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Percent,
    justifyContent: 'flex-end',
  },
];

interface Props {
  activeTab: CompositionType;
  assetId: string;
  name: string;
  positions: PositionItem[];
  industries: Map<string, Asset>;
}

export const AssetDetails: FC<Props> = ({
  activeTab,
  assetId,
  name,
  positions,
  industries,
}) => {
  const orderDimension = useStore(orderDimensionSelector);
  const isLotCount = orderDimension === OrderDimensionEnum.LOT;
  const data = useAssetDetailsTable(activeTab, assetId, positions, industries);

  const isCurrency = assetId === ObjectGroup.Currency.toString();

  const cellRender = useCallback(
    (columnKey, _, row: AssetDetailsTableRow) => {
      switch (columnKey) {
        case Column.Name:
          const quantity = isLotCount
            ? (row.quantity ?? 0) / (row.lot || 1)
            : row.quantity;

          return (
            <>
              <SymbolCell
                idObject={row.idObject}
                symbolObject={row.symbolObject}
              />
              &nbsp;
              {!isCurrency && quantity ? (
                <Typography.Text view="secondary-large" color="secondary">
                  {quantity}&nbsp;
                  {isLotCount
                    ? plural(['лот', 'лота', 'лотов'], quantity)
                    : 'шт'}
                </Typography.Text>
              ) : null}
            </>
          );
        case Column.Price:
          return (
            <Typography.Text view="secondary-large" color="secondary">
              {
                formatAmount({
                  value: row.price * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'default',
                }).formattedWithCurrency
              }
            </Typography.Text>
          );
        case Column.Percent:
          return (
            <Typography.Text view="secondary-large">
              {String(row.percent).replace('.', ',')}&nbsp;%
            </Typography.Text>
          );
      }
    },
    [isCurrency, isLotCount]
  );

  return (
    <div>
      <Typography.Text
        view="primary-medium"
        weight="bold"
        className={styles.title}
      >
        {name}
      </Typography.Text>

      <Gap size="2xs" />
      <Typography.Text view="secondary-large" tag="div" color="secondary">
        {assetId === CustomAssetId.OTHER
          ? 'В этом разделе отображены денежные средства на срочном рынке и вариационная маржа'
          : 'В этом разделе за сто процентов приняты все активы данного типа'}
      </Typography.Text>
      <Gap size="m" />
      <SimpleTable<AssetDetailsTableRow, Column>
        columns={COLUMN_SETTINGS}
        data={data}
        cellRender={cellRender}
        className={styles.table}
      />

      {!data.length ? (
        <PortfolioAnalyticsWarningContainer
          Icon={ChartPieLineMIcon}
          title="Тут ничего нет"
          description="На вашем счёте нет активов"
        />
      ) : null}
    </div>
  );
};
