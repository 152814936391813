import { ObjectGroup } from '../lib/client/entities';

export const NAME_BY_OBJECT_GROUP = new Map<number, string>([
  [ObjectGroup.Stocks, 'Акции'],
  [ObjectGroup.Bonds, 'Облигации'],
  [ObjectGroup.Currency, 'Валюта'],
  [ObjectGroup.EquityParticipations, 'Фонды'],
  [ObjectGroup.Metals, 'Металлы'],
  [ObjectGroup.Futures, 'Прочее'],
]);
