import { ReactNode } from 'react';
import { StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  createWithEqualityFn,
  UseBoundStoreWithEqualityFn,
} from 'zustand/traditional';

import { TransferStatusEnum } from '../types/transferStatus';
import { TransferStatusStore } from '../types/transferStatusStore';

export const useTransferStatusStore: UseBoundStoreWithEqualityFn<
  StoreApi<TransferStatusStore>
> = createWithEqualityFn<TransferStatusStore>()(
  (set) => ({
    status: TransferStatusEnum.None,
    description: null,
    setStatus: (status: TransferStatusEnum) => set({ status }),
    setDescription: (description: ReactNode) => set({ description }),
    reset: () =>
      set({
        status: TransferStatusEnum.None,
        description: null,
      }),
  }),
  shallow
);
