import React, { FC, useEffect } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { usePrevious } from '@terminal/core/hooks';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection, Spinner } from '../../../../shared';
import { mapOperationChapterKeyToName } from '../../lib/mapOperationChapterKeyToName';
import { OperationChapterType } from '../../model/FinResult';
import { InputOutputTableRow } from '../../model/FinResultTable';
import { DateFilters } from '../DateFilters';

interface Props {
  chapter: OperationChapterType;
  byCurrency: InputOutputTableRow['byCurrency'];
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  isEveryThingLoaded: boolean;
}

export const OperationChapterDetails: FC<Props> = ({
  chapter,
  byCurrency,
  dateFilter,
  setDateFilter,
  isEveryThingLoaded,
}) => {
  const { trackOpenPortfolioReportDetails } = usePortfolioReportsMetrics();

  const prevChapter = usePrevious(chapter);

  useEffect(() => {
    if (!prevChapter && chapter && prevChapter !== chapter) {
      trackOpenPortfolioReportDetails(mapOperationChapterKeyToName(chapter));
    }
  }, [prevChapter, chapter, trackOpenPortfolioReportDetails]);

  return (
    <div>
      <DateFilters value={dateFilter} onChange={setDateFilter} />

      <Gap size="l" />

      <PortfolioSection title={mapOperationChapterKeyToName(chapter)}>
        <Typography.Text view="secondary-large" color="secondary">
          {`Сколько денег и бумаг вы ${
            chapter === 'inputs' ? 'внесли на счёт' : 'вывели со счёта'
          } в рублях или валюте`}
        </Typography.Text>

        <Gap size="xs" />

        {isEveryThingLoaded ? (
          <>
            <CurrencyDetails
              label="В рублях"
              sum={byCurrency.rub}
              currency="RUB"
            />
            {byCurrency.usd !== 0 ? (
              <CurrencyDetails
                label="В долларах"
                sum={byCurrency.usd}
                currency="USD"
              />
            ) : null}
            {byCurrency.eur !== 0 ? (
              <CurrencyDetails
                label="В евро"
                sum={byCurrency.eur}
                currency="EUR"
              />
            ) : null}
            {byCurrency.cny !== 0 ? (
              <CurrencyDetails
                label="В юанях"
                sum={byCurrency.cny}
                currency="CNY"
              />
            ) : null}
            {byCurrency.other !== 0 ? (
              <CurrencyDetails
                label="Прочие валюты"
                sum={byCurrency.other}
                currency="CNY"
              />
            ) : null}
            {byCurrency.papers !== 0 ? (
              <CurrencyDetails
                label="Ценные бумаги"
                sum={byCurrency.papers}
                currency="RUB"
              />
            ) : null}
          </>
        ) : (
          <Spinner />
        )}
      </PortfolioSection>
    </div>
  );
};

function CurrencyDetails({ label, sum, currency }) {
  return (
    <div>
      <Typography.Text view="secondary-small" color="secondary">
        {label}
      </Typography.Text>
      <Gap size="3xs" />
      <Typography.Text view="secondary-large">
        {
          formatAmount({
            value: sum * MINORITY,
            currency,
            minority: MINORITY,
            view: 'withZeroMinorPart',
          }).formatted
        }
        &nbsp;
        <Typography.Text tag="span" view="secondary-large" color="secondary">
          {getSymbolByCurrency(currency)}
        </Typography.Text>
      </Typography.Text>
      <Gap size="xs" />
    </div>
  );
}
