import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { getAdvisorDocuments } from '../api';

export const useAdvisorGetDocuments = (enabled: boolean, url: string) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.AddAdvisorDocuments, url],
    () => getAdvisorDocuments(url),
    {
      enabled,
      onError: (error: any) =>
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
