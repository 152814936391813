import { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { IssuerFinancials } from '@terminal/core/types/issuerFinancials';

import { InstrumentsSimpleTable } from '../../ui';
import { toFixedWithCeil } from './lib/toFixedWithCeil';
import { TitleWithHint } from './TitleWithHint';

import { useEventCompanyIndicators } from '../../hooks/useEventCompanyIndicators';

import { InstrumentTableHeaderItem } from '../../model/types';

import classes from './CompanyIndicatorsTable.module.css';

export const CompanyIndicatorsTable: FC<{ dataSource: IssuerFinancials }> = ({
  dataSource,
}) => {
  const companyIndicators = useEventCompanyIndicators(dataSource);

  const columns: InstrumentTableHeaderItem[] = [
    {
      title: 'Показатель',
      width: 50,
    },
    {
      title: 'Текущее',
      width: 50,
    },
    // TODO: раскомментить, когда на беке доработают financialIndustryAvg
    // dataSource.financialIndustryAvg && {
    //   title: (
    //     <TitleWithHint
    //       title="Среднее"
    //       gap="var(--gap-xs)"
    //       contentClassName={classes.tooltipContent}
    //       hintContent="Среднее значение показателя компании за последние 2 года"
    //       iconSize={10}
    //     />
    //   ),
    //   width: 33,
    // },
  ].filter(Boolean);

  const renderIndicatorValue = (
    value: number | string,
    withPercent = false
  ) => (
    <>
      {toFixedWithCeil(value)}
      {withPercent && (
        <Typography.Text view="secondary-large" color="secondary">
          {' %'}
        </Typography.Text>
      )}
    </>
  );

  const companyIndicatorsToRender = companyIndicators.map(
    ({
      title,
      current,
      average,
      tooltip,
      withPercent,
      renderCurrent,
      renderAverage,
    }) => [
      tooltip ? (
        <TitleWithHint
          title={title}
          gap="var(--gap-2xs)"
          contentClassName={classes.tooltipContent}
          hintContent={
            <>
              {tooltip.title && <b>{tooltip.title}</b>}
              {tooltip.content}
            </>
          }
        />
      ) : (
        title
      ),
      current
        ? renderCurrent?.(current) ?? renderIndicatorValue(current, withPercent)
        : '',
      average
        ? renderAverage?.(average) ?? renderIndicatorValue(average, withPercent)
        : '',
    ]
  );

  return companyIndicatorsToRender.length ? (
    <>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.companyIndicatorsTitle}
      >
        Показатели компании
      </Typography.TitleMobile>
      <InstrumentsSimpleTable
        columns={columns}
        data={companyIndicatorsToRender}
      />
    </>
  ) : null;
};
