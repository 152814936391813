import { fetchThroughProxy } from '../../url';

export function fetchRetry(
  tries: number,
  url: string,
  fetchOptions: Record<string, any> = {}
) {
  function onError(err) {
    const triesLeft = tries - 1;

    if (!triesLeft) {
      throw err;
    }

    return fetchRetry(triesLeft, url, fetchOptions);
  }

  return fetchThroughProxy(url, fetchOptions).catch(onError);
}
