import { useQuery } from 'react-query';

import { getIssuerFinancials } from '../lib/rest/investApi';
import useNotification from './useNotification';

import { NotificationType } from '../types/ui';

export const useIssuerFinancials = (
  isin: string = '',
  queryParams?: Parameters<typeof getIssuerFinancials>[1]
) => {
  const addNotification = useNotification();

  return useQuery({
    queryKey: ['getIssuerFinancials', isin],
    queryFn: () => getIssuerFinancials(isin, queryParams),
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса информации по финансовым показателям эмитента',
        text: error?.message || '',
      });
    },
    enabled: Boolean(isin),
    /* По факту данные обновляются раз в неделю */
    staleTime: 1000 * 60 * 60,
  });
};
