import { ObjectGroup } from '@terminal/core/lib/client/entities';

export const ASSETS_COLORS_MAPPED = new Map<number, string>([
  [ObjectGroup.Stocks, 'var(--color-static-graphic-blue-maya)'],
  [ObjectGroup.Bonds, 'var(--color-static-graphic-olivine)'],
  [ObjectGroup.Currency, 'var(--color-static-graphic-chick)'],
  [ObjectGroup.EquityParticipations, 'var(--color-dark-graphic-attention)'],
  [ObjectGroup.Futures, 'var(--color-static-graphic-jaffa)'],
  [5, 'var(--color-static-graphic-raspberry)'],
  [6, 'var(--color-static-graphic-fun-blue)'],
  [7, 'var(--color-static-graphic-blue-chill)'],
  [9, 'var(--color-static-graphic-french-lilac)'],
  [10, 'var(--color-static-graphic-valencia)'],
  [11, 'var(--color-static-graphic-sundust)'],
  [12, 'var(--color-static-graphic-crete)'],
  [13, 'var(--color-static-graphic-salomie)'],
  [13, 'var(--color-static-graphic-blue-moody)'],
]);

export const ASSETS_COLORS = Array.from(ASSETS_COLORS_MAPPED.values());

export const enum CustomAssetId {
  OTHER = 'OTHER',
  CURRENCY = 'CURRENCY',
}
