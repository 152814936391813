import sumBy from 'lodash/sumBy';
import { useCallback, useMemo } from 'react';

import { OrderDirection, OrderType } from '@terminal/core/lib/client/entities';
import { getOrderKeyPrice } from '@terminal/core/lib/formulas';
import { OrderItem } from '@terminal/core/types/order';
import { OrderBookLine } from '@terminal/core/types/orderBook';

// Отображаем заявки
// с этим типом https://jira.moscow.alfaintra.net/browse/ADIRWEB-1488
// + MKT https://jira.moscow.alfaintra.net/browse/ADIRWEB-3734
const ordersTypeFilter = [
  OrderType.MIT,
  OrderType.MKT,
  OrderType.LMT,
  OrderType.STL,
  OrderType.STP,
  OrderType.BRS,
  OrderType.BSL,
  OrderType.TRS,
  OrderType.TSL,
  OrderType.TBRS,
  OrderType.TRL,
];

export const useOrderBookOrders = (
  activeOrders: OrderItem[],
  lines: OrderBookLine[],
  upSpreadIndex: number,
  downSpreadIndex: number,
  centerSpreadIndex: number
) => {
  const upSpreadPrice = useMemo(
    () => lines[upSpreadIndex]?.Price,
    [lines, upSpreadIndex]
  );

  const downSpreadPrice = useMemo(
    () => lines[downSpreadIndex]?.Price,
    [lines, downSpreadIndex]
  );

  const centerSpreadPrice = useMemo(
    () => lines[centerSpreadIndex]?.Price,
    [lines, centerSpreadIndex]
  );

  const filteredActiveOrders = useMemo(
    () =>
      activeOrders.filter(({ idOrderType }) =>
        ordersTypeFilter.includes(idOrderType)
      ),
    [activeOrders]
  );

  const upperOrders = useMemo(() => {
    if (centerSpreadPrice) {
      return filteredActiveOrders.filter(
        (order) => order.price > centerSpreadPrice
      );
    } else {
      return filteredActiveOrders;
    }
  }, [filteredActiveOrders, centerSpreadPrice]);

  const lowerOrders = useMemo(() => {
    if (centerSpreadPrice) {
      return filteredActiveOrders.filter(
        (order) =>
          order.price <= centerSpreadPrice ||
          order.idOrderType === OrderType.MKT ||
          order.idOrderType === OrderType.MIT
      );
    }
  }, [filteredActiveOrders, centerSpreadPrice]);

  const getPriceMap = useCallback(
    (orders: OrderItem[]) =>
      orders.reduce((acc, order) => {
        //Если заявка парная
        if ([OrderType.BRS, OrderType.BSL].includes(order.idOrderType)) {
          const stopPriceKey = String(order.stopPrice);
          const limitPriceKey = String(order.limitPrice);

          return {
            ...acc,
            [stopPriceKey]: [...(acc[stopPriceKey] ?? []), order],
            [limitPriceKey]: [...(acc[limitPriceKey] ?? []), order],
          };
        } else {
          //Определяем цену для отображения
          const priceType = getOrderKeyPrice(order);
          const priceKey = String(order[priceType]);

          return {
            ...acc,
            [priceKey]: [...(acc[priceKey] ?? []), order],
          };
        }
      }, {} as Record<string, OrderItem[]>),
    []
  );

  const buyOrdersMap = useMemo(
    () =>
      getPriceMap(
        filteredActiveOrders.filter((o) => o.buySell === OrderDirection.Buy)
      ),
    [filteredActiveOrders, getPriceMap]
  );

  const sellOrdersMap = useMemo(
    () =>
      getPriceMap(
        filteredActiveOrders.filter((o) => o.buySell === OrderDirection.Sell)
      ),
    [filteredActiveOrders, getPriceMap]
  );

  const totalBuyQty = useMemo(() => sumBy(lines, 'BuyQty'), [lines]);
  const totalSellQty = useMemo(() => sumBy(lines, 'SellQty'), [lines]);

  return {
    upperOrders,
    upSpreadPrice,
    downSpreadPrice,
    lowerOrders,
    buyOrdersMap,
    sellOrdersMap,
    totalBuyQty,
    totalSellQty,
  };
};
