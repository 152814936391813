import React, { useEffect, useState } from 'react';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { SelectTreaty } from '@terminal/lk/legacy/components/SelectTreaty';
import { Treaty } from '@terminal/lk-core';
import {
  FinancialDocumentsSubpage,
  History,
} from '@terminal/lk-desktop-common';

import { Order, TaxInfo } from '../../../../widgets/FinancialDocuments';

import styles from './FinancialDocuments.module.css';

export const FinancialDocuments = () => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [subpage, setSubpage] = useState<FinancialDocumentsSubpage>(
    FinancialDocumentsSubpage.TaxInfo
  );

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
    setSubpage(selectedId as FinancialDocumentsSubpage);

  useEffect(() => {
    switch (subpage) {
      case FinancialDocumentsSubpage.TaxInfo:
        trackMainMenu(LOCATIONS.LK_TAXES_INFO);
        break;
      case FinancialDocumentsSubpage.Order:
        trackMainMenu(LOCATIONS.LK_TAXES_DOCS);
        break;
      case FinancialDocumentsSubpage.History:
        trackMainMenu(LOCATIONS.LK_TAXES_HISTORY);
    }
  }, [subpage]);

  return (
    <div className={styles.root}>
      <div className={styles.treaty}>
        <SelectTreaty
          zeros={false}
          hint="Все настройки будут применены к выбранному счёту"
          onSelect={(treaty) => setTreaty(treaty)}
          treatyFilter={(treaty) => !treaty.isMoneybox}
          label="Генеральное соглашение"
        />
      </div>
      <TabsDesktop
        selectedId={subpage}
        onChange={handleChange}
        scrollable={true}
        size="s"
      >
        <Tab
          title="Налоговая информация"
          id={FinancialDocumentsSubpage.TaxInfo}
          className={styles.tab}
        >
          <TaxInfo treaty={treaty} />
        </Tab>
        <Tab
          title="Заказ документов"
          id={FinancialDocumentsSubpage.Order}
          className={styles.tab}
        >
          <Order treaty={treaty} onSubpageChange={setSubpage} />
        </Tab>
        <Tab
          title="История"
          id={FinancialDocumentsSubpage.History}
          className={styles.tab}
        >
          <History treaty={treaty} />
        </Tab>
      </TabsDesktop>
    </div>
  );
};
