import { useEffect } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import { WorkspaceConfigurationAdd } from '../WorkspaceConfigurationAdd';
import { WorkspaceConfigurationCloud } from '../WorkspaceConfigurationCloud';
import { WorkspaceConfigurationExportDoc } from '../WorkspaceConfigurationExportDoc';
import { WorkspaceConfigurationList } from '../WorkspaceConfigurationList';

import { useWorkspaceConfigurationStore } from '../../model/store/useWorkspaceConfigurationStore';

import { WorkspaceConfigurationScreen } from '../../model/types';

import styles from './WorkspaceConfiguration.module.css';

export const WorkspaceConfiguration = () => {
  const [screen, setScreen, reset] = useWorkspaceConfigurationStore((state) => [
    state.screen,
    state.setScreen,
    state.reset,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <>
      <WorkspaceConfigurationList />
      <Portal id="common-settings-modals">
        <FunctionalModalSidebar
          open={screen === WorkspaceConfigurationScreen.Add}
          onClose={() => setScreen(WorkspaceConfigurationScreen.List)}
          backLabel="Конфигурации"
        >
          <WorkspaceConfigurationAdd />
        </FunctionalModalSidebar>

        <FunctionalModalSidebar
          open={screen === WorkspaceConfigurationScreen.Export}
          onClose={() => setScreen(WorkspaceConfigurationScreen.List)}
          backLabel="Конфигурации"
        >
          <WorkspaceConfigurationExportDoc />
        </FunctionalModalSidebar>

        <FunctionalModalSidebar
          open={screen === WorkspaceConfigurationScreen.Cloud}
          onClose={() => setScreen(WorkspaceConfigurationScreen.List)}
          backLabel="Конфигурации"
          contentClassname={styles.cloudContent}
          innerClassname={styles.cloudInner}
        >
          <WorkspaceConfigurationCloud />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
