import { useQuery } from 'react-query';

import { getTestingList } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../shared';

export const useTestingList = (accountId?: number) => {
  const { addNotification } = useWidgetContext();

  return useQuery(
    ['testingList', { accountId }],
    async () => getTestingList({ treaty: accountId || 0 }),
    {
      enabled: Boolean(accountId),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка тестов',
          text: error?.message || '',
        });
      },
    }
  );
};
