import { FC, ReactNode, SVGProps } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './StatusPanel.module.css';

type StatusPanelProps = {
  title: string;
  description: ReactNode;
  buttonLabel: string;
  onClick: () => void;
  ButtonIcon?: FC<SVGProps<SVGSVGElement>>;
  Icon: FC<SVGProps<SVGSVGElement>>;
  iconColor?: string;
};

export const StatusPanel = (props: StatusPanelProps) => {
  const {
    description,
    title,
    buttonLabel,
    onClick,
    ButtonIcon,
    Icon,
    iconColor,
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.iconWrapper}>
        <SuperEllipse className={styles.iconBackground}>
          <Icon
            width={32}
            height={32}
            fill={iconColor ?? 'var(--color-dark-graphic-secondary-inverted)'}
            className={styles.icon}
          />
        </SuperEllipse>
      </div>
      <Typography.Title view="xsmall" tag="h3" className={styles.title}>
        {title}
      </Typography.Title>
      <Typography.Text view="secondary-large" className={styles.description}>
        {description}
      </Typography.Text>
      <ButtonDesktop
        size="xxs"
        onClick={onClick}
        leftAddons={
          ButtonIcon && (
            <ButtonIcon
              width={16}
              height={16}
              fill="var(--color-dark-graphic-secondary-inverted)"
            />
          )
        }
      >
        {buttonLabel}
      </ButtonDesktop>
    </div>
  );
};
