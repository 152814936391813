import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';

import { useSingleFORTSMarket } from '@terminal/core/hooks/useSingleFORTSMarket';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { roundPrice } from '@terminal/core/lib/format';
import { PositionItem } from '@terminal/core/types/position';

import { CompositionType } from '../../../entities/PortfolioComposition';
import { ASSETS_COLORS_MAPPED } from '../lib/const';
import { getDerivativesMarketCurrency } from '../lib/getDerivativesMarketCurrency';
import { useFilteredAssets } from './useFilteredAssets';
import { useGroupedAssets } from './useGroupedAssets';

import { Asset } from '../model/types/PortfolioComposition';
import { AssetDetailsTableRow } from '../model/types/PortfolioCompositionTable';

export const useAssetDetailsTable = (
  activeTab: CompositionType,
  assetId: string,
  positions: PositionItem[],
  industries: Map<string, Asset>
): AssetDetailsTableRow[] => {
  const isSingleFORTSMarketAvailable = useSingleFORTSMarket();
  const getFilteredAssets = useFilteredAssets(positions, assetId);
  const getGroupedAssets = useGroupedAssets(
    positions,
    isSingleFORTSMarketAvailable
  );

  return useMemo(() => {
    let items: Asset[] = [];

    switch (assetId) {
      case ObjectGroup.Futures.toString(): {
        const derivativesCurrency = getDerivativesMarketCurrency(positions);

        if (!derivativesCurrency) {
          break;
        }

        items = [
          ...derivativesCurrency.currencyPositions,
          ...getGroupedAssets(assetId),
        ];

        break;
      }

      case ObjectGroup.Stocks.toString():
      case ObjectGroup.Receipts.toString():
      case ObjectGroup.Bonds.toString():
      case ObjectGroup.Currency.toString():
      case ObjectGroup.EquityParticipations.toString(): {
        items = getFilteredAssets();
        break;
      }

      default: {
        if (assetId) {
          let filteredPositions: PositionItem[];

          if (activeTab === CompositionType.CURRENCIES) {
            filteredPositions = positions.filter(
              (pos) =>
                assetId === pos.object?.idObjectFaceUnit?.toString() ||
                assetId === pos.object?.idObject?.toString()
            );
          } else if (activeTab === CompositionType.INDUSTRIES) {
            const isins = industries.get(assetId)?.isins ?? [];

            filteredPositions = positions.filter((position) =>
              isins.includes(position.object?.isin ?? '')
            );
          } else {
            filteredPositions = [];
          }

          items = filteredPositions.map((position, index) => {
            return {
              id: position.object?.idObject ?? index,
              assetId: position.object?.idObject?.toString() || '',
              name: position.object?.symbolObject ?? '',
              idObject: position.object?.idObject,
              symbolObject: position.object?.symbolObject,
              price: position.torgPosCostRur!,
              quantity: position.backPos,
              lot: position.finInfoExt?.lot || 1,
              percent: 0,
            };
          });
        }
      }
    }

    const sum = sumBy(items, (i) => i.price);

    return items
      .map((asset) => ({
        ...asset,
        key: String(asset.id),
        percent: roundPrice((asset.price * 100) / sum, 0.01),
      }))
      .sort((a, b) => b.price - a.price)
      .map((asset, index) => ({
        ...asset,
        color: ASSETS_COLORS_MAPPED.get(index + 1),
      }));
  }, [
    activeTab,
    positions,
    assetId,
    industries,
    getFilteredAssets,
    getGroupedAssets,
  ]);
};
