import cn from 'classnames';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { BlankLineMIcon } from '@alfalab/icons-glyph/BlankLineMIcon';

import classes from './InstrumentError.module.css';

interface Props {
  type: 'dividends' | 'coupons';
}

export const InstrumentError = ({ type }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <SuperEllipse size={64}>
          <BlankLineMIcon />
        </SuperEllipse>
        <div className={cn([classes.title, classes.wrapper])}>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
          >
            Нет информации
          </Typography.TitleMobile>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
          >
            {type === 'coupons' ? 'по купонам' : 'по дивидендам'}
          </Typography.TitleMobile>
        </div>
        <div className={cn([classes.description, classes.wrapper])}>
          <Typography.Text color="secondary" view="secondary-large">
            По этой бумаге нет {type === 'coupons' ? 'купонов' : 'дивидендов'},
          </Typography.Text>
          <Typography.Text color="secondary" view="secondary-large">
            или мы не знаем о них
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
