import { FC, PropsWithChildren, ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import styles from './PortfolioSection.module.css';

interface Props {
  title: string;
  tooltip?: ReactNode;
  onTooltipOpen?: () => void;
}

export const PortfolioSection: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  tooltip,
  onTooltipOpen,
}) => {
  const tooltipBlock = tooltip ? (
    <TooltipDesktop
      position="top"
      trigger="hover"
      targetClassName={styles.tooltipTarget}
      contentClassName={styles.tooltipContent}
      content={
        <Typography.Text view="secondary-small" className={styles.tertiary}>
          {tooltip}
        </Typography.Text>
      }
      onOpen={onTooltipOpen}
    >
      <InformationCircleLineSIcon
        height={16}
        width={16}
        color="var(--color-light-neutral-700)"
      />
    </TooltipDesktop>
  ) : null;

  return (
    <div>
      <Typography.Text
        view="primary-medium"
        weight="bold"
        className={styles.title}
      >
        {title}
        {tooltipBlock}
      </Typography.Text>

      <Gap size="m" />

      {children}
    </div>
  );
};
