import { ValidateResult } from '../model';

export const validateInn = (inn: string): ValidateResult => {
  const result: ValidateResult = {
    code: 0,
    message: '',
  };

  if (!inn.length) {
    return {
      code: 1,
      message: 'ИНН пуст',
    };
  } else if (/[^0-9]/.test(inn)) {
    return {
      code: 2,
      message: 'ИНН может состоять только из цифр',
    };
  } else if ([12].indexOf(inn.length) === -1) {
    return {
      code: 3,
      message: 'ИНН должен состоять из 12 цифр',
    };
  } else {
    var checkDigit = function (inn: string, coefficients: number[]) {
      let n = 0;

      for (let i in coefficients) {
        n += coefficients[i] * parseInt(inn.charAt(parseInt(i)));
      }

      return Math.ceil((n % 11) % 10);
    };

    switch (inn.length) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);

        if (n10 === parseInt(inn[9])) {
          return result;
        }

        break;
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);

        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          return result;
        }

        break;
    }

    return {
      code: 4,
      message: 'Неправильное контрольное число',
    };
  }
};
