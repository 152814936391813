import { addDays, parse, parseISO, startOfDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { CalendarDesktop } from '@alfalab/core-components/calendar/desktop';
import { parseDateString } from '@alfalab/core-components/calendar-input/utils';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { UniversalDateInputDesktop } from '@alfalab/core-components/universal-date-input/desktop';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import {
  DocumentDateType,
  DocumentTheme,
  ReportTheme,
} from '@terminal/lk-core';

interface OrderPeriodProps {
  documentTheme?: DocumentTheme | ReportTheme;
  periodStart: string;
  periodEnd: string;
  onPeriodStartChange: (date: string) => void;
  onPeriodEndChange: (date: string) => void;
}

export const OrderPeriod = ({
  documentTheme,
  periodStart,
  periodEnd,
  onPeriodStartChange,
  onPeriodEndChange,
}: OrderPeriodProps) => {
  const [periodStartTouched, setPeriodStartTouched] = useState(false);
  const [periodEndTouched, setPeriodEndTouched] = useState(false);

  const minPeriodStart = startOfDay(
    documentTheme?.startDate
      ? parseISO(documentTheme.startDate)
      : parseISO('2001-01-01T00:00:00')
  ).getTime();

  const themeEndDate = addDays(
    documentTheme?.endDate ? parseISO(documentTheme.endDate) : new Date(),
    -1
  ).getTime();

  const maxPeriodStart =
    documentTheme?.dateType === DocumentDateType.Date
      ? themeEndDate
      : Math.min(
          themeEndDate,
          parse(periodEnd, DEFAULT_VIEW_DATE_FORMAT, new Date()).getTime()
        );

  const minPeriodEnd = Math.max(
    documentTheme?.startDate
      ? parseISO(documentTheme.startDate).getTime()
      : minPeriodStart,
    parse(periodStart, DEFAULT_VIEW_DATE_FORMAT, new Date()).getTime()
  );
  const maxPeriodEnd = addDays(
    documentTheme?.endDate ? parseISO(documentTheme.endDate) : new Date(),
    -1
  ).getTime();

  const errorStart = useMemo(() => {
    if (periodStart && periodStartTouched) {
      const date = parseDateString(periodStart);

      if (
        Number.isNaN(date.getTime()) ||
        date.getTime() < minPeriodStart ||
        date.getTime() > maxPeriodStart
      ) {
        return 'Некорректная дата';
      }
    }

    return '';
  }, [periodStart, periodStartTouched, minPeriodStart, maxPeriodStart]);

  const errorEnd = useMemo(() => {
    if (periodEnd && periodEndTouched) {
      const date = parseDateString(periodEnd);

      if (
        Number.isNaN(date.getTime()) ||
        date.getTime() < minPeriodEnd ||
        date.getTime() > maxPeriodEnd
      ) {
        return 'Некорректная дата';
      }
    }

    return '';
  }, [periodEnd, periodEndTouched, minPeriodEnd, maxPeriodEnd]);

  if (
    !documentTheme ||
    (documentTheme.dateType !== DocumentDateType.Date &&
      documentTheme.dateType !== DocumentDateType.Period)
  ) {
    return null;
  }

  return (
    <Row>
      <Col width={6}>
        <UniversalDateInputDesktop
          label={
            documentTheme.dateType === DocumentDateType.Date
              ? 'Дата'
              : 'Начало периода'
          }
          labelView="outer"
          size="s"
          block
          autoCorrection={false}
          value={periodStart}
          minDate={minPeriodStart}
          maxDate={maxPeriodStart}
          onChange={(e, { value }) => onPeriodStartChange(value)}
          onBlur={() => setPeriodStartTouched(true)}
          error={errorStart}
          view="date"
          picker
          Calendar={CalendarDesktop}
          calendarProps={{
            selectorView: 'full',
          }}
        />
      </Col>
      {documentTheme.dateType === DocumentDateType.Period && (
        <Col width={6}>
          <UniversalDateInputDesktop
            label="Конец периода"
            labelView="outer"
            size="s"
            block
            autoCorrection={false}
            value={periodEnd}
            minDate={minPeriodEnd}
            maxDate={maxPeriodEnd}
            onChange={(e, { value }) => onPeriodEndChange(value)}
            onBlur={() => setPeriodEndTouched(true)}
            error={errorEnd}
            view="date"
            picker
            Calendar={CalendarDesktop}
            calendarProps={{
              selectorView: 'full',
            }}
          />
        </Col>
      )}
    </Row>
  );
};
