import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button/modern';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { ActivationTime } from '@terminal/core/constants/orderBook';
import {
  ExecutionType,
  LifeTime,
  OrderType,
} from '@terminal/core/lib/client/entities';
import { SendOrderFn } from '@terminal/core/lib/services/trading/useTradingService';
import { PositionItem } from '@terminal/core/types/position';
import {
  InputSendOrderParams,
  SendOrderParams,
} from '@terminal/core/types/trading';

import { OrderSource, useOrderAnalyticsMetrics } from '../../entities/Order';
import { TextOrder } from '../Orders';

import styles from './ClosePositionModal.module.css';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  position: PositionItem;
  isLotCount: boolean;
  lot?: number;
  onCloseRequest: () => void;
  sendOrder: SendOrderFn;
}

export const ClosePositionModal = ({
  isOpen,
  onCloseRequest,
  isLoading,
  setIsLoading,
  sendOrder,
  isLotCount,
  lot = 1,
  position,
}: Props) => {
  const [isOpenTextOrder, setIsOpenTextOrder] = useState(false);

  const { trackStartOrder, trackConfirmOrder } = useOrderAnalyticsMetrics(
    position.finInfoExt?.idFI ?? position.position.idFIBalance
  );

  const sendOrderParams: (SendOrderParams & InputSendOrderParams) | undefined =
    useMemo(() => {
      if (position) {
        const { finInfoExt, torgPos, position: pos } = position;

        return {
          idFi: finInfoExt?.idFI ?? pos.idFIBalance,
          idSubaccount: pos.idSubAccount,
          idOrderType: OrderType.MKT,
          buy: !(torgPos > 0),
          quantity: Math.abs(torgPos),
          idLifeTime: LifeTime.D30,
          idExecutionType: ExecutionType.ASIS,
          idActivationTime: ActivationTime.NOW,
          inputPrice: 0,
        };
      }
    }, [position]);

  useEffect(() => {
    if (sendOrderParams && isOpen) {
      trackConfirmOrder(OrderSource.CLOSE_POSITION, sendOrderParams);
    }
  }, [isOpen, sendOrderParams, trackConfirmOrder]);

  const handleSendOrder = useCallback(() => {
    if (sendOrderParams) {
      trackStartOrder(OrderSource.CLOSE_POSITION, sendOrderParams);
      sendOrder(sendOrderParams);
    }
  }, [sendOrder, sendOrderParams, trackStartOrder]);

  // Формируем текст который будет показан в плашке
  let descripion = <></>;

  if (position) {
    const orderType = OrderType[OrderType.MKT];
    const quantity = Math.abs(position.torgPos) / (isLotCount ? lot : 1);
    const buySell =
      position.torgPos > 0 ? (
        <ArrowUpCompactXsIcon color="#2fc26e" />
      ) : (
        <ArrowDownCompactXsIcon color="#d91d0b" />
      );

    const ticker = position.object?.symbolObject;
    const text = `${quantity} ${ticker} ${orderType}`;

    descripion = (
      <>
        {buySell} {text}
      </>
    );
  }

  return position && position.torgPos !== 0 && isOpen ? (
    <BaseModal open={isOpen} onClose={() => onCloseRequest()}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography.Text view="primary-medium" weight="bold">
            Подтверждение
          </Typography.Text>
          <CrossMIcon
            className={styles.closeIcon}
            width={18}
            height={18}
            onClick={() => {
              onCloseRequest();
            }}
          />
        </div>
        <div className={styles.info}>
          <Typography.Text view="secondary-large">
            Вы уверены, что хотите закрыть позицию
            <br />
            {descripion} ?
          </Typography.Text>
        </div>
        <div
          className={styles.orderTextButton}
          onClick={() => setIsOpenTextOrder(true)}
        >
          <DocumentLinesMIcon />
          <Typography.Text view="primary-small">
            Текстовое поручение
          </Typography.Text>
          <ChevronRightMIcon />
        </div>
        <TextOrder
          params={sendOrderParams}
          isOpen={isOpenTextOrder}
          onClose={() => setIsOpenTextOrder(false)}
          onConfirm={() => {
            setIsLoading(true);
            handleSendOrder();
          }}
        />
        <div className={styles.buttonToolbar}>
          <Button
            className={cn(styles.customButton, styles.customCancelButton)}
            view="ghost"
            type="submit"
            size="xxs"
            block
            onClick={() => {
              onCloseRequest();
            }}
            leftAddons={<CrossMIcon width={18} height={18} />}
          >
            Отмена
          </Button>
          <Button
            className={styles.customButton}
            loading={isLoading}
            size="xxs"
            view="primary"
            block
            onClick={() => {
              setIsLoading(true);
              handleSendOrder();
              //Закрываем модалку через 30 секунд, если так и не получили ответа от сервера
              setTimeout(() => onCloseRequest(), 30000);
            }}
            leftAddons={<CheckmarkMIcon width={18} height={18} />}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </BaseModal>
  ) : null;
};
