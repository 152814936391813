// ошибки типизации бойлерплейта из библиотеки Альфа метрики
// @ts-nocheck
import log from 'loglevel';

import { ANALYTICS, DIMENSIONS_SCHEMAS } from '../../constants/analytics';
import {
  ANALYTICS_APP_ID,
  ANALYTICS_URL,
  IS_ANALYTICS_ENABLED,
} from '../../env';
import { featureFlags } from '../../featureFlags';

import { UserCredentials } from '../../types/user';

const isAnalyticsEnabled = IS_ANALYTICS_ENABLED;
const applicationId = ANALYTICS_APP_ID;
const analyticsUrl = ANALYTICS_URL;

let onSuccess = () => {};
let onError = () => {};

export const analyticsInstance = new Promise((resolve, reject) => {
  onSuccess = resolve;
  onError = reject;
});

let defaultDimensions = [];

export interface AnalyticsInitParams {
  enabled?: boolean;
  appId?: string;
  dimensions?: unknown;
  handler?: (url: string, qParams: string) => void;
  snowplowLoader: () => Promise<void>;
}

export const initAnalytics = ({
  enabled = isAnalyticsEnabled,
  appId = applicationId,
  dimensions = [],
  handler,
  snowplowLoader,
}: AnalyticsInitParams = {}) => {
  if (enabled && appId) {
    const initTracker = () => {
      window.sp('newTracker', 'ab', analyticsUrl, {
        appId: appId,
        platform: 'web',
        discoverRootDomain: true,
        forceSecureTracker: true,
        // кастомный метод для sp.js в apps/pro
        handler,
      });
      onSuccess(window.sp);
    };

    defaultDimensions = dimensions;

    const handleGlobalErrors = (
      msg: string,
      source: string,
      line: number,
      col: number,
      error: Error
    ) => {
      if (window.sp) {
        const { href, search } = window.location;

        track(
          ANALYTICS.CATEGORY.TECH_TRACE,
          ANALYTICS.ACTION.APP_ERROR,
          ANALYTICS.LABEL.ERROR,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                1: `Message=${msg}`,
                2: `Trace=${
                  JSON.stringify(error.stack) || 'No trace provided'
                }`,
                3: `Location=${href}${search}`,
              },
            },
          ]
        );
      }
    };

    // код из бойлерплейта продуктовой аналитики
    (function (win: Window, name: string) {
      if (!win[name]) {
        win.GlobalSnowplowNamespace = win.GlobalSnowplowNamespace || [];
        win.GlobalSnowplowNamespace.push(name);

        win[name] = function () {
          (win[name].q = win[name].q || []).push(arguments);
        };

        win[name].q = win[name].q || [];

        snowplowLoader()
          .then(() => {
            initTracker();
          })
          .catch((e) => {
            log.error('Ошибка загрузки модуля аналитики');
            onError(e);
            delete window.sp;
          });

        window.onerror = handleGlobalErrors;
      }
    })(window, 'sp');
  }
};

export const track = async (
  category: string,
  action: string,
  label?: string | null,
  property?: string | null,
  value?: number | null,
  dimensions?: object[] = [] // TODO Dimensions[] ( api-extractor не может собрать этот тип )
) => {
  //
  if (featureFlags.DEBUG_ANALYTICS) {
    log.debug('[Track]', category, action, label, property, value, dimensions);
  }

  //
  if (!isAnalyticsEnabled) {
    log.debug('Модуль аналитики отключен или произошла ошибка');

    return;
  }

  try {
    const sp = await analyticsInstance;

    sp(
      'trackStructEvent',
      category,
      action,
      label || null,
      property || null,
      value || null,
      [...defaultDimensions, ...dimensions]
    );
  } catch (e) {
    log.debug('В модуле аналитики произошла ошибка' + e);
  }
};

export const analyticsUserInit = (user: UserCredentials) => {
  analyticsInstance.then((sp) => sp('setUserId', user.cus || user.login));
};
