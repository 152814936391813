import { format, startOfMonth } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import { Result } from '@terminal/lk/shared';
import {
  DocumentsHistoryFilter,
  DocumentsHistoryStatus,
  DocumentsHistoryTheme,
  Treaty,
  useDocumentsHistory,
} from '@terminal/lk-core';

import {
  HistoryFilter,
  HistoryFilterLine,
  HistoryList,
} from '../../../../features/FinancialDocuments';

import styles from './History.module.css';

interface HistoryProps {
  treaty: Treaty | null;
  emailConfirmed?: boolean;
  loading?: boolean;
  withAutomaticOrders?: boolean;
}

const PAGE_SIZE = 20;

export const defaultHistoryFilter: DocumentsHistoryFilter = {
  startDate: format(startOfMonth(new Date()), DEFAULT_VIEW_DATE_FORMAT),
  endDate: format(new Date(), DEFAULT_VIEW_DATE_FORMAT),
  statuses: '',
  theme: [],
};

export const History = ({
  treaty,
  emailConfirmed,
  loading,
  withAutomaticOrders,
}: HistoryProps) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] =
    useState<DocumentsHistoryFilter>(defaultHistoryFilter);
  const [openFilter, setOpenFilter] = useState(false);

  const prevFilterStatuses = useRef<DocumentsHistoryStatus[]>([]);
  const prevFilterThemes = useRef<DocumentsHistoryTheme[]>([]);

  const historyParams = useMemo(() => {
    return {
      ...filter,
      startDate: dateToRequestFormat(filter.startDate),
      endDate: dateToRequestFormat(filter.endDate),
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
      withAutomaticOrders,
    };
  }, [filter, page, treaty, withAutomaticOrders]);

  const [debouncedHistoryParams] = useDebounce(historyParams, 250);

  const {
    data: history,
    isFetching: historyPending,
    isFetchingNextPage: historyPendingNext,
    hasNextPage: hasMoreHistory,
    fetchNextPage: fetchMoreHistory,
  } = useDocumentsHistory(debouncedHistoryParams);

  const historyItems = useMemo(() => {
    if (history) {
      return history.pages.flatMap((page) => page.items);
    }

    return null;
  }, [history]);

  const filterStatuses = useMemo(() => {
    if (history) {
      return history.pages[0].statuses;
    }

    return [];
  }, [history]);

  useEffect(() => {
    if (filterStatuses.length) {
      prevFilterStatuses.current = filterStatuses;
    }
  }, [filterStatuses]);

  const filterThemes = useMemo(() => {
    if (history) {
      return history.pages[0].themes;
    }

    return [];
  }, [history]);

  useEffect(() => {
    if (filterThemes.length) {
      prevFilterThemes.current = filterThemes;
    }
  }, [filterThemes]);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchMoreHistory();
    }
  }, [loaderInView, fetchMoreHistory]);

  // // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const isFilterActive = useMemo<boolean>(() => {
    return !isEqual(filter, defaultHistoryFilter);
  }, [filter]);

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <div className={styles.filter}>
        <HistoryFilterLine
          filter={filter}
          active={isFilterActive}
          statuses={
            filterStatuses.length ? filterStatuses : prevFilterStatuses.current
          }
          onFilterOpen={() => setOpenFilter(true)}
          onSetFilter={(filter) => setFilter(filter)}
        />
      </div>

      <div className={styles.results}>
        {Boolean(historyItems) && (
          <HistoryList
            emailConfirmed={emailConfirmed}
            history={historyItems}
            treaty={treaty?.treaty || 0}
          />
        )}

        {Boolean(historyPending || historyPendingNext || loading) && (
          <Loader dataTestId="history-loader" />
        )}

        {Boolean(historyItems) &&
          historyItems?.length === 0 &&
          !(historyPending || historyPendingNext) && (
            <Result
              title="Результатов нет"
              text={
                <>
                  По заданным параметрам
                  <br />
                  не&nbsp;найдено ни&nbsp;одного документа
                </>
              }
              icon={<FilterMIcon />}
            />
          )}

        {hasMoreHistory && !(historyPending || historyPendingNext) && (
          <div className={styles.loader} ref={loaderRef}></div>
        )}
      </div>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={openFilter}
          backLabel="История"
          onClose={onCloseFilter}
        >
          <HistoryFilter
            filter={filter}
            pending={historyPending}
            statuses={
              filterStatuses.length
                ? filterStatuses
                : prevFilterStatuses.current
            }
            themes={
              filterThemes.length ? filterThemes : prevFilterThemes.current
            }
            onSetFilter={(filter) => setFilter(filter)}
            onResetFilter={() => setFilter(defaultHistoryFilter)}
            onClose={onCloseFilter}
          />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
