import React, { FC, useCallback, useEffect, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { usePrevious } from '@terminal/core/hooks';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection } from '../../../../shared';
import { Drawer } from '../../../Drawer';
import { SimpleTable } from '../../../SimpleTable';
import { InputOutputTableRow } from '../../model/FinResultTable';
import { OperationChapterDetails } from '../OperationChapterDetails';

import { useInputsOutputsTable } from '../../hooks/useInputsOutputsTable';

import styles from './InputOutput.module.css';

enum Column {
  Name = 'name',
  Income = 'income',
  Actions = 'actions',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.Income,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Actions,
  },
];

const TABLE_TITLE = 'Операции';

interface Props {
  analytics?: PortfolioAnalyticsResult;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  isEveryThingLoaded: boolean;
}

export const InputOutput: FC<Props> = ({
  analytics,
  dateFilter,
  setDateFilter,
  isEveryThingLoaded,
}) => {
  const [drawerProps, setDrawerProps] = useState<Pick<
    InputOutputTableRow,
    'key' | 'byCurrency'
  > | null>(null);

  const { trackClickPortfolioReportElement } = usePortfolioReportsMetrics();

  const drawer = (
    <Drawer isOpen={Boolean(drawerProps)} onClose={() => setDrawerProps(null)}>
      {drawerProps ? (
        <OperationChapterDetails
          chapter={drawerProps.key}
          byCurrency={drawerProps.byCurrency}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          isEveryThingLoaded={isEveryThingLoaded}
        />
      ) : null}
    </Drawer>
  );

  const data = useInputsOutputsTable(analytics);

  const handleOpenDrawer = useCallback(
    (row: InputOutputTableRow) => {
      setDrawerProps({ key: row.key, byCurrency: row.byCurrency });
      trackClickPortfolioReportElement(TABLE_TITLE, row.name);
    },
    [trackClickPortfolioReportElement]
  );

  const prevData = usePrevious(data);

  useEffect(() => {
    if (drawerProps?.key && prevData !== data) {
      const chapterData = data.find((c) => c.key === drawerProps.key);

      setDrawerProps({
        key: drawerProps.key,
        byCurrency: chapterData?.byCurrency || {
          all: 0,
          rub: 0,
          usd: 0,
          eur: 0,
          cny: 0,
          other: 0,
          papers: 0,
        },
      });
    }
  }, [drawerProps, prevData, data]);

  const cellRender = useCallback(
    (columnKey, prop, row) => {
      switch (columnKey) {
        case Column.Name:
          const Icon = row.Icon;

          return (
            <Typography.Text
              view="secondary-large"
              className={styles.chapterName}
            >
              <Icon
                width={16}
                height={16}
                color="var(--color-light-text-secondary)"
              />
              {prop}
            </Typography.Text>
          );
        case Column.Income:
          return (
            <Typography.Text view="secondary-large">
              {row.key === 'inputs' ? '+' : '-'}&nbsp;
              {
                formatAmount({
                  value: Math.abs(row.income) * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formatted
              }
              &nbsp;
              <Typography.Text
                tag="span"
                view="secondary-large"
                color="secondary"
              >
                {getSymbolByCurrency(row.currency)}
              </Typography.Text>
            </Typography.Text>
          );
        case Column.Actions:
          return (
            <IconButton
              size="xs"
              view="secondary"
              icon={ChevronRightMIcon}
              onClick={() => handleOpenDrawer(row.key)}
            />
          );
      }
    },
    [handleOpenDrawer]
  );

  return (
    <>
      {analytics && data.length && isEveryThingLoaded ? (
        <>
          <PortfolioSection title={TABLE_TITLE}>
            <SimpleTable<InputOutputTableRow, Column>
              columns={COLUMN_SETTINGS}
              data={data}
              cellRender={cellRender}
              onRowClick={(_, row) => handleOpenDrawer(row)}
              className={styles.table}
            />
          </PortfolioSection>
          <Gap size="2xl" />
        </>
      ) : null}

      {drawer}
    </>
  );
};
