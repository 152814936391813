import cn from 'classnames';
import { useRef, useState } from 'react';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { SlidersMIcon } from '@alfalab/icons-glyph/SlidersMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { Dropdown } from '../../../components/Dropdown';
import { PUOptions } from '../PUFilters';
import { TypeOptions } from '../TypeOptions';

import styles from './DropdownMode.module.css';

interface Props {
  isShowChips: boolean;
  selectedTypes: ObjectGroup[];
  saveTypes: (positionTypes: ObjectGroup[]) => void;
  selectedPUMode: PUShowMode;
  savePUMode: (selectedPUMode: PUShowMode) => void;
}

export const DropdownMode = ({
  isShowChips,
  selectedTypes,
  selectedPUMode,
  saveTypes,
  savePUMode,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const subMenuTypeRef = useRef<HTMLDivElement>(null);
  const [openSubMenuType, setOpenSubMenuType] = useState(false);

  const subMenuPURef = useRef<HTMLDivElement>(null);
  const [openSubMenuPU, setOpenSubMenuPU] = useState(false);

  useOnClickOutside(
    [dropdownRef, filtersButtonRef, subMenuTypeRef, subMenuPURef],
    () => setIsOpen(false)
  );

  return (
    <>
      <div
        ref={filtersButtonRef}
        className={cn(
          styles.orderStatusFiltersButton,
          (selectedPUMode !== PUShowMode.ANY ||
            Boolean(selectedTypes.length)) &&
            styles.activeFilterButton
        )}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <SlidersMIcon height={16} width={16} color="currentColor" />
        <ChevronDownCompactMIcon
          height={16}
          width={16}
          color="currentColor"
          className={cn(styles.chevron, isOpen && styles.chevronUp)}
        />
      </div>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={cn(
          styles.positionTypeSelectPoppover,
          styles.PUModeSelectPoppover
        )}
      >
        <Dropdown.Item
          content="По типу"
          rightIcon={
            <ChevronRightMIcon height={16} width={16} color="#C1C1C3" />
          }
          ref={subMenuTypeRef}
          onMouseEnter={() => setOpenSubMenuType(true)}
          onMouseLeave={() => setOpenSubMenuType(false)}
        >
          <Dropdown
            anchorElement={subMenuTypeRef.current}
            open={openSubMenuType}
            position="right-start"
            offset={[-8, 4]}
            popperClassName={styles.positionTypeSelectPoppover}
          >
            <TypeOptions
              isShowChips={isShowChips}
              selectedTypes={selectedTypes}
              saveTypes={(types) => {
                saveTypes(types);
                setOpenSubMenuType(false);
                setIsOpen(false);
              }}
            />
          </Dropdown>
        </Dropdown.Item>
        <Dropdown.Item
          content="По доходности"
          rightIcon={
            <ChevronRightMIcon height={16} width={16} color="#C1C1C3" />
          }
          ref={subMenuPURef}
          onMouseEnter={() => setOpenSubMenuPU(true)}
          onMouseLeave={() => setOpenSubMenuPU(false)}
        >
          <Dropdown
            anchorElement={subMenuPURef.current}
            open={openSubMenuPU}
            position="right-start"
            offset={[-8, 4]}
            popperClassName={cn(
              styles.positionTypeSelectPoppover,
              styles.PUModeSelectPoppover
            )}
          >
            <PUOptions
              selectedPUMode={selectedPUMode}
              savePUMode={(mode) => {
                savePUMode(mode);
                setOpenSubMenuPU(false);
                setIsOpen(false);
              }}
            />
          </Dropdown>
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};
