import { useCloudConfigList } from '../hooks/useCloudConfigList';
import { useDeleteCloudConfigMutation } from '../hooks/useDeleteCloudConfigMutation';
import { useGetCloudConfigMutation } from '../hooks/useGetCloudConfigMutation';
import { useUpdateCloudConfigMutation } from '../hooks/useUpdateCloudConfigMutation';
import { useUploadCloudConfigMutation } from '../hooks/useUploadCloudConfigMutation';

import { CloudConfigHooks } from '../model/types/cloudConfigHooks';

export const cloudConfigHooks: CloudConfigHooks = {
  useCloudConfigList,
  useUploadCloudConfigMutation,
  useDeleteCloudConfigMutation,
  useGetCloudConfigMutation,
  useUpdateCloudConfigMutation,
};
