import {
  ObjectGroup,
  RazdelGroupType,
} from '@terminal/core/lib/client/entities';
import { PositionItem } from '@terminal/core/types/position';

import { Asset } from '../model/types/PortfolioComposition';

export const getDerivativesMarketCurrency = (positions: PositionItem[]) => {
  const currencyPositions: Asset[] = [];

  if (positions.some((i) => i.torgPosCostRur === null)) {
    return null;
  }

  const balance = positions.reduce((acc, val, index) => {
    if (
      val.objectType.idObjectGroup === ObjectGroup.Currency &&
      val.idBalanceGroup === RazdelGroupType.DerivativesMarket
    ) {
      currencyPositions.push({
        id: val.objectType.idObjectGroup ?? index,
        assetId: val.objectType.idObjectGroup.toString() || '',
        idObject: val.object?.idObject ?? index,
        symbolObject: val.object?.symbolObject,
        name: val.nameObject ?? '',
        price: val.torgPosCostRur!,
        quantity: val.backPos,
        lot: val.finInfoExt?.lot || 1,
        percent: 0,
      });

      return acc + val.torgPosCostRur!;
    }

    return acc;
  }, 0);

  return { balance, currencyPositions };
};
