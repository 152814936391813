import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { SearchSecuritiesDTOAI } from './types/SearchSecuritiesDTO';
import { SearchSecuritiesParamsAI } from './types/SearchSecuritiesParams';

export async function searchSecuritiesAI(
  data: SearchSecuritiesParamsAI
): Promise<SearchSecuritiesDTOAI> {
  const result = await fetchLkResult<SearchSecuritiesDTOAI | LKResult>(
    qs.stringifyUrl({
      url: '/api/transfers/securities/in/search-actives',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
