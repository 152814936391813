import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';

interface Props {
  viewBox?: {
    x: number;
    y: number;
    width: number;
  };
  price: number;
  suffix?: string;
  fill?: string;
  yAxisWidth: number;
}

export const CurrentPriceLabel = ({
  viewBox,
  price,
  suffix,
  fill,
  yAxisWidth,
}: Props) => {
  if (!viewBox) {
    return null;
  }

  const { x, y, width } = viewBox;

  const decimalsNumber = countDecimals(price);
  const minority = Math.pow(10, decimalsNumber);
  const label = formatAmount({
    value: price * minority,
    currency: 'RUR' as CurrencyCodes,
    minority: minority,
    view: 'withZeroMinorPart',
  }).formatted;

  return (
    <text
      x={x + width + yAxisWidth + 4}
      y={y}
      fill={fill}
      fontSize={11}
      textAnchor="end"
      dominantBaseline="middle"
    >
      {label}
      {suffix}
    </text>
  );
};
