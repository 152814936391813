import cn from 'classnames';
import React, { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { Modal, ModalProps } from '../../../../legacy/components/Modal';

import styles from './WorkspaceConfigurationModal.module.css';

export type WorkspaceConfigurationModalProps = ModalProps;

export const WorkspaceConfigurationModal: FC<
  WorkspaceConfigurationModalProps
> = (props) => {
  const { className, title, ...restProps } = props;
  const rootClassName = cn(className, styles.root, 'hello');

  return (
    <Modal
      className={rootClassName}
      headerClassname={styles.header}
      footerClassname={styles.footer}
      contentClassname={styles.content}
      headerIconProps={{
        className: styles.closeIcon,
      }}
      title={
        <Typography.TitleMobile view="xsmall" tag="div">
          {title}
        </Typography.TitleMobile>
      }
      {...restProps}
    />
  );
};
