import { useMemo, useState } from 'react';

import { useRequirement } from '@terminal/core/hooks/domain';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { useInitialiseTreties } from '@terminal/lk/entities/SelectTreaty';
import { useTreaties } from '@terminal/lk/legacy/hooks';
import {
  BaseSelectTreatyProps,
  Loader,
  SelectTreaty as BaseSelectTreaty,
} from '@terminal/lk-desktop-common';

export const SelectTreaty = (props: BaseSelectTreatyProps) => {
  const { onSelect, treatyFilter, selectedAccountId, ...restProps } = props;
  const [treaty, setTreaty] = useState<Treaty | null>(null);

  const { data: treaties, isFetching: pending, isLoading } = useTreaties();

  const treatiesCodes = useMemo(
    () => (treaties ? treaties.map(({ name }) => name) : []),
    [treaties]
  );
  const marginCall = useRequirement(treatiesCodes);

  useInitialiseTreties({
    treaty,
    treaties,
    selectedAccountId,
    setTreaty,
    treatyFilter,
    onSelect,
  });

  return (
    <>
      <BaseSelectTreaty
        treaty={treaty}
        setTreaty={setTreaty}
        pending={pending}
        marginCall={marginCall}
        treatyFilter={treatyFilter}
        treaties={treaties}
        onSelect={onSelect}
        {...restProps}
      />
      {isLoading && <Loader />}
    </>
  );
};
