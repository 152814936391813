import cn from 'classnames';
import { ReactNode } from 'react';

import { Column } from '../../model/types';

import styles from './SimpleTable.module.css';

type CellData = {
  key: string | number;
  [prop: string]: any;
};

interface Props<D extends CellData, K extends string> {
  columns: Column<K>[];
  data: D[];
  cellRender?: (columnKey: K, prop: any, row: D, index: number) => ReactNode;
  onRowClick?: (row: D['key'], D) => void;
  className?: string;
}

export const SimpleTable = <
  D extends CellData = CellData,
  K extends string = string
>({
  columns,
  data,
  cellRender,
  onRowClick,
  className,
}: Props<D, K>) => {
  const hasHeader = columns.some((column) => 'header' in column);

  const widths = columns
    .map((column) => {
      if (!column.width) {
        return 'auto';
      }

      if (typeof column.width === 'number') {
        return `${column.width}px`;
      }

      return column.width;
    })
    .join(' ');

  return (
    <div
      className={cn(styles.grid, className)}
      style={{
        gridTemplateColumns: widths,
      }}
    >
      {hasHeader && (
        <div className={styles.row}>
          {columns.map((column) => (
            <div
              key={column.key}
              className={styles.cell}
              style={{
                justifyContent: column.justifyContent,
              }}
            >
              {column.header}
            </div>
          ))}
        </div>
      )}

      {data.map((row, index) => (
        <div
          key={index}
          onClick={() => onRowClick?.(row.key, row)}
          className={cn(styles.row, {
            [styles.clickable]: Boolean(onRowClick),
          })}
        >
          {columns.map((column) => (
            <div
              key={column.key}
              className={cn(styles.cell, {
                [styles.withoverflow]: String(column.width).includes('fr'),
              })}
              style={{
                justifyContent: column.justifyContent,
              }}
            >
              {cellRender
                ? cellRender(column.key, row[column.key], row, index)
                : row[column.key]}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
