import React, { useCallback, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { DotMediumSIcon } from '@alfalab/icons-glyph/DotMediumSIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { PositionItem } from '@terminal/core/types/position';

import {
  CompositionType,
  usePortfolioCompositionMetrics,
} from '../../../../entities/PortfolioComposition';
import { Drawer } from '../../../Drawer';
import { SimpleTable } from '../../../SimpleTable';
import { AssetDetails } from '../AssetDetails';

import { useCompositionTable } from '../../hooks/useCompositionTable';

import { Asset } from '../../model/types/PortfolioComposition';
import { AssetsTableRow } from '../../model/types/PortfolioCompositionTable';

import styles from './PortfolioAssetsTable.module.css';

enum Column {
  Name = 'name',
  Price = 'price',
  Percent = 'percent',
  Actions = 'actions',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.Price,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Percent,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Actions,
  },
];

interface Props {
  activeTab: CompositionType;
  assets: Asset[];
  positions: PositionItem[];
  industries: Map<string, Asset>;
  isFetching: boolean;
}

export const PortfolioAssetsTable = ({
  activeTab,
  assets,
  positions,
  industries,
  isFetching,
}: Props) => {
  const [drawerProps, setDrawerProps] = useState<Pick<
    AssetsTableRow,
    'assetId' | 'name'
  > | null>(null);

  const { trackClickPortfolioCompositionElement } =
    usePortfolioCompositionMetrics();

  const drawer = (
    <Drawer isOpen={Boolean(drawerProps)} onClose={() => setDrawerProps(null)}>
      <AssetDetails
        activeTab={activeTab}
        assetId={drawerProps?.assetId || ''}
        name={drawerProps?.name || ''}
        positions={positions}
        industries={industries}
      />
    </Drawer>
  );

  const handleOpenDrawer = useCallback(
    (row: AssetsTableRow) => {
      setDrawerProps({ assetId: row.assetId, name: row.name });
      trackClickPortfolioCompositionElement(activeTab, row.name);
    },
    [activeTab, trackClickPortfolioCompositionElement]
  );

  const data = useCompositionTable(assets);

  const cellRender = useCallback(
    (columnKey, _, row: AssetsTableRow) => {
      switch (columnKey) {
        case Column.Name:
          return (
            <Typography.Text view="secondary-large" className={styles.itemName}>
              <DotMediumSIcon
                width={16}
                height={16}
                color={row.color || 'var(--color-static-graphic-pewter)'}
              />
              {row.name}
            </Typography.Text>
          );
        case Column.Price:
          return (
            <Typography.Text view="secondary-large" color="secondary">
              {
                formatAmount({
                  value: row.price * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formattedWithCurrency
              }
            </Typography.Text>
          );
        case Column.Percent:
          return (
            <Typography.Text view="secondary-large">
              {String(row.percent).replace('.', ',')}&nbsp;%
            </Typography.Text>
          );
        case Column.Actions:
          return (
            <IconButton
              size="xs"
              view="secondary"
              icon={ChevronRightMIcon}
              onClick={() => handleOpenDrawer(row)}
            />
          );
      }
    },
    [handleOpenDrawer]
  );

  return (
    <>
      {!isFetching ? (
        <SimpleTable<AssetsTableRow, Column>
          columns={COLUMN_SETTINGS}
          data={data}
          cellRender={cellRender}
          onRowClick={(_, row) => handleOpenDrawer(row)}
          className={styles.table}
        />
      ) : null}

      {drawer}
    </>
  );
};
