import React, { FC, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import PlusMIcon from '@alfalab/icons-glyph/PlusMIcon';

import { INDICES_CHIPSE } from '../../lib/const';
import { IndexChip } from '../IndexChip';
import { IndicesPopover } from '../IndicesPopover';

import { ChartIndex } from '../../model/types';

import styles from './IndicesChipse.module.css';

interface Props {
  selected: ChartIndex[];
  onChange: (selected: ChartIndex[]) => void;
}

export const IndicesChipse: FC<Props> = ({ selected, onChange }) => {
  const [popoverVisible, togglePopoverVisible] = useToggle(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useClickAway(popoverRef, (event) => {
    if (
      buttonRef.current === event.target ||
      buttonRef.current?.contains(event.target as Node)
    ) {
      return;
    }

    togglePopoverVisible(false);
  });

  const handleRemoveIndex = (chip: ChartIndex) => {
    onChange(selected.filter((c) => c !== chip));
  };

  const handleClosePopover = () => togglePopoverVisible(false);

  return (
    <div className={styles.chipseRow}>
      <IndexChip name="Портфель" color="#c1c1c3" />

      {selected.map((index) => (
        <IndexChip
          key={index}
          index={index}
          {...INDICES_CHIPSE[index]}
          onClose={handleRemoveIndex}
        />
      ))}

      <ButtonDesktop
        ref={buttonRef}
        view="ghost"
        size="xs"
        leftAddons={
          <PlusMIcon
            width={16}
            height={16}
            color="var(--color-light-text-secondary)"
          />
        }
        onClick={togglePopoverVisible}
        className={styles.addButton}
      >
        Сравнить
      </ButtonDesktop>

      <IndicesPopover
        isOpen={popoverVisible}
        popoverRef={popoverRef}
        buttonRef={buttonRef}
        selected={selected}
        onChange={onChange}
        onClose={handleClosePopover}
      />
    </div>
  );
};
