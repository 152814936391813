import { format } from 'date-fns';
import React, { FC, useEffect, useMemo } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';

import {
  useAlfaDirectContext,
  useAllSubAccountsIds,
} from '@terminal/alfadirect/hooks';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { useOrdersMap } from '@terminal/core/hooks';
import {
  useIdFiBalances,
  usePosition,
  usePositions,
  useSubAccountRazdel,
} from '@terminal/core/hooks/domain';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { getClientOrders } from '@terminal/core/lib/domain/getClientOrders';
import { useFinInfoExt } from '@terminal/core/lib/services/finInfoExt/useFinInfoExt';
import { useQuotes } from '@terminal/core/lib/services/quotes';
import { useStore } from '@terminal/core/store';
import { DateFilterValue } from '@terminal/core/types/operation';
import { PositionItem } from '@terminal/core/types/position';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import {
  PortfolioAnalyticsWarningContainer,
  PortfolioSection,
  Spinner,
} from '../../../../shared';
import { mapActGroupToBanchmarkName } from '../../lib/mapActGroupToBenchmarkName';
import { mapActGroupToIncomeTitle } from '../../lib/mapActGroupToIncomeTitle';
import { ActGroupType } from '../../model/FinResult';
import { FinResultByEmitentResponse } from '../../model/FinResultByEmitent';
import { DateFilters } from '../DateFilters';
import { Rates } from '../Rates';
import { PortfolioPosition } from './PortfolioPosition';
import { Summary } from './Summary';

import { useFinResultByEmitent } from '../../hooks/useFinResultByEmitent';

interface Props {
  actId: number;
  actGroup: ActGroupType;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  selectedAccountId: number;
}

export const IncomeByFI: FC<Props> = ({
  actId,
  actGroup,
  dateFilter,
  setDateFilter,
  selectedAccountId,
}) => {
  const {
    data: finResultForInstrument,
    isLoading: isFinResultLoading,
    isIdle: isFinResultIdle,
  } = useFinResultByEmitent({
    accountId: selectedAccountId,
    dateFrom: format(dateFilter.valueFrom.date, 'yyyy-MM-dd'),
    dateTo: format(dateFilter.valueTo.date, 'yyyy-MM-dd'),
    actId,
  });

  const {
    trackOpenPortfolioReportFIDetails,
    trackHoverPortfolioReportTooltip,
  } = usePortfolioReportsMetrics();

  useEffect(() => {
    if (finResultForInstrument) {
      trackOpenPortfolioReportFIDetails(
        mapActGroupToBanchmarkName(actGroup),
        finResultForInstrument.ticker,
        finResultForInstrument.isin
      );
    }
  }, [actGroup, finResultForInstrument, trackOpenPortfolioReportFIDetails]);

  const selectedSubAccounts = useAllSubAccountsIds(selectedAccountId);
  const positions = usePositions();

  const { accounts, subAccountsList: subAccounts } = useAlfaDirectContext();
  const subAccountRazdels = useSubAccountRazdel();
  const subAccountIds = useMemo(
    () => subAccounts.map(({ idSubAccount }) => idSubAccount),
    [subAccounts]
  );

  const selectedPositions = useMemo(
    () =>
      positions.filter(
        (position) =>
          position.idObject === finResultForInstrument?.idObject &&
          subAccountIds.includes(position.idSubAccount)
      ),
    [finResultForInstrument?.idObject, positions, subAccountIds]
  );

  const currenciesQuotes = useQuotes(currenciesFI);
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);
  const orders = useStore((store) => getClientOrders(store.orders, accounts));

  const positionsToIdFiBalances = useIdFiBalances(selectedPositions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);
  const quotes = useQuotes(fi);

  const ordersMap = useOrdersMap(orders);

  const calculatedPositions = usePosition(
    {
      positions: selectedPositions,
      quotes,
      finInfoExts,
      subAccountRazdels,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );

  const nonZeroFilteredData = useMemo(
    () => calculatedPositions.filter((position) => position.torgPos !== 0),
    [calculatedPositions]
  );

  const position: Pick<
    PositionItem,
    | 'NPLtoMarketCurPrice'
    | 'torgPos'
    | 'marketBoard'
    | 'NKDRur'
    | 'idObjectGroup'
  > = useMemo(
    () =>
      nonZeroFilteredData.reduce(
        (acc, current) => {
          if (current.objectType.idObjectGroup === ObjectGroup.Bonds) {
            acc.NKDRur += current.NKDRur;
          }

          if (current.NPLtoMarketCurPrice) {
            acc.NPLtoMarketCurPrice += current.NPLtoMarketCurPrice;
          }

          acc.torgPos += current.torgPos;

          return acc;
        },
        {
          NPLtoMarketCurPrice: 0,
          NKDRur: 0,
          torgPos: 0,
          idObjectGroup: nonZeroFilteredData[0]?.idObjectGroup,
          marketBoard: nonZeroFilteredData[0]?.marketBoard,
        }
      ),
    [nonZeroFilteredData]
  );

  return (
    <div>
      <DateFilters value={dateFilter} onChange={setDateFilter} />

      <Gap size="l" />

      {isFinResultLoading || isFinResultIdle ? <Spinner /> : null}

      {!isFinResultLoading && finResultForInstrument ? (
        <>
          <PortfolioSection
            title={mapActGroupToIncomeTitle(actGroup, true)}
            tooltip="Показывает, сколько денег вы заработали за конкретный период с учётом всех закрытых позиций и полученных дивидендов. Доход по открытым позициям считается отдельно."
            onTooltipOpen={() => {
              trackHoverPortfolioReportTooltip(
                mapActGroupToIncomeTitle(actGroup, true)
              );
            }}
          >
            <Summary
              finResult={finResultForInstrument.finResult}
              actGroup={actGroup}
              currency={finResultForInstrument.currency}
              idObject={finResultForInstrument.idObject}
              symbolObject={finResultForInstrument.symbolObject}
            />

            {!checkHasData(finResultForInstrument, position) ? (
              <PortfolioAnalyticsWarningContainer
                Icon={ClockMIcon}
                title="Измените даты"
                description={
                  <>
                    Нет закрытых позиций за этот период
                    <br />
                    Попробуйте изменить даты
                  </>
                }
              />
            ) : null}

            {position.torgPos ? (
              <>
                <Gap size="xl" />
                <PortfolioPosition position={position} />
              </>
            ) : null}
          </PortfolioSection>

          <Gap size="2xl" />

          <Rates
            finResultRates={finResultForInstrument.finResult}
            tooltip="Показатели отображаются по закрытым позициям за конкретный период."
          />
        </>
      ) : null}
    </div>
  );
};

function checkHasData(
  result?: FinResultByEmitentResponse,
  position?: Pick<
    PositionItem,
    'NPLtoMarketCurPrice' | 'torgPos' | 'marketBoard'
  >
): boolean {
  if (!result && !position) {
    return false;
  }

  return (
    [
      result?.finResult?.total?.allOperations,
      result?.finResult?.total?.coupons,
      result?.finResult?.total?.dividends,
      result?.finResult?.total?.closedPositions,
      result?.finResult?.tradesCount,
      position?.torgPos,
    ].filter((value) => Boolean(value)).length > 0
  );
}
