import isEqual from 'lodash/isEqual';
import { useCallback, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import {
  getOperationsHistory,
  InvestQueryKeys,
  OperationsHistoryResult,
} from '../lib/rest/investApi';
import { USE_PASSPORT } from '../lib/rest/lkApi';
import { operationHistoryMap } from '../mapping/operationMapping';
import { useObjectByIdMap } from './domain';
import useNotification from './useNotification';
import { usePrevious } from './usePrevious';

import { MarketBoardItem, ObjectItem } from '../types/core';
import { OperationHistoryItem, OperationHistoryType } from '../types/operation';
import { NotificationType } from '../types/ui';

export enum AssetType {
  ID_OBJECT = 'ID_OBJECT',
  SYMBOL_OBJECT = 'SYMBOL_OBJECT',
}

/**
 *   subAccount: string; 456454-000
 *   dateFrom?: string; UTC Date - 2022-01-26T05:34:20.680Z
 *   dateTo?: string; UTC Date - 2022-02-26T05:34:20.680Z
 *   assetId?: string;
 */
export interface InfiniteOperationsHistoryParams {
  subAccount: string;
  operationTypes?: OperationHistoryType[];
  dateFrom?: string;
  dateTo?: string;
  assetId?: string;
  manual?: boolean;
}

interface ResultOperation extends OperationHistoryItem {
  object?: ObjectItem;
  market?: MarketBoardItem;
}

export const useInfiniteOperationsHistory = ({
  manual = false,
  ...params
}: InfiniteOperationsHistoryParams) => {
  const addNotification = useNotification();

  const { marketBoardsTable } = useAlfaDirectContext();
  const objectByIdMap = useObjectByIdMap();

  const assetIds = params?.assetId ? [params?.assetId?.toUpperCase()] : [];

  const query = useInfiniteQuery<
    OperationsHistoryResult,
    unknown,
    ResultOperation
  >(
    [InvestQueryKeys.OperationsHistory, params],
    ({ pageParam: cursor }) => {
      return getOperationsHistory({
        ...params,
        assetIds,
        cursor,
        assetIdType: assetIds ? AssetType.SYMBOL_OBJECT : AssetType.ID_OBJECT,
        maxElements: 100,
        operationTypes: (
          params.operationTypes || [
            OperationHistoryType.E,
            OperationHistoryType.D,
            OperationHistoryType.S,
          ]
        ).join(','),
      });
    },
    {
      enabled: USE_PASSPORT && !manual,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
      select: useCallback(
        ({ pages, pageParams }) => ({
          pages: pages
            .flatMap((page) => page.operations)
            .map((operation) => {
              const parsedObject = operationHistoryMap(operation);
              const object = parsedObject.operation.objectId
                ? objectByIdMap.get(parsedObject.operation.objectId)
                : undefined;

              const market = parsedObject.operation.marketId
                ? marketBoardsTable.get(
                    'universalMarketCode',
                    parsedObject.operation.marketId
                  )
                : undefined;

              return {
                ...parsedObject,
                object,
                market,
              } as ResultOperation;
            }),
          pageParams,
        }),
        [marketBoardsTable, objectByIdMap]
      ),
      getNextPageParam: (lastPage) => {
        return lastPage.cursor;
      },
    }
  );

  const { refetch } = query;

  const prevParams = usePrevious(params);

  useEffect(() => {
    if (!isEqual(prevParams, params) && manual) {
      refetch();
    }
  }, [prevParams, params, manual, refetch]);

  return query;
};
