import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';
import { Amount } from '@alfalab/core-components/amount';

import { useOperations } from '@terminal/alfadirect/hooks';
// eslint-disable-next-line no-restricted-imports
import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import {
  BlueprintTable,
  Cell,
  CellRenderProps,
} from '@terminal/common/ui/Table';
import { getDefaultOperationsColumnSetting } from '@terminal/core/constants/tableProps';
import { useDoubleRowMode } from '@terminal/core/hooks/useDoubleRowMode';
import { useFilterTable } from '@terminal/core/hooks/useFilterTable';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import { OperationGroup } from '@terminal/core/lib/client/entities';
import {
  getMinority,
  getStringDate,
  getStringTime,
} from '@terminal/core/lib/format';
import { USE_PASSPORT } from '@terminal/core/lib/rest/lkApi';
import {
  Filter,
  Sort,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import {
  OperationStatusMap,
  OperationTypeMap,
} from '@terminal/core/types/operation';
import { OrderDirectionMap } from '@terminal/core/types/order';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import {
  defaultColumnFilterRender,
  useWidgetContext,
  WidgetProps,
} from '../../../shared';
import { PortfolioAnalytics } from '../../PortfolioAnalytics';
import { OperationRow } from '../model/operation';
import { Header } from '../ui';

import { useSaveColumnSettings } from '../../../shared/hooks/useSaveColumnSettings';
import { useSelectedAccount } from '../../../shared/hooks/useSelectedAccount';
import { useSortTable } from '../../../shared/hooks/useSortTable';

import styles from './Operation.module.css';

export function Operations({ nodeId, tableProps, link, idFi }: WidgetProps) {
  const [isPortfolioModalOpen, togglePortfolioModalOpen] = useToggle(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { updateNode, useSettings, onWidgetLoad } = useWidgetContext();
  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  const defaultOperationsColumnSetting = useMemo(
    () => getDefaultOperationsColumnSetting(selectedDisplayInstrumentType),
    [selectedDisplayInstrumentType]
  );

  const columnsSetting = useTableColumns(
    tableProps?.columns,
    defaultOperationsColumnSetting
  );

  const { selectedSubAccounts, subAccounts } = useSelectedAccount(tableProps);

  const items = useOperations({ selectedSubAccounts });
  // Скрываем операции с рублями
  const filteredOperations = items.filter(
    (row) => row.operation.idOperationGroup !== OperationGroup.MON
  );

  const updateNodeHandler = useCallback(
    (config) => {
      nodeId && updateNode(nodeId, config);
    },
    [nodeId, updateNode]
  );

  const saveFilter = (filter?: Filter) => {
    updateNodeHandler({ tableProps: { filter } });
  };
  const filterState = useMemo(() => tableProps?.filter, [tableProps?.filter]);
  const { filteredData, filter, onFilter } = useFilterTable({
    data: filteredOperations,
    saveFilter,
    filterState,
  });
  const sort = useMemo(() => tableProps?.sort, [tableProps?.sort]);
  const setSort = (sort?: Sort) => {
    updateNodeHandler({ tableProps: { sort } });
  };
  const { sortedData, onSort } = useSortTable({
    data: filteredData,
    sort,
    setSort,
  });
  const [isDoubleRowMode, switchDoubleRowMode] = useDoubleRowMode(
    Widget.OPERATIONS,
    columnsSetting,
    defaultOperationsColumnSetting,
    updateNodeHandler
  );

  const prevSelectedDisplayInstrumentType = usePrevious(
    selectedDisplayInstrumentType
  );

  useEffect(() => {
    if (prevSelectedDisplayInstrumentType !== selectedDisplayInstrumentType) {
      updateNodeHandler({
        tableProps: {
          columns: columnsSetting.map((column) => {
            if (
              column.key === TableColumnKey.SymbolObject ||
              column.key === TableColumnKey.NameObject
            ) {
              return {
                ...column,
                key:
                  selectedDisplayInstrumentType === 'name'
                    ? TableColumnKey.NameObject
                    : TableColumnKey.SymbolObject,
              };
            }

            return column;
          }),
        },
      });

      //Сбрасываем не совпадающие по ключу фильтры при переключении отображания тикера
      if (
        (filterState?.key === TableColumnKey.SymbolObject &&
          selectedDisplayInstrumentType === 'name') ||
        (filterState?.key === TableColumnKey.NameObject &&
          selectedDisplayInstrumentType !== 'name')
      ) {
        onFilter();
      }
    }
  }, [
    updateNodeHandler,
    selectedDisplayInstrumentType,
    prevSelectedDisplayInstrumentType,
    columnsSetting,
    filterState?.key,
    onFilter,
  ]);

  const onRowClick = (row: OperationRow) => {
    if (link && nodeId && row.idFi) {
      updateNode(
        nodeId,
        { idFi: row.idFi, symbol: row.object?.symbolObject },
        row.object?.symbolObject
      );
    }
  };

  const cellRender: CellRenderProps<OperationRow> = (
    rowIndex,
    columnIndex,
    columnKey,
    data,
    setHoverIndex,
    columnName,
    isDoubleRowMode
  ) => {
    const { object, operation, order, market, shortDescription } =
      data[rowIndex];

    const secondRow: TableColumnSetting | undefined = isDoubleRowMode
      ? columnsSetting.find(({ key }) => key === columnKey)?.secondRow
      : undefined;

    const doubleRowCellRender = (
      secondRowColumnKey: TableColumnKey | undefined,
      firstRowColumnKey: TableColumnKey,
      firstRowContent: React.ReactElement
    ) => (
      <div className={styles.cellDoubleRow}>
        <div className={styles.firstRowContent}>{firstRowContent}</div>
        <div className={styles.secondRowWrapper}>
          {firstRowColumnKey === secondRowColumnKey ? (
            firstRowContent
          ) : (
            <>
              {secondRowColumnKey && (
                <div className={styles.secondRowContent}>
                  {cellRender(
                    rowIndex,
                    columnIndex,
                    secondRowColumnKey,
                    data,
                    setHoverIndex,
                    columnKey
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );

    switch (columnKey) {
      case TableColumnKey.ShortDescription:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <div className={styles.symbolCellSecondRow}>
                  {shortDescription}
                </div>
              )
            ) : (
              <div className={styles.symbolCellSecondRow}>
                {shortDescription}
              </div>
            )}
          </Cell>
        );
      case TableColumnKey.ReleaseNameObject:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            <span>{get(data[rowIndex], columnKey)}</span>
          </Cell>
        );
      case TableColumnKey.NameObject:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              <div className={styles.symbolCellDoubleRow}>
                <SymbolCell
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                  onlyIcon
                />
                <div className={styles.symbolCellDoubleRowRightSide}>
                  {doubleRowCellRender(
                    secondRow?.key,
                    columnKey,
                    <SymbolCell
                      idObject={object?.idObject}
                      symbolObject={object?.symbolObject}
                      noIcon
                    />
                  )}
                </div>
              </div>
            ) : (
              <SymbolCell
                idObject={object?.idObject}
                symbolObject={object?.symbolObject}
              />
            )}
          </Cell>
        );
      case TableColumnKey.SymbolObject:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              <div className={styles.symbolCellDoubleRow}>
                <SymbolCell
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                  onlyIcon
                />
                <div className={styles.symbolCellDoubleRowRightSide}>
                  {doubleRowCellRender(
                    secondRow?.key,
                    columnKey,
                    <SymbolCell
                      idObject={object?.idObject}
                      symbolObject={object?.symbolObject}
                      noIcon
                    />
                  )}
                </div>
              </div>
            ) : (
              <SymbolCell
                idObject={object?.idObject}
                symbolObject={object?.symbolObject}
              />
            )}
          </Cell>
        );
      case TableColumnKey.OperationTime:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{getStringTime(operation.timeOperation)}</>
              )
            ) : (
              <span>{getStringTime(operation.timeOperation)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.OperationSettleDate:
      case TableColumnKey.OperationDeliveryDate:
      case TableColumnKey.OperationDate:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{getStringDate(get(data[rowIndex], columnKey))}</>
              )
            ) : (
              <span>{getStringDate(get(data[rowIndex], columnKey))}</span>
            )}
          </Cell>
        );
      case TableColumnKey.OperationBuySell:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>
                  {market.codeMarketBoard !== 'DEPO' ? (
                    <span
                      className={
                        operation.buySell === 1 ? styles.buy : styles.sell
                      }
                    >
                      {OrderDirectionMap.get(operation.buySell)}
                    </span>
                  ) : (
                    <span
                      className={
                        operation.buySell === 1 ? styles.buy : styles.sell
                      }
                    >
                      {operation.buySell === 1 ? 'ЗАЧИСЛЕНИЕ' : 'СПИСАНИЕ'}
                    </span>
                  )}
                </>
              )
            ) : (
              <>
                {market.codeMarketBoard !== 'DEPO' ? (
                  <span
                    className={
                      operation.buySell === 1 ? styles.buy : styles.sell
                    }
                  >
                    {OrderDirectionMap.get(operation.buySell)}
                  </span>
                ) : (
                  <span
                    className={
                      operation.buySell === 1 ? styles.buy : styles.sell
                    }
                  >
                    {operation.buySell === 1 ? 'ЗАЧИСЛЕНИЕ' : 'СПИСАНИЕ'}
                  </span>
                )}
              </>
            )}
          </Cell>
        );
      case TableColumnKey.MarketNameMarketBoard:
      case TableColumnKey.OperationQuantity:
      case TableColumnKey.OperationCurrency:
      case TableColumnKey.OperationAccount:
      case TableColumnKey.CodeSubAccount:
      case TableColumnKey.OrderLogin:
      case TableColumnKey.OperationNumEDocument:
      case TableColumnKey.OrderComment:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{get(data[rowIndex], columnKey)}</>
              )
            ) : (
              <span>{get(data[rowIndex], columnKey)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.OperationValue:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <div
                  className={operation.buySell === 1 ? styles.buy : styles.sell}
                >
                  <Amount.Pure
                    value={operation.value * 100}
                    minority={100}
                    view="withZeroMinorPart"
                  />
                </div>
              )
            ) : (
              <div
                className={operation.buySell === 1 ? styles.buy : styles.sell}
              >
                <Amount.Pure
                  value={operation.value * 100}
                  minority={100}
                  view="withZeroMinorPart"
                />
              </div>
            )}
          </Cell>
        );
      case TableColumnKey.OperationStatus:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{OperationStatusMap.get(operation.idOperationStatus)}</>
              )
            ) : (
              <span>{OperationStatusMap.get(operation.idOperationStatus)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.OrderClientNumEDocument:
        const num = order?.clientNumEDocument;

        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>
                  {num ? (
                    <span className={styles.numEDocumentCell} title={num}>
                      {num}
                    </span>
                  ) : null}
                </>
              )
            ) : (
              <span>
                {num ? (
                  <span className={styles.numEDocumentCell} title={num}>
                    {num}
                  </span>
                ) : null}
              </span>
            )}
          </Cell>
        );
      case TableColumnKey.OperationPrice:
        const value = operation.price;
        //Подсчитываем минорные единицы
        const minority = getMinority(value);

        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <Amount.Pure
                  value={value * minority}
                  minority={minority}
                  view="withZeroMinorPart"
                />
              )
            ) : (
              <Amount.Pure
                value={value * minority}
                minority={minority}
                view="withZeroMinorPart"
              />
            )}
          </Cell>
        );
      case TableColumnKey.OperationYield:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{operation.yield ? operation.yield : null}</>
              )
            ) : (
              <span>{operation.yield ? operation.yield : null}</span>
            )}
          </Cell>
        );
      case TableColumnKey.OperationAccruedInt:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <Amount.Pure
                  value={operation.accruedInt * 100}
                  minority={100}
                  view="withZeroMinorPart"
                />
              )
            ) : (
              <Amount.Pure
                value={operation.accruedInt * 100}
                minority={100}
                view="withZeroMinorPart"
              />
            )}
          </Cell>
        );
      case TableColumnKey.OperationTypeId:
        return (
          <Cell onClick={() => onRowClick(data[rowIndex])}>
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>{OperationTypeMap.get(operation.idOperationType)}</>
              )
            ) : (
              <span>{OperationTypeMap.get(operation.idOperationType)}</span>
            )}
          </Cell>
        );

      default:
        return null;
    }
  };

  const saveColumnSetting = useSaveColumnSettings(nodeId);

  const selectedRowIndex = useMemo(() => {
    if (idFi) {
      const selected: number[] = [];

      for (let index in sortedData) {
        if (sortedData[index].idFi === idFi) {
          selected.push(Number(index));
        }
      }

      return selected;
    }
  }, [idFi, sortedData]);

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  return (
    <div className={styles.container} ref={containerRef}>
      <Header
        nodeId={nodeId}
        selectedSubAccounts={selectedSubAccounts}
        columnsSetting={columnsSetting}
        defaultOperationsColumnSetting={defaultOperationsColumnSetting}
        isDoubleRowMode={isDoubleRowMode}
        switchDoubleRowMode={switchDoubleRowMode}
        subAccounts={subAccounts}
        selectedDisplayInstrumentType={selectedDisplayInstrumentType}
        togglePortfolioModalOpen={togglePortfolioModalOpen}
      />
      <BlueprintTable<OperationRow>
        filter={filter}
        onFilter={onFilter}
        sort={sort}
        onSort={onSort}
        data={sortedData}
        columnSetting={columnsSetting}
        cellRender={cellRender}
        columnFilterRender={defaultColumnFilterRender}
        defaultSelectedRows={selectedRowIndex}
        onReorder={saveColumnSetting}
        onChangeWidth={saveColumnSetting}
        isDoubleRowMode={isDoubleRowMode}
      />

      {USE_PASSPORT && (
        <PortfolioAnalytics
          nodeId={nodeId}
          isOpen={isPortfolioModalOpen}
          onClose={() => togglePortfolioModalOpen(false)}
          selectedSubAccounts={selectedSubAccounts}
        />
      )}
    </div>
  );
}
