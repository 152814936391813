import { FinResultByInstrumentParams } from '../model/FinResultParams';
import { useFinResultByInstrument } from './useFinResultByInstrument';

export const useIncomeByInstrumentsTable = ({
  actGroup,
  accountId,
  dateFrom,
  dateTo,
}: FinResultByInstrumentParams) => {
  const { data = { assetsFinResult: [] }, isLoading } =
    useFinResultByInstrument({
      actGroup,
      accountId,
      dateFrom,
      dateTo,
    });

  return {
    data: data.assetsFinResult.map((result) => ({
      ...result,
      key: String(result.actId),
    })),
    isLoading,
  };
};
