import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { PersistStoreKey } from '@terminal/core/constants/persistStoreKey';

import { SR_EFR_BANNER_CLOSING_THRESHOLD } from './const';

import { SrEfrBannerStore } from '../model/types/SrEfrBannerStore';

export const useSrEfrBannerStore = createWithEqualityFn<SrEfrBannerStore>()(
  persist(
    (set, get) => ({
      viewed: false,
      bannerCloseCount: 0,
      increaseBannerCloseCount: () => {
        let bannerCloseCount = get().bannerCloseCount;

        if (++bannerCloseCount >= SR_EFR_BANNER_CLOSING_THRESHOLD) {
          set({ viewed: true });
        }

        set({ bannerCloseCount });
      },
      getViewed: () => get().viewed,
      setViewed: () => set({ viewed: true }),
    }),
    {
      name: PersistStoreKey.SrEfrBanner,
    }
  ),
  shallow
);
