import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { Dropdown } from '../../../components/Dropdown';

import styles from './PUFilters.module.css';

const filtersByPU = [
  {
    name: 'Любая доходность',
    mode: PUShowMode.ANY,
  },
  {
    name: 'Прибыльные: день',
    mode: PUShowMode.UP_DAY,
  },
  {
    name: 'Прибыльные: все время',
    mode: PUShowMode.UP_ALL,
  },
  {
    name: 'Убыточные: день',
    mode: PUShowMode.DOWN_DAY,
  },
  {
    name: 'Убыточные: все время',
    mode: PUShowMode.DOWN_ALL,
  },
];

interface PUFiltersProps {
  selectedPUMode: PUShowMode;
  savePUMode: (selectedPUMode: PUShowMode) => void;
  isShowChips: boolean;
}

export const PUFilters = ({
  selectedPUMode,
  savePUMode,
  isShowChips,
}: PUFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const selectedOption = useMemo(
    () =>
      filtersByPU.find(({ mode }) => mode === selectedPUMode) ?? filtersByPU[0],
    [selectedPUMode]
  );

  return (
    <>
      <div
        ref={filtersButtonRef}
        className={cn(
          styles.orderStatusFiltersButton,
          styles.PUFiltersButton,
          selectedPUMode !== PUShowMode.ANY && styles.activeFilterButton,
          !isShowChips && styles.PUFiltersButtonSmall
        )}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {selectedOption.name}
        <ChevronDownCompactMIcon
          height={16}
          width={16}
          color="currentColor"
          className={cn(styles.chevron, isOpen && styles.chevronUp)}
        />
      </div>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={cn(
          styles.positionTypeSelectPoppover,
          styles.PUModeSelectPoppover
        )}
      >
        <PUOptions
          selectedPUMode={selectedPUMode}
          savePUMode={(mode) => {
            savePUMode(mode);
            setIsOpen(false);
          }}
        />
      </Dropdown>
    </>
  );
};

interface PUOptionsProps {
  selectedPUMode: PUShowMode;
  savePUMode: (selectedPUMode: PUShowMode) => void;
}

export const PUOptions = ({ selectedPUMode, savePUMode }: PUOptionsProps) => (
  <>
    {filtersByPU.map(({ name, mode }) => {
      const checked = mode === selectedPUMode;

      return (
        <Dropdown.Item
          key={name}
          rightIcon={
            checked && <CheckmarkMIcon color="#FFFFFF" height={16} width={16} />
          }
          content={
            <Typography.Text view="secondary-large">{name}</Typography.Text>
          }
          onClick={() => {
            if (!checked && mode !== PUShowMode.ANY) {
              savePUMode(mode);
            } else {
              savePUMode(PUShowMode.ANY);
            }
          }}
        />
      );
    })}
  </>
);
