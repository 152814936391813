export const enum ReportType {
  MyPortfolio = 17,
  MyBroker = 18,
  Taxes = 19,
  TaxAppendix = 20,
  MyMoneyMove = 21,
  SecuritiesMovementStatementDepoAccount = 23,
  DepoAccountStatusStatement = 24,
  IncomePaymentsReportOnSecurities = 25,
}
