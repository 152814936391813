import cn from 'classnames';
import React, { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Popover } from '@alfalab/core-components/popover';
import { Space } from '@alfalab/core-components/space';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { usePrevious } from '@terminal/core/hooks';

import { INDICES_CHIPSE } from '../../lib/const';

import { ChartIndex } from '../../model/types';

import styles from './IndicesPopover.module.css';

interface Props {
  isOpen: boolean;
  popoverRef: RefObject<HTMLDivElement>;
  buttonRef: RefObject<HTMLButtonElement>;
  selected: ChartIndex[];
  onChange: (selected: ChartIndex[]) => void;
  onClose: () => void;
}

export const IndicesPopover: FC<Props> = ({
  isOpen,
  popoverRef,
  buttonRef,
  selected,
  onChange,
  onClose,
}) => {
  const [localSelected, setLocalSelected] = useState<ChartIndex[]>(selected);

  const isSelectedSameOptions = useMemo(() => {
    return (
      selected.length === localSelected.length &&
      selected.every((index) => localSelected.includes(index))
    );
  }, [localSelected, selected]);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (prevIsOpen !== isOpen && isOpen) {
      setLocalSelected(selected);
    }
  }, [prevIsOpen, isOpen, selected]);

  const handleChange = (index: ChartIndex) => {
    setLocalSelected((prev) =>
      prev.includes(index)
        ? localSelected.filter((s) => s !== index)
        : [...localSelected, index]
    );
  };

  const handleCancel = () => {
    onChange([]);
    onClose();
  };

  const handleSubmit = () => {
    onChange(localSelected);
    onClose();
  };

  return (
    <Popover
      ref={popoverRef}
      anchorElement={buttonRef.current}
      open={isOpen}
      offset={[0, 4]}
      position="bottom-start"
      withTransition={false}
      className={styles.popover}
    >
      <div>
        {Object.values(ChartIndex).map((index) => {
          const selected = localSelected.includes(index);

          const Icon = selected ? CheckmarkOnCircleMIcon : SelectionMaskMIcon;

          return (
            <div
              key={index}
              className={styles.item}
              onClick={() => handleChange(index)}
            >
              <Space direction="horizontal" align="center" size={8}>
                <Icon
                  width={16}
                  height={16}
                  className={cn(
                    styles.block,
                    selected ? styles.iconSelected : styles.icon
                  )}
                />

                <Typography.Text view="secondary-large">
                  {INDICES_CHIPSE[index].name}
                </Typography.Text>

                <Tooltip
                  targetClassName={styles.block}
                  contentClassName={styles.tooltipContent}
                  content={
                    <div>
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        weight="bold"
                        className={styles.tertiary}
                      >
                        {INDICES_CHIPSE[index].name}
                      </Typography.Text>
                      <Gap size="2xs" />
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        className={styles.tertiary}
                      >
                        {INDICES_CHIPSE[index].description}
                      </Typography.Text>
                    </div>
                  }
                  trigger="hover"
                  position="top"
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-light-graphic-secondary)"
                  />
                </Tooltip>
              </Space>
            </div>
          );
        })}
      </div>

      <Gap size="m" />

      <Space direction="horizontal" align="center" size={8}>
        <ButtonDesktop
          view="outlined"
          size="xs"
          onClick={handleCancel}
          className={styles.reset}
        >
          Сбросить
        </ButtonDesktop>
        <ButtonDesktop
          view="secondary"
          color="quaternary"
          size="xs"
          onClick={handleSubmit}
          disabled={isSelectedSameOptions}
        >
          Применить
        </ButtonDesktop>
      </Space>
    </Popover>
  );
};
