import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { OrderAutomaticDocumentParams as OrderAutomaticDocumentRequest } from './types/OrderAutomaticDocumentParams';

export interface OrderAutomaticDocumentParams {
  params: OrderAutomaticDocumentRequest;
}

export async function orderAutomaticDocument(
  data: OrderAutomaticDocumentParams
) {
  const result = await fetchLkResult<LKResult>(
    '/api/client/documents/order-automatic',
    'POST',
    data.params
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
