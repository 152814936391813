import { memo } from 'react';

import { OrderType } from '@terminal/core/lib/client/entities';
import { Widget } from '@terminal/core/types/layout';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { SlippageType, StopOrderType } from '@terminal/core/types/ui';

import { AccountFilters } from '../../../../../../features/AccountFilters';
import { FinInstrAutocomplete } from '../../../../../../features/FinInstrAutocomplete';
import { WidgetHeader } from '../../../../../../features/WidgetHeader';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '../../../../../../features/WidgetServiceNotification';
import { useWidgetContext } from '../../../../../../shared';
import { useOrderBookContext } from '../../../../context';

import { useIsLotCount } from '../../../../../../shared/hooks/useIsLotCount';
import { useSettings } from '../../../../hooks';

interface Props {
  selectedSubAccounts: string[];
  nodeId?: string;
  idFi?: number;
  autoCenter?: boolean;
  hideForm?: boolean;
  finInfoExt?: FinInfoExt;
  setIsOpenLimitsModal: (state: boolean) => void;
  setIsOpenContextMenuModal: (state: boolean) => void;
  setIsOpenQuantityModal: (state: boolean) => void;
  setIsOpenSlippageModal: (state: boolean) => void;
}

const OrderBookHeader = ({
  idFi,
  nodeId,
  selectedSubAccounts,
  hideForm,
  finInfoExt,
  setIsOpenLimitsModal,
  setIsOpenContextMenuModal,
  setIsOpenQuantityModal,
  setIsOpenSlippageModal,
}: Props) => {
  const { slippageType, slippageValue } = useOrderBookContext();

  const { updateNode } = useWidgetContext();
  const isLotCount = useIsLotCount();

  const {
    autoCenter = true,
    showSpread,
    showEmptyPrice,
    sendTrallingOrders,
    stopOrderType,
    defaultQuantity,
    showYield,
  } = useOrderBookContext();

  //Не можем показывать разреженный стакан и спред без шага цен (он в finInfoExt)
  const isShowSpread = finInfoExt && showSpread;
  const isShowEmptyPrice = finInfoExt && showEmptyPrice;

  const saveForm = (
    hideForm: boolean,
    showSpread: boolean,
    showEmptyPrice: boolean,
    sendTrallingOrders: boolean,
    autoCenter: boolean,
    stopOrderType: StopOrderType,
    showYield: boolean
  ) => {
    if (nodeId) {
      updateNode(nodeId, {
        hideForm,
        showSpread,
        showEmptyPrice,
        sendTrallingOrders,
        autoCenter,
        stopOrderType,
        showYield,
      });
    }
  };

  const pcsVol = finInfoExt?.lot
    ? { quantity: finInfoExt.lot, assetUnits: 'шт' }
    : undefined;

  const widgetSettings = useSettings({
    autoCenter,
    hideForm: hideForm ?? false,
    showSpread: isShowSpread ?? false,
    showEmptyPrice: isShowEmptyPrice ?? false,
    stopOrderType: stopOrderType || OrderType.STL,
    showYield: showYield ?? true,
    saveForm,
    setIsOpenLimitsModal,
    setIsOpenContextMenuModal,
    setIsOpenQuantityModal,
    setIsOpenSlippageModal,
    defaultQuantity:
      defaultQuantity ||
      (isLotCount ? { quantity: 1, assetUnits: 'лот' } : pcsVol),
    sendTrallingOrders: sendTrallingOrders ?? false,
    slippageType: slippageType || SlippageType.TICK,
    slippageValue: slippageValue || 1,
  });

  return (
    <>
      <WidgetHeader
        inputs={
          <>
            <FinInstrAutocomplete
              idFi={idFi}
              nodeId={nodeId}
              widget={Widget.ORDERBOOK}
            />
            <AccountFilters
              selectedSubAccounts={selectedSubAccounts}
              updateNode={updateNode}
              nodeId={nodeId}
              isOrderBookVersion
            />
          </>
        }
        widgetSettings={widgetSettings}
      />
      <WidgetServiceNotification
        code={TECH_MESSAGE_CODE.TRADE}
        variant="line"
        canBeShort
      />
    </>
  );
};

export const Header = memo(OrderBookHeader);
