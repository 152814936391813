import { useState } from 'react';
import { useMount } from 'react-use';

import { useFeatureOnboarding } from '../model/store/useFeatureOnboarding';

import { Feature } from '../model/types';

export const useOneTimeShowing = (feature: Feature) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const setFeatureViewed = useFeatureOnboarding(
    (store) => store.setFeatureViewed
  );

  useMount(() => {
    // Неистовый лайфхак, чтобы нереактивно получить значение, установленное другим инстансом на этом же тике реакта
    // Задача - показать поповер только на одном из виджетов
    const featureState = useFeatureOnboarding.getState().features[feature];

    if (featureState) {
      return;
    }

    setIsVisible(true);
    setFeatureViewed(feature);
  });

  const handleClose = () => {
    setIsVisible(false);
  };

  return { isVisible, onClose: handleClose };
};
