import { FC, SVGProps } from 'react';
import { BanknotesMIcon } from '@alfalab/icons-glyph/BanknotesMIcon';
import { ChartPieCoinsMIcon } from '@alfalab/icons-glyph/ChartPieCoinsMIcon';
import { ChartRingMIcon } from '@alfalab/icons-glyph/ChartRingMIcon';
import { DocumentHorizontalMarkMIcon } from '@alfalab/icons-glyph/DocumentHorizontalMarkMIcon';
import { PercentMIcon } from '@alfalab/icons-glyph/PercentMIcon';

import { ActGroupType, ReportChapterType } from '../model/FinResult';

export const START_ANALYTICS_DATE = new Date('2021-07-01');
export const MARGIN_TEST_ID = 2;

export const ASSETS = [
  {
    key: 'shares',
    actGroup: ActGroupType.STOCKS,
    groups: ['sharesRevenue', 'dividends'],
    color: '#7eb3ff',
  },
  {
    key: 'currencies',
    actGroup: ActGroupType.CURRENCY,
    groups: ['currenciesRevenue'],
    color: '#7879cf',
  },
  {
    key: 'bonds',
    actGroup: ActGroupType.BONDS,
    groups: ['bondsRevenue', 'couponRevenue'],
    color: '#87b965',
  },
  {
    key: 'fonds',
    actGroup: ActGroupType.FUNDS,
    groups: ['fundsRevenue'],
    color: '#f0c330',
  },
  {
    key: 'mets',
    actGroup: ActGroupType.METALLS,
    groups: ['pmetsRevenue'],
    color: '#d9d6ce',
  },
  {
    key: 'derivatives',
    actGroup: ActGroupType.FUTURES,
    groups: ['derivativesRevenue'],
    color: '#eb7a58',
  },
];

export const PAYMENTS_EXPENSES: {
  key: ReportChapterType;
  Icon: FC<SVGProps<SVGSVGElement>>;
  hasHistory?: boolean;
}[] = [
  {
    key: 'dividends',
    Icon: ChartRingMIcon,
  },
  {
    key: 'couponRevenue',
    Icon: DocumentHorizontalMarkMIcon,
  },
  {
    key: 'taxes',
    Icon: PercentMIcon,
  },
  {
    key: 'commissions',
    Icon: BanknotesMIcon,
  },
  {
    key: 'marginTrade',
    Icon: ChartPieCoinsMIcon,
    hasHistory: false,
  },
];

export const CHARTS_DOTS_COUNT = 30;
