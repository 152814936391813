import cn from 'classnames';
import { MouseEventHandler, useCallback, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CopyMIcon } from '@alfalab/icons-glyph/CopyMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';
import { NavigationChatMIcon } from '@alfalab/icons-glyph/NavigationChatMIcon';
import { PhoneMIcon } from '@alfalab/icons-glyph/PhoneMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import useNotification from '@terminal/core/hooks/useNotification';
import { trackClickOnManuelLK } from '@terminal/core/lib/analytics';
import { copyToClipboard } from '@terminal/core/lib/copyToClipboard';
import { cascanaChatService } from '@terminal/core/lib/services/cascanaChat';
import { useStore as useRootStore } from '@terminal/core/store';
import { HeaderMenuItem, NotificationType } from '@terminal/core/types/ui';

import { useChatContext } from '../../shared/lib/ChatContext';
import { getTitleNode } from '../../utils';

import { useSupportMessage } from '../../hooks/useSupportMessage';

import itemStyles from './RoomListItem.module.css';
import styles from './SupportPanel.module.css';

enum TooltipType {
  EMAIL,
  TG,
  PHONE,
}

interface Props {
  mobile: boolean;
  onClose?: () => void;
}

export const SupportPanel = ({ mobile, onClose = () => {} }: Props) => {
  const { user } = useAlfaDirectContext();
  const setHeaderMenuOpen = useRootStore((state) => state.setHeaderMenuOpen);
  const addNotification = useNotification();
  const getSupportMessage = useSupportMessage();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>('');

  const { supportPhone, supportEmail, supportChat, feedbackEmail } =
    useChatContext();

  const { manuals } = useAppContext();

  const showTooltip = (type: TooltipType) => {
    if (type === TooltipType.EMAIL) {
      setTooltipText('Адрес скопирован');
    } else if (type === TooltipType.PHONE) {
      setTooltipText('Номер телефона скопирован');
    } else if (type === TooltipType.TG) {
      setTooltipText('Ссылка на бота скопирована');
    } else {
      return;
    }

    setTooltipVisible(true);

    setTimeout(() => setTooltipVisible(false), 2000);
  };

  const handleCopyEmail: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyToClipboard(supportEmail);
    showTooltip(TooltipType.EMAIL);
  };

  const handleCopyPhone: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyToClipboard(supportPhone || '');
    showTooltip(TooltipType.PHONE);
  };

  // Токены для чата и скрипт чата загружаются только если пользователю нужна техподдержка
  const openChat = useCallback(async () => {
    onClose();

    try {
      cascanaChatService.initialize();
      const res = await cascanaChatService.loadExternalToken();

      await cascanaChatService.loadChatTokens(res.accessToken);
      await cascanaChatService.loadClient(user?.fullName || '');
      cascanaChatService.showChatWindow();
    } catch (error) {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка загрузки чата поддержки',
      });
    }
  }, [user, addNotification, onClose]);

  return (
    <div className={mobile ? styles.supportPanelMobile : styles.supportPanel}>
      {manuals && manuals.SupportBanner && (
        <manuals.SupportBanner
          onClick={() => {
            trackClickOnManuelLK();
            onClose();
            setHeaderMenuOpen(HeaderMenuItem.MANUAL);
          }}
        />
      )}

      <div
        className={cn(itemStyles.item, {
          [itemStyles.itemMobile]: mobile,
        })}
        onClick={() => {
          window.open(
            `mailto:${
              feedbackEmail || supportEmail
            }?subject=${encodeURIComponent(
              'Отзыв Альфа-Инвестиции'
            )}&body=${encodeURIComponent(getSupportMessage())}`
          );
        }}
      >
        <div className={itemStyles.iconContainer}>
          <MailMIcon width={24} height={24} className={itemStyles.icon} />
        </div>

        <div className={itemStyles.itemInfo}>
          {getTitleNode('Написать на почту', mobile)}
          <Typography.Text
            tag="div"
            view={mobile ? 'secondary-medium' : 'secondary-small'}
            color="secondary"
            className={cn(
              itemStyles.itemDescription,
              styles.wrappingDescription
            )}
          >
            {feedbackEmail || supportEmail}
          </Typography.Text>
        </div>

        <IconButton
          view="secondary"
          size="s"
          icon={CopyMIcon}
          onClick={handleCopyEmail}
        />
      </div>

      {/* Cascana CHAT */}
      {supportChat ? (
        <div
          className={cn(itemStyles.item, {
            [itemStyles.itemMobile]: mobile,
          })}
          onClick={openChat}
        >
          <div className={itemStyles.iconContainer}>
            <NavigationChatMIcon
              width={24}
              height={24}
              className={itemStyles.icon}
            />
          </div>

          <div className={itemStyles.itemInfo}>
            {getTitleNode('Чат поддержки Go Invest', mobile)}
            <Typography.Text
              tag="div"
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              color="secondary"
              className={cn(
                itemStyles.itemDescription,
                styles.wrappingDescription
              )}
            >
              Открыть окно чата
            </Typography.Text>
          </div>
        </div>
      ) : null}

      {supportPhone ? (
        <div
          className={cn(itemStyles.item, {
            [itemStyles.itemMobile]: mobile,
          })}
          onClick={() => window.open(`tel:${supportPhone}`)}
        >
          <div className={itemStyles.iconContainer}>
            <PhoneMIcon width={24} height={24} className={itemStyles.icon} />
          </div>

          <div className={itemStyles.itemInfo}>
            {getTitleNode('Позвонить', mobile)}
            <Typography.Text
              tag="div"
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              color="secondary"
              className={cn(
                itemStyles.itemDescription,
                styles.wrappingDescription
              )}
            >
              {supportPhone}
              <br />
              Бесплатно по России
            </Typography.Text>
          </div>

          <IconButton
            view="secondary"
            size="s"
            icon={CopyMIcon}
            onClick={handleCopyPhone}
          />
        </div>
      ) : null}
      <Typography.Text
        view="secondary-small"
        className={cn(styles.copyTooltip, {
          [styles.copyTooltipVisible]: tooltipVisible,
        })}
      >
        {tooltipText}
      </Typography.Text>
    </div>
  );
};
