import { useEffect } from 'react';

import { useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';
import { ConnectionStatus } from '@terminal/core/types/connection';

import { useLoginPinCodeStore } from '../lib/storage';

// В этом компоненте реализован флоу авторизации в торговых сервисах через сервис одноразовых кодов
// Подробнее http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1024236610
// Данный флоу предполагает что за авторизацию в паспорте отвечают церберус вебы
// Но нам нужен атворизоваться в вебсокете торговых сервисво
// Предполагается что если пользователь получил страницу с реакт приложением, то церберус вебы
// одобрили его реквест по сессионной куке и юзер корректно авторизован
// Примерный конфиг церберус веба можно посмотреть в файле docs/cerberus-web.yaml
// Предполагается что роут получения токена настроен в корне того же сервера что раздает статику
const TOKEN_URI = `/api/token/v1/token/get_token`;

export const useLoginWithCerberus = (isWebView?: boolean) => {
  const connectionError = useStore((store) => store.connectionError);
  const setConnectionError = useStore((store) => store.setConnectionError);
  const setConnectionStatus = useStore((store) => store.setConnectionStatus);
  const connectPassport = useStore((store) => store.loginWithPassport);
  const setAppState = useStore((store) => store.setAppState);
  const appState = useStore((store) => store.appState);

  const hasPinCode = useLoginPinCodeStore((store) => store.hasPinCode);

  // Эффект запросит одноразовый код из сервиса и передаст его в торговые сервисы
  useEffect(() => {
    // важное условие, чтобы избежать двойной авторизации
    if (AppState.NOT_READY !== appState || connectionError) {
      return;
    }

    async function loginWithCerberus() {
      setAppState(AppState.AUTHORIZING_PASSPORT);

      try {
        // запрашиваем токен из рестового сервиса
        const codeResponse = await fetch(TOKEN_URI);

        if (codeResponse.ok) {
          const codeResponseObject = await codeResponse.json();

          if (!codeResponseObject.hasOwnProperty('token')) {
            //сервер вернул какую-то лажу
            throw new Error();
          }

          const code = codeResponseObject.token;

          connectPassport(
            code,
            true,
            await hasPinCode(),
            isWebView ? false : undefined
          );
        }
      } catch {
        // сетевая ошибка
        setConnectionError('Произошла ошибка при получении кода авторизации');
        setConnectionStatus(ConnectionStatus.ERROR);
        setAppState(AppState.NOT_READY);
      }
    }

    loginWithCerberus();
  }, [
    setAppState,
    connectPassport,
    setConnectionError,
    setConnectionStatus,
    appState,
    hasPinCode,
    connectionError,
    isWebView,
  ]);

  const hideButton = Boolean(connectionError) === false;

  return { hideButton, connectionError };
};
