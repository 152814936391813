import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Fragment, useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
// eslint-disable-next-line no-restricted-imports
import { Tab, Tabs } from '@alfalab/core-components/tabs';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleSIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleSIcon';

import { usePositionByIdFI } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { isValidCurrency } from '@terminal/core/lib/currencies';
import { getCouponWithMinority } from '@terminal/core/lib/format';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../../shared';
import {
  InstrumentDataRow,
  InstrumentError,
  InstrumentLoader,
  InstrumentsSimpleTable,
} from '../../ui';
import { NextCouponsPaymentBlock } from './NextCouponsPaymentBlock';

import { InstrumentTableHeaderItem } from '../../model/types';

import classes from './InstrumentDividends.module.css';

const columns: InstrumentTableHeaderItem[] = [
  {
    title: 'Дата выплаты',
    width: 40,
  },
  {
    title: 'Доходность',
    width: 20,
    align: 'right',
  },
  {
    title: 'Размер купона',
    width: 30,
    align: 'right',
  },
  {
    title: '',
    width: 10,
    align: 'right',
  },
];

interface Props {
  isin?: string;
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
}

export const InstrumentCoupons = ({ isin, fullFI, finInfoExt }: Props) => {
  const { useCoupons } = useWidgetContext();
  const { data: couponsData, isLoading } = useCoupons(isin, fullFI.idObject);
  let { paidCoupons, comingCoupons } = couponsData ?? {};

  paidCoupons = paidCoupons || [];
  comingCoupons = comingCoupons || [];

  const [firstCouponDate, lastCouponDate] = useMemo(() => {
    const paid = [...paidCoupons].reverse();
    const all = [...paid, ...comingCoupons!];

    if (all.length === 0) {
      return [null, null];
    }

    const [first, last] = [all[0], all[all.length - 1]];

    return [new Date(first?.date), new Date(last?.date)];
  }, [paidCoupons, comingCoupons]);
  const position = usePositionByIdFI(fullFI.idFI);

  if (isLoading) {
    return <InstrumentLoader />;
  }

  if (!couponsData) {
    return <InstrumentError type="coupons" />;
  }

  const hasComingCoupons = (comingCoupons ?? []).length > 0;
  const [comingCoupon] = comingCoupons;
  const [paidCount, comingCount] = [paidCoupons.length, comingCoupons.length];
  const progress = (paidCount / (paidCount + comingCount)) * 100;
  const sortedData =
    couponsData?.paidCoupons
      ?.slice()
      .sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      .map(({ date, rate, amount: { currency, value, minorUnits } }) => [
        format(new Date(date), 'dd.M.yyy', { locale: ru }),
        `${rate} %`,
        <Amount.Pure
          key={`amount-${date}`}
          value={getCouponWithMinority(value, minorUnits)}
          currency={isValidCurrency(currency) ? currency : undefined}
          minority={MINORITY}
          view="withZeroMinorPart"
        />,
        <CheckmarkOnCircleSIcon
          key={`check-${date}`}
          color={
            new Date(Date.now()).getTime() - new Date(date).getTime() > 0
              ? 'var(--color-light-text-positive)'
              : undefined
          }
        />,
      ]) ?? [];

  return (
    <>
      <Tabs containerClassName={classes.tabs} view="secondary" size="xxs">
        {[<Tab key="coupons" id="coupons" title="Купоны" />]}
      </Tabs>
      {hasComingCoupons && (
        <NextCouponsPaymentBlock
          coupons={couponsData}
          fullFI={fullFI}
          finInfoExt={finInfoExt}
        />
      )}
      {position?.backPos && (
        <div className={classes.positionCount}>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
            className={classes.positionTitle}
          >
            В портфеле
          </Typography.TitleMobile>
          <InstrumentDataRow
            title="Количество"
            value={<>{position?.backPos} шт</>}
          />
          {hasComingCoupons && (
            <InstrumentDataRow
              title="Ближайшее начисление"
              value={
                <Amount.Pure
                  value={
                    getCouponWithMinority(
                      comingCoupon.amount.value,
                      comingCoupon.amount.minorUnits
                    ) * position.backPos
                  }
                  currency={comingCoupon.amount.currency as 'RUB'}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                />
              }
            />
          )}
        </div>
      )}
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.couponsTitle}
      >
        Календарь выплат
      </Typography.TitleMobile>
      <InstrumentDataRow
        className={classes.positionTitle}
        title="Выплачено купонов"
        value={
          <>
            {paidCount} из {paidCount + comingCount}
          </>
        }
      />
      <ProgressBar
        size="s"
        value={progress}
        view="accent"
        className={classes.progressBar}
      />
      <div className={classes.datesRow}>
        <Typography.Text view="secondary-large" color="secondary">
          {format(firstCouponDate || 0, 'dd MMMM yyy', { locale: ru })}
        </Typography.Text>
        <Typography.Text view="secondary-large" color="secondary">
          {format(lastCouponDate || 0, 'dd MMMM yyy', { locale: ru })}
        </Typography.Text>
      </div>
      <InstrumentsSimpleTable columns={columns} data={sortedData} />
    </>
  );
};
