import noop from 'lodash/noop';
import { useCallback } from 'react';

import {
  ANALYTICS,
  DIMENSIONS_SCHEMAS,
} from '@terminal/core/constants/analytics';
import {
  LimitsDisplayMap,
  NPUDisplayMap,
} from '@terminal/core/constants/tradeLimits';
import { TradingLimitsShowMode } from '@terminal/core/types/layout';

import { AnalyticsTrack, useWidgetContext } from '../../../shared';

interface HybridOrderSettings {
  hideActiveOrders?: boolean;
  showPosition: boolean;
  showLimits: TradingLimitsShowMode;
  showNPU: TradingLimitsShowMode;
}

export const useHybridOrderAnalyticsMetrics = () => {
  const {
    analytics: { track = noop as AnalyticsTrack } = {
      track: noop as AnalyticsTrack,
    },
  } = useWidgetContext();

  return {
    trackHybridOrderSettings: useCallback(
      ({
        hideActiveOrders,
        showPosition,
        showLimits,
        showNPU,
      }: HybridOrderSettings) => {
        track(
          ANALYTICS.CATEGORY.TRADING_WIDGET,
          ANALYTICS.ACTION.IMPRESSION,
          ANALYTICS.LABEL.TRADING,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                1: hideActiveOrders ? 'Выкл' : 'Вкл', // Положение тоггла по отображению активных заявок в настройках пользователя
                2: showPosition ? 'Вкл' : 'Выкл', // Положение тоггла по отображению позиции в информационной стироке в настройках пользователя
                3: showLimits > 0 ? 'Вкл' : 'Выкл', // Положение тоггла по отображению лимитов в информационной стироке в настройках пользователя
                4: LimitsDisplayMap[showLimits], // Настройки отображения лимитов в информационной строке
                5: showNPU > 0 ? 'Вкл' : 'Выкл', // Положение тоггла по отображению результата в информационной стироке в настройках пользователя
                6: NPUDisplayMap[showNPU], // Настройки отображения результатов в информационной строке
              },
            },
          ]
        );
      },
      [track]
    ),
  };
};
