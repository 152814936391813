import { useQuery } from 'react-query';

import { checkTransferLimits } from '@terminal/core/lib/rest/investApi';

export const useCheckTransferLimits = (
  source: string,
  destination: string,
  isPairChecked: boolean
) => {
  return useQuery({
    queryKey: ['lk', 'transfer', 'checkTransferLimits', source, destination],
    queryFn: () => checkTransferLimits(source, destination),
    enabled: Boolean(source) && Boolean(destination) && isPairChecked,
  });
};
