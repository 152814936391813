import { Operation } from '../lib/client/entities';

export interface FinInfo {
  idFI: number;
  idTradePeriodStatus: TradePeriodType;
  last: number;
  lastQty: number;
  lastTime: Date;
  bid: number;
  ask: number;
  yield: number;
  duration: number;
  bidQty: number;
  askQty: number;
  sumBid: number;
  sumAsk: number;
  numBids: number;
  numAsks: number;
  high: number;
  low: number;
  wAPrice: number;
  yieldWAPrice: number;
  numTrades: BigInt;
  volToday: BigInt;
  valToday: BigInt | number;
  pos: number;
  highBid: number;
  lowOffer: number;
  quote: number;
  quoteTime: Date;
  exTime: Date;
  idSession: number;
  idBoard: number;
  idGate: number;
  revision: BigInt;
  flags: number;
  minCurLast: number;
  open: number;
  prevCloseYield: number;
  prevPos: number;
  prevWAPrice: number;
}

export interface FinInfoExt {
  idFI: number;
  idGate: number;
  idMarket: number;
  idBoard: number;
  idTradePeriodStatus: TradePeriodType;
  idAllowOrderStatus: number;
  idSession: number;
  sessionDate: Date;
  lot: number;
  priceStep: number;
  priceStepCost: number;
  idObjectCurrency: number;
  rate: number;
  PSTNKD: number;
  upPrice: number;
  downPrice: number;
  gtBuy: number;
  gtSell: number;
  auctActivationDate: Date;
  counterPrice: number;
  prevSessionId: number;
  prevSessionDate: Date;
  prevQuote: number;
  prevLastDate: Date;
  prevLast: number;
  nextCoupon: Date;
  buyBackDate: Date;
  couponPeriod: number;
  duration: number;
  accruedInt: number;
  couponValue: number;
  closeYield: number;
  yieldAtPrevWaPrice: number;
  version: BigInt;
  operation: Operation;
  fairPrice: number;
  prevFairPrice: number;
  prevFairPriceDate: Date;
  icebergMinQuantity: number;
  icebergMinOpenQuantity: number;
  agentCode?: string;
}

// Тип сделки
export enum TradeType {
  XZ = 0, // Несистемная биржевая
  AN = 1, // Сделка системная: биржевая анонимная, в т.ч сделка  с биржевым «производным» контрактом (FWD, РЕПО, СВОП, БК)
  RPS = 2, // Сделка системная:  биржевая с контрагентом ( в режиме РПС)
  IPO = 3, // Сделка системная: в связи с Первичным  размещением
  OTC = 4, // Сделка несистемная: внебиржевая
}

// None, UnP и NegAu - Статусы при которых нельзя сказать однозначно идут ли торги
export enum TradePeriodType {
  None = 0, // 0 - N/A. Зарезервированный статус, означающий неопределённость
  Bloc = 1, // 1 - Сессия завершена. Торги не проводятся. (Торги по инструменту остановлены)
  Clrng = 2, // 2 - Клиринг и/или Исполнение обязательств по сделкам. Торги не проводятся, но разрешено  исполнение сделок
  Opng = 3, // 3 - Открытие. Период открытия. (Сессия назначена)
  Clsng = 4, // 4 - Закрытие Период закрытия
  Break = 5, // 5 - Перерыв торговой сессии. Торги приостановлены, но будут продолжены
  Trdng = 6, // 6 - Торговая сессия (разрешены все операции)
  Evng = 7, // 7 - Дополнительная вечерняя торговая сессия
  Mrng = 8, // 8 - Дополнительная утренняя торговая сессия
  Auct = 9, // 9 - Аукцион
  Attn = 10, // 10 - Внимание	Подготовка к старту новой (объявленной) сессии или ее возобновлению после остановки или перерыва
  ClsAu = 11, // 11 - ПА	Послеторговый аукцион. Сбор ордеров для участия в «послеторговом» аукционе  !!!Используется для маркировки  сделок, зарегистрированных по итогам послеторгового аукциона
  DPAu = 12, // 12 - АКП	Аукцион «крупных пакетов».  Сбор  ордеров для участия в аукционе «крупных пакетов»  !!!Используется для маркировки  сделок, зарегистрированных по итогам Аукциона «крупных пакетов».
  Stop = 13, // 13 - Остановка	Внеплановый краткосрочный перерыв торговой сессии (в т.ч. по требованию Регулятора)
  Closed = 14, // 14 - Завершена	Перерыв между торговыми сессиями
  Trnsf = 15, // 15 - Трансфер	Специальный период. Разрешены подача / отзыв ордеров только на сделки между клиентами Участника Рынка
  Exp = 16, // 16 - Экспирация	Специальный период. Разрешены подача / отзыв ордеров только на востребование прав по опционам.
  Price = 17, // 17 - Цена	Специальный период в котором производится расчет цены аукциона
  UnP = 18, // 18 - Неизвестно	Неопознанный период/статус сессии.
  NegAu = 19, // 19 - Не понятно, что за статус, но как сказали коллеги, считаем, что торги идут
}
