import {
  NetValuePoint,
  PortfolioValueResult,
} from '@terminal/core/lib/rest/investApi';

import { CHARTS_DOTS_COUNT } from './const';

export const thinPortfolioGraph = (
  graph?: PortfolioValueResult
): NetValuePoint[] => {
  if (!graph) {
    return [];
  }

  if (graph.netValueGraph.length <= CHARTS_DOTS_COUNT) {
    return graph.netValueGraph;
  }

  // Всегда берем первую точку за интервал
  const reducedArr = [graph.netValueGraph[0]];

  const step = (graph.netValueGraph.length - 2) / (CHARTS_DOTS_COUNT - 2);

  for (let i = 1; i < CHARTS_DOTS_COUNT - 1; i++) {
    const index = Math.floor(i * step) + 1;

    if (index < graph.netValueGraph.length - 1) {
      reducedArr.push(graph.netValueGraph[index]);
    }
  }

  // Всегда берем последнюю точку за интервал
  reducedArr.push(graph.netValueGraph[graph.netValueGraph.length - 1]);

  return reducedArr;
};
