import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { getOperationsHistory, InvestQueryKeys } from '../lib/rest/investApi';
import { USE_PASSPORT } from '../lib/rest/lkApi';
import { operationHistoryMap } from '../mapping/operationMapping';
import { useObjectByIdMap } from './domain';
import { AssetType } from './useInfiniteOperationsHistory';
import useNotification from './useNotification';

import { MarketBoardItem, ObjectItem } from '../types/core';
import {
  OperationHistoryItem,
  OperationHistoryResult,
  OperationHistoryType,
} from '../types/operation';
import { NotificationType } from '../types/ui';

//TODO: Дописать по необходимости
//http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=679648143
export interface OperationsHistoryParams {
  subAccount: string; //456454-000
  operationTypes?: OperationHistoryType[];
  dateFrom?: string; //UTC Date - 2022-01-26T05:34:20.680Z
  dateTo?: string; //UTC Date - 2022-02-26T05:34:20.680Z
  assetIds?: string[];
}

export interface HistoryOperation extends OperationHistoryItem {
  object?: ObjectItem;
  marker?: MarketBoardItem;
}

export const useOperationsHistory = (params: OperationsHistoryParams) => {
  const { marketBoardsTable } = useAlfaDirectContext();
  const addNotification = useNotification();
  const objectByIdMap = useObjectByIdMap();
  const transformOperations = useCallback(
    (data: OperationHistoryResult[]): HistoryOperation[] =>
      data.map((operation) => {
        const parsedObject = operationHistoryMap(operation);
        const object = parsedObject.operation.objectId
          ? objectByIdMap.get(parsedObject.operation.objectId)
          : undefined;

        const market = parsedObject.operation.marketId
          ? marketBoardsTable.get(
              'universalMarketCode',
              parsedObject.operation.marketId
            )
          : undefined;

        return {
          ...parsedObject,
          object,
          market,
        };
      }),
    [marketBoardsTable, objectByIdMap]
  );

  return useQuery(
    [InvestQueryKeys.OperationsHistory, params],
    () => {
      const data = {
        ...params,
        assetIdType: params.assetIds
          ? AssetType.SYMBOL_OBJECT
          : AssetType.ID_OBJECT,
        maxElements: 100,
        operationTypes: params.operationTypes?.join(','),
      } as const;

      return getOperationsHistory(data);
    },
    {
      enabled: USE_PASSPORT,
      select: ({ operations }) => transformOperations(operations),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
    }
  );
};
