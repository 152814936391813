import isNull from 'lodash/isNull';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { useThrottledCallback } from 'use-debounce';

import { DEFAULT_LINES, MIN_LINES_LENGTH } from '../../constants/orderBook';
import {
  OrderBookEntity,
  OrderBookWithYieldEntity,
  OrderDirection,
  OrderType,
} from '../../lib/client/entities';
import { getOrderKeyPrice } from '../../lib/formulas';
import { getOrderBookTableLines } from '../../lib/orderBook/getOrderBookTableLines';
import { OrderBookService } from '../../lib/services/orderbook';
import { SubscribeReturnData } from '../../lib/services/streaming';

import { OrderItem } from '../../types/order';
import { OrderBookLine } from '../../types/orderBook';
import { DefaultQuantity } from '../../types/quantity';
import { FinInfoExt } from '../../types/quotes';

const UPDATER_INTERVAL = 1000;

const DEFAULT_SPREAD_INDEX = 20;

/**
 * Оставлено для того, чтобы не менять импорты по всему приложению
 * @todo удалить ре-экспорт типа, использовать напрямую из @terminal/core/types/orderbook
 * */
export type { OrderBookLine } from '../../types/orderBook';

type Options = {
  finInfoExt?: FinInfoExt;
  showSpread?: boolean;
  showEmptyPrice?: boolean;
  orderBookService?: OrderBookService;
};

interface State {
  lines: OrderBookLine[];
  upSpreadIndex: number;
  downSpreadIndex: number;
  centerSpreadIndex: number;
}

/*
Сервис получает линии стакана. Пример использования:

  const service = new OrderbookService();
  service.on('update', (lines: OrderBookLines[]) => {
    // do stuff;
  });
  const subsbcription = service.subscribe(14507);
  // отписка по окончанию работы
  servie.unsubscribe(subscription);

  Важно знать что есть два типа ордербуков, котрые немного по-разному работают
*/

//Заполняем стакан данными
export const useOrderBook = (idFi: number, options: Options = {}) => {
  const { showSpread, showEmptyPrice, finInfoExt, orderBookService } = options;

  const [orderBook, setOrderBook] = useState<State>(() => ({
    lines: orderBookService?.getInitialData(idFi) ?? [],
    upSpreadIndex: DEFAULT_SPREAD_INDEX,
    downSpreadIndex: DEFAULT_SPREAD_INDEX,
    centerSpreadIndex: DEFAULT_SPREAD_INDEX,
  }));

  // Подписывается на данные
  useEffect(() => {
    if (idFi) {
      let subscribeReturnData:
        | SubscribeReturnData<OrderBookEntity | OrderBookWithYieldEntity>
        | undefined = orderBookService?.subscribe(idFi);

      return () => {
        if (subscribeReturnData) {
          orderBookService?.unsubscribe(idFi, subscribeReturnData);
        }
      };
    }
  }, [idFi, orderBookService]);

  const clear = useCallback(
    (idFiToClear: number) => {
      if (idFiToClear === idFi) {
        setOrderBook({
          lines: DEFAULT_LINES,
          upSpreadIndex: DEFAULT_SPREAD_INDEX,
          downSpreadIndex: DEFAULT_SPREAD_INDEX,
          centerSpreadIndex: DEFAULT_SPREAD_INDEX,
        });
      }
    },
    [idFi]
  );

  const updater = useThrottledCallback(
    ({
      idFI,
      lines: serverLines,
    }: {
      idFI: number;
      lines: OrderBookLine[];
    }) => {
      if (idFi === idFI) {
        const { lines, upSpreadIndex, downSpreadIndex } =
          getOrderBookTableLines({
            serverLines,
            finInfoExt,
            MIN_LINES_LENGTH,
            DEFAULT_SPREAD_INDEX,
            isShowEmptyPrice: showEmptyPrice,
            isShowSpread: showSpread,
          });

        let newSpreadIndices;

        if (
          orderBook.upSpreadIndex !== upSpreadIndex ||
          orderBook.downSpreadIndex !== downSpreadIndex
        ) {
          const centerSpreadIndex = Math.ceil(
            (downSpreadIndex + upSpreadIndex) / 2
          );

          newSpreadIndices = {
            upSpreadIndex,
            downSpreadIndex,
            centerSpreadIndex,
          };
        }

        setOrderBook((prev) => ({
          ...prev,
          lines,
          ...newSpreadIndices,
        }));
      }
    },
    UPDATER_INTERVAL
  );

  //Сбрасываем данные при смене инструмента
  useEffect(() => {
    setOrderBook((prev) => ({
      ...prev,
      lines: DEFAULT_LINES,
    }));
    updater.cancel();
  }, [idFi, updater]);

  //Обновляем строчки
  useEffect(() => {
    orderBookService?.on('update', updater);
    orderBookService?.on('clear', clear);

    return () => {
      orderBookService?.off('update', updater);
      orderBookService?.off('clear', clear);
    };
  }, [clear, orderBookService, updater]);

  return orderBook;
};

export const useOrderBookScroll = (
  idFi: number,
  centerSpreadIndex: number,
  lines: OrderBookLine[],
  autoCenter: boolean,
  nodeId?: string
) => {
  const listRef = useRef<FixedSizeList<OrderBookLine>>(null);
  const isMount = useRef(false);

  useEffect(() => {
    isMount.current = false;
  }, [idFi]);

  const isScrollReady =
    !isNull(listRef.current) && lines.some(({ Price }) => Boolean(Price));

  const scrollToCenter = useCallback(() => {
    if (typeof listRef.current?.scrollToItem === 'function' && isScrollReady) {
      const centerRow = document.getElementById(
        `${nodeId}-${centerSpreadIndex}`
      );

      if (centerRow) {
        centerRow.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else {
        listRef.current?.scrollToItem(centerSpreadIndex, 'center');
      }
    }
  }, [centerSpreadIndex, isScrollReady, nodeId]);

  //Скроллим при маунте к центру таблицы
  useEffect(() => {
    if (!isMount.current && !autoCenter && isScrollReady) {
      scrollToCenter();
      isMount.current = true;
    }
  }, [autoCenter, isScrollReady, scrollToCenter]);

  return {
    listRef,
    scrollToCenter,
  };
};

export const useOrderBookForm = (
  idFi: number,
  lines: OrderBookLine[],
  setPrice: (newPrice: number) => void,
  isLotCount?: boolean,
  finInfoExt?: FinInfoExt,
  defaultQuantity?: DefaultQuantity
) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setPrice(0);

    if (finInfoExt && !isLotCount) {
      setQuantity(defaultQuantity?.quantity ?? finInfoExt.lot);
    } else {
      setQuantity(defaultQuantity?.quantity ?? 1);
    }
  }, [defaultQuantity?.quantity, finInfoExt, idFi, isLotCount, setPrice]);

  const bestBid = useMemo(
    () =>
      maxBy(
        lines.filter((line) => line.BuyQty > 0),
        'Price'
      )?.Price || 0,
    [lines]
  );

  const bestAsk = useMemo(
    () =>
      minBy(
        lines.filter((line) => line.SellQty > 0),
        'Price'
      )?.Price || 0,
    [lines]
  );

  return {
    quantity,
    setQuantity,
    bestBid,
    bestAsk,
  };
};

// Отображаем заявки только с  этим типом https://jira.moscow.alfaintra.net/browse/ADIRWEB-1488
const ordersTypeFilter = [
  OrderType.LMT,
  OrderType.STL,
  OrderType.STP,
  OrderType.BRS,
  OrderType.BSL,
  OrderType.TRS,
  OrderType.TSL,
  OrderType.TBRS,
  OrderType.TRL,
];

export const useOrderBookOrders = (
  activeOrders: OrderItem[],
  lines: OrderBookLine[],
  upSpreadIndex: number,
  downSpreadIndex: number
) => {
  const upSpreadPrice = useMemo(
    () => lines[upSpreadIndex]?.Price,
    [lines, upSpreadIndex]
  );

  const downSpreadPrice = useMemo(
    () => lines[downSpreadIndex]?.Price,
    [lines, downSpreadIndex]
  );

  const filteredActiveOrders = useMemo(
    () =>
      activeOrders.filter(({ idOrderType }) =>
        ordersTypeFilter.includes(idOrderType)
      ),
    [activeOrders]
  );

  const upperOrders = useMemo(() => {
    if (upSpreadPrice) {
      return filteredActiveOrders.filter(
        (order) => order.price > upSpreadPrice
      );
    } else {
      return filteredActiveOrders;
    }
  }, [filteredActiveOrders, upSpreadPrice]);

  const lowerOrders = useMemo(() => {
    if (downSpreadPrice) {
      return filteredActiveOrders.filter(
        (order) => order.price < downSpreadPrice
      );
    }
  }, [filteredActiveOrders, downSpreadPrice]);

  const getPriceMap = useCallback(
    (orders: OrderItem[]) =>
      orders.reduce((acc, order) => {
        //Если заявка парная
        if ([OrderType.BRS, OrderType.BSL].includes(order.idOrderType)) {
          const stopPriceKey = String(order.stopPrice);
          const limitPriceKey = String(order.limitPrice);

          return {
            ...acc,
            [stopPriceKey]: [...(acc[stopPriceKey] ?? []), order],
            [limitPriceKey]: [...(acc[limitPriceKey] ?? []), order],
          };
        } else {
          //Определяем цену для отображения
          const priceType = getOrderKeyPrice(order);
          const priceKey = String(order[priceType]);

          return {
            ...acc,
            [priceKey]: [...(acc[priceKey] ?? []), order],
          };
        }
      }, {} as Record<string, OrderItem[]>),
    []
  );

  const buyOrdersMap = useMemo(
    () =>
      getPriceMap(
        filteredActiveOrders.filter((o) => o.buySell === OrderDirection.Buy)
      ),
    [filteredActiveOrders, getPriceMap]
  );

  const sellOrdersMap = useMemo(
    () =>
      getPriceMap(
        filteredActiveOrders.filter((o) => o.buySell === OrderDirection.Sell)
      ),
    [filteredActiveOrders, getPriceMap]
  );

  const totalBuyQty = useMemo(() => sumBy(lines, 'BuyQty'), [lines]);
  const totalSellQty = useMemo(() => sumBy(lines, 'SellQty'), [lines]);

  return {
    upperOrders,
    upSpreadPrice,
    downSpreadPrice,
    lowerOrders,
    buyOrdersMap,
    sellOrdersMap,
    totalBuyQty,
    totalSellQty,
  };
};
