import React, { FC, ReactNode, SVGProps } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './PortfolioAnalyticsWarningContainer.module.css';

interface Props {
  title: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export const PortfolioAnalyticsWarningContainer: FC<Props> = ({
  title,
  description,
  actions,
  Icon,
}) => {
  return (
    <div>
      <Gap size="4xl" />

      <div className={styles.container}>
        <SuperEllipse size={48}>
          <Icon
            width={24}
            height={24}
            color="var(--color-light-graphic-secondary)"
          />
        </SuperEllipse>

        <Gap size="m" />

        <Typography.Text
          view="secondary-large"
          weight="bold"
          className={styles.title}
        >
          {title}
        </Typography.Text>

        <Gap size="2xs" />

        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.description}
        >
          {description}
        </Typography.Text>

        <Gap size="s" />

        {actions}
      </div>
    </div>
  );
};
