import cn from 'classnames';
import { FC } from 'react';
import { Gap } from '@alfalab/core-components/gap';

import { BackButton } from '../BackButton';

import { DrawerParams } from '../../model/types';

import styles from './Drawer.module.css';

interface Props extends DrawerParams {
  onClose: () => void;
}

export const Drawer: FC<Props> = ({ isOpen, children, onClose }) => {
  return (
    <div
      className={cn(styles.drawer, {
        [styles.open]: isOpen,
      })}
    >
      <div className={styles.backButton}>
        <BackButton onClick={onClose} />
      </div>
      <Gap size="s" />

      <div className={styles.body}>{children}</div>
    </div>
  );
};
