/* eslint-disable simple-import-sort/imports */
// Тут порядок импортов важен для запуска приложения
import { addSeconds } from 'date-fns';
import { useCallback, useEffect, useRef } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { shallow, useStore } from '@terminal/core/store';
import {
  analyticsUserInit,
  initAnalytics,
  trackAuth,
} from '@terminal/core/lib/analytics';
import { ActivitySpinner } from '@terminal/common/components/ActivitySpinner';
import { Workspace } from '@terminal/desktop/src/legacy/screens/Workspace';
import { renderLoginRoutes } from '@terminal/lk/legacy/login/Router';
import { wrapWithComponents } from '@terminal/core/lib/react/wrapWithComponents';

import { useInitApp } from '@terminal/core/hooks';
import { useTrackPriceSubscription } from '@terminal/core/lib/services/trackPrice/hooks/useTrackPriceSubscription';
import { useTechMetrics } from '@terminal/core/hooks/useTechMetrics';

import { AppState } from '@terminal/core/types/app';
import {
  LK_LOGOUT_TIMEOUT,
  DEV_APP_AUTOLOGIN,
  USE_PASSPORT_AUTH,
  DEV_APP_DEFAULT_USER_LOGIN,
  DEV_APP_DEFAULT_USER_PASSWORD,
  ENABLE_LK_FEATURES,
  DEV_IS_DEBUG_REACT_QUERY,
  TRADE_SERVICE_DEVELOPER_CODE,
  F1_URI,
  F2_URI,
  F3_URI,
  F4_URI,
  F31_URI,
} from '@terminal/core/env';
import { AppContext } from '@terminal/core/hooks/useAppContext';

import { SoundManagerProvider } from '@terminal/desktop/src/app/providers/SoundManagerProvider';
import { QueryClientProvider } from '@terminal/desktop/src/app/providers/QueryClientProvider';
import { ThemeProvider } from '@terminal/desktop/src/app/providers/ThemeProvider';

import initClient from '@terminal/core/lib/client/init';

import { BadBrowserLock } from '@terminal/desktop/src/app/ui/BadBrowserLock';
import { ErrorBoundary } from '@terminal/desktop/src/app/ui/ErrorBoundary';
import { RenderCountProfiler } from '@terminal/desktop/src/app/ui/RenderCountProfiler';
import { setAuthInfo } from '@terminal/core/lib/analytics/performance';

import { AppProvider } from './providers/AppProvider';

import './initCoreComponents';

import 'chartiq/css/stx-chart.css';
import 'chartiq/css/chartiq.css';
import { Theme } from '../shared/lib/themeContext';

let nextChildWindowWasOpen = false;

// инициализируем торговые сервисы
initClient({
  allowMultipleSessions: true,
  developerCode: TRADE_SERVICE_DEVELOPER_CODE,
  feURI: { FE1: F1_URI, FE2: F2_URI, FE3: F3_URI, FE4: F4_URI, FE31: F31_URI },
});

interface Props {
  config: AppContext;
  darkStyles: string;
}

function App({ config, darkStyles }: Props): JSX.Element {
  let countDownDate = useRef<Date>(addSeconds(new Date(), LK_LOGOUT_TIMEOUT));
  const [user, clientState, connect, logout, reopenOtherWorkspaceWindows] =
    useStore(
      (state) => [
        // eslint-disable-next-line no-restricted-syntax
        state.user,
        state.appState,
        state.loginWithPassword,
        state.logout,
        state.reopenOtherWorkspaceWindows,
      ],
      shallow
    );

  useInitApp();
  useTrackPriceSubscription();

  // Открываем дочерние окна
  // @see reopenWorkspaceWindows
  // and
  // @see reopenOtherWorkspaceWindows
  useEffect(() => {
    if (!nextChildWindowWasOpen) {
      setTimeout(() => {
        reopenOtherWorkspaceWindows();
      }, 100);
    }

    nextChildWindowWasOpen = true;
  }, [reopenOtherWorkspaceWindows]);

  useTechMetrics(clientState);

  useEffect(() => {
    if (DEV_APP_AUTOLOGIN && !USE_PASSPORT_AUTH) {
      connect(
        DEV_APP_DEFAULT_USER_LOGIN as string,
        DEV_APP_DEFAULT_USER_PASSWORD as string
      );
    }
  }, [connect]);

  useEffect(() => {
    initAnalytics(config.analytics);
  }, [config.analytics]);

  useEffect(() => {
    if (user) {
      analyticsUserInit(user);
      setAuthInfo('login', user.cus || user.login);
    }
  }, [user]);

  useEffect(() => {
    if (clientState === AppState.READY && user) {
      trackAuth();
    }
  }, [user, clientState]);

  const checkTimeout = useCallback(() => {
    if (countDownDate.current.getTime() - new Date().getTime() < 0) {
      logout();
    }
  }, [countDownDate, logout]);

  useEffect(() => {
    if (
      clientState === AppState.READY &&
      user &&
      LK_LOGOUT_TIMEOUT &&
      ENABLE_LK_FEATURES
    ) {
      if (LK_LOGOUT_TIMEOUT) {
        countDownDate.current = addSeconds(new Date(), LK_LOGOUT_TIMEOUT);

        const logoutInterval = setInterval(checkTimeout, 1000);

        return () => clearInterval(logoutInterval);
      }
    }
  }, [checkTimeout, clientState, logout, user]);

  const renderScreen = ({
    appLogo,
    copyright,
    theme,
  }: {
    appLogo: JSX.Element | null;
    copyright: string;
    theme: Theme;
  }) => {
    // Запоминаем путь, чтобы после логина вернуться на этот-же URL
    const { pathname, search } = window.location;

    switch (clientState) {
      case AppState.READY:
        if (user) {
          return (
            <Routes>
              <Route path="*" element={<Workspace user={user} />} />
            </Routes>
          );
        } else {
          return null;
        }
      case AppState.LOADING_DICTIONARY:
        return <ActivitySpinner />;
      default:
        return (
          <Routes>
            <Route path="/money-in-result/complete" element={null} />
            {renderLoginRoutes('pro', appLogo, copyright, theme)}
            <Route
              path="*"
              element={
                <Navigate
                  to="/"
                  replace
                  state={{
                    redirect: pathname,
                    search,
                  }}
                />
              }
            />
          </Routes>
        );
    }
  };

  return (
    <>
      <AppProvider config={config}>
        <ThemeProvider darkStyles={darkStyles}>
          {(theme) => {
            const screen = renderScreen({
              appLogo: config.appLogo,
              copyright: config.copyright,
              theme,
            });

            return (
              <ErrorBoundary>
                {config.NotDesktopPlatformLock ? (
                  <config.NotDesktopPlatformLock>
                    {screen}
                  </config.NotDesktopPlatformLock>
                ) : (
                  screen
                )}
              </ErrorBoundary>
            );
          }}
        </ThemeProvider>
      </AppProvider>
      {DEV_IS_DEBUG_REACT_QUERY && <ReactQueryDevtools initialIsOpen={false} />}
    </>
  );
}

export default wrapWithComponents([
  RenderCountProfiler,
  BadBrowserLock,
  QueryClientProvider,
  SoundManagerProvider,
  BrowserRouter,
])(App);
