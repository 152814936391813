import { useCallback } from 'react';

import {
  createNotification,
  CreateNotificationParams,
} from '../lib/notifications';

import { useStore } from '../store';

export default function useNotification() {
  const addNotification = useStore((state) => state.addNotification);

  return useCallback(
    (params: CreateNotificationParams) => {
      const notification = createNotification(params);

      addNotification(notification);
    },
    [addNotification]
  );
}
