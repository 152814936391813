import { Textarea } from '@alfalab/core-components/textarea';

interface ReceiveByMailProps {
  address?: string;
  hint?: string;
}

export const ReceiveByMail = ({ address, hint }: ReceiveByMailProps) => {
  return (
    <>
      <Textarea
        label="Адрес"
        labelView="outer"
        block
        value={address}
        disabled={true}
        hint={hint}
      />
    </>
  );
};
