import React, { FC, useCallback, useState } from 'react';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { SelectTreaty } from '@terminal/lk-desktop-go';

import { isFeatureHidden, useFeatureSettings } from '../../shared';
import { MoneyInMenu } from './MoneyInMenu';
import { MoneyInCards } from './tabs/MoneyInCards';
import { MoneyRequisites } from './tabs/MoneyRequisites';

import { useInit } from '../hooks';

import styles from './MoneyIn.module.css';

type MoneyInSubpageType = 'in' | 'xpay' | 'requisites';

interface Props {
  defaultSelectedAccountId?: number;
  defaultTopUpSum?: number;
}

export const MoneyIn: FC<Props> = React.memo(
  ({ defaultSelectedAccountId, defaultTopUpSum }) => {
    const [subPage, setSubPage] = useState<MoneyInSubpageType>('in');
    const [treaty, setTreaty] = useState<Treaty | null>(null);

    const { data: init } = useInit();

    const { data: featureSettings } = useFeatureSettings();

    const renderContent = () => {
      switch (subPage) {
        case 'in':
          return (
            <div className={styles.formControlWrapper}>
              <MoneyInCards treaty={treaty} defaultTopUpSum={defaultTopUpSum} />
            </div>
          );
        case 'requisites':
          return (
            <MoneyRequisites
              treaty={treaty}
              email={init?.email}
              emailConfirmed={init?.emailConfirmed}
            />
          );
      }
    };

    const treatyFilter = useCallback(
      (acc: Treaty) => Boolean(acc.isOwner) && !acc.isFirm && !acc.isMoneybox,
      []
    );

    const onSelect = useCallback((treaty: Treaty) => setTreaty(treaty), []);

    return (
      <div>
        <div className={styles.treaty}>
          <SelectTreaty
            label="Счёт пополнения"
            selectedAccountId={defaultSelectedAccountId}
            treatyFilter={treatyFilter}
            onSelect={onSelect}
          />
        </div>
        <div className={styles.menu}>
          <MoneyInMenu
            subPage={subPage}
            twoFactorEnabled={init?.twoFactorAuthenticationEnabled}
            accessCard={
              !isFeatureHidden(
                'access-to-card-transfer',
                featureSettings || []
              ) && !treaty?.isForeignMarket
            }
            accessXpay={!isFeatureHidden('money-xpay', featureSettings || [])}
            accessRequisites={
              !isFeatureHidden('money-requisites', featureSettings || [])
            }
            onItemSelect={(type) => setSubPage(type as MoneyInSubpageType)}
          />
        </div>
        <div>{renderContent()}</div>
      </div>
    );
  }
);
