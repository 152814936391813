import { useMutation, useQueryClient } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { deleteCloudConfig } from '../api/deleteCloudConfig';

export function useDeleteCloudConfigMutation() {
  const client = useQueryClient();
  const addNotification = useNotification();

  return useMutation({
    mutationFn: deleteCloudConfig,
    onSuccess: () => client.invalidateQueries([LkQueryKeys.CloudConfigList]),
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При удалении конфигурации произошла ошибка',
      });
    },
  });
}
