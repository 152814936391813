import { useQuery } from 'react-query';

import {
  getFinResultByAccount,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';

import { useWidgetContext } from '../../../shared';
import { FinResultByAccountResponse } from '../model/FinResultByAccount';
import { FinResultByAccountParams } from '../model/FinResultParams';

export const useFinResultByAccount = (params: FinResultByAccountParams) => {
  const { addNotification } = useWidgetContext();

  return useQuery<FinResultByAccountResponse>(
    [InvestQueryKeys.FinResultByAccount, params],
    () => getFinResultByAccount(params),
    {
      enabled: Boolean(params.accountId),
      onError: (error: any) =>
        addNotification({
          id: new Date().toString(),
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
