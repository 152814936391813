import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns дневной объем на продажу
 * @param positionItem элемент в портфеле
 * */
export function getDailySellVolume(
  position: SubAccountPositionItem,
  objectType: ObjectTypeItem,
  TorgPos: number,
  BackPos: number,
  PrevPrice: number,
  Price: number | null,
  nominal: number,
  NominalCurPrice: number,
  MarketCurPrice: number,
  TrnOut: number,
  PriceStep: number,
  PriceStepCost: number
): number | null {
  if (position?.sessionSellVal !== 0) {
    return position?.sessionSellVal;
  }

  if (Price === null) {
    return null;
  }

  if (TorgPos >= BackPos - TrnOut) {
    return 0;
  }

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Futures: {
      return (
        ((BackPos - TrnOut - TorgPos) *
          (PrevPrice + Price) *
          (PriceStepCost / PriceStep)) /
        2
      );
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals: {
      return ((BackPos - TrnOut - TorgPos) * (PrevPrice + Price)) / 2;
    }
    case ObjectGroup.Bonds: {
      return (
        ((BackPos - TrnOut - TorgPos) *
          (PrevPrice + Price) *
          (nominal * NominalCurPrice)) /
        200
      );
    }
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts: {
      return (
        ((BackPos - TrnOut - TorgPos) * (PrevPrice + Price) * MarketCurPrice) /
        2
      );
    }

    default: {
      return 0;
    }
  }
}
