import {
  OrderType,
  PriceControlType,
  QuantityType,
} from '@terminal/core/lib/client/entities';
import {
  checkOrderType,
  getExecutionType,
  getOrderSendPrice,
} from '@terminal/core/lib/formulas';
import { PositionItem } from '@terminal/core/types/position';
import { SubGTAccountItem } from '@terminal/core/types/subAccount';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { LimitRequestParams, OrderRequest } from '@terminal/core/types/trading';

import { calculateLimits } from './calculateLimits';

const ORDER_TYPES_WITH_LAST_PRICE = [
  OrderType.STP,
  OrderType.TRS,
  OrderType.TRL,
  OrderType.BRS,
  OrderType.TBRS,
  OrderType.STL,
];

interface GetLimitsParams {
  requestParams: LimitRequestParams;
  selectedSubAccountId: number;
  computedPositions: PositionItem[];
  subGTAccounts: SubGTAccountItem[];
  warrantyBuy: number;
  warrantySell: number;
  tradingService: any;
  isEFRAccount: boolean;
  checkTradeLimits: (
    buy: boolean,
    price: number,
    params: OrderRequest
  ) => Promise<TradeLimitsResult>;
}

export const getLimits = async ({
  requestParams,
  selectedSubAccountId,
  computedPositions,
  subGTAccounts,
  warrantyBuy,
  warrantySell,
  tradingService,
  isEFRAccount,
  checkTradeLimits,
}: GetLimitsParams) => {
  const { idFi, orderType, lifeTime, price, secondPrice, openQuantity } =
    requestParams;

  const { limitPrice, stopPrice } = getOrderSendPrice(
    orderType,
    price,
    null,
    secondPrice
  );

  const inputPrice = limitPrice || stopPrice || 0;

  const idPriceControlType = ORDER_TYPES_WITH_LAST_PRICE.includes(orderType)
    ? PriceControlType.LAST
    : requestParams.idPriceControlType ?? PriceControlType.None;

  const checkTradeLimitsSell = (params: OrderRequest) =>
    checkTradeLimits(false, inputPrice, params);
  const checkTradeLimitsBuy = (params: OrderRequest) =>
    checkTradeLimits(true, inputPrice, params);

  const idOrderType = checkOrderType(orderType, secondPrice);

  const params = await tradingService.prepareRequestParams(
    idFi,
    selectedSubAccountId,
    getExecutionType(idOrderType, openQuantity),
    lifeTime,
    idOrderType,
    idPriceControlType,
    QuantityType.None
  );

  if (params) {
    //Отдельно сохраняем лимиты по покупке
    const tradeLimitsLong = await calculateLimits({
      checkTradeLimits: checkTradeLimitsBuy,
      params,
      isEFRAccount,
      subGTAccounts,
      positions: computedPositions,
      warranty: warrantyBuy,
      selectedSubAccountId,
    });

    //Отдельно сохраняем лимиты по продаже
    const tradeLimitsShort = await calculateLimits({
      checkTradeLimits: checkTradeLimitsSell,
      params,
      isEFRAccount,
      subGTAccounts,
      positions: computedPositions,
      warranty: warrantySell,
      selectedSubAccountId,
    });

    if (!tradeLimitsLong || !tradeLimitsShort) {
      return;
    }

    // Если маржа 0, подкидываем туда свои средства, чтобы пользоваться одним свойством
    return {
      tradeLimitsLong: {
        ...tradeLimitsLong,
        amount: tradeLimitsLong.amount || tradeLimitsLong.freeMoney,
        quantity:
          tradeLimitsLong.quantity || tradeLimitsLong.quantityForOwnAssets,
      },
      // Шорт не трогаем, без марж. торговли недоступен
      tradeLimitsShort,
      idFi,
      selectedSubAccountId,
    };
  }
};
