import qs from 'query-string';

import { dateToRequestFormat } from '@terminal/core/lib/rest/lkApi';
import { LK_API } from '@terminal/lk/legacy/const/externalUrls';
import { getReportType, ReportFormat, ReportType } from '@terminal/lk-core';

export function generateReportUrl(params: {
  report: ReportType;
  format: ReportFormat;
  start: string;
  end: string;
  treaty?: string;
  hideSections?: string;
  multiple?: boolean;
  inline?: boolean;
}) {
  const defaults = {
    treaty: '0',
    hideSections: '0',
    multiple: false,
  };
  const opt = {
    ...defaults,
    ...params,
    report: getReportType(params.report),
  };

  if (opt.format === ReportFormat.XmlImport) {
    const request = {
      treaty: opt.treaty,
      startDate: dateToRequestFormat(opt.start),
      endDate: dateToRequestFormat(opt.end),
      isMultiple: opt.multiple.toString(),
    };

    return qs.stringifyUrl({
      url: `${LK_API.REPORT_FILE_BASE}/broker-report`,
      query: request,
    });
  } else {
    let url = LK_API.REPORT_FILE_BASE + '/';

    if (opt.inline) {
      url += 'inline/';
    }

    url += opt.report + '/';
    url += dateToRequestFormat(opt.start) + '/';
    url += dateToRequestFormat(opt.end) + '/';
    url += opt.format + '/';
    url += opt.hideSections + '/';
    url += opt.treaty;

    if (opt.inline) {
      url += '?report.' + opt.format.toLowerCase();
    }

    return url;
  }
}
