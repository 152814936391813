import { Spinner } from '@alfalab/core-components/spinner';

import styles from './Loader.module.css';

export const Loader = () => {
  return (
    <div className={styles.root}>
      <Spinner visible size="m" />
    </div>
  );
};
