import cn from 'classnames';
import React, { FC, PropsWithChildren, useRef } from 'react';
import { useClickAway } from 'react-use';
import { Popover, PopoverProps } from '@alfalab/core-components/popover';

import styles from './FeatureOnboardingPopover.module.css';

interface Props
  extends PropsWithChildren<{
    isOpen: boolean;
    anchorElement: PopoverProps['anchorElement'];
    onClose: () => void;
    className?: string;
  }> {}

export const FeatureOnboardingPopover: FC<Props> = ({
  isOpen,
  anchorElement,
  children,
  onClose,
  className,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useClickAway(popoverRef, onClose);

  return (
    <Popover
      ref={popoverRef}
      preventOverflow={false}
      withArrow
      anchorElement={anchorElement}
      open={isOpen}
      offset={[0, 12]}
      position="top"
      popperClassName={cn(styles.popover, className)}
    >
      {children}
    </Popover>
  );
};
