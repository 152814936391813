import uniq from 'lodash/uniq';

import { useSubAccountRazdel } from './useSubAccountRazdel';

export const useAllSubAccountsIds = (
  accountId: number | number[]
): string[] => {
  const accountIds = Array.isArray(accountId) ? accountId : [accountId];

  const subAccountRazdels = useSubAccountRazdel();

  return uniq(
    subAccountRazdels
      .filter((subAccount) => accountIds.includes(subAccount.idAccount))
      .map((subAccount) => subAccount.codeSubAccount)
  );
};
