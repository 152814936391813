import cn from 'classnames';
import { ReactNode } from 'react';
import { CircularProgressBar } from '@alfalab/core-components/circular-progress-bar';
import { Gap } from '@alfalab/core-components/gap';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCircleMIcon } from '@alfalab/icons-glyph/ArrowDownCircleMIcon';
import { RocketMIcon } from '@alfalab/icons-glyph/RocketMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { plural } from '@terminal/core/lib/plural';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection } from '../../../../shared';
import { FinResultRates } from '../../model/FinResult';

import styles from './Rates.module.css';

interface Props {
  finResultRates?: FinResultRates;
  tooltip: ReactNode;
}

export const Rates = ({ finResultRates, tooltip }: Props) => {
  const { trackHoverPortfolioReportTooltip } = usePortfolioReportsMetrics();

  if (!finResultRates) {
    return null;
  }

  if (!finResultRates.tradesCount) {
    return null;
  }

  const {
    tradesCount,
    allLongCount,
    allShortCount,
    profitableTrades = {
      percentage: 0,
      count: 0,
      longCount: 0,
      shortCount: 0,
    },
    unprofitableTrades = {
      percentage: 0,
      count: 0,
      longCount: 0,
      shortCount: 0,
    },
    bestTrade,
    worstTrade,
    average,
  } = finResultRates;

  return (
    <PortfolioSection
      title="Показатели"
      tooltip={tooltip}
      onTooltipOpen={() => {
        trackHoverPortfolioReportTooltip('Показатели');
      }}
    >
      <Typography.Text
        tag="div"
        view="secondary-large"
        weight="bold"
        className={styles.tertiary}
      >
        Все сделки&nbsp;
        <Typography.Text
          tag="span"
          view="secondary-large"
          weight="bold"
          color="secondary"
        >
          {tradesCount}
        </Typography.Text>
      </Typography.Text>

      <Gap size="s" />

      <div>
        <div className={styles.flexBetween}>
          <Typography.Text
            tag="div"
            view="primary-medium"
            weight="medium"
            className={cn({
              [styles.positive]: profitableTrades.percentage > 0,
              [styles.neutral]: profitableTrades.percentage === 0,
            })}
          >
            {profitableTrades.percentage}%
          </Typography.Text>
          <Typography.Text
            tag="div"
            view="primary-medium"
            weight="medium"
            className={cn({
              [styles.negative]: unprofitableTrades.percentage > 0,
              [styles.neutral]: unprofitableTrades.percentage === 0,
            })}
          >
            {unprofitableTrades.percentage}%
          </Typography.Text>
        </div>
        <Gap size="xs" />
        <ProgressBar
          size="s"
          value={profitableTrades.percentage}
          className={styles.progressBar}
        />
        <Gap size="xs" />
        <div className={styles.flexBetween}>
          <Typography.Text tag="div" view="secondary-large" color="secondary">
            {profitableTrades.count || 'Нет'}&nbsp;
            {plural(
              ['прибыльная', 'прибыльных', 'прибыльных'],
              profitableTrades.count
            )}
          </Typography.Text>
          <Typography.Text tag="div" view="secondary-large" color="secondary">
            {unprofitableTrades.count || 'Нет'}&nbsp;
            {plural(
              ['убыточная', 'убыточных', 'убыточных'],
              unprofitableTrades.count
            )}
          </Typography.Text>
        </div>
      </div>

      {allLongCount > 0 ? (
        <>
          <Gap size="xl" />
          <CircularRatesSummary
            title="Лонг"
            length={allLongCount}
            profitable={profitableTrades.longCount}
            unprofitable={unprofitableTrades.longCount}
          />
        </>
      ) : null}

      {allShortCount > 0 ? (
        <>
          <Gap size="xl" />
          <CircularRatesSummary
            title="Шорт"
            length={allShortCount}
            profitable={profitableTrades.shortCount}
            unprofitable={unprofitableTrades.shortCount}
          />
        </>
      ) : null}

      <Gap size="3xl" />

      <div className={styles.bestWorstContainer}>
        <div className={styles.bestWorstCard}>
          <RocketMIcon
            width={16}
            height={16}
            color="var(--color-light-text-secondary)"
          />
          <Gap size="xs" />
          <Typography.Text
            tag="div"
            view="secondary-large"
            weight="bold"
            className={styles.tertiary}
          >
            {
              formatAmount({
                value: bestTrade * MINORITY,
                currency: 'RUR' as CurrencyCodes,
                minority: MINORITY,
                view: 'withZeroMinorPart',
              }).formattedWithCurrency
            }
          </Typography.Text>
          <Gap size="2xs" />
          <Typography.Text tag="div" view="secondary-small" color="secondary">
            Лучшая сделка
          </Typography.Text>
        </div>

        {worstTrade ? (
          <div className={styles.bestWorstCard}>
            <ArrowDownCircleMIcon
              width={16}
              height={16}
              color="var(--color-light-text-secondary)"
            />
            <Gap size="xs" />
            <Typography.Text
              tag="div"
              view="secondary-large"
              weight="bold"
              className={styles.tertiary}
            >
              {
                formatAmount({
                  value: worstTrade * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formattedWithCurrency
              }
            </Typography.Text>
            <Gap size="2xs" />
            <Typography.Text tag="div" view="secondary-small" color="secondary">
              Худшая сделка
            </Typography.Text>
          </div>
        ) : (
          <div />
        )}
      </div>

      {average ? (
        <>
          <Gap size="m" />

          <div className={styles.averageList}>
            {'profit' in average ? (
              <AverageItem
                title="Средняя прибыль"
                description="По прибыльным сделкам"
                sum={average.profit}
              />
            ) : null}

            {'loss' in average ? (
              <AverageItem
                title="Средний убыток"
                description="По убыточным сделкам"
                sum={-Math.abs(average.loss)}
              />
            ) : null}

            {'all' in average ? (
              <AverageItem
                title="Средний результат"
                description="По всем сделкам"
                sum={average.all}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </PortfolioSection>
  );
};

function CircularRatesSummary({ title, length, profitable, unprofitable }) {
  const profitablePercent = Math.round((profitable * 100) / length);
  const unprofitablePercent = 100 - profitablePercent;

  return (
    <div>
      <Typography.Text
        tag="div"
        view="secondary-large"
        weight="bold"
        className={styles.tertiary}
      >
        {title}&nbsp;
        <Typography.Text
          tag="span"
          view="secondary-large"
          weight="bold"
          color="secondary"
        >
          {length}
        </Typography.Text>
      </Typography.Text>

      <Gap size="s" />

      <div>
        <div className={styles.circularProgressContainer}>
          <CircularProgressBar
            value={profitablePercent}
            size="xs"
            progressStrokeColor="var(--color-light-status-positive-inverted)"
            strokeColor="var(--color-light-graphic-accent)"
          />

          <div className={styles.grow}>
            {profitablePercent > 0 ? (
              <Typography.Text
                tag="div"
                view="secondary-large"
                className={styles.tertiary}
              >
                {profitablePercent}%&nbsp;прибыльных
              </Typography.Text>
            ) : null}
            {unprofitablePercent > 0 ? (
              <>
                <Gap size="2xs" />
                <Typography.Text
                  tag="div"
                  view="secondary-large"
                  className={styles.tertiary}
                >
                  {unprofitablePercent}%&nbsp;убыточных
                </Typography.Text>
              </>
            ) : null}
          </div>

          <div>
            {profitable > 0 ? (
              <Typography.Text
                tag="div"
                view="secondary-large"
                className={styles.tertiary}
              >
                {profitable}&nbsp;
                {plural(['сделка', 'сделки', 'сделок'], profitable)}
              </Typography.Text>
            ) : null}
            {unprofitable > 0 ? (
              <>
                <Gap size="2xs" />
                <Typography.Text
                  tag="div"
                  view="secondary-large"
                  className={styles.tertiary}
                >
                  {unprofitable}&nbsp;
                  {plural(['сделка', 'сделки', 'сделок'], unprofitable)}
                </Typography.Text>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function AverageItem({ title, description, sum }) {
  return (
    <div>
      <div className={styles.flexBetween}>
        <Typography.Text
          tag="div"
          view="secondary-large"
          className={styles.tertiary}
        >
          {title}
        </Typography.Text>
        <Typography.Text
          tag="div"
          view="secondary-large"
          className={styles.tertiary}
        >
          {
            formatAmount({
              value: sum * MINORITY,
              currency: 'RUR' as CurrencyCodes,
              minority: MINORITY,
              view: 'withZeroMinorPart',
            }).formattedWithCurrency
          }
        </Typography.Text>
      </div>
      <Gap size="2xs" />
      <Typography.Text tag="div" view="secondary-small" color="secondary">
        {description}
      </Typography.Text>
    </div>
  );
}
