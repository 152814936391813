import React, { FC } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { DotMediumMIcon } from '@alfalab/icons-glyph/DotMediumMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';

import { SymbolCell } from '../../../SymbolCell';
import { ActGroupType } from '../../model/FinResult';
import { FinResultByEmitentResponse } from '../../model/FinResultByEmitent';

import styles from './Summary.module.css';

const DOT_COLORS = ['#7eb3ff', '#f6bf65', '#87b965'];

interface Props {
  finResult: FinResultByEmitentResponse['finResult'];
  actGroup: ActGroupType;
  currency: FinResultByEmitentResponse['currency'];
  idObject: FinResultByEmitentResponse['idObject'];
  symbolObject: FinResultByEmitentResponse['symbolObject'];
}

export const Summary: FC<Props> = ({
  finResult,
  actGroup,
  currency,
  idObject,
  symbolObject,
}) => {
  if (!finResult && !idObject) {
    return null;
  }

  const {
    total: {
      allOperations = 0,
      coupons = 0,
      dividends = 0,
      closedPositions = 0,
    } = {},
  } = finResult || { total: {} };

  return (
    <div>
      <div className={styles.summary}>
        <SymbolCell
          idObject={idObject}
          symbolObject={symbolObject}
          onlyIcon
          isDesktop
        />
        <Typography.Text
          view="primary-medium"
          weight="bold"
          tag="div"
          className={styles.tertiary}
        >
          {symbolObject}
        </Typography.Text>
        {'allOperations' in (finResult?.total || {}) ? (
          <Typography.Text
            view="primary-medium"
            weight="bold"
            className={styles.tertiary}
          >
            {allOperations > 0 ? '+' : '-'}
            {
              formatAmount({
                value: Math.abs(allOperations) * MINORITY,
                currency: currency as CurrencyCodes,
                minority: MINORITY,
                view: 'withZeroMinorPart',
              }).formattedWithCurrency
            }
          </Typography.Text>
        ) : null}
      </div>

      <Gap size="s" />

      {closedPositions ? (
        <div className={styles.profitItem}>
          <DotMediumMIcon width={16} height={16} color={DOT_COLORS[0]} />
          <Typography.Text
            view="secondary-large"
            tag="div"
            className={styles.tertiary}
          >
            {actGroup === ActGroupType.FUTURES
              ? 'Вариационная маржа'
              : 'Закрытые позиции'}
          </Typography.Text>
          <Typography.Text view="secondary-large" className={styles.tertiary}>
            {closedPositions > 0 ? '+' : '-'}
            {
              formatAmount({
                value: Math.abs(closedPositions) * MINORITY,
                currency: currency as CurrencyCodes,
                minority: MINORITY,
                view: 'withZeroMinorPart',
              }).formattedWithCurrency
            }
          </Typography.Text>
        </div>
      ) : null}

      {dividends !== 0 || coupons !== 0 ? (
        <div className={styles.profitItem}>
          <DotMediumMIcon width={16} height={16} color={DOT_COLORS[1]} />
          <Typography.Text
            view="secondary-large"
            tag="div"
            className={styles.tertiary}
          >
            {actGroup === ActGroupType.STOCKS ? 'Дивиденды' : 'Купоны'}
          </Typography.Text>
          <Typography.Text view="secondary-large" className={styles.tertiary}>
            {coupons + dividends > 0 ? '+' : '-'}
            {
              formatAmount({
                value: Math.abs(coupons + dividends) * MINORITY,
                currency: currency as CurrencyCodes,
                minority: MINORITY,
                view: 'withZeroMinorPart',
              }).formattedWithCurrency
            }
          </Typography.Text>
        </div>
      ) : null}
    </div>
  );
};
