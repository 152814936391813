import cn from 'classnames';
import { format } from 'date-fns';
import sumBy from 'lodash/sumBy';
import React, { CSSProperties, FC, useCallback, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';
import { DateFilterValue } from '@terminal/core/types/operation';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { PortfolioSection } from '../../../../shared';
import { Drawer } from '../../../Drawer';
import { SimpleTable } from '../../../SimpleTable';
import { START_ANALYTICS_DATE } from '../../lib/const';
import { IncomeByAssetsTableRow } from '../../model/FinResultTable';
import { IncomeByInstruments } from '../IncomeByInstruments';

import { useIncomeByAssetsTable } from '../../hooks/useIncomeByAssetsTable';

import styles from './IncomeByAssetTypes.module.css';

enum Column {
  Name = 'name',
  ProgressBar = 'progressBar',
  Income = 'income',
  Actions = 'actions',
}

const COLUMN_SETTINGS = [
  {
    key: Column.Name,
    width: '1fr',
  },
  {
    key: Column.ProgressBar,
    width: 80,
  },
  {
    key: Column.Income,
    justifyContent: 'flex-end',
  },
  {
    key: Column.Actions,
  },
];

const TABLE_TITLE = 'Доходы по активам';

interface Props {
  analytics?: PortfolioAnalyticsResult;
  dateFilter: DateFilterValue;
  setDateFilter: (filter: DateFilterValue) => void;
  selectedAccountId: number;
}

export const IncomeByAssetTypes: FC<Props> = ({
  analytics,
  dateFilter,
  setDateFilter,
  selectedAccountId,
}) => {
  const [drawerProps, setDrawerProps] = useState<Pick<
    IncomeByAssetsTableRow,
    'actGroup'
  > | null>(null);

  const isDrawerOpen = Boolean(drawerProps);

  const { trackClickPortfolioReportElement, trackHoverPortfolioReportTooltip } =
    usePortfolioReportsMetrics();

  const drawer = (
    <Drawer isOpen={isDrawerOpen} onClose={() => setDrawerProps(null)}>
      <IncomeByInstruments
        actGroup={drawerProps?.actGroup}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        selectedAccountId={selectedAccountId}
      />
    </Drawer>
  );

  const handleOpenDrawer = useCallback(
    (row: IncomeByAssetsTableRow) => {
      setDrawerProps({ actGroup: row.actGroup });
      trackClickPortfolioReportElement(TABLE_TITLE, row.name);
    },
    [trackClickPortfolioReportElement]
  );

  const data = useIncomeByAssetsTable(analytics);
  const absSum = sumBy(data, (b) => Math.abs(b.income));

  const cellRender = useCallback(
    (columnKey, prop, row) => {
      switch (columnKey) {
        case Column.Name:
          return (
            <Typography.Text view="secondary-large">{prop}</Typography.Text>
          );
        case Column.ProgressBar:
          return (
            <div
              className={styles.progressBarContainer}
              style={
                {
                  '--progressbar-positive-color': row.progressBarColor,
                } as CSSProperties
              }
            >
              <ProgressBar
                size="s"
                value={Math.abs(row.income / absSum) * 100}
                className={cn({
                  [styles.negativeBar]: row.income < 0,
                })}
              />
            </div>
          );
        case Column.Income:
          return (
            <Typography.Text view="secondary-large">
              {row.income >= 0 ? '+' : '-'}&nbsp;
              {
                formatAmount({
                  value: Math.abs(row.income) * MINORITY,
                  currency: 'RUR' as CurrencyCodes,
                  minority: MINORITY,
                  view: 'withZeroMinorPart',
                }).formatted
              }
              &nbsp;
              <Typography.Text
                tag="span"
                view="secondary-large"
                color="secondary"
              >
                {getSymbolByCurrency('RUB')}
              </Typography.Text>
            </Typography.Text>
          );
        case Column.Actions:
          return (
            <IconButton
              size="xs"
              view="secondary"
              icon={ChevronRightMIcon}
              onClick={() => handleOpenDrawer(row.key)}
            />
          );
      }
    },
    [absSum, handleOpenDrawer]
  );

  return (
    <>
      {analytics && data.length ? (
        <>
          <PortfolioSection
            title={TABLE_TITLE}
            tooltip={
              <>
                Сколько вы заработали по разным типам активов за конкретный
                период.
                <br />
                <br />
                В расчет включены:
                <br />
                -изменение стоимости текущих активов;
                <br />
                -закрытые сделки;
                <br />
                -выплаченные дивиденды и купоны.
                <br />
                Не учтены комиссии, торговля с плечом, налоги.
                <br />
                <br />
                Данные доступны с {format(START_ANALYTICS_DATE, 'dd.MM.yyyy')}
              </>
            }
            onTooltipOpen={() => {
              trackHoverPortfolioReportTooltip(TABLE_TITLE);
            }}
          >
            <SimpleTable<IncomeByAssetsTableRow, Column>
              columns={COLUMN_SETTINGS}
              data={data}
              cellRender={cellRender}
              onRowClick={(_, row) => handleOpenDrawer(row)}
              className={styles.table}
            />
          </PortfolioSection>
          <Gap size="2xl" />
        </>
      ) : null}

      {drawer}
    </>
  );
};
