import noop from 'lodash/noop';
import { useCallback } from 'react';

import { ANALYTICS } from '@terminal/core/constants/analytics';
import { SendOrderParams } from '@terminal/core/types/trading';

import {
  DetailedOrderInfoAnalyticsTrack,
  useFullFI,
  useWidgetContext,
} from '../../../shared';
import { OrderSource } from '../const';
import { mapOrderParamsToAnalytics } from '../utils/mapOrderParamsToAnalytics';

export const useOrderAnalyticsMetrics = (idFi: number) => {
  const {
    analytics: {
      trackDetailedOrderInfo = noop as DetailedOrderInfoAnalyticsTrack,
    },
  } = useWidgetContext();

  const fullFi = useFullFI(idFi);

  return {
    trackStartOrder: useCallback(
      (source: OrderSource, params: SendOrderParams, sum?: number) => {
        fullFi &&
          trackDetailedOrderInfo(
            ANALYTICS.ACTION.START,
            mapOrderParamsToAnalytics(source, params, fullFi, sum)
          );
      },
      [fullFi, trackDetailedOrderInfo]
    ),
    trackSuccessOrder: useCallback(
      (source: OrderSource, params: SendOrderParams, sum?: number) => {
        fullFi &&
          trackDetailedOrderInfo(
            ANALYTICS.ACTION.SUCCESS,
            mapOrderParamsToAnalytics(source, params, fullFi, sum)
          );
      },
      [fullFi, trackDetailedOrderInfo]
    ),
    trackErrorOrder: useCallback(
      (
        source: OrderSource,
        params: SendOrderParams,
        sum?: number,
        errorText?: string
      ) => {
        fullFi &&
          trackDetailedOrderInfo(
            ANALYTICS.ACTION.ERROR,
            mapOrderParamsToAnalytics(source, params, fullFi, sum, errorText)
          );
      },
      [fullFi, trackDetailedOrderInfo]
    ),
    trackConfirmOrder: useCallback(
      (source: OrderSource, params: SendOrderParams, sum?: number) => {
        fullFi &&
          trackDetailedOrderInfo(
            ANALYTICS.ACTION.CONFIRM,
            mapOrderParamsToAnalytics(source, params, fullFi, sum)
          );
      },
      [fullFi, trackDetailedOrderInfo]
    ),
  };
};
