import { useMemo } from 'react';

import { MINORITY } from '@terminal/core/constants/ui';
import { roundPrice } from '@terminal/core/lib/format';

import { Asset } from '../model/types/PortfolioComposition';
import { AssetsTableRow } from '../model/types/PortfolioCompositionTable';

export const useCompositionTable = (assets: Asset[]): AssetsTableRow[] => {
  return useMemo(() => {
    return assets.map((asset) => ({
      ...asset,
      price: roundPrice(asset.price, 1 / MINORITY),
      percent: roundPrice(asset.percent, 0.01),
      key: String(asset.id),
    }));
  }, [assets]);
};
