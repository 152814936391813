import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';
import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Прибыль/убыток по позиции за текущий день по отношению к предыдущему торговому дню (ПУ(дн))
 * */
export function getDailyPLtoMarketCurPrice(
  objectType: ObjectTypeItem,
  Price: number | null,
  nominal: number,
  NominalCurPrice: number,
  MarketCurPrice: number,
  TorgPosCost: number | null,
  BackPosCost: number,
  DailySellVolume: number | null,
  DailyBuyVolume: number | null,
  TrnIn: number,
  TrnOut: number,
  PriceStep: number,
  idObject?: number
): number | null {
  if (
    objectType?.idObjectGroup === undefined ||
    MarketCurPrice === 0 ||
    idObject === ECurrencyId.RUR ||
    (NominalCurPrice === 0 &&
      objectType?.idObjectGroup &&
      objectType.idObjectGroup !== ObjectGroup.Futures) ||
    PriceStep === 0
  ) {
    return 0;
  }

  if (
    Price === null ||
    TorgPosCost === null ||
    DailySellVolume === null ||
    DailyBuyVolume === null
  ) {
    return null;
  }

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Bonds: {
      return (
        TorgPosCost -
        BackPosCost +
        (DailySellVolume - DailyBuyVolume) / NominalCurPrice +
        (TrnOut - TrnIn) * ((Price * nominal) / 100)
      );
    }
    case ObjectGroup.Futures: {
      return TorgPosCost - BackPosCost + (DailySellVolume - DailyBuyVolume);
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals: {
      return (
        TorgPosCost -
        BackPosCost +
        (DailySellVolume - DailyBuyVolume) +
        (TrnOut - TrnIn) * Price
      );
    }
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts:
    default:
      return (
        TorgPosCost -
        BackPosCost +
        (DailySellVolume - DailyBuyVolume) / MarketCurPrice +
        (TrnOut - TrnIn) * Price
      );
  }
}
