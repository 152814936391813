import { smsSignOperation } from '@terminal/core/lib/rest/lkSmsSignOperation';

import { SignFatcaParams as SignFatcaRequest } from './types/SignFatcaParams';

export interface SignFatcaParams {
  params: SignFatcaRequest;
}

export async function signFatcaSms(data: SignFatcaParams) {
  return await smsSignOperation(
    '/api/documents/accept-fatca',
    'POST',
    data.params
  );
}
