import { useMutation } from 'react-query';
import { CurrencyCodes } from '@alfalab/core-components/amount';

import useNotification from '@terminal/core/hooks/useNotification';
import { createRefillOperation } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useCreateRefillFromAlfa = (
  sourceAccount: string | undefined,
  destinationAccount: string | undefined,
  value: number | null,
  currency: CurrencyCodes | undefined,
  minorUnits: number | undefined,
  CUS: string | undefined
) => {
  const addNotification = useNotification();

  return useMutation(
    // TODO: не понятно, зачем этот ключ, попробовать удалить
    [`create-refill-operation${sourceAccount}${destinationAccount}`],
    async () =>
      createRefillOperation(
        sourceAccount,
        destinationAccount,
        value,
        currency,
        minorUnits,
        CUS
      ),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка пополнения',
          text: error?.message || '',
        });
      },
    }
  );
};
