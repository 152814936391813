import { useCallback, useEffect } from 'react';
import { useEvent } from 'react-use';

import { useTransferStatusStore } from '../model/store/useTransferStatusStore';

import { TransferStatusEnum } from '../model/types/transferStatus';

export function useOfflineTransferStatus() {
  const setStatus = useTransferStatusStore((store) => store.setStatus);

  const handleOffline = useCallback(() => {
    setStatus(TransferStatusEnum.NoNetwork);
  }, [setStatus]);

  const handleOnline = useCallback(() => {
    setStatus(TransferStatusEnum.None);
  }, [setStatus]);

  useEffect(() => {
    if (!window.navigator.onLine) {
      setStatus(TransferStatusEnum.NoNetwork);
    }
  }, [setStatus]);

  useEvent('offline', handleOffline);
  useEvent('online', handleOnline);
}
