import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getNotificationsCount } from '@terminal/core/lib/rest/lkNotifications';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useNotificationsCount = (treaty?: number, enabled?: boolean) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.NotificationsCount, { treaty }],
    () => getNotificationsCount(treaty),
    {
      enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса уведомлений',
          text: error?.message || '',
        });
      },
    }
  );
};
