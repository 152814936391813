import { useEffect, useRef, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarDesktop } from '@alfalab/core-components/calendar/desktop';
import { usePeriod } from '@alfalab/core-components/calendar/usePeriod';
import { Popover } from '@alfalab/core-components/popover';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';

import { DatePeriod, formatPeriod } from './helpers';

import styles from './PeriodTag.module.css';

export interface PeriodTagProps {
  dateForm: number;
  dateTo: number;
  onApply?: (period: DatePeriod) => void;
  onReset?: () => void;
}

export const PeriodTag = ({
  dateForm,
  dateTo,
  onApply,
  onReset,
}: PeriodTagProps) => {
  const [open, setOpen] = useState(false);

  const tagRef = useRef<HTMLButtonElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(calendarRef, (e) => {
    if (
      e.target !== tagRef.current &&
      !tagRef.current?.contains(e.target as Node)
    ) {
      setOpen(false);
    }
  });

  const { selectedFrom, selectedTo, updatePeriod, setStart, setEnd } =
    usePeriod({
      initialSelectedFrom: dateForm ? new Date(dateForm).getTime() : undefined,
      initialSelectedTo: dateTo ? new Date(dateTo).getTime() : undefined,
    });

  const handleUpdatePeriod = (date?: number) => {
    // NOTE: эмулирует свойство "rangeBehavior='reset'" из новой версии календаря
    // можно убрать после обновления @alfalab/core-components
    if (selectedFrom && selectedTo) {
      setStart(undefined);
      setEnd(undefined);
    }

    updatePeriod(date);
  };

  useEffect(() => {
    if (dateForm) {
      setStart(new Date(dateForm).getTime());
    }

    if (dateTo) {
      setEnd(new Date(dateTo).getTime());
    }
  }, [dateForm, dateTo, setEnd, setStart]);

  const onApplyPeriod = () => {
    if (onApply) {
      onApply({
        dateFrom: selectedFrom,
        dateTo: selectedTo,
      });
    }

    setOpen(false);
  };

  const onResetPeriod = () => {
    if (onReset) {
      onReset();
    }

    setOpen(false);
  };

  const TODAY = new Date().getTime();

  return (
    <>
      <TagDesktop
        size="xs"
        view="filled"
        checked={Boolean(dateForm) || Boolean(dateTo)}
        onClick={() => setOpen(true)}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        ref={tagRef}
      >
        {formatPeriod(dateForm, dateTo)}
      </TagDesktop>

      <Popover
        anchorElement={tagRef.current}
        position="bottom-start"
        open={open}
        offset={[0, 4]}
      >
        <div className={styles.content} ref={calendarRef}>
          <CalendarDesktop
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
            onChange={handleUpdatePeriod}
            maxDate={TODAY}
          />
          <div className={styles.dateButtons}>
            {Boolean(onReset) && (
              <ButtonDesktop
                className={styles.dateButton}
                view="tertiary"
                size="s"
                block
                onClick={onResetPeriod}
                leftAddons={
                  <CrossMIcon
                    height={18}
                    width={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
              >
                Сбросить
              </ButtonDesktop>
            )}
            <ButtonDesktop
              className={styles.dateButton}
              view="secondary"
              size="s"
              block
              leftAddons={
                <CheckmarkMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={onApplyPeriod}
            >
              Применить
            </ButtonDesktop>
          </div>
        </div>
      </Popover>
    </>
  );
};
