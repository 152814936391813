import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { IconButtonProps } from '@alfalab/core-components/icon-button';

import {
  ModalFooter,
  ModalFooterProps,
} from '../../../legacy/components/ModalFooter';
import { ModalHeader } from '../../../legacy/components/ModalHeader';

import styles from './Modal.module.css';

export type ModalProps = Pick<
  BaseModalProps,
  'open' | 'onClose' | 'className'
> &
  ModalFooterProps & {
    /**
     * Заголовок окна
     * */
    title: ReactNode;
    /**
     * Если `true`, то контент не оборачивается в контейнер с отступами
     * @default false
     * */
    isRawContent?: boolean;
    headerClassname?: string;
    footerClassname?: string;
    contentClassname?: string;
    headerIconProps?: Partial<IconButtonProps>;
  };

/**
 * Модальное окно
 * */
export const Modal: FC<ModalProps> = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    className,
    actionButtonsStart = [],
    actionButtonsEnd = [],
    isRawContent,
    headerClassname,
    footerClassname,
    contentClassname,
    headerIconProps,
  } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <BaseModal open={open} onClose={onClose} className={rootClassName}>
      <ModalHeader
        className={cn(styles.header, headerClassname)}
        onClose={onClose}
        iconProps={headerIconProps}
      >
        {title}
      </ModalHeader>
      <div
        className={cn({ [styles.content]: !isRawContent }, contentClassname)}
      >
        {children}
      </div>
      <ModalFooter
        className={footerClassname}
        actionButtonsStart={actionButtonsStart}
        actionButtonsEnd={actionButtonsEnd}
      />
    </BaseModal>
  );
};
