import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import {
  GetDocumentsThemesDTOAI,
  GetDocumentsThemesDTOGI,
} from './types/GetDocumentsThemesDTO';
import { GetDocumentsThemesParams } from './types/GetDocumentsThemesParams';

async function getDocumentsThemes<
  T extends GetDocumentsThemesDTOGI | GetDocumentsThemesDTOAI
>(data: GetDocumentsThemesParams): Promise<T> {
  const result = await fetchLkResult<T | LKResult>(
    qs.stringifyUrl({
      url: '/api/client/documents/order-themes',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result as T;
}

export async function getDocumentsThemesGI(
  data: GetDocumentsThemesParams
): Promise<GetDocumentsThemesDTOGI> {
  return getDocumentsThemes<GetDocumentsThemesDTOGI>(data);
}

export async function getDocumentsThemesAI(
  data: GetDocumentsThemesParams
): Promise<GetDocumentsThemesDTOAI> {
  return getDocumentsThemes<GetDocumentsThemesDTOAI>(data);
}
