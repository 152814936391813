import React, { useCallback, useEffect, useState } from 'react';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { SelectTreaty } from '../components/SelectTreaty';
import { NotificationsMain } from './tabs/NotificationsMain';

import { useNotificationsCount } from './hooks';

import styles from './Notifications.module.css';

interface NotificationsProps {
  onNotificationRead?: () => void;
}

export const Notifications = React.memo(
  ({ onNotificationRead }: NotificationsProps) => {
    const [treaty, setTreaty] = useState<Treaty | null>(null);

    const { data: notificationsCount, refetch: updateNotificationsCount } =
      useNotificationsCount(treaty?.treaty, Boolean(treaty));

    useEffect(() => {
      trackMainMenu(LOCATIONS.LK_NOTIFICATIONS);
    }, []);

    const handleNotificationRead = useCallback(() => {
      updateNotificationsCount();

      if (onNotificationRead) {
        onNotificationRead();
      }
    }, [updateNotificationsCount, onNotificationRead]);

    return (
      <div className={styles.container}>
        <div className={styles.treaty}>
          <SelectTreaty
            hideLabel
            zeros
            onSelect={(treaty) => setTreaty(treaty)}
          />
        </div>
        <div className={styles.content}>
          <NotificationsMain
            treaty={treaty}
            notificationsCount={notificationsCount?.count}
            onNotificationRead={handleNotificationRead}
          />
        </div>
      </div>
    );
  }
);
