export enum ActGroupType {
  STOCKS = 2,
  BONDS,
  CURRENCY,
  FUTURES,
  FUNDS,
  METALLS,
}

export type ReportChapterType =
  | 'dividends'
  | 'couponRevenue'
  | 'taxes'
  | 'commissions'
  | 'marginTrade';

export type OperationChapterType = 'inputs' | 'outputs';

/**
 * Общая информация по финансовому результату
 * */
export interface BaseFinResultData {
  actId: number;
  shortName: string;
  name: string;
  isin: string;
  regCode: string;
  ticker: string;
  symbolObject: string;
  idObject: number;
  idObjectBase: number;
  currency: string;
}

export interface FinResultTradesStatistics {
  count: number;
  percentage: number;
  longCount: number;
  shortCount: number;
}

export interface FinResultRates {
  total: {
    allOperations: number;
    closedPositions: number;
    dividends: number;
    coupons: number;
  };
  tradesCount: number;
  profitableTrades: FinResultTradesStatistics;
  unprofitableTrades: FinResultTradesStatistics;
  allLongCount: number;
  allShortCount: number;
  bestTrade: number;
  worstTrade: number;
  average: {
    profit: number;
    loss: number;
    all: number;
  };
}
