import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import {
  GetReportsThemesDTOAI,
  GetReportsThemesDTOGI,
} from './types/GetReportsThemesDTO';
import { GetReportsThemesParams } from './types/GetReportsThemesParams';

async function getReportsThemes<
  T extends GetReportsThemesDTOGI | GetReportsThemesDTOAI
>(data: GetReportsThemesParams): Promise<T> {
  const result = await fetchLkResult<T | LKResult>(
    qs.stringifyUrl({
      url: '/api/client/documents/report-themes',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result as T;
}

export async function getReportsThemesGI(
  data: GetReportsThemesParams
): Promise<GetReportsThemesDTOGI> {
  return getReportsThemes<GetReportsThemesDTOGI>(data);
}

export async function getReportsThemesAI(
  data: GetReportsThemesParams
): Promise<GetReportsThemesDTOAI> {
  return getReportsThemes<GetReportsThemesDTOAI>(data);
}
