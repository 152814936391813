import { NotificationProps } from '@alfalab/core-components/notification';

import { ClientEnterOrderReq, OrderType } from '../lib/client/entities';
import { OrderItem } from './order';
import { OrderRequest } from './trading';

export type NotificationBadge =
  | 'positive'
  | 'attention'
  | 'negative'
  | 'chat'
  | undefined;

export type PendingOrder = OrderItem | (ClientEnterOrderReq & OrderRequest);

export enum NotificationType {
  TRADE = 'trade',
  SYSTEM = 'system',
  BROKER = 'broker',
  CHAT = 'chat',
}

export interface Notification {
  id: string;
  type?: NotificationType;
  badge?: NotificationBadge;
  title?: string;
  text?: string;
  link?: string;
  orderNotification?: boolean;
  order?: PendingOrder;
  colors?: NotificationProps['colors'];
}

export enum OrderDimensionEnum {
  LOT,
  PIECE,
}

export type DisplayInstrumentType = 'symbol' | 'name';
export type DirectionColors = 'greenAndRed' | 'blueAndRed' | 'blueAndWhite';
export type PLMode = 'money' | 'procent' | 'points';

export interface CommonSettings {
  design: {
    directionColors: DirectionColors;
  };
  notifications: {
    isShow: boolean;
    isShowTradeNotifications: boolean;
    isShowSystemNotifications: boolean;
    isShowBrokerNotifications: boolean;
    isShowNewMessages: boolean;
    maxCount: number;
    time: number;
  };
  defaultValues: {
    alwaysConfirmOrders: boolean;
    orderDimension: OrderDimensionEnum;
    selectedDisplayInstrumentType: DisplayInstrumentType;
    selectedPLMode: PLMode;
    //Сохраняем умолчательный размер для торговли по idFi (всегда в штуках)
    quantityMap: Record<string, number>;
  };
}

export type SettingType = keyof CommonSettings;

export enum HeaderMenuItem {
  FEEDBACK,
  MONEY_TRANSFER,
  NEW_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  MAIN_LK,
  CONTEST,
  EDUCATION,
  MANUAL,
  COMMON_SETTINGS,
  ABOUT,
  SECURITIES,
  PROFILE_SETTINS,
  MARGIN_CALL,
}

export type StopOrderType = OrderType.STL | OrderType.STP;

export enum SlippageType {
  TICK = 'tick',
  PERCENT = 'percent',
}

export enum BracketPriceType {
  PRICE = 'price',
  PERCENT = 'percent',
  TICK = 'tick',
}
