import { getYear, parseISO } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';

import {
  DocumentDateType,
  DocumentTheme,
  ReportTheme,
} from '@terminal/lk-core';

interface OrderYearProps {
  documentTheme?: DocumentTheme | ReportTheme;
  year: string;
  onYearChange: (year: string) => void;
}

export const OrderYear = ({
  documentTheme,
  year,
  onYearChange,
}: OrderYearProps) => {
  const curYear: number | null =
    documentTheme && documentTheme.endDate
      ? getYear(parseISO(documentTheme.endDate))
      : getYear(new Date());
  const minYear: number | null =
    documentTheme && documentTheme.startDate
      ? getYear(parseISO(documentTheme.startDate))
      : null;

  const yearOptions = useMemo(() => {
    if (curYear && minYear) {
      const yearOptions: { key: string; content: React.ReactNode }[] = [];

      for (let y = curYear; y >= minYear; y--) {
        yearOptions.push({
          key: y.toString(),
          content: y.toString(),
        });
      }

      return yearOptions;
    }

    return [];
  }, [curYear, minYear]);

  useEffect(() => {
    if (yearOptions.length) {
      const finded = yearOptions.find((y) => y.key === year);

      if (!finded) {
        onYearChange(yearOptions[0].key);
      }
    }
  }, [year, yearOptions, onYearChange]);

  const handleYearChange: SelectDesktopProps['onChange'] = ({ selected }) => {
    onYearChange(selected?.key || '');
  };

  if (!documentTheme || documentTheme.dateType !== DocumentDateType.Year) {
    return null;
  }

  return (
    <Row>
      <Col width={6}>
        <SelectDesktop
          label="Период"
          labelView="outer"
          placeholder="Выберите период"
          size="s"
          block
          options={yearOptions}
          selected={year}
          onChange={handleYearChange}
          error={yearOptions.length === 0 && 'Отсутствует подходящий период'}
        />
      </Col>
    </Row>
  );
};
