import cn from 'classnames';
import { ReactNode, useCallback } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { MAX_CERTIFICATES_COUNT } from '@terminal/core/constants/certificates';
import { CertificateStateType } from '@terminal/core/lib/client/entities';
import { shallow, useStore } from '@terminal/core/store';
import { CertificateEnrollStep } from '@terminal/core/types/certificate';

import { TradingStub } from '../../components/TradingStub';

import styles from './TradingCertificateLock.module.css';

interface Props {
  children?: ReactNode;
  //Когда нужно просто скрыть элемент и ничего не показывать
  hideMode?: boolean;
  size?: 's' | 'xs';
  title?: ReactNode;
  description?: ReactNode;
  buttonCaption?: string;
  fullHeight?: boolean;
}

// HOC который не рендерит чилдренов и вместо них выводит
// кнопку выпустить сертификать, если у пользователя нет валидного сертификата
// <TradingCertificateLock>
//    <MyAwesomeTradeForm /> <--- не отрендерится если нет серта
// </TradingCertificateLock>
// На кмопнент есть интеграционный тест /src/mobile/components/__tests__/CertificateProvider.tsx
export const TradingCertificateLock = (props: Props) => {
  const {
    children,
    hideMode,
    title,
    size,
    description,
    buttonCaption,
    fullHeight = false,
  } = props;
  const isSizeS = size === 's';
  const isSizeXs = size === 'xs';

  const [
    certificates,
    workingCertificate,
    enrollStep,
    enrollCertificate,
    confirmCertificate,
    certificateEnrollId,
  ] = useStore(
    (store) => [
      store.certificates,
      store.workingCertificate,
      store.certificateEnrollStep,
      store.enrollCertificate,
      store.confirmCertificate,
      store.certificateEnrollId,
    ],
    shallow
  );

  const isAllowIssueCertificate =
    Boolean(certificateEnrollId) ||
    certificates.length < MAX_CERTIFICATES_COUNT;

  const handleClick = useCallback(() => {
    if (!certificateEnrollId) {
      enrollCertificate();
    } else {
      confirmCertificate(certificateEnrollId);
    }
  }, [enrollCertificate, confirmCertificate, certificateEnrollId]);

  const tradingEnabled =
    workingCertificate &&
    workingCertificate.state === CertificateStateType.Ready;

  if (tradingEnabled) {
    return <>{children}</>;
  }

  if (hideMode) {
    return null;
  }

  const isDisabled =
    enrollStep === CertificateEnrollStep.InProgress ||
    enrollStep === CertificateEnrollStep.Success;

  return (
    <TradingStub
      size={size}
      isFullHeight={fullHeight}
      icon={<ShieldMIcon />}
      title={
        title ? (
          title
        ) : (
          <>
            Необходим сертификат
            <br />
            электронной подписи
          </>
        )
      }
      description={
        description ? (
          description
        ) : (
          <>
            Необходим сертификат
            <br />
            электронной подписи
          </>
        )
      }
      actions={
        <>
          <div
            className={cn(styles.buttonWrapper, {
              [styles.buttonWrapper_size_s]: isSizeS,
              [styles.buttonWrapper_size_xs]: isSizeXs,
            })}
          >
            <ButtonDesktop
              onClick={handleClick}
              disabled={!isAllowIssueCertificate || isDisabled}
              loading={isDisabled}
              size={(isSizeXs && 'xs') || 's'}
              block
              nowrap
              leftAddons={
                buttonCaption ? null : <AddMIcon width={16} height={16} />
              }
            >
              {buttonCaption
                ? buttonCaption
                : `Выпустить ${!isSizeXs ? ' сертификат' : ''}`}
            </ButtonDesktop>
          </div>
          {!isAllowIssueCertificate && (
            <Typography.Text
              view="secondary-large"
              color="secondary"
              defaultMargins={false}
              className={cn(styles.actionsText, {
                [styles.small]: isSizeS,
                [styles.xsmall]: isSizeXs,
              })}
            >
              Чтобы выпустить новый сертификат,
              <br />
              удалите ненужный, выпущенный для
              <br />
              другого браузера или устройства
            </Typography.Text>
          )}
        </>
      }
    />
  );
};
