import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

export const useSearchSecurities = <T, R>(
  fetcher: (params: T) => Promise<R>
) => {
  const addNotification = useNotification();

  return useMutation(fetcher, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text:
          error?.message || 'Произошла ошибка при запросе списка ценных бумаг',
      });
    },
  });
};
