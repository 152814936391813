import { ReportFormat } from '../model/ReportFormat';
import { getReportType } from './getReportType';

import { GenerateMultiplyReportsParams } from '../api/types/GenerateMultiplyReportsParams';

export function prepareMultiplyReportsParams(params: {
  themeId: string;
  periodStart: string;
  periodEnd: string;
  format: ReportFormat;
}): GenerateMultiplyReportsParams {
  return {
    report: getReportType(parseInt(params.themeId)),
    strDateStart: params.periodStart,
    strDateEnd: params.periodEnd,
    format: params.format,
    hideSection: 0,
  };
}
