import cn from 'classnames';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { UNICODE_CHARS } from '@terminal/core/constants/unicodeChars';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { roundPrice } from '@terminal/core/lib/format';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { START_ANALYTICS_DATE } from '../../lib/const';

import styles from './BalanceSummary.module.css';

interface Props {
  analytics?: PortfolioAnalyticsResult;
}

export const BalanceSummary: FC<Props> = ({ analytics }) => {
  const { trackHoverPortfolioReportTooltip } = usePortfolioReportsMetrics();

  if (!analytics || (!analytics?.portfolioRevenue && !analytics?.beginNAV)) {
    return null;
  }

  const balance = analytics?.endNAV?.value || 0;
  const profit =
    analytics &&
    analytics.portfolioRevenue &&
    'value' in analytics.portfolioRevenue
      ? analytics.portfolioRevenue.value
      : balance - (analytics?.beginNAV?.value || 0);
  const beginBalance = analytics?.beginNAV?.value || balance - profit;
  const roughBalance = beginBalance
    ? roundPrice((100 * profit) / beginBalance, 0.01)
    : 100;
  const profitPL =
    analytics && analytics.returnOnAssets && 'value' in analytics.returnOnAssets
      ? analytics.returnOnAssets.value
      : roughBalance;

  const symbol = analytics?.endNAV?.currency || 'RUB';

  const formattedBalance = formatAmount({
    value: balance * MINORITY,
    currency: symbol,
    minority: MINORITY,
    view: 'withZeroMinorPart',
  }).formatted;

  const formattedProfit = formatAmount({
    value: Math.abs(profit) * MINORITY,
    currency: symbol,
    minority: MINORITY,
    view: 'withZeroMinorPart',
  }).formattedWithCurrency;

  return (
    <div>
      <Typography.Title tag="div" view="small" color="primary" weight="bold">
        {formattedBalance}&nbsp;
        <span className={styles.symbol}>{getSymbolByCurrency(symbol)}</span>
      </Typography.Title>

      <Typography.Text
        monospaceNumbers
        tag="div"
        view="primary-medium"
        color="secondary"
        className={cn(styles.change, {
          [styles.positive]: profit > 0,
          [styles.negative]: profit < 0,
        })}
      >
        {`${profit > 0 ? '+' : '-'}${UNICODE_CHARS.NBSP}${formattedProfit}${
          UNICODE_CHARS.NBSP
        }(${profitPL}%)`.replaceAll('.', ',')}
        &nbsp;
        <TooltipDesktop
          position="top"
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content={
            <Typography.Text view="secondary-small" className={styles.tertiary}>
              Доходность показывает, сколько денег вы заработали за конкретный
              период с учетом всех поступлений и списаний. Доходность в
              процентах - это отношение прибыли к вложенным деньгам с учетом
              вводов и выводов.
              <br />
              <br />
              В расчет включены:
              <br />
              -изменение стоимости текущих активов;
              <br />
              -закрытые сделки;
              <br />
              -выплаченные дивиденды и купоны;
              <br />
              -уплаченные налоги;
              <br />
              -списанные комиссии;
              <br />
              <br />
              {`Данные доступны с ${format(
                START_ANALYTICS_DATE,
                'dd.MM.yyyy'
              )}`}
            </Typography.Text>
          }
          onOpen={() => {
            trackHoverPortfolioReportTooltip('Баланс');
          }}
        >
          <InformationCircleLineSIcon height={16} width={16} color="#8D8D93" />
        </TooltipDesktop>
      </Typography.Text>
      <Gap size="m" />
    </div>
  );
};
