import { DateFilterType } from '@terminal/core/types/operation';

import { PortfolioChart } from '../model';

export const FiltersNameMap = new Map<number, string>([
  [DateFilterType.NONE, 'Всё'],
  [DateFilterType['30DAYS'], 'Месяц'],
  [DateFilterType['3MONTHS'], '3 месяца'],
  [DateFilterType.RANGE, 'Даты'],
  [DateFilterType.YEAR, 'Год'],
  [DateFilterType.THIS_YEAR, 'С начала года'],
]);

export const PortfolioChartNameMap: Record<PortfolioChart, string> = {
  [PortfolioChart.BALANCE]: 'Стоимость',
  [PortfolioChart.PROFIT]: 'Доходность',
};
