import log from 'loglevel';

import { clientMessageSuccess, defaultUserData } from '../../constants/auth';
import { featureFlags } from '../../featureFlags';
import { userCredentialMap } from '../../mapping/userMapping';
import {
  checkMessageId,
  isConnectionError,
  makeAuthMessage,
} from '../client/auth';
import {
  ClientMessageEntity,
  FrontEndType,
  MessageUnitedType,
  PassportTokenType,
  UserCredentialsEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';
import { SocketStatus } from '../client/socket';
import { setAlfaTokens } from '../rest/investApi';
import { getServerTime } from './time';

import { UserCredentials } from '../../types/user';

export enum AuthServiceEvents {
  AUTH_ATTEMPT = 'AUTH_ATTEMPT',
}

interface ConnectPassportParams {
  code: string;
  withCerberus: boolean;
}

/*
  Singletone сервис для авторизации пользователя
*/
class AuthService extends AdirClientService {
  login: string = '';
  password: string = '';
  code: string = '';
  userCredentials: UserCredentials;
  withCerberus: boolean;
  authCallback?: (err: Error | null, user?: UserCredentials | null) => void;
  authPending: boolean;
  tryConnectAttempt: number = 0;
  maxConnectAttempt: number = 3;
  reconnectRetryTimeout: number = 500;

  subscribed = false;
  // Для каждой вкладки генерируется новый уникальный deviceID
  // Для PWA DeviceId берется из localStorage
  idDevice: string = '';

  override init() {
    this.addClientListener(
      this.client.events.SOCKET_ERROR,
      this.onSocketError,
      this
    );
    this.addClientListener(
      this.client.events.SOCKET_CLOSE,
      this.onSocketClose,
      this
    );
  }

  setIdDevice(idDevice: string) {
    this.idDevice = idDevice;
  }

  getAuthMessage(frontEndType: FrontEndType, login?: string) {
    return makeAuthMessage(
      {
        ...this.userCredentials,
        login: login || this.userCredentials.login,
      },
      frontEndType,
      this.idDevice
    );
  }

  connect = (login: string, password: string) =>
    new Promise<UserCredentials>(async (resolve, reject) => {
      this.initAuthCallback((data) => {
        this.removeClientListener(
          this.client.events.SOCKET_ERROR,
          this.onSocketError,
          this
        );
        this.removeClientListener(
          this.client.events.SOCKET_CLOSE,
          this.onSocketClose,
          this
        );
        resolve(data);
      }, reject);

      this.login = login;
      this.password = password;
      this.authPending = true;
      const authenticationRequestMessage = {
        ...defaultUserData,
        DeveloperCode: this.client.settings.developerCode,
        IdDevice: this.client.idDevice,
        Login: this.login,
        Password: this.password,
        LocalTime: new Date(),
      };

      // resetConnections убивает сокеты и листнеры, создаем заново
      this.client.createWebSockets();
      const fe1 = this.client.sockets.get(FrontEndType.AuthAndOperInitServer);

      if (!fe1) {
        throw new Error('fe1 not created');
      }

      await fe1.open();

      log.debug('SOCKET OPEN');

      this.client.send({
        frontend: FrontEndType.AuthAndOperInitServer,
        isArray: false,
        payload: {
          type: EntityType.AuthenticationRequestEntity,
          data: authenticationRequestMessage,
        },
      });
    });

  connectPassport = (params?: ConnectPassportParams) =>
    new Promise<UserCredentials>((resolve, reject) => {
      this.initAuthCallback(resolve, reject);

      this.code = params?.code || this.code;
      this.withCerberus = params?.withCerberus || this.withCerberus;
      this.authPending = true;
      this.ensureSubscriptions();

      this.tryConnectWithPassport();
    });

  private async tryConnectWithPassport() {
    if (!this.code) {
      throw new Error('Отсутсвует код токена');
    }

    log.info(`Connect attempt ${this.tryConnectAttempt}`);

    this.emit(
      AuthServiceEvents.AUTH_ATTEMPT,
      this.maxConnectAttempt - this.tryConnectAttempt
    );

    this.tryConnectAttempt++;

    const authenticationRequestMessage = {
      ...defaultUserData,
      DeveloperCode: this.client.settings.developerCode,
      Token: this.code,
      TokenType: this.withCerberus
        ? PassportTokenType.CheckOneTimeToken
        : PassportTokenType.AuthorizationCode,
      LocalTime: getServerTime(),
      IdDevice: this.client.idDevice,
    };

    // resetConnections убивает сокеты и листнеры, создаем заново
    await this.client.createWebSockets();
    const fe1 = this.client.sockets.get(FrontEndType.AuthAndOperInitServer);

    if (!fe1) {
      throw new Error('No socket fe1');
    }

    if (fe1.status !== SocketStatus.Closed) {
      await fe1.close();
    }

    // устанавливаем также максимальное количество попыток восстановить подключение внутри сокетов
    // важно ограничить первое подключение, иначе фронт будет бесконечно пытаться соединиться
    fe1.setMaxReconnects(this.maxConnectAttempt);
    await fe1.open();

    log.debug('SOCKET OPEN');
    fe1.setMaxReconnects(Infinity);

    const attemptsRemain = this.maxConnectAttempt - this.tryConnectAttempt;

    // имитируем проблемы с сетью чтобы протестировать реконнекты при логине
    if (attemptsRemain > 0 && featureFlags.RECONNECTS_LOGIN_ENABLED) {
      return;
    }

    this.client.send({
      frontend: FrontEndType.AuthAndOperInitServer,
      isArray: false,
      payload: {
        type: EntityType.PassportAuthenticationRequestEntity,
        data: authenticationRequestMessage,
      },
    });
  }

  onClientMessageEntity = (message: MessageUnitedType) => {
    const messages = message.data as ClientMessageEntity[];
    const frontend = message.frontend;

    if (frontend !== FrontEndType.AuthAndOperInitServer) {
      return;
    }

    messages.forEach((message) => {
      if (
        isConnectionError(message) &&
        this.tryConnectAttempt < this.maxConnectAttempt
      ) {
        setTimeout(
          () => this.tryConnectWithPassport(),
          this.reconnectRetryTimeout
        );

        return;
      }

      try {
        checkMessageId(message.MessageId);

        if (clientMessageSuccess === message.MessageId) {
          log.debug(`FE ${frontend} number ${message.Objects[0]}`);

          if (this.authCallback) {
            this.authPending = false;
            this.authCallback(null, this.userCredentials);
          }

          const store = this.store.getState();

          store.setCredentials(this.userCredentials);
        }
      } catch (err) {
        this.authPending = false;
        this.authCallback?.(err as Error);
      }
    });
  };

  persistTokens(message: UserCredentialsEntity) {
    if (message.AccessToken && message.RefreshToken) {
      setAlfaTokens(message.AccessToken, message.RefreshToken);
    }
  }

  onError() {
    if (this.authPending && this.authCallback) {
      this.authCallback(new Error('Ошибка соединения'), null);
      this.authPending = false;
    }
  }

  unsubscribe() {
    this.removeClientListener(
      EntityType.UserCredentialsEntity,
      this.onUserCredentialsEntity
    );
    this.removeClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessageEntity
    );
    this.subscribed = false;
  }

  ensureSubscriptions() {
    // обычно я выносил одтельно подписку в метод init
    // который вызывается в конце файла client.ts
    // но в данном случае это не работает и выдает ошибку вебпака
    // я думаю связано с тем что в дереве импортов этот сервис видимо раньше появляется
    // чем клиент, поэтому так вот закостылена подписка
    // штука в том что conenct может вызываться несколько раз
    // если пользователь перелогинивается
    if (!this.subscribed) {
      this.addClientListener(
        EntityType.UserCredentialsEntity,
        this.onUserCredentialsEntity
      );
      this.addClientListener(
        EntityType.ClientMessageEntity,
        this.onClientMessageEntity
      );
      this.subscribed = true;
    }
  }

  private initAuthCallback = (
    resolve: (value: UserCredentials) => void,
    reject: (error?: Error | null) => void
  ) => {
    this.authCallback = (err, user) => {
      if (err || !user) {
        reject(err);
      } else {
        resolve(user);
      }

      this.unsubscribe();

      this.authCallback = undefined;
    };
  };

  private onSocketError(message?: string) {
    this.authCallback?.(new Error(message), null);
  }
  private onSocketClose(message?: string) {
    this.authCallback?.(new Error('Ошибка соединения'), null);
  }

  private onUserCredentialsEntity = (
    message: MessageUnitedType<UserCredentialsEntity>
  ) => {
    const messages = message.data;

    messages.forEach((message) => {
      try {
        checkMessageId(message.MessageId);

        this.persistTokens(message);

        this.userCredentials = userCredentialMap(message);

        // сохраняем ордер префикс
        this.store.getState().setOrderPrefix(message.OrderPrefix);

        this.client.sockets.forEach(async (socket) => {
          await socket.open();

          try {
            if (await socket.auth(this.userCredentials)) {
              socket.resubscribe();
            }
          } catch (e) {
            log.error(e);
          }
        });
      } catch (err) {
        log.error(err);
        this.authPending = false;

        this.authCallback?.(err as Error, null);
      }
    });
  };
}

const authService = new AuthService();

export { authService as AuthService };
