import { FC, memo, useState } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { ChartRingMIcon } from '@alfalab/icons-glyph/ChartRingMIcon';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { RestLock } from '@terminal/common/components/RestLock';
import {
  ColumnSettingModal,
  DoubleColumnSettingModal,
} from '@terminal/common/ui/Table';
import { TableColumnSetting, Widget } from '@terminal/core/types/layout';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';
import { CommonSettings } from '@terminal/core/types/ui';

import { AccountFilters } from '../../../../features/AccountFilters';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import { useWidgetContext } from '../../../../shared';
import { OperationsHistory } from '../operationsHistory';

import { useSettings as useTableSettings } from '../../../../shared/hooks/useSettings';

import styles from './Header.module.css';

interface Props {
  nodeId?: string;
  selectedSubAccounts: string[];
  columnsSetting: TableColumnSetting[];
  defaultOperationsColumnSetting: TableColumnSetting[];
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
  subAccounts: Record<string, SubAccountRazdelItem[]>;
  selectedDisplayInstrumentType: CommonSettings['defaultValues']['selectedDisplayInstrumentType'];
  togglePortfolioModalOpen: () => void;
}

const OperationsHeader: FC<Props> = ({
  nodeId,
  selectedSubAccounts,
  columnsSetting,
  defaultOperationsColumnSetting,
  isDoubleRowMode,
  switchDoubleRowMode,
  subAccounts,
  selectedDisplayInstrumentType,
  togglePortfolioModalOpen,
}) => {
  const [isOpenColumnSettings, setIsOpenColumnSettings] = useState(false);
  // Если нет сохраненного конфига, значит это первый выбор
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const { updateNode } = useWidgetContext();

  const widgetSettings = useTableSettings({
    isDoubleRowMode,
    switchDoubleRowMode,
    setIsOpenColumnSettings,
  });

  return (
    <>
      <WidgetHeader
        inputs={
          <AccountFilters
            classNames={{ wrapper: styles.accountsFilter }}
            selectedSubAccounts={selectedSubAccounts}
            updateNode={updateNode}
            nodeId={nodeId}
          />
        }
        widgetSettings={widgetSettings}
        controls={
          <RestLock>
            <IconButton
              size="xs"
              view="secondary"
              icon={ClockMIcon}
              onClick={() => setIsOpenHistory(!widgetSettings.open)}
            />
            <IconButton
              size="xs"
              view="secondary"
              icon={ChartRingMIcon}
              onClick={() => togglePortfolioModalOpen()}
            />
            <BaseModal
              open={isOpenHistory}
              onClose={() => setIsOpenHistory(false)}
              wrapperClassName={styles.containerModal}
              className={styles.modal}
            >
              <div className={styles.modalHeader}>
                <Typography.Text view="secondary-large" weight="bold">
                  История операций
                </Typography.Text>
                <IconButton
                  size="xs"
                  view="secondary"
                  icon={CrossMIcon}
                  onClick={() => setIsOpenHistory(false)}
                />
              </div>
              <div className={styles.modalContent}>
                <OperationsHistory
                  parentNodeId={nodeId}
                  selectedSubAccounts={
                    !selectedSubAccounts.length
                      ? //Если не выбран ни один счет, то берем первый
                        [Object.keys(subAccounts)[0]]
                      : selectedSubAccounts
                  }
                />
              </div>
            </BaseModal>
          </RestLock>
        }
      />

      {!isDoubleRowMode && (
        <ColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.OPERATIONS}
        />
      )}
      {isDoubleRowMode && (
        <DoubleColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          defaultColumnSetting={defaultOperationsColumnSetting}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.OPERATIONS}
          selectedDisplayInstrumentType={selectedDisplayInstrumentType}
        />
      )}
    </>
  );
};

export const Header = memo(OperationsHeader);
