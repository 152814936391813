import { OperationChapterType } from '../model/FinResult';

const MESSAGES: Record<OperationChapterType, string> = {
  inputs: 'Пополнения',
  outputs: 'Выводы',
};

export const mapOperationChapterKeyToName = (
  key: OperationChapterType
): string => {
  return MESSAGES[key];
};
