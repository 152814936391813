/**
 * @returns Прибыль(дн) в процентах (ПУ(дн)%)
 * */
export function getDailyPLPrecentToMarketCurPrice(
  BaseDailyPLtoMarketCurPrice: number | null,
  DailyPLtoMarketCurPrice: number | null
): number | null {
  if (
    BaseDailyPLtoMarketCurPrice === null ||
    DailyPLtoMarketCurPrice === null
  ) {
    return null;
  }

  return BaseDailyPLtoMarketCurPrice === 0
    ? BaseDailyPLtoMarketCurPrice
    : (100 * DailyPLtoMarketCurPrice) / BaseDailyPLtoMarketCurPrice;
}
