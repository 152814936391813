import { TradingLimitsShowMode } from '../types/layout';

export const LimitsDisplayMap: Record<TradingLimitsShowMode, string> = {
  [TradingLimitsShowMode.NONE]: 'Выкл',
  [TradingLimitsShowMode.MONEY]: 'Деньги',
  [TradingLimitsShowMode.QUANTITY]: 'Штуки',
  [TradingLimitsShowMode.POINTS]: '-',
};

export const NPUDisplayMap: Record<TradingLimitsShowMode, string> = {
  [TradingLimitsShowMode.NONE]: 'Выкл',
  [TradingLimitsShowMode.MONEY]: 'Деньги',
  [TradingLimitsShowMode.QUANTITY]: 'Проценты',
  [TradingLimitsShowMode.POINTS]: 'Пункты',
};
