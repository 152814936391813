import { useCallback } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { NAME_BY_OBJECT_GROUP } from '@terminal/core/constants/objectGroupNames';
import {
  ObjectGroup,
  RazdelGroupType,
} from '@terminal/core/lib/client/entities';
import { PositionItem } from '@terminal/core/types/position';

import { ASSETS_COLORS_MAPPED } from '../lib/const';

import { useSelectedDisplayInstrumentType } from '../../../shared/hooks/useSelectedDisplayInstrumentType';

import { Asset } from '../model/types/PortfolioComposition';

export const useGroupedAssets = (
  positions: PositionItem[],
  isUnifiedMarket = false
) => {
  const { objectsTable } = useAlfaDirectContext();
  const isNameDisplayType = useSelectedDisplayInstrumentType() === 'name';

  const objects = objectsTable.getColumn('idObject');

  return useCallback(
    (assetId?: string) => {
      const isCurrencyPage = assetId === ObjectGroup.Currency.toString();
      const isFuturePage = assetId === ObjectGroup.Futures.toString();

      const grouped = positions.reduce<Map<ObjectGroup, Asset>>(
        (acc, position, index) => {
          let groupId: number;

          // Группировка по валютам
          if (isCurrencyPage) {
            const isCurrencyPosition =
              position.objectType.idObjectGroup === ObjectGroup.Currency;

            // Если сама позиция валютная, то берем ее object
            const baseAsset = isCurrencyPosition
              ? position.object
              : objects.get(position.object?.idObjectFaceUnit);

            if (!baseAsset) {
              return acc;
            }

            groupId = baseAsset.idObject;

            if (!acc.has(groupId)) {
              acc.set(groupId, {
                id: groupId,
                assetId: groupId.toString(),
                name: baseAsset[
                  isNameDisplayType ? 'nameObject' : 'symbolObject'
                ],
                price: 0,
                percent: 0,
              });
            }
            // Группировка фьючей
          } else if (isFuturePage) {
            if (position.objectType.idObjectGroup.toString() !== assetId) {
              return acc;
            }

            const baseAsset = position.object;

            if (!baseAsset) {
              return acc;
            }

            groupId = baseAsset.idObject;

            if (!acc.has(groupId)) {
              acc.set(groupId, {
                id: groupId,
                assetId: groupId.toString(),
                idObject: baseAsset.idObject,
                symbolObject: baseAsset.symbolObject,
                name: baseAsset[
                  isNameDisplayType ? 'nameObject' : 'symbolObject'
                ],
                price: 0,
                percent: 0,
                color: ASSETS_COLORS_MAPPED.get(index),
              });
            }
          } else {
            groupId = position.objectType.idObjectGroup;

            if (groupId === ObjectGroup.Receipts) {
              groupId = ObjectGroup.Stocks;
            }

            if (!acc.has(groupId)) {
              const name =
                groupId === ObjectGroup.Futures && isUnifiedMarket
                  ? 'Фьючерсы'
                  : NAME_BY_OBJECT_GROUP.get(Number(groupId))?.toString() ??
                    `#${groupId}`;

              acc.set(groupId, {
                id: groupId,
                assetId: groupId.toString(),
                idObject: position.object?.idObject,
                symbolObject: position.object?.symbolObject,
                name,
                price: 0,
                percent: 0,
                color: ASSETS_COLORS_MAPPED.get(groupId),
              });
            }
          }

          if (
            position.objectType.idObjectGroup === ObjectGroup.Currency &&
            position.idBalanceGroup === RazdelGroupType.DerivativesMarket
          ) {
            return acc;
          }

          let price = 0;

          if (position.objectType.idObjectGroup === ObjectGroup.Futures) {
            price =
              (isFuturePage
                ? position.NPLtoMarketCurPrice
                : position.dailyPLtoMarketCurPrice) || 0;
          } else if (position.torgPosCostRur !== null) {
            price =
              position.objectType.idObjectGroup === ObjectGroup.Bonds
                ? position.torgPosCostRur + position.NKDRur
                : position.torgPosCostRur;
          }

          if (price) {
            acc.get(groupId)!.price += price;
          }

          return acc;
        },
        new Map<ObjectGroup, Asset>()
      );

      return Array.from(grouped.values());
    },
    [positions, objects, isNameDisplayType, isUnifiedMarket]
  );
};
