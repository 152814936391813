import { smsSignOperation } from '@terminal/core/lib/rest/lkSmsSignOperation';

import { OrderDocumentsParams as OrderDocumentsRequest } from '../model/OrderDocumentsParams';

export interface OrderDocumentsParams {
  params: OrderDocumentsRequest;
}

export async function orderDocumentsSms(data: OrderDocumentsParams) {
  return await smsSignOperation(
    '/api/client/documents/order-create',
    'POST',
    data.params
  );
}
