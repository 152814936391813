import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { MoneyTransferToAlfaParams } from './types/MoneyTransferToAlfaParams';

export async function moneyTransferToAlfa(data: MoneyTransferToAlfaParams) {
  return await signOperation(
    '/api/transfers/money/alfa',
    'POST',
    data.params,
    data.signOptions
  );
}
