import isNull from 'lodash/isNull';
import orderBy from 'lodash/orderBy';

import { DEFAULT_LINE, DEFAULT_LINES } from '../../constants/orderBook';
import { roundFloatNumber } from '../format';

import { OrderBookLine } from '../../types/orderBook';
import { FinInfoExt } from '../../types/quotes';

type getTableLinesProps = {
  serverLines: OrderBookLine[];
  MIN_LINES_LENGTH?: number;
  defaultLines?: OrderBookLine[];
  DEFAULT_SPREAD_INDEX?: number;
  //Отсюда берем шаг цены
  finInfoExt?: FinInfoExt;
  //Параметр отвечающий за отображение цен без заявок
  isShowEmptyPrice?: boolean;
  //Параметр отвечающий за отображение спреда
  isShowSpread?: boolean;
};

interface ServerLinesAccumulator {
  up: OrderBookLine[];
  down: OrderBookLine[];
  minUpPrice: number;
  maxDownPrice: number;
  maxPrice: number;
  minPrice: number;
}

interface OrberbookTableLines {
  lines: OrderBookLine[];
  upSpreadIndex: number;
  downSpreadIndex: number;
}

//Функция для построения линий стакана
export const getOrderBookTableLines = ({
  serverLines,
  MIN_LINES_LENGTH = 40,
  defaultLines = DEFAULT_LINES,
  DEFAULT_SPREAD_INDEX = 20,
  finInfoExt,
  isShowEmptyPrice,
  isShowSpread,
}: getTableLinesProps): OrberbookTableLines => {
  const priceStep = finInfoExt?.priceStep;
  const halfMinLinesLength = MIN_LINES_LENGTH / 2;
  let lines: OrderBookLine[] = [...defaultLines];
  let upSpreadIndex = DEFAULT_SPREAD_INDEX;
  let downSpreadIndex = DEFAULT_SPREAD_INDEX;

  //Аукцион - состояние при котором для одной цены могут выставляться заявки как на продажу, так и на покупку
  if (serverLines.some((order) => order.SellQty > 0 && order.BuyQty > 0)) {
    lines = lines.filter(
      ({ Price, SellQty, BuyQty }) => Price || SellQty || BuyQty
    );
    lines = orderBy(serverLines, 'Price', 'desc');

    //Код ниже взят из ДТ и переписан на js
    let buyNearPivot = -1;
    let sellNearPivot = lines.length;

    lines.forEach((order, i) => {
      if (!isNull(order.SellQty)) {
        sellNearPivot = Math.min(sellNearPivot, i);
      }

      if (!isNull(order.BuyQty)) {
        buyNearPivot = Math.max(buyNearPivot, i);
      }
    });

    const spreadIndex = Math.round((buyNearPivot + 1 + sellNearPivot) / 2);
    //Код выше взят из ДТ и переписан на js

    //Чтобы стакан корректно отображался нужно добавить до 40 строк
    if (lines.length < MIN_LINES_LENGTH) {
      //Считаем сколько строк нужно добавить
      const addLinesCount = MIN_LINES_LENGTH - lines.length;

      lines.push(...new Array(addLinesCount).fill(DEFAULT_LINE));
    }

    return {
      lines,
      upSpreadIndex: spreadIndex,
      downSpreadIndex: spreadIndex,
    };
  }

  const {
    up,
    down,
    minUpPrice: minUpPriceRaw,
    maxDownPrice: maxDownPriceRaw,
    maxPrice: maxPriceRaw,
    minPrice: minPriceRaw,
  } = serverLines.reduce<ServerLinesAccumulator>(
    (acc, order) => {
      if (order.Price <= 0) {
        return acc;
      }

      if (order.SellQty > 0) {
        acc.up.push(order);
        acc.minUpPrice = Math.min(acc.minUpPrice, order.Price);
        acc.maxPrice = Math.max(acc.maxPrice, order.Price);
      }

      if (order.BuyQty > 0) {
        acc.down.push(order);
        acc.maxDownPrice = Math.max(acc.maxDownPrice, order.Price);
        acc.minPrice = Math.min(acc.minPrice, order.Price);
      }

      return acc;
    },
    {
      up: [],
      down: [],
      minUpPrice: Infinity,
      maxDownPrice: -Infinity,
      maxPrice: -Infinity,
      minPrice: Infinity,
    }
  );
  const [minUpPrice, maxDownPrice, maxPrice, minPrice] = [
    minUpPriceRaw,
    maxDownPriceRaw,
    maxPriceRaw,
    minPriceRaw,
  ].map((price) => (isFinite(price) ? price : null));

  const upPrices = orderBy(up, 'Price', 'asc');

  const downPrices = orderBy(down, 'Price', 'desc');

  //Если не нужно отображать спред и разреженный вид
  if (!isShowEmptyPrice && !isShowSpread) {
    //Заполняем 20 линий и в конце разворачиваем массив, так как пустые линии должны находиться в начале
    const upLines = new Array(halfMinLinesLength)
      .fill(DEFAULT_LINE)
      .map((line, index) => upPrices[index] || line)
      .reverse();
    //Пустые линии в конце, разворачивать не нужно
    const downLines = new Array(halfMinLinesLength)
      .fill(DEFAULT_LINE)
      .map((line, index) => downPrices[index] || line);

    lines = [...upLines, ...downLines];
  }

  //Если нужно показывать только спред
  if (isShowSpread && priceStep && !isShowEmptyPrice) {
    //Заполняем цены спреда
    let spreadPrices: OrderBookLine[] = [];

    if (
      minUpPrice &&
      maxDownPrice &&
      roundFloatNumber(minUpPrice - maxDownPrice - priceStep) > 0
    ) {
      for (
        let i = roundFloatNumber(minUpPrice - priceStep);
        i > maxDownPrice;
        i = roundFloatNumber(i - priceStep)
      ) {
        spreadPrices.push({
          Price: roundFloatNumber(i),
          BuyQty: 0,
          SellQty: 0,
          LineId: -1,
          Revision: 0n,
          Yield: 0,
        });
      }
    }

    //Заполняем 20 линий и в конце разворачиваем массив, так как пустые линии должны находиться в начале
    const upLines = new Array(halfMinLinesLength)
      .fill(DEFAULT_LINE)
      .map((line, index) => upPrices[index] || line)
      .reverse();
    //Пустые линии в конце, разворачивать не нужно
    const downLines = new Array(halfMinLinesLength)
      .fill(DEFAULT_LINE)
      .map((line, index) => downPrices[index] || line);

    //Спред цены вставляем между
    lines = [...upLines, ...spreadPrices, ...downLines];
    //Нижняя граница смещается на кол-во спред цен
    downSpreadIndex = downSpreadIndex + spreadPrices.length;
  }

  //Если нужно показывать разреженный вид
  if (isShowEmptyPrice && priceStep) {
    if (maxPrice && minPrice) {
      //Идем от самой максимальной цены заявки к минимальной с шагом равным шагу цены
      for (
        let price = maxPrice, index = 0;
        price >= minPrice;
        price = roundFloatNumber(price - priceStep), index++
      ) {
        //Если заявка с такой ценой есть
        const currentLine = serverLines.find((line) => line.Price === price);
        //Иначе просто цена с учетом шага
        const defaultLine = {
          Price: roundFloatNumber(price),
          BuyQty: 0,
          SellQty: 0,
          LineId: -1,
          Revision: 0n,
          Yield: 0,
        };

        if (!isShowSpread && minUpPrice && maxDownPrice) {
          //Если не нужно показывать спред в разреженном стакане,то пропускаем цену из спреда
          if (price < minUpPrice && price > maxDownPrice) {
            //Нужно уменьшить индекс, так как мы  пропустили шаг цены (удаляем строку)
            index--;
            continue;
          }
        }

        // Так как изначально в линиях первые 40 элементов всегда предзаполнены, то для этих индексов производим замену
        if (index < 40) {
          lines[index] = currentLine ?? defaultLine;
          // Далее просто добавляем новые строки
        } else {
          lines.push(currentLine ?? defaultLine);
        }
      }
    }

    upSpreadIndex = lines.findIndex((line) => line.Price === minUpPrice) + 1;
    downSpreadIndex = lines.findIndex((line) => line.Price === maxDownPrice);
  }

  return {
    lines,
    upSpreadIndex,
    downSpreadIndex,
  };
};
