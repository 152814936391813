import { IconButton } from '@alfalab/core-components/icon-button';
import { Radio } from '@alfalab/core-components/radio';
import { Typography } from '@alfalab/core-components/typography';
import { CloudMIcon } from '@alfalab/icons-glyph/CloudMIcon';
import { TrashCanMIcon } from '@alfalab/icons-glyph/TrashCanMIcon';

import { Column } from '@terminal/common/ui';
import { CloudConfigListItem } from '@terminal/lk/features/CloudConfiguration';

import { formatDateTime } from '../../lib/formatDateTime';

import styles from './WorkspaceConfigurationCloud.module.css';

export function getColumns(params: {
  onDeleteClick: (config: CloudConfigListItem) => void;
  selectedCloudConfigName: string;
  onClick: (id: string) => void;
}): Column<CloudConfigListItem>[] {
  const { onDeleteClick, onClick, selectedCloudConfigName } = params;

  return [
    {
      id: 'select',
      customCellClassName: styles.selectionColumn,
      Cell: ({ row }) => {
        const { configName, configUid } = row.original;

        return (
          <Radio
            name="select-cloud-config"
            checked={configName === selectedCloudConfigName}
            value={configUid}
            onChange={() => onClick(configUid)}
          />
        );
      },
    },
    {
      id: 'name',
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <div className={styles.infoWrapper}>
            <div className={styles.label}>
              <Typography.Text view="secondary-large" weight="medium">
                {original.configName}
              </Typography.Text>
              <CloudMIcon
                width={16}
                height={16}
                color="var(--color-light-text-secondary)"
                className={styles.icon}
              />
            </div>
            <Typography.Text view="secondary-small" color="secondary">
              Сохранено {formatDateTime(new Date(original.dtUpdated))}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      id: 'remove',
      Cell: ({ row }) => {
        return (
          <IconButton
            size="xxs"
            icon={TrashCanMIcon}
            onClick={(event) => {
              event.stopPropagation();
              onDeleteClick(row.original);
            }}
          />
        );
      },
    },
  ];
}
