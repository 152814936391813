import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getScansList } from '../api/getScansList';

export const useScansList = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'documents/scans-list'],
    async () => {
      if (!treaty) {
        return;
      }

      return await getScansList({ treaty });
    },
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса документов',
          text: error?.message || '',
        });
      },
    }
  );
};
