import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Row } from 'react-table';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { Table } from '@terminal/common/ui';
import { useWidgetProps } from '@terminal/desktop/shared/hooks/useWidgetProps';
import {
  CloudConfigListItem,
  prepareConfigForCloud,
} from '@terminal/lk/features/CloudConfiguration';

import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';
import { getColumns } from './helpers';

import { useWorkspaceConfigurationStore } from '../../model/store/useWorkspaceConfigurationStore';

import { WorkspaceConfigurationScreen } from '../../model/types';

import styles from './WorkspaceConfigurationCloud.module.css';

const MAX_CLOUD_AMOUNT = 10;

export const WorkspaceConfigurationCloud = () => {
  const {
    cloudConfigHooks: {
      useCloudConfigList,
      useUploadCloudConfigMutation,
      useDeleteCloudConfigMutation,
      useUpdateCloudConfigMutation,
    },
  } = useWidgetProps();
  const [currentDeleteConfig, setCurrentDeleteConfig] = useState({
    isOpen: false,
    id: '',
    name: '',
  });
  const [currentUpdateConfig, setCurrentUpdateConfig] = useState({
    isOpen: false,
    name: '',
  });
  const { data: configList } = useCloudConfigList();
  const { mutate: uploadConfig, isLoading: isUploading } =
    useUploadCloudConfigMutation();
  const { mutate: deleteConfig, isLoading: isDeleting } =
    useDeleteCloudConfigMutation();
  const { mutateAsync: updateConfig, isLoading: isUpdating } =
    useUpdateCloudConfigMutation();
  const cloudConfigurations = useMemo(
    () => configList?.items ?? [],
    [configList]
  );
  const isMaxAmountReached = cloudConfigurations.length >= MAX_CLOUD_AMOUNT;
  const [activeConfiguration, setScreen, screen] =
    useWorkspaceConfigurationStore((state) => [
      state.cloudSelectedConfiguration,
      state.setScreen,
      state.screen,
    ]);
  const [name, setName] = useState(
    activeConfiguration?.name ?? 'Моя конфигурация'
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDeleteConfig = useCallback((config: CloudConfigListItem) => {
    setCurrentDeleteConfig({
      isOpen: true,
      name: config.configName,
      id: config.configUid,
    });
  }, []);

  const handleConfigSelect = useCallback(
    (id: string) => {
      const selectedConfig = cloudConfigurations.find(
        (config) => config.configUid === id
      );

      if (selectedConfig) {
        setName(selectedConfig.configName);
      }
    },
    [cloudConfigurations]
  );

  const columns = useMemo(() => {
    return getColumns({
      onDeleteClick: handleDeleteConfig,
      onClick: handleConfigSelect,
      selectedCloudConfigName: name,
    });
  }, [handleDeleteConfig, name, handleConfigSelect]);

  // При достижении максимального количества конфигураций, разрешена только замена
  const isExistingConfigMaxAmount = useMemo(() => {
    return (
      isMaxAmountReached &&
      !cloudConfigurations.some((config) => config.configName === name)
    );
  }, [cloudConfigurations, isMaxAmountReached, name]);

  const onNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setName(value);
  };

  const onSaveButtonClick = async () => {
    if (!activeConfiguration) {
      return;
    }

    const hasSameConfigName = cloudConfigurations.some(
      (config) => config.configName === name
    );

    if (hasSameConfigName) {
      setCurrentUpdateConfig({
        isOpen: true,
        name,
      });
    } else {
      const newCloudConfig = await prepareConfigForCloud(activeConfiguration);

      uploadConfig(
        {
          name,
          config: newCloudConfig,
        },
        {
          onSuccess: () => {
            setScreen(WorkspaceConfigurationScreen.List);
          },
        }
      );
    }
  };

  const handleCloseDeleteConfigurationModal = () => {
    setCurrentDeleteConfig({
      isOpen: false,
      name: '',
      id: '',
    });
  };

  const handleCloseUpdateConfigurationModal = () => {
    setCurrentUpdateConfig({
      isOpen: false,
      name: '',
    });
  };

  const handleUpdateConfig = async () => {
    if (!activeConfiguration) {
      return;
    }

    const newCloudConfig = await prepareConfigForCloud(activeConfiguration);

    const selectedConfig = cloudConfigurations.find(
      (config) => config.configName === name
    );

    if (!selectedConfig) {
      return;
    }

    await updateConfig(
      {
        id: selectedConfig.configUid,
        name,
        config: newCloudConfig,
      },
      {
        onSuccess: () => {
          setScreen(WorkspaceConfigurationScreen.List);
        },
      }
    );
    handleCloseUpdateConfigurationModal();
  };

  // Если изменилась конфигурация, которую сохраняем в облако, ставим её имя
  useEffect(() => {
    if (activeConfiguration?.name) {
      setName(activeConfiguration.name);
    }
  }, [activeConfiguration?.name]);

  // Так как сайдбар рисуется при открытии модального окна, фокус делаем при открытии сайдбара
  useEffect(() => {
    if (screen === WorkspaceConfigurationScreen.Cloud) {
      inputRef.current?.focus({
        preventScroll: true,
      });
    }
  }, [screen]);

  return (
    <>
      <div className={styles.root}>
        <Typography.Text view="primary-small" weight="bold">
          Сохранить конфигурацию в облаке
        </Typography.Text>
        {isMaxAmountReached ? (
          <>
            <Gap size="2xs" />
            <div className={styles.attentionWrapper}>
              <ExclamationCircleMIcon
                width={16}
                height={16}
                color="var(--color-light-text-attention"
              />
              <Typography.Text view="secondary-large" color="attention">
                Вы можете сохранить в облаке до {MAX_CLOUD_AMOUNT} конфигураций.
                Выберите конфигурацию для замены. Если хотите сохранить новую
                конфигурацию, сначала удалите ненужную.
              </Typography.Text>
            </div>
          </>
        ) : (
          <>
            <Typography.Text view="secondary-large" color="secondary">
              Вы можете сохранить в облаке до {MAX_CLOUD_AMOUNT} конфигураций.
            </Typography.Text>
            <Gap size="s" />
            <InputDesktop
              ref={inputRef}
              size="s"
              label="Название"
              labelView="outer"
              block
              clear
              value={name}
              onChange={onNameInputChange}
              onClear={() => setName('')}
              className={styles.input}
              addonsClassName={styles.inputAddons}
            />
          </>
        )}
        <Gap size="xl" />
        {cloudConfigurations.length !== 0 && (
          <>
            <Typography.Text view="secondary-small" color="secondary">
              Выберите конфигурацию для замены
            </Typography.Text>
            <Gap size="xs" />

            <Table
              withHeader={false}
              data={cloudConfigurations}
              columns={columns}
              getRowProps={(row) => {
                const { getRowProps, original } =
                  row as Row<CloudConfigListItem>;

                return {
                  key: getRowProps().key,
                  onClick: () => {
                    handleConfigSelect?.(original.configUid);
                  },
                };
              }}
            />
          </>
        )}

        <div className={styles.footer}>
          <ButtonDesktop
            onClick={onSaveButtonClick}
            size="xs"
            disabled={!name || isExistingConfigMaxAmount}
            loading={isUploading || isDeleting || isUpdating}
            leftAddons={
              <CheckmarkMIcon
                width={16}
                height={16}
                fill="var(--color-light-graphic-tertiary-inverted)"
                className={styles.checkIcon}
              />
            }
          >
            <Typography.Text view="secondary-large" weight="medium">
              Сохранить
            </Typography.Text>
          </ButtonDesktop>
        </div>
      </div>

      <WorkspaceConfigurationModal
        title="Удалить конфигурацию?"
        open={currentDeleteConfig.isOpen}
        onClose={handleCloseDeleteConfigurationModal}
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: handleCloseDeleteConfigurationModal,
          },
          {
            children: 'Удалить',
            view: 'secondary',
            onClick: () => {
              deleteConfig(currentDeleteConfig.id);
              handleCloseDeleteConfigurationModal();
            },
          },
        ]}
      >
        Вы уверены, что хотите удалить конфигурацию «{currentDeleteConfig.name}
        »? Вы не сможете ее восстановить.
      </WorkspaceConfigurationModal>

      <WorkspaceConfigurationModal
        title="Заменить конфигурацию?"
        open={currentUpdateConfig.isOpen}
        onClose={handleCloseUpdateConfigurationModal}
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: handleCloseUpdateConfigurationModal,
          },
          {
            children: 'Заменить',
            view: 'secondary',
            onClick: handleUpdateConfig,
            loading: isUpdating,
          },
        ]}
      >
        Конфигурация «{currentUpdateConfig.name}» уже существует. Заменить ее?
      </WorkspaceConfigurationModal>
    </>
  );
};
