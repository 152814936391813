import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';
import { useSignSmsDialog } from '@terminal/lk/legacy/components/SmsSignDialog';

import { orderDocuments } from '../api/orderDocuments';
import { orderDocumentsSms } from '../api/orderDocumentsSms';
import { OrderDocumentsParams } from '../model/OrderDocumentsParams';
import { SignType } from '../model/SignType';

interface UseDocumentsOrderAIParams {
  successCallback?: () => void;
}

export const useDocumentsOrderAI = ({
  successCallback,
}: UseDocumentsOrderAIParams) => {
  const addNotification = useNotification();

  const browserSignProps = useMutation(orderDocuments, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при заказе документа',
      });
    },
  });

  const smsSignProps = useSignSmsDialog({
    initAction: orderDocumentsSms,
    errorMsg: {
      title: 'Ошибка заказа документа',
      text: 'Попробуйте ещё раз или напишите нам в чат',
    },
  });

  const { createPending, smsSending, create, isSuccess } = smsSignProps;
  const { mutateAsync, isLoading } = browserSignProps;

  const handleOrderDocument = useCallback(
    async (params: OrderDocumentsParams & SignType) => {
      if (params.signType === 'Simple') {
        delete params.signType;
        await create({ params });
      } else {
        delete params.signType;
        await mutateAsync({
          params,
          signOptions: {
            successCallback,
          },
        });
      }
    },
    [create, mutateAsync, successCallback]
  );

  useEffect(() => {
    if (isSuccess) {
      successCallback?.();
    }
  }, [isSuccess, successCallback]);

  return {
    browserSignProps,
    smsSignProps,
    isLoading: isLoading || smsSending || createPending,
    orderDocument: handleOrderDocument,
  };
};
