import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getBaseChartConfig,
  getCommonInstrumentsConfig,
  getPositionsCommonConfig,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHART_RIGHT_CHARTIQ_SETTINGS,
  TRADE_CHARTIQ_SMALL,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const swingSmall = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const leftRow = new Row(50);
    const rightRow = new Row(50);

    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );

    const instrumentsTabSet = new TabSet(50);

    instrumentsTabSet.addChildren([leftInstrumentsTab]);

    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const operationTab = new Tab(Widget.OPERATIONS, {});

    const leftBottomTabSet = new TabSet(50);

    leftBottomTabSet.addChildren([positionsTab, operationTab]);

    leftRow.addChildren([instrumentsTabSet, leftBottomTabSet]);

    const rightChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_CHARTIQ_SMALL
    );
    const rightChartTabset = new TabSet(100);

    rightChartTabset.addChildren([rightChartTab]);

    rightRow.addChildren([rightChartTabset]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink]);
  };
  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const leftChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );
    const leftChartTabset = new TabSet(50);

    leftChartTabset.addChildren([leftChartTab, leftInstrumentsTab]);

    const rightChartTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(50);

    rightChartTabset.addChildren([rightChartTab]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftChartTabset, rightChartTabset]);

    return new Layout('Графики', chartRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Среднесрочная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
