import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SelectDesktop } from '@alfalab/core-components/select/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { LK_CERTIFICATES } from '@terminal/core/env';
import { useCertificatesAll } from '@terminal/core/hooks/useCertificates';
import { CertificateStateType } from '@terminal/core/lib/client/entities';
import { FormControlSummary } from '@terminal/lk/shared';

import styles from './OtherCertificates.module.css';

export const OtherCertificates = () => {
  const {
    certificates,
    iconColor,
    isEnrollStepInProgress,
    certificatesCountRest,
    certificatesOptions,
    selectedOptionKey,
    selectedCertificateStateStr,
    onClickOptionSelect,
    onClickCertificateConfirm,
    onClickCertificateReject,
    selectedCertificate,
    startDate,
    endDate,
  } = useCertificatesAll();

  const selectedOption = certificatesOptions.find(
    (option) => option.key === selectedOptionKey
  );

  if (!certificates || certificates.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
        defaultMargins={false}
      >
        Другие сертификаты{' '}
        <TooltipDesktop
          trigger="hover"
          position="right"
          content="Сертификаты, выпущенные для других устройств"
          targetClassName={styles.tooltipTarget}
        >
          <InformationCircleLineMIcon className={styles.tooltipIcon} />
        </TooltipDesktop>
      </Typography.Text>
      <Gap size="m" />
      <div className={styles.container}>
        <SuperEllipse size={48} className={styles.icon}>
          <ShieldMIcon color={iconColor} />
        </SuperEllipse>
        <div className={styles.certificateRows}>
          <SelectDesktop
            label="Сертификат электронной подписи"
            labelView="outer"
            className={styles.select}
            options={certificatesOptions}
            selected={selectedOption}
            optionsListWidth="field"
            onChange={onClickOptionSelect}
            block
            hint={
              certificatesCountRest > 0
                ? `Вы можете создать ещё ${certificatesCountRest}`
                : 'Достигнуто максимальное количество сертификатов'
            }
          />
          {selectedCertificate && (
            <>
              <Gap size="s" />
              <FormControlSummary label="Статус">
                {selectedCertificateStateStr}
              </FormControlSummary>
              <Gap size="s" />
              <FormControlSummary label="Дата действия">
                {startDate}
                &nbsp;–&nbsp;
                {endDate}
              </FormControlSummary>
              <Gap size="s" />
              <FormControlSummary label="Владелец">
                {selectedCertificate?.parsedPayload?.owner}
              </FormControlSummary>
              <Gap size="l" />
              <div className={styles.buttons}>
                <Row gutter={16}>
                  {selectedCertificate.state ===
                    CertificateStateType.Certifying && (
                    <Col width={6}>
                      <ButtonDesktop
                        view="secondary"
                        size="s"
                        leftAddons={<CheckmarkMIcon width={18} height={18} />}
                        onClick={onClickCertificateConfirm}
                        disabled={isEnrollStepInProgress}
                        loading={isEnrollStepInProgress}
                        block
                      >
                        Подтвердить
                      </ButtonDesktop>
                    </Col>
                  )}
                  {LK_CERTIFICATES && (
                    <Col width={6}>
                      <ButtonDesktop
                        view="tertiary"
                        size="s"
                        leftAddons={<CrossMIcon width={18} height={18} />}
                        onClick={onClickCertificateReject}
                        disabled={isEnrollStepInProgress}
                        loading={isEnrollStepInProgress}
                        block
                      >
                        Отозвать
                      </ButtonDesktop>
                    </Col>
                  )}
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
