import flatten from 'lodash/flatten';

import { WorkspaceConfiguration } from '../../store/slices/workspaceConfigurationsSlice';

import { Widget } from '../../types/layout';

export const findAllWatchlistIds = (configuration: WorkspaceConfiguration) => {
  const ids = new Set();

  function traverse(node) {
    if (node.component === Widget.WATCHLIST && node.config?.selectedListId) {
      ids.add(node.config.selectedListId);
    }

    if (node.children) {
      node.children.forEach((child) => traverse(child));
    }
  }

  traverse({
    children: flatten(
      configuration.lastAutoSavedConfig.layouts.map((l) => l.layout)
    ),
  });

  return [...ids];
};
