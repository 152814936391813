import { format, startOfDay, subMonths } from 'date-fns';
import React, { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { ChartPieLineMIcon } from '@alfalab/icons-glyph/ChartPieLineMIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import { usePortfolioAnalytics } from '@terminal/core/hooks/usePortfolioAnalytics';
import { getStringDate } from '@terminal/core/lib/format';
import { getServerTime } from '@terminal/core/lib/services/time';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';
import {
  DateFilterType,
  DateFilterValue,
} from '@terminal/core/types/operation';

import {
  PortfolioAnalyticsWarningContainer,
  Spinner,
} from '../../../../shared';
import { MARGIN_TEST_ID, START_ANALYTICS_DATE } from '../../lib/const';
import { FinResultRates } from '../../model/FinResult';
import { BalanceSummary } from '../BalanceSummary';
import { DateFilters } from '../DateFilters';
import { IncomeByAssetTypes } from '../IncomeByAssetTypes';
import { InputOutput } from '../InputOutput';
import { MarginTradeBanner } from '../MarginTradeBanner';
import { PaymentsAndExpenses } from '../PaymentsAndExpenses';
import { PortfolioCharts } from '../PortfolioCharts';
import { Rates } from '../Rates';

import { useFinResultByAccount } from '../../hooks/useFinResultByAccount';
import { useTestingList } from '../../hooks/useTestingList';

import styles from './PortfolioReport.module.css';

interface Props {
  selectedAccountId: number;
  onClose: () => void;
}

export const PortfolioReport = ({ selectedAccountId, onClose }: Props) => {
  const [dateFilter, setDateFilter] = useState<DateFilterValue>(() => {
    // Всегда берем Московское время, чтобы сервисы корректно возвращали данные
    const TODAY = startOfDay(getServerTime());

    return {
      type: DateFilterType['30DAYS'],
      valueFrom: {
        value: getStringDate(subMonths(TODAY, 1)),
        date: subMonths(TODAY, 1).getTime(),
      },
      valueTo: {
        value: getStringDate(TODAY),
        date: TODAY.getTime(),
      },
    };
  });

  const dateFrom = format(dateFilter.valueFrom.date, 'yyyy-MM-dd');
  const dateTo = format(dateFilter.valueTo.date, 'yyyy-MM-dd');

  const {
    data: portfolioAnalytics,
    isIdle: isPortfolioAnalyticsIdle,
    isSuccess: isPortfolioAnalyticsSuccess,
    isLoading: isPortfolioAnalyticsLoading,
    isError: isPortfolioAnalyticsError,
    refetch: refetchAnalytics,
  } = usePortfolioAnalytics({
    accountId: selectedAccountId,
    dateFrom,
    dateTo,
  });

  const {
    data: finResultByAccount,
    isIdle: isFinResultByAccountIdle,
    isSuccess: isFinResultByAccountSuccess,
    isLoading: isFinResultByAccountLoading,
    isError: isFinResultByAccountError,
  } = useFinResultByAccount({
    accountId: selectedAccountId,
    dateFrom,
    dateTo,
  });

  const { data: testingList } = useTestingList(selectedAccountId);
  const isMarginTradeTestPassed = Boolean(
    testingList?.testStatusItems?.find((test) => test.testId === MARGIN_TEST_ID)
      ?.completed
  );

  const isNoData = Boolean(
    portfolioAnalytics &&
      (finResultByAccount || isFinResultByAccountError) &&
      !checkHasData(portfolioAnalytics, finResultByAccount)
  );

  const isEveryThingLoaded =
    isPortfolioAnalyticsSuccess && isFinResultByAccountSuccess;

  return (
    <div className={styles.container}>
      <DateFilters value={dateFilter} onChange={setDateFilter} />

      {isPortfolioAnalyticsLoading || isPortfolioAnalyticsIdle ? (
        <Spinner />
      ) : null}

      {isPortfolioAnalyticsError ? (
        <PortfolioAnalyticsWarningContainer
          Icon={ToolsMIcon}
          title="Не получилось загрузить"
          description={
            <>
              Уже знаем, в чём дело, и чиним.
              <br />
              Попробуйте обновить или зайти позже
            </>
          }
          actions={
            <ButtonDesktop
              view="secondary"
              color="quaternary"
              size="xs"
              onClick={() => refetchAnalytics()}
            >
              Повторить
            </ButtonDesktop>
          }
        />
      ) : null}

      {isNoData ? (
        <PortfolioAnalyticsWarningContainer
          Icon={ChartPieLineMIcon}
          title="Тут ничего нет"
          description="На вашем счёте нет активов"
        />
      ) : null}

      <Gap size="xl" />
      {isNoData ? null : <BalanceSummary analytics={portfolioAnalytics} />}
      <PortfolioCharts
        accountId={selectedAccountId}
        dateFrom={dateFilter.valueFrom?.date || START_ANALYTICS_DATE.getTime()}
        dateTo={dateFilter.valueTo?.date || Date.now()}
        isPortfolioSuccess={isPortfolioAnalyticsSuccess}
        isEveryThingLoaded={isEveryThingLoaded}
      />
      <MarginTradeBanner
        isMarginTradeTestPassed={isMarginTradeTestPassed}
        analytics={portfolioAnalytics}
        onClose={onClose}
      />

      <IncomeByAssetTypes
        analytics={portfolioAnalytics}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        selectedAccountId={selectedAccountId}
      />
      <PaymentsAndExpenses
        analytics={portfolioAnalytics}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        selectedAccountId={selectedAccountId}
      />

      <InputOutput
        analytics={portfolioAnalytics}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        isEveryThingLoaded={isEveryThingLoaded}
      />

      {isFinResultByAccountLoading || isFinResultByAccountIdle ? (
        !isPortfolioAnalyticsLoading && !isPortfolioAnalyticsIdle && <Spinner />
      ) : (
        <Rates
          finResultRates={finResultByAccount}
          tooltip={
            <>
              Показатели отображаются по закрытым позициям за конкретный период.
              <br />
              <br />
              В них включены:
              <br />
              -общее количество всех сделок и их детализация на прибыльные и
              убыточные;
              <br />
              -лучшая и худшая сделки;
              <br />
              -средняя прибыль по прибыльным сделкам;
              <br />
              -средний убыток по убыточным сделкам;
              <br />
              -средний результат по всем сделкам.
            </>
          }
        />
      )}
    </div>
  );
};

function checkHasData(
  analytics?: PortfolioAnalyticsResult,
  finResultRates?: FinResultRates
): boolean {
  if (!analytics && !finResultRates) {
    return false;
  }

  return (
    [
      analytics?.endNAV?.value,
      analytics?.sharesRevenue?.value,
      analytics?.dividends?.value,
      analytics?.currenciesRevenue?.value,
      analytics?.bondsRevenue?.value,
      analytics?.fundsRevenue?.value,
      analytics?.pmetsRevenue?.value,
      analytics?.derivativesRevenue?.value,
      analytics?.couponRevenue?.value,
      analytics?.taxes?.value,
      analytics?.commissions?.value,
      analytics?.marginTrade?.value,
      analytics?.inputs?.value,
      analytics?.outputs?.value,
      finResultRates?.tradesCount,
    ].filter((value) => Boolean(value)).length > 0
  );
}
