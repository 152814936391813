import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { FormControlSummary } from '@terminal/lk/shared';

interface ReceiveEmailProps {
  email?: string;
  emailConfirmed: boolean;
  onEmailChange?: () => void;
}

export const ReceiveEmail = ({
  email,
  emailConfirmed,
  onEmailChange,
}: ReceiveEmailProps) => {
  if (!emailConfirmed) {
    return null;
  }

  return (
    <Row>
      <Col width={12}>
        <FormControlSummary label="Адрес электронной почты">
          {email}
        </FormControlSummary>
        <Gap size="2xs" />
        <Typography.Text view="secondary-large" color="secondary">
          Адрес можно изменить в{' '}
          <Link view="secondary" onClick={onEmailChange}>
            Настройках профиля
          </Link>
        </Typography.Text>
      </Col>
    </Row>
  );
};
