import sumBy from 'lodash/sumBy';

import { ObjectGroup } from '../client/entities';

import { PositionItem } from '../../types/position';

/**
 * @returns Стоимость всех позиций в портфеле в рублевом эквиваленте  на предыдущий торговый день (ВхБаланс)
 * */
export function calculatePrevBalance(positions: PositionItem[]) {
  const notFuturesPositions = positions.filter(
    (position) => position.objectType?.idObjectGroup !== ObjectGroup.Futures
  );

  return sumBy(notFuturesPositions, (position) =>
    Math.abs(position.backPosCostRur)
  );
}
