import { formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';

export const getTopUpButtonLabel = (amount: number | null) => {
  if (amount) {
    const amountFormated = formatAmount({
      value: amount,
      currency: 'RUR',
      minority: MINORITY,
      view: 'default',
    });

    return `Пополнить на ${amountFormated.formattedWithCurrency}`;
  }

  return 'Пополнить';
};
