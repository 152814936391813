import cn from 'classnames';
import React, { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Radio } from '@alfalab/core-components/radio';
import { RadioGroupDesktop } from '@alfalab/core-components/radio-group/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';

import { WORKSPACE_CONFIGURATION_MAX_COUNT } from '@terminal/core/constants/workspaces';
import { getStringDate } from '@terminal/core/lib/format';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { inactiveConfigurationsSelector } from '@terminal/core/store/selectors/workspaceConfigurations/inactiveConfigurationsSelector';

import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';

import { useWorkspaceConfigurationStore } from '../../model/store/useWorkspaceConfigurationStore';

import { WorkspaceConfigurationScreen } from '../../model/types';

import styles from './WorkspaceConfigurationAdd.module.css';

const regex = new RegExp('([A-Za-zА-Яа-я0-9])+');
const defaultReplaceModalState = {
  isOpen: false,
  id: '',
  name: '',
};

export const WorkspaceConfigurationAdd = () => {
  const [name, setName] = useState('');
  const [selectedSaveAsConfiguration, setSelectedSaveAsConfiguration] =
    useState<string | null>(null);
  const [replaceModalState, setReplaceModalState] = useState(
    defaultReplaceModalState
  );

  const [workspaceConfigurations, addNewConfiguration, editConfiguration] =
    useStore(
      (state) => [
        state.workspaceConfigurations,
        state.addNewConfiguration,
        state.editConfiguration,
      ],
      shallow
    );

  const activeConfiguration = useStore(activeConfigurationSelector);
  const otherConfigurations = useStore(inactiveConfigurationsSelector);

  const setScreen = useWorkspaceConfigurationStore((state) => state.setScreen);

  const handleReset = () => {
    setName('');
    setReplaceModalState(defaultReplaceModalState);
    setSelectedSaveAsConfiguration(null);
  };

  const handleClickSaveConfiguration = () => {
    if (selectedSaveAsConfiguration) {
      setReplaceModalState({
        isOpen: true,
        id: selectedSaveAsConfiguration,
        name:
          otherConfigurations.find(
            (config) => config.id === selectedSaveAsConfiguration
          )?.name || '',
      });
    } else {
      addNewConfiguration(name);
      setScreen(WorkspaceConfigurationScreen.List);
      handleReset();
    }
  };

  const isNameValid = regex.test(name);
  const isCountValid =
    workspaceConfigurations.length < WORKSPACE_CONFIGURATION_MAX_COUNT;

  const isDisabled =
    !isCountValid || (!isNameValid && !selectedSaveAsConfiguration);

  return (
    <div>
      <Typography.Text
        view="primary-small"
        tag="div"
        weight="bold"
        className={styles.quaternary}
      >
        Сохранить конфигурацию
      </Typography.Text>

      <Gap size="xl" />

      <InputDesktop
        label="Название"
        labelView="outer"
        value={name}
        block
        required
        size="s"
        placeholder="Моя конфигурация"
        onChange={(e) => setName(e.target.value)}
      />

      <Gap size="xl" />

      <Typography.Text
        view="secondary-small"
        tag="div"
        weight="medium"
        color="secondary"
      >
        Или выберите конфигурацию для замены
      </Typography.Text>

      <Gap size="3xs" />

      <RadioGroupDesktop
        direction="vertical"
        value={selectedSaveAsConfiguration}
        onChange={(_, { value }) => {
          setSelectedSaveAsConfiguration(value);
        }}
      >
        {otherConfigurations.map((config) => (
          <Radio
            key={config.id}
            value={config.id}
            label={config.name}
            hint={`Сохранено ${getStringDate(
              new Date(config.lastManualSavedConfig.timeStamp)
            )}`}
            onClick={() => {
              if (config.id === selectedSaveAsConfiguration) {
                setSelectedSaveAsConfiguration(null);
              }
            }}
            className={cn(
              styles.radioItem,
              config.id === selectedSaveAsConfiguration &&
                styles.selectedRadioItem
            )}
          />
        ))}
      </RadioGroupDesktop>

      <div className={styles.footerButtons}>
        <div>
          <ButtonDesktop
            size="xs"
            view="secondary"
            disabled={isDisabled}
            onClick={handleClickSaveConfiguration}
            leftAddons={<AddMIcon width={18} height={18} />}
          >
            Сохранить
          </ButtonDesktop>
        </div>
      </div>

      <WorkspaceConfigurationModal
        open={replaceModalState.isOpen}
        onClose={() => setReplaceModalState(defaultReplaceModalState)}
        title="Заменить конфигурацию?"
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: () => setReplaceModalState(defaultReplaceModalState),
          },
          {
            children: 'Заменить',
            view: 'secondary',
            onClick: () => {
              editConfiguration(replaceModalState.id, {
                ...activeConfiguration,
                name: replaceModalState.name,
              });

              handleReset();

              setScreen(WorkspaceConfigurationScreen.List);
            },
          },
        ]}
        className={styles.replaceModal}
      >
        Конфигурация «{replaceModalState.name}» уже существует. Заменить ее?
      </WorkspaceConfigurationModal>
    </div>
  );
};
