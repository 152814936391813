import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns дневной объем на покупку
 * @param positionItem элемент в портфеле
 * */
export function getDailyBuyVolume(
  position: SubAccountPositionItem,
  objectType: ObjectTypeItem,
  TorgPos: number,
  BackPos: number,
  PrevPrice: number,
  Price: number | null,
  nominal: number,
  NominalCurPrice: number,
  MarketCurPrice: number,
  TrnIn: number,
  PriceStep: number,
  PriceStepCost: number
): number | null {
  if (position?.sessionBuyVal !== 0) {
    return position?.sessionBuyVal;
  }

  if (Price === null) {
    return null;
  }

  if (TorgPos <= BackPos + TrnIn) {
    return 0;
  }

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Futures: {
      return (
        ((TorgPos - BackPos - TrnIn) *
          (PrevPrice + Price) *
          (PriceStepCost / PriceStep)) /
        2
      );
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals: {
      return ((TorgPos - BackPos - TrnIn) * (PrevPrice + Price)) / 2;
    }
    case ObjectGroup.Bonds: {
      return (
        ((TorgPos - BackPos - TrnIn) *
          (PrevPrice + Price) *
          nominal *
          NominalCurPrice) /
        200
      );
    }
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts: {
      return (
        ((TorgPos - BackPos - TrnIn) * (PrevPrice + Price) * MarketCurPrice) / 2
      );
    }

    default: {
      return 0;
    }
  }
}
