import { useQuery } from 'react-query';

import { getPortfolioAnalytics, InvestQueryKeys } from '../lib/rest/investApi';
import useNotification from './useNotification';

import { NotificationType } from '../types/ui';

export const usePortfolioAnalytics = (params) => {
  const addNotification = useNotification();

  return useQuery(
    [InvestQueryKeys.PortfolioAnalytics, params],
    () => getPortfolioAnalytics(params),
    {
      onError: (error: any) =>
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
