import {
  NumberInputDesktop,
  NumberInputDesktopProps,
} from '@alfalab/core-components/number-input/desktop';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';

interface HardcopyFieldsProps {
  count: string;
  onCountChange: (count: string) => void;
  comment: string;
  onCommentChange: (count: string) => void;
  hideCount?: boolean;
  hideComment?: boolean;
}

export const HardcopyFields = ({
  count,
  onCountChange,
  comment,
  onCommentChange,
  hideCount = false,
  hideComment = false,
}: HardcopyFieldsProps) => {
  const handleCountChange: NumberInputDesktopProps['onChange'] = (
    e,
    { valueString }
  ) => {
    const count = valueString.replace(/[^\d]/g, '');

    onCountChange(count);
  };

  if (hideCount && hideComment) {
    return null;
  }

  return (
    <Space size={16} fullWidth direction="vertical">
      {!hideCount && (
        <NumberInputDesktop
          value={count}
          fractionLength={0}
          onChange={handleCountChange}
          step={1}
          min={0}
          max={9999}
          block
          size="s"
          label="Количество экземпляров"
          labelView="outer"
        />
      )}
      {!hideComment && (
        <Textarea
          label="Дополнительная информация"
          labelView="outer"
          placeholder="Введите комментарий"
          block
          minRows={3}
          size="s"
          value={comment}
          onChange={(_, { value }) => onCommentChange(value)}
        />
      )}
    </Space>
  );
};
