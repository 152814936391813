import { forwardRef } from 'react';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';

import styles from './MoneyAmountInput.module.css';

export type MoneyAmountInputProps = AmountInputProps;

export const MoneyAmountInput = forwardRef<
  HTMLInputElement,
  MoneyAmountInputProps
>((props, ref) => {
  return (
    <AmountInput
      ref={ref}
      id="amount"
      block
      autoComplete="off"
      size="s"
      currency="RUR"
      placeholder="Введите сумму"
      integerLength={12}
      fieldClassName={styles.customInputField}
      {...props}
    />
  );
});
