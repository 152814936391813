import { format } from 'date-fns';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { getAccountInfoGI } from '@terminal/core/lib/rest/lkAccountInfo';
import { StaticField } from '@terminal/lk/shared/ui/StaticField';
import { Treaty, useAccountInfo } from '@terminal/lk-core';
import { DocumentsList } from '@terminal/lk-desktop-common';

import styles from './TaxInfo.module.css';

interface TaxInfoProps {
  treaty: Treaty | null;
}

export const TaxInfo = ({ treaty }: TaxInfoProps) => {
  const { data: accountInfo, isFetching: accountInfoPending } = useAccountInfo(
    treaty?.treaty,
    getAccountInfoGI
  );

  if (accountInfoPending) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  const openDate = accountInfo ? new Date(accountInfo?.openDate) : null;

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
      >
        Генеральное соглашение
      </Typography.Text>
      <Space fullWidth={true} direction="vertical" className={styles.info}>
        <Row>
          <Col width={6}>
            <StaticField label="Номер">
              {treaty?.treaty}
              {openDate ? ` от ${format(openDate, 'dd.MM.yyyy')}` : null}
            </StaticField>
          </Col>
          <Col width={6}>
            <StaticField label="Владелец">{accountInfo?.owner}</StaticField>
          </Col>
        </Row>
        <Row>
          <Col width={6}>
            <StaticField label="Налоговая ставка">
              <span>{accountInfo?.taxRate}%</span>
              <TooltipDesktop
                targetClassName={styles.tooltipTrigger}
                contentClassName={styles.tooltipContent}
                position="top"
                preventOverflow={false}
                trigger="hover"
                content={
                  <>
                    Налоговая ставка по&nbsp;операциям с&nbsp;ценными бумагами
                    и&nbsp;ПФИ:
                    <br />– 13% для налоговых резидентов РФ
                    <br />– 30% для налоговых нерезидентов РФ
                  </>
                }
                fallbackPlacements={['left', 'right']}
              >
                <InformationCircleLineSIcon
                  height={16}
                  width={16}
                  color="#8D8D93"
                />
              </TooltipDesktop>
            </StaticField>
          </Col>
        </Row>
      </Space>

      <DocumentsList treaty={treaty} className={styles.documentList} />
    </div>
  );
};
