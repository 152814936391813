import { EntityType } from './entityTypes';
import {
  ADEntity,
  ADMaskedEntity,
  ArrayField,
  BooleanField,
  ByteArray,
  ConditionField,
  DateTimeField,
  DoubleField,
  EnumField,
  FieldType,
  getArrayFieldMeta,
  Int16Field,
  Int32Field,
  Int64Field,
  StringField,
  UInt32Field,
  UnknownField,
  VariantField,
} from './serialization';

import { TradePeriodType, TradeType } from '../../types/quotes';
import { TrackPriceState, TrackPriceType } from '../../types/trackPrice';

export const UNKNOWN_ENTITY = EntityType.UnknownEntity;

export enum ClientMessage {
  NoErrors = 0,
  FrontEndAuthenticationFailed = 10000,
  FrontEndAuthenticationSuccess = 10001,
}

export enum Operation {
  Inserted = 0,
  Deleted = 1,
  Updated = 2,
  InitFinished = 3,
  DoNotSave = 128,
}

export enum NotificationState {
  None = 0,
  Sent = 1,
  Failure = 2,
}

export enum SubscribeType {
  Unsubscribe = 0,
  UnsubscribeSeconds = 0,
  Subscribe = 1,
  SubscribeSeconds = 1,
  // эти значения сделаны тут по-аналогии, из ad.xml не очень ясно как это передается
  UnsubscribeMinutes = 0,
  SubscribeMinutes = 1,
  UnsubscribeHours = 0,
  SubscribeHours = 1,
}

export enum FrontEndType {
  Unknown = 0,
  AuthAndOperInitServer = 1,
  OperServer = 2,
  RealTimeBirzInfoServer = 3,
  RealTimeBirzInfoDelayedServer = 31,
  BirzArchAndMediaServer = 4,
}

export enum InitFlagType {
  None = 0,
  IsInit = 1,
  IsStartRecord = 2,
  IsAllChanged = 3,
}

export enum ObjectTypes {
  NA = 0,
  AO = 1,
  AP = 2,
  OE = 3,
  BC = 4,
  RR = 5,
  BB = 6,
  BG = 7,
  BS = 8,
  BM = 9,
  BR = 10,
  BI = 11,
  BE = 12,
  PP = 13,
  PM = 14,
  CP = 15,
  CM = 16,
  FR = 17,
  FD = 18,
  PO = 19,
  PI = 20,
  PZ = 21,
  RA = 22,
  RG = 23,
  IF = 24,
  IV = 25,
  IT = 26,
  CC = 27,
  VL = 28,
  SF = 29,
  FC = 30,
  EK = 32,
  EM = 33,
  EG = 34,
  EA = 35,
  EZ = 36,
  VF = 37,
  VM = 38,
  MS = 39,
  BT = 40,
  BF = 41,
  ES = 42,
  EF = 43,
  RT = 44,
  ZZ = 45,
  EE = 46,
  SW = 47,
  RE = 48,
  XX = 49,
  FP = 50,
  IM = 51,
  XM = 52,
  XS = 53,
  XF = 54,
  SS = 55,
  MC = 56,
  DC = 57,
  VR = 58,
  CO = 59,
  SB = 60,
}

export enum CandleType {
  Standard = 0,
  VolumeBidAsk = 1,
  MPV = 2,
}

export enum BaseTimeFrame {
  Second = 0,
  Minute = 1,
  Hour = 2,
  Vol = 3,
  Ticks = 4,
  Price = 5,
  Day = 6,
  Week = 7,
  Month = 8,
  Mins15 = 10,
  Hours4 = 11,
  Days2 = 12,
}

export enum ObjectGroup {
  None = 0,
  Stocks = 1, // Акции
  Bonds = 2, // Облигации
  EquityParticipations = 3, // паи
  Futures = 4, // Фьючерсы
  Options = 5,
  Receipts = 6, // Депозитарные расписки
  Indexes = 7,
  Currency = 8, // Валюты
  Assets = 9,
  Emitents = 10,
  Others = 11,
  Rates = 12,
  Sector = 13,
  Contract = 14,
  Repo = 15,
  Swap = 16,
  Basket = 17,
  Spread = 18,
  ExchgBills = 19,
  Crypto = 20,
  Metals = 21,
  StockRf = 101,
  StockUsa = 102,
  FederalLoanBond = 103,
  NonFederalLoanBond = 104,
  CorporateBonds = 105,
  EuroBonds = 106,
  GovernmentBonds = 107,
  StockEU = 108,
  CurrencyFraction = 110,
}

export enum IIAType {
  Unknown = -1, // неизвестный тип
  NonIIA = 0, // не ИИС
  IIA3rdParty = 1, // ИИС в интересах третьих лиц
  IIATypeAB = 2, // ИИС наш
  IIATypeA = 3, // ИИС наш Тип А
  IIATypeB = 4, // ИИС наш Тип Б
  IIA3 = 6, // ИИС 3,
}

export enum Messages {
  NoErrors = 0,
  UnhandledException = 1, // Ошибка: {0}
  TextMessage = 2, // {0}
  ConnectionInfoIsEmpty = 3, // Отсутствует или поврежден файл 'ConnectionInfo.cfg'. Подключение к серверу невозможно.
  DictionaryKeyNotFound_Actives = 100, // {0}
  DictionaryKeyNotFound_Security = 101, // {0}
  DictionaryKeyNotFound_TradePlace = 102, // {0}
  DictionaryKeyNotFound_PortfolioMarket = 103, // {0}
  DictionaryKeyNotFound_ExchangeContragent = 104, // {0}
  DictionaryKeyNotFound_Emitent = 105, // {0}
  DictionaryKeyNotFound_ActType = 106, // {0}
  DictionaryKeyNotFound_Depositary = 107, // {0}
  ConnectionToServer_BackOffice = 200, // Бэк-офис сервер: {0}
  ConnectionToServer_Trade = 201, // Торговый сервер: {0}
  ConnectionToServer_Information = 202, // Информационный сервер: {0}
  ConnectionToServer_Archive = 203, // Архивный сервер: {0}
  Connection_ConnectingToWeb = 500, // Подключаюсь к серверу {0} для авторизации...
  ConnectionWeb_ErrorConnectingTo = 501, // Ошибка подключения к {0}. Прична: {1}
  ConnectionWeb_AuthSuccesseful = 502, // Авторизация прошла успешно."
  ConnectionWeb_AuthFailed = 503, // Ошбика авторизации. Код ошибки {0}"
  ConnectionCFE_Connecting = 504, // Установление сокетного соединения по адресу {0}"
  ConnectionCFE_ConncetionFailed = 505, // Ошибка установления соединения с сервером {0}. Причина: {1}
  ConnectionCFE_Authorizing = 506, // Соединение успешно установлено. Авторизация..."
  ConnectionCFE_ConnectionLost = 507, // Соединение с сервером разорвано. Причина: {0}"
  ConnectionCFE_NoResponse = 508, // Соединение с сервером установлено, но не получен ответ от сервера. Возможные способы устранения неисправности:\n  - Настройте фаервол Вашего компьютера, разрешив соединение по адресу: {0}\n  - Настройте прокси сервер Вашей организации, разрешив доступ по адресу {1}"
  ConnectionCFE_NoResponseOnAuthorizedConnection = 509, // За установленное время не получен ответ сервера. Соединение будет разорвано."
  FrontEndAuthenticationFailed = 10000, // Ошибка авторизации. Некорректный сессионный ключ.
  FrontEndAuthenticationSuccess = 10001, // Авторизация подтверждена.
  FrontEndDisconnectSuccess = 10002, // Отключение от сервера.
  FrontEndDisconnectBecauseOfNewLogin = 10003, // C Вашим логином произошло другое соединение с сервером. Соединение разорвано.
  FrontEndDoNotSupportThisCommand = 10004, // Сервис не поддерживает команду {0}.
  FrontEndWrongPacket = 10005, // Ошибка разбора входящего пакета. Подробности см в файле error.log
  FrontEndNoArchiveServices = 10006, // Архивные сервера недоступны. Попробуйте повторить запрос позже.
  LoginLastExtNumValue = 10007, // Последний номер ордера клиента отправляется с ФЕ2 в момент логина или при неправильном номере в заявке
  FrontEndWrondDeveloperKey = 10008, // Некорректный код приложения.
  FrontEndSessionLimitsOverflow = 10009, // Превышение сессионных ограничений по команде {0}.
  FrontEndSessionCommandLimitsOverflow = 10010, // Превышение ограничений по запросам команды {0}.
  FrontEndDisconectFrequencyLimitsOverflow = 10011, // Превышение сессионных ограничений по команде {0}. ПРОИЗОШЛО ПРИНУДИТЕЛЬНОЕ ОТКЛЮЧЕНИЕ СОКЕТНОГО СОЕДИНЕНИЯ.
  FrontEndDisconnectBecauseOfClientInactivity = 10012, // Фронтэнд завершил сессию клиента, так как клиент не активен. ПРОИЗОШЛО ПРИНУДИТЕЛЬНОЕ ОТКЛЮЧЕНИЕ СОКЕТНОГО СОЕДИНЕНИЯ.
  FrontEndSubscribeLimitsOverflow = 10013, // Превышено бизнес ограничение по команде
  FrontEndAuthenticationFailedNoExtNum = 10014, // На фронтэнде не найдена запись о последнем номере клиентского ордера. Свяжитесь со службой технической поддержки.
  FrontEndSubscribeErrorNoIdFIFound = 10015, // Запрошенные данные типа {0} по инструменту {1} отсутствуют на сервере.
  FrontEndNoAuthServices = 10016, // Нет доступных серверов авторизации.
  FrontEndBadCommandParameters = 10017, // Неверные параметры команды.
  FrontEndDisconnectBecauseOfExpirationOfRequestedData = 10018, // Данные запроса устарели.
  FrontEndDisconnectBecauseOfOutQueueOverflow = 10019, // Очередь исходящих данных переполнилась.
  FrontEndTSAvailable = 10020, // Торговый сервер снова доступен для приёма поручений."
  FrontEndNoRoomForNewConnections = 10021, //
  FrontEndTooLongRequest = 10022, //
  FrontEndNotSupportedProtocolVersion = 10023, // Неподдерживаемая версия протокола.\n Новая версия {0}.
  FrontEndArvhiceServiceAvailable = 10024, // Архивный сервер снова доступен для приёма запросов."
  FrontEndDemoClientsAreNotSupported = 10025, // "
  LimitRequestFailed = 20000, // Ошибка запроса торговых лимитов.
  LimitRequestRejected_NoConnectedLimitServices = 20001, // Нет ни одного лимит сервиса.
  OrderRejected_TimedOut = 30000,
  OrderRejected_FloodDetected = 30001,
  OrderRejected_ExchangeBrake = 30002,
  OrderRejected_InputerNotAvailable = 30003, // For LS internal use only
  OrderRejected_ExchangeNotAvailable = 30004,
  OrderRejected_RegionBegin = 40000, // Начало интервала OrderRejected
  OrderRejected = 40001, // Заявка #{0} отклонена.
  OrderRejected_NoAccessRights = 40002, // Заявка #{0} отклонена. Причина: Нет прав доступа.
  OrderRejected_BadOrderSign = 40003, // Заявка #{0} отклонена. Причина: Подпись не разборчива.
  OrderRejected_NoInOrdersAccepted = 40004, // Заявка #{0} отклонена. Причина: В списке принятых нет такой заявки.
  OrderRejected_BaseOrderDoesnotEqual = 40005, // Заявка #{0} отклонена. Причина: У заявок не совпадают поля.
  OrderRejected_OrderTypeNotSupported = 40006, // Заявка #{0} отклонена. Тип заявки не поддерживается.
  OrderRejected_UnapprovedQual = 41106, // Заявка #{0} отклонена. В соответствии с базовым станартом подтвердите свою квалификацию или откажитесь от нее.
  OrderRejected_BadInputFormat = 40007, // Заявка #{0} отклонена. Неправильно заданы входящие параметры.
  OrderRejected_AccessDifferentAccount = 40008, // Заявка #{0} отклонена. Торговый аккаунт в поручении не соотвествует торговому разделу.
  OrderRejected_WrongClientOrderNumber = 40009, // Заявка #{0} отклонена. Исходящий номер меньше последнего принятого #{1}
  OrderRejected_NotEnoughClientFunds = 40010, // Заявка #{0} отклонена. Нехватка средств по лимитам клиента.
  OrderRejected_BadTimeOrderSign = 40011, // Заявка #{0} отклонена. Причина: Неверное время подписи.
  OrderRejected_BadpacketFormat = 40012, // Заявка #{0} отклонена. Причина: Некорректный формат пакета.
  OrderRejected_AlreadyGone = 40013, // Заявка #{0} уже удалена.
  OrderRejected_BadOrderParams = 40014, // Заявка #{0} отклонена. Составной тип торгового поручения не разрешён.
  OrderRejected_WithdrawTime = 40015, // Заявка #{0} отклонена. Время исполнения уже в прошлом
  OrderRejected_ActivationTime = 40016, // Заявка #{0} отклонена. Время активации +1 сек уже в прошлом
  OrderRejected_PriceOutOfExchangeLimits = 40017, // Заявка #{0} ожидает попадания в ценовой коридор.
  OrderRejected_NoConnectedSUZ = 40018, // Условная заявка #{0} отклонена. Повторите попытку через минуту.
  OrderRejected_TradeBreak = 40019, // Заявка #{0} ожидает наступления торгов.
  OrderRejected_MultipleLinksToBaseOrder = 40020, // Заявка #{0} отклонена. Множественные ссылки на базовую заявку.
  OrderRejected_WrongBaseOrderStatus = 40021, // Заявка #{0} отклонена. Неверный статус базовой заявки.
  OrderRejected_OrdersForAccountNotAllowed = 40022, // Заявка #{0} отклонена. Торговые поручения по аккаунту не разрешены.
  OrderRejected_OrdersForTradePlaceNotAllowed = 40023, // Заявка #{0} отклонена. Торговые поручения по торговой площадке не разрешены.
  OrderRejected_NotEnoughBrokerFirmLimits = 40024, // Заявка #{0} отклонена. Превышено ограничение по брокерской фирме.
  OrderRejected_BadOrderPrice = 40025, // Заявка #{0} отклонена. Неверная цена.
  OrderRejected_BadOrderDirection = 40026, // Заявка #{0} отклонена. Неверное направление.
  OrderRejected_BadOrderInstrument = 40027, // Заявка #{0} отклонена. Неверный инструмент.
  OrderRejected_BadOrderPriceControlType = 40028, // Заявка #{0} отклонена. Неверный тип контроля цены.
  OrderRejected_CauseCrossTrade = 40029, // Заявка #{0} отклонена. Приводит к заключению кросс-сделки.
  OrderRejected_PartialCrossTrade = 40030, // Заявка #{0} отклонена после частичного исполнения. Приводит к заключению кросс-сделки.
  OrderRejected_ForceMajeureDelay = 40031, // Исполнение заявки #{0} задержано вследствие обстоятельств непреодолимой силы.
  OrderRejected_OrdersForSubaccountNotAllowed = 40032, // Заявка #{0} отклонена. Торговые поручения по портфелю не разрешены.
  OrderRejected_BadOrderLot = 40033, // Заявка #{0} отклонена. Неверное количество.
  OrderRejected_InstrumentNotAllowed = 40034, // Заявка #{0} отклонена. Инструмент не доступен к торгам.
  OrderRejected_UnknownIdRazdel = 40035, // Заявка #{0} отклонена. IdRazdel не известен.
  OrderRejected_UnknownClientCode = 40036, // Заявка #{0} отклонена. Счет не зарегистрирован на бирже.
  OrderRejected_AwaitingStockExchangeRegistration = 40037, // Заявка #{0} отклонена. Ожидание регистрации на бирже.
  OrderRejected_QUIK = 40038, // Заявка #{0} отклонена. Торговой платформой клиента является QUIK. В этом приложении - только просмотр.
  OrderRejected_BadOrderParamsByLogin = 40039, // Заявка #{0} отклонена. Составной тип торгового поручения не разрешён для логина.
  OrderRejected_BadInputFormatZeroQuantity = 40040, // Заявка #{0} отклонена. Количество штук должно быть положительным.
  OrderRejected_DVFO = 40049, //
  OrderRejected_TimeoutExpired = 40050, //
  OrderRejected_AlreadyOnExchange = 40051, //
  OrderRejected_RestExceedsQuantity = 40052, //
  OrderRejected_ClientCodeMustBeLegalEntity = 40055, // Заявка #{0} отклонена. Для выбранного инструмента допускается указание только кодов клиентов, являющихся юридическими лицами
  OrderRejected_UnableToFillImmediately = 40056, // Заявка #{0} отклонена. Невозможно немедленно удовлетворить заявку типа 'Немедленно или отменить'.
  OrderRejected_Sanctions = 40057, // Заявка #{0} отклонена. Вам запрещены операции с данным инструментом.
  OrderRejected_NoFATCA = 40058, // Заявка #{0} отклонена. Вам необходимо подписать анкету W8-BEN.
  OrderRejected_SpbexGlobalLimitDenied = 40059, // Заявка #{0} отклонена, в связи с временным ограничением на совершение сделок с привлечением заемных средств на СПб. Пожалуйста, повторите попытку позже, либо подайте заявку без использования заемных средств
  OrderRejected_RequiredRSACertificate = 40060, // Заявка #{0} отклонена. Не можем проверить подпись. Пожалуйста, используйте RSA-сертификат.
  OrderRejected_BadOrderRazdel = 40061, // Заявка #{0} отклонена. Прием поручений по {1} не разрешен.
  OrderRejected_BadOrderIndInvAcc = 40062, // Заявка #{0} отклонена. Запрет вывода денежных средств с ИИС.
  OrderRejected_TemporarilyBlocked = 40063, // Заявка #{0} отклонена. Временное ограничение.
  OrderRejectedInvalidOpenQty = 40064, // При указании скрытого количества, видимое количество должно быть не меньше 100 лотов (или иного количества).
  OrderRejected_QualStatusRequired = 40065, // Заявка #{0} отклонена. Требуется получение статуса квалифицированного инвестора.
  OrderRejected_QualStatusRequired_StockExchg = 40066, // Заявка #{0} отклонена. Спасибо, что получили статус квалифицированного инвестора. Торговля инструментов будет доступна, начиная со следующего дня.
  OrderRejected_IPOInstrumentsBookClosed = 40067, // Заявка #{0} отклонена. Сбор IPO поручений прекращён.
  OrderRejected_TestRequired_REPO = 40068, // Заявка #{0} отклонена. Не пройден тест по договорам РЕПО. https://lk.alfadirect.ru/testing/1
  OrderRejected_TestRequired_MarginTrading = 40069, // Заявка #{0} отклонена. Не пройден тест по маржинальной торговле. https://lk.alfadirect.ru/testing/2
  OrderRejected_TestRequired_DerivativesTrading = 40070, // Заявка #{0} отклонена. Не пройден тест по ПФИ. https://lk.alfadirect.ru/testing/3
  OrderRejected_TestRequired_ForeignSharesTrading = 40072, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по иностранным акциям или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/6
  OrderRejected_TestRequired_ETFTrading = 40073, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по ETF или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/10
  OrderRejected_TestRequired_UnlistedSharesTrading = 40074, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по акциям, не включенным в котировальные списки, или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/4
  OrderRejected_TestRequired_NoRatingRussianBondsTrading = 40076, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по российским облигациям без необходимого рейтинга или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/8
  OrderRejected_TestRequired_NoRatingForeignBondsTrading = 40077, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по иностранным облигациям без необходимого рейтинга или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/7
  OrderRejected_TestRequired_StructuredBondsTrading = 40078, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по структурным облигациям или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/11
  OrderRejected_TestRequired_StructuredIncomeBondsTrading = 40079, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по облигациям со структурным доходом или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/9
  OrderRejected_TestRequired_CEMFTrading = 40081, // Заявка #{0} отклонена. Для данной операции нужно пройти тест по паям ЗПИФов или получить статус квалифицированного инвестора. https://lk.alfadirect.ru/testing/5
  OrderRejected_TestRequired_Reserve2 = 40089, // reserved values 40082-40089
  OrderRejected_NoConnectedTradeServices = 49998, // Заявка #{0} отклонена. Повторите попытку через минуту.
  OrderRejected_RegionEnd = 49999, // Конец интервала OrderRejected
  OrderRejected_NotEnoughClientFunds_DueToActiveOrders = 41104, // Заявка #{0} отклонена. Нехватка средств по лимитам клиента из-за активных заявок.
  WithdrawalOrderRejected = 41000, // Ошибка вывода денежных средств. Обратитесь в службу поддержки.
  TransferOrderRejected = 41001, // Ошибка перевода денежных средств. Обратитесь в службу поддержки.
  WithdrawalLimitRejected = 41100, // Ошибка запроса лимитов на вывод денежных средств.
  TransferLimitRejected = 41101, // Ошибка запроса лимитов на перевод денежных средств.
  CertificateRequestRejected = 41201, // Ошибка запроса сертификата.
  CertificateRequestRejected_NoSignServiceConnected = 41202, // Ошибка запроса сертификата. Нет подключенного сервиса подписей.
  CertificateKeyHasWrongAlgorithm = 50000, // Открытый ключ сертификата не ГОСТ, RSA или DSA
  CertificateSubjectNotMeetRequirements = 50001, // Субъект сертификата не соответствует требованиям
  CertificateExpired = 50002, // Срок действия сертификата прошел или не соответствует требованиям
  CertificateInvalidTestSignature = 50003, // Невозможно проверить подпись тестового сообщения полученным сертификатом, или несовпадает текст поручения
  CertificateKeyHasWrongBitness = 50004, // Длина открытого ключа не соответствует требованиям
  CertificateNotUnique = 50005, // Невозможно зарегистрировать ранее отозванный сертификат
  CertificateInvalidMasterSignature = 50006, // Неверная подпись мастер ключом
  CertificateInvalidEntityParameters = 50007, // Недопустимые параметры управлеющей ентити
  CertificateInvalidState = 50008, // Недопустимое состояние сертификата
  CertificateTooMuchCertificates = 50009, // Слишком много сертификатов
  CertificateRejected = 50010, // Не удалось создать сертификат
  CertificateEnd = 50100, // Конец интервала собщений об операциях с клиентскими сертификатами
  AuthenticationLoginFailed = 90000, // Ошибка аутентификации. Некорректно введены имя пользователя/пароль.
  ArchiveRequestError = 90001, // Ошибка запроса графиков: {1}. Номер запроса {0}
  ArchiveBadObjectGroup = 90002,
  ArchiveTooManyIntstruments = 90003,
  ArchiveBadSortingType = 90004,
  AuthenticationFailedTokenExpired = 90005, // Ошибка аутентификации. Токен больше недействителен.
  AuthenticationFailedInvalidToken = 90006, // Ошибка аутентификации. Недопустимый токен.
  AuthenticationFailedInternalError = 90007, // Ошибка аутентификации. Обратитесь в службу поддержки.
  AuthenticationFailedAuthorizationCodeExpired = 90008, // Ошибка аутентификации. Код авторизации больше недействителен.
  AuthenticationFailedInvalidAuthorizationCode = 90009, // Ошибка аутентификации. Недопустимый код авторизации.
  AuthenticationFailedUnknownCUS = 90010, // Ошибка аутентификации. Не найден пользователь Альфа-Директ.
  AuthenticationFailedClientRegistrationInProgress = 90011, // Ошибка аутентификации. Регистрация пользователя Альфа-Директ ещё не завершена.
  FE32AssetPricesRequestFailed = 90020,
  ReportAvailable = 92000,
  ReportAccessExpired = 92001,
  ReportNoAccess = 92002,
  ReportWrongLevel = 92003,
  ReportAbstractError = 92004,
  SigningCertificateWithSmsRejected_LoginWrong = 101001, // Вы указали неверный логин {0}.
  SigningCertificateWithSmsRejected_LoginUnknown = 101002, // Мы не можем идентифицировать Вас по вашему логину {0}.
  SigningCertificateWithSmsRejected_PhoneUnknown = 101003, // Мы не можем определить номер вашего мобильного телефона.
  SigningCertificateWithSmsRejected_ServiceException = 101004, // Возникла ошибка при подключении к сервису операций.
  SigningCertificateWithSmsRejected_OperationException = 101005, // Возникла ошибка при создании операции.
  SigningCertificateWithSmsRejected_ReferenceEmpty = 101006, // Операция без ссылки недопустима.
  SigningCertificateWithSmsRejected_ServiceCall = 101007, // Возникла ошибка при обращению к сервису отправки СМС.
  SigningCertificateWithSmsRejected_SmsException = 101008, // Возникла ошибка при отправке СМС.
  SigningCertificateWithSmsRejected_OperationBlocked = 101009, // Операция заблокирована. Обратитесь в службу поддержки.
  SigningCertificateWithSmsRejected_AuthorizationUnavailable = 101010, // Данный метод авторизации недоступен для Вашего логина.
  SigningCertificateWithSmsRejected_AuthorizationWasExpire = 101011, // Данный метод авторизации просрочен для Вашего логина.
  SigningCertificateWithSmsRejected_PasswordException = 101012, // Возникла ошибка при генерации пароля.
  SigningCertificateWithSmsRejected_OperationBlockedByBank = 101013, // Операция заблокирована в системе банка.
  SigningCertificateWithSmsRejected_SmsImpossibleCheck = 101014, // Невозможно проверить СМС-код.
  SigningCertificateWithSmsRejected_SmsWasExpire = 101015, // Ваш СМС-код просрочен. Запросите его еще раз.
  SigningCertificateWithSmsRejected_SmsWrong = 101016, // Вы ввели неверный СМС-код.
  SigningCertificateWithSmsRejected_ExceptionUnknown = 101017, // Возникла ошибка. Обратитесь в службу поддержки.
  SigningCertificateWithSmsRejected_PhoneNotRegistered = 101018, // Указанный номер мобильного телефона не зарегистрирован в банке.
}

export enum OrderType {
  None = 0,
  MKT = 1, // Рыночный
  LMT = 2, // Лимитный
  LWA = 3, // Лимитный "по средней"
  NOS = 4, // Лимитный "по единой"
  MIT = 5, // Рыночный приказ с активацией при достижении заданной цены указанным фининструментом
  LIT = 6, // Лимитный приказ с активацией при достижении заданной цены указанным фининструментом
  STP = 7, // Стоп
  STL = 8, // Стоп-лимит
  TRL = 9, // Лимитный «с трейлингом»
  TRS = 10, // Стоп «с трейлингом»
  TSL = 11, // Стоп-лимит «с трейлингом»
  BRS = 12, // Брекет «лимит- стоп»
  BSL = 13, // Брекет «лимит- стоп-лимит»
  RPS = 14, // Адресный
  INT = 15, //  Адресный с участником Альфа-Директ
  SMU = 16, // «изменить место аналитического учета (перевод без изменения депо счета и раздела)
  SMX = 17, //  «изменить место хранения  (раздела учета в составе депо счета)» если DEP (без изменения депо счета)
  VDP = 18, // «внутридепозитарный перевод» если DEP (перевод без изменения депозитария)
  MDP = 19, // «междепозитарный перевод» если DEP (перевод с изменением Депозитария)
  REES = 20, //  «списать в реестр/зачислить из реестра» если DEP (перевод в реестр или из реестра)
  CLMON = 21, // На банковский счет клиента
  DUMON = 22, // На специальный счет открытый для учета ден средств по договору ДУ
  TechRPS = 23, // Адресный (технический)
  SWAP = 24, // СВОП
  TechSWAP = 25, // СВОП (технический)
  REPO = 26, // РЕПО
  TechREPO = 27, // РЕПО (технический)
  TBRS = 28, // Трейлинг стоп маркет и Тейк профит
}

export enum QuantityType {
  None = 0,
  QTY = 1, // Штук (Объем ордера зафиксирован в штуках) допускается исполнить частично
  AON = 2, // Штук (Объем ордера зафиксирован в штуках)  Исполнить ордер только целиком.
  OTO = 3, // Штук (Исполнять ордер пропорционально объему исполнения в штуках другого ордера)
  ICE = 4, // Айсберг ( публиковать в котировках заданный объем в штуках)
  REV = 5, // Исполнить ордер заданное число раз подряд с промежуточным контролем результатов (Револьверный Айсберг)
  VAL = 6, // На сумму (Объем ордера зафиксирован по сумме сделки)
  QVAL = 7, // Штук на сумму (Объем ордера зафиксирован по количеству и сумме)
  QDISC = 8, //  Штук с дисконтом (Объем ордера РЕПО зафиксирован по количеству и дисконту )
  VDISC = 9, // На сумму с дисконтом (Объем ордера зафиксирован по сумме 1-й части и дисконту)
  PSUB = 10, // В процентах от стоимости Портфеля
  PPOS = 11, // В процентах от величины открытой позиции
  PORD = 12, // В процентах от величины ордера
  PTRD = 13, // В процентах от объема сделки или сделок
}

export enum PriceType {
  QUO = 1, // Цена
  SWAP = 2, // Своп цена
  RATE = 3, // Своп ставка или Ставка РЕПО(% годовых)
  YLD = 4, // Доходность
  CUP = 5, // Ставка купона (% годовых)
  CUP_100PRC = 6, // В LimitPrice указывается величина купона % при том, что Price = 100%
}

export enum LifeTime {
  None = 0,
  IMM = 1, // «Исполнить немедленно и/или отменить»
  CLS = 2, // "На закрытии" в период закрытия сессии/ на послеторговом аукционе
  OPN = 3, // "На открытии" в период открытия сессии/ на предторговом аукционе
  AU = 4, // "На аукционе" На аукционе (периодическом)
  DAY = 5, // "Сессия" «Исполнять ордер до конца сессии».
  GTD = 6, // "До даты" «Исполнять ордер до конца указанной даты» Прекратить исполнение ордера в 23:59:59 Мск указанной календарной даты
  GTT = 7, // "До даты/времени" «Исполнять ордер до указанного времени » Прекратить исполнение ордера в дату и время, указанные в тексте ордера
  FIN = 8, // "Финализировать в дату/время" «Исполнять ордер до конца указанной даты» В дату истечения срока действия в заданное время передать ордер в торговую систему для немедленного исполнения, если рыночные котировки не достигли заданного стоп –уровня
  D30 = 9, //"30 дней" «Исполнить в течение 30 дней или прекратить исполнение»
}

export enum ExecutionType {
  ASIS = 1, // Передать в ТС как есть
  ASM = 2, // Передать в ТС как MKT
  ASL = 3, // Передать в ТС как LMT
}

export enum PriceControlType {
  None = 0,
  LIND = 1, // контролировать условие по значению Индекса
  QUOTE = 2, // контролировать условие по транслируемому биржей значению расчетной рыночной котировки
  LAST = 3, // контролировать условие по цене сделок в течение торговой сессии (без учета сделок периода открытия и закрытия)
  GLAST = 4, // контролировать условие по цене сделок в течение основной торговой сессии (без учета сделок дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
  LYLD = 5, // контролировать условие по доходности сделок в течение торговой сессии (без учета сделок периода открытия и закрытия)
  GLYLD = 6, // контролировать условие по доходности сделок в течение основной торговой сессии (без учета сделок дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
  BID = 7, // контролировать условие по цене спроса в течение торговой сессии (без учета ордеров периода открытия и закрытия)
  GBID = 8, // контролировать условие по цене спроса в течение основной торговой сессии (без учета дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
  BYLD = 9, // контролировать условие по доходности спроса в течение торговой сессии (без учета периода открытия и закрытия)
  GBYLD = 10, // контролировать условие по цене спроса в течение основной торговой сессии (без учета дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
  ASK = 11, // контролировать условие по цене предложения в течение торговой сессии (без учета периода открытия и закрытия)
  GASK = 12, // контролировать условие по цене предложения в течение основной торговой сессии (без учета дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
  AYLD = 13, // контролировать условие по доходности предложения в течение торговой сессии (без учета периода открытия и закрытия)
  GAYLD = 14, // контролировать условие по цене предложения в течение основной торговой сессии (без учета дополнительных вечерней и/или утренней сессий периода открытия и закрытия)
}

export enum OrderStatus {
  Parked = 1, // Сохранен. Ожидает  электронной подписи и отправки брокеру (приказ в локальном буфере Клиента
  New = 2, // Новый. Ожидает подтверждения приема от сервера брокера
  Offer = 3, // Оферта. Ожидает отправки акцепта , т.е   отправки  встречного ордера, либо отклонения
  Sign = 4, // Подписать. Ожидает подтверждения (второй электронной подписи), перед исполнением
  HiddenTime = 5, // Скрытый. Ожидает активации по времени
  HiddenOrder = 6, // Скрытый. Ожидает исполнения  другого ордера
  HiddenB = 7, // Скрытый.
  HiddenPrice = 8, // Скрытый. Ожидает сделку с ценой больше/меньше/равной указанной.
  CheckLimits = 9, // Контроль лимитов. Ожидает завершения контроля Лимит Cервисом до передачи биржевому агенту или контрагенту
  Accepted = 10, // Принят. Ожидает передачи биржевому агенту или контрагенту  (для исполнения)
  PendingEnter = 11, // Текущая заявка отправляется на биржу агентом
  HiddenPeriod = 12, // Скрытый. Ожидает наступления заданного периода торгов
  HiddenZ = 13, // Скрытый. Ожидает изменения ценового коридора
  Working = 14, // Активный. Подтвержден прием приказа  биржевым агентом или контрагентом
  ToBeCancelled = 15, // Удалить. Ранее принятый приказ помечен для удаления.
  PendingCancel = 16, // Удаляется. Приказ, помеченный  для удаления, ожидает подтверждения отмены от  биржевого агента  или контрагента
  ExchangeCancelled = 17, // Текущая заявка удалена с биржи
  Rejected = 18, // Отклонен. Приказ отклонен брокером
  ExchangeFilled = 19, // Текущая заявка исполнена биржей
  Filled = 20, // Исполнен. Подтверждено полное исполнение приказа.
  Cancelled = 21, // Аннулирован. Подтверждена отмена помеченного для удаления  приказа
  Verify = 22, // Подтвердить. Исполненный приказ (в т.ч. частично исполненный приказ, дальнейшее исполнение которого прекращено), условия которого были подтверждены устно, ожидает письменного или электронного подтверждения клиента.
  Edit = 23, // ToEdit. Редактировать Помечен к удалению для последующего редактирования
  Moving = 24, // Moving. Редактируется Приказ EDIT передан торговой системе Ожидает подтверждения удаления  от  торговой системой перед редактированием
  ExchangeRejected = 25, // Удалена с биржи до попадания в стакан
  ExchangeRemoved = 26, // Удалена с биржи после попадания в стакан из-за изменившихся параметров
}

export enum OrderDirection {
  Undefined = 0,
  Sell = -1,
  Buy = 1,
}

export enum OrderStopPriceDirection {
  Lower = -1,
  Undefined = 0,
  Upper = 1,
}

export enum CommandType {
  Unknown = 0,
  Enter = 1,
  MTS = 2,
  ALG = 3,
  SET = 4,
  EDIT = 5,
  CONF = 6,
  Cancel = 7,
  MCancel = 8,
  COM = 9,
  IND = 10,
  PhoneEnter = 11,
  PhoneCancel = 12,
  MarginCallEnter = 13,
  MarginCallCancel = 14,
  MTRAEnter = 15,
  MTRACancel = 16,
  CancelAllSubordinates = 17,
  EnterAndEnterSuborder = 18,
}

export enum DocumentType {
  Unknown = 0,
  TRD = 1,
  EXP = 2,
  REP = 3,
  DEP = 4,
  MON = 5,
  URE = 6,
  OCO = 7,
  HEG = 8,
  Certificate = 9,
  SWA = 10,
  Withdrawal = 11,
  Transfer = 12,
  Manual = 25,
  Subordinate = 26,
}

export enum OperationType {
  XZ = 0,
  MON = 1, // Списание/зачисление  денежных средств, кроме расчетов по сделкам
  DEP = 2, // Депо списание/зачисление, кроме расчетов по сделкам
  TRD = 3, // Сделка купли-продажи, в т.ч. погашение облигации или опциона , в т.ч. сделка с инструментом XX (кроме «расчетных» сделок)
  R1 = 4, // 1-я часть РЕПО  (см. особенности трансляции параметров в табл. CFORDERS) !!! Этот код используется, если Агент статусов  НЕ транслирует  вместе со сделкой РЕПО пару сделок  RS
  S1 = 5, // 1-я часть сделки СВОП  (см. особенности трансляции параметров в табл. CFORDERS) !!! Этот код используется, если Агент статусов  НЕ транслирует  вместе со сделкой СВОП пару сделок  RS
  RS = 6, // Нога в составе  сделки СВОП, РЕПО !!!не транслируется на Клиента, предназначена исключительно для балансовых расчетов !!!Если 1-я часть РЕПО маркирована R1, то 2-я часть РЕПО маркируется RS  и не транслируется Клиенту
  BS = 7, // Нога в составе  сделки BSKT, либо расчетная сделка по сделке с инструментом XX. !!!не транслируется на Клиента, предназначена исключительно для балансовых расчетов
  REPO = 8, // Сделка купли-продажи с обратным выкупом  РЕПО  (см. особенности трансляции параметров в табл CFORDERS) !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  SWAP = 9, // Сделка СВОП    !!! Не используется для балансовых расчетов, только для трансляции на клиента !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  BSKT = 10, // Сделка с корзиной активов   !!! Не используется для балансовых расчетов, только для трансляции на клиента !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  RPS = 11, // Сделка системная: биржевая с контрагентом ( в режиме РПС)
  MON_EXTRA = 12, // Списание/зачисление  денежных средств (Неторговые операции), кроме расчетов по сделкам.
  MON_INTRA = 13, // Списание/зачисление  денежных средств (Неторговые операции), кроме расчетов по сделкам.
  MON_PAYMENT = 14, // Списание/зачисление  денежных средств (Платежи), кроме расчетов по сделкам.
  DEP_EXTRA = 15, // Депо списание/зачисление (Неторговые операции), кроме расчетов по сделкам.
  DEP_INTRA = 16, // Депо списание/зачисление (Неторговые операции), кроме расчетов по сделкам.
  R2 = 17, // 2-я часть РЕПО
  MON_VM = 18, // Платежи ВМ
  MON_EXTRA_DIV = 19, // Зачисление дивидендов на счет
  MON_EXTRA_COUPON_RU = 20, // Зачисление купонов российских эмитентов на счет
  MON_EXTRA_COUPON_FOREIGN = 21, // Зачисление купонов иностранных эмитентов на счет
}

export enum OperationStatus {
  New = 1, // Новая   Операция подтверждена. Требует подтверждения для проведения расчетов
  Clearing = 2, // Расчеты Обязательства по операции (по 1-й части РЕПО) включены в в расчеты
  Repo = 3, // Выкуп Обязательства по 2-й части РЕПО включены в расчеты
  Settled = 4, // Закрыта Урегулирована. Исполнены обязательства по поставке и оплате.
  Cancelled = 5, // Отмена Операция аннулирована
  Partial = 6, // Частично Частично урегулирована.  Хз для чего  ? может и не нужен этот статус
  Delivered = 7, // Поставка Обязательства по поставке исполнены. Ожидается оплата
  Payed = 8, // Аванс Обязательства по оплате  исполнены. Ожидается поставка
  Default = 9, // Дефолт Дефолт/просрочка исполнения обязательств
}

export enum OperationGroup {
  XZ = 0,
  MON = 1, // Списание/зачисление  денежных средств, кроме расчетов по сделкам
  DEP = 2, // Депо списание/зачисление, кроме расчетов по сделкам
  TRD = 3, // Сделка купли-продажи, в т.ч. погашение облигации или опциона , в т.ч. сделка с инструментом XX (кроме «расчетных» сделок)
  R1 = 4, // 1-я часть РЕПО  (см. особенности трансляции параметров в табл. CFORDERS) !!! Этот код используется, если Агент статусов  НЕ транслирует  вместе со сделкой РЕПО пару сделок  RS
  S1 = 5, // 1-я часть сделки СВОП  (см. особенности трансляции параметров в табл. CFORDERS) !!! Этот код используется, если Агент статусов  НЕ транслирует  вместе со сделкой СВОП пару сделок  RS
  RS = 6, // Нога в составе  сделки СВОП, РЕПО !!!не транслируется на Клиента, предназначена исключительно для балансовых расчетов !!!Если 1-я часть РЕПО маркирована R1, то 2-я часть РЕПО маркируется RS  и не транслируется Клиенту
  BS = 7, // Нога в составе  сделки BSKT, либо расчетная сделка по сделке с инструментом XX. !!!не транслируется на Клиента, предназначена исключительно для балансовых расчетов
  REPO = 8, // Сделка купли-продажи с обратным выкупом  РЕПО  (см. особенности трансляции параметров в табл CFORDERS) !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  SWAP = 9, // Сделка СВОП    !!! Не используется для балансовых расчетов, только для трансляции на клиента !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  BSKT = 10, // Сделка с корзиной активов   !!! Не используется для балансовых расчетов, только для трансляции на клиента !!! Не используется для балансовых расчетов, только для маркировки  операций при трансляции на клиента
  RPS = 11, // Сделка системная: биржевая с контрагентом ( в режиме РПС)
  R2 = 12, // 2-я часть РЕПО
  NOB = 13, // Операция, которая не модифицирует позиции
}

export enum RazdelGroupType {
  None = 0,
  StocksAndBondsMarket = 1, // РЦБ:MICEX,SELT,OTC
  DerivativesMarket = 2, // FORTS
  CurrencyMarket = 3, // SELT
  RDC = 4, //Расшифровывается как "НТР" для рынков (где "РЦБ", "ВР") и "БЭБ НТР" для портфелей (где "НРБ" и "БЭБ")
}

export enum RazdelType {
  Derivatives = 18,
}

export enum AccessType {
  QuickAdministrator = -4,
  QuickSeniorTrader = -3,
  QuickTrader = -2,
  QuickController = -1,
  None = 0,
  Controller = 1,
  Trader = 2,
  SeniorTrader = 3,
  Administrator = 4,
}

export enum FrontType {
  ADir3 = 0,
  Quick = 1,
  ADir4 = 2,
}

export enum CertificateStateType {
  Unknown = 0,
  Ready = 1, // Сертификат готов к использованию
  Withdrawing = 2, // Сертификат отозван пользователем или закончился срок действия
  Verifying = 3, // Сертификат проходит проверку на соответсвие требованиям
  Certifying = 4, // Сертификат ожидает удостоверения
  Malformed = 5, // Сертификат не удовлетворяет требованиям
}

export enum CertificateType {
  Unknown = 0,
  ImportedFrom35 = 1,
  AD40 = 2,
  LK = 3,
  MT = 4,
}

export enum CertificateActionType {
  Unknown = 0,
  Certify = 1, // Подтверждение сертификата
  Withdraw = 2, // Отзыв сертификата
  SetMaster = 3,
}

export enum SignType {
  UNKNOWN = 0, // В ad.xml этого нет но кажется ДТ так шлет
  UEP = 1, // УЭП, подпись есть, текста поручения есть
  BP = 2, // Бумажное поручение, подписи нет, текста поручения нет
  PEP = 3, // ПЭП, подписи нет, текста поручения есть
  TEL = 4, // Телефонная сессия, подписи нет, текста поручения нет
  PP = 5, // Произвольное поручение, хрен поймёшь
  RG = 6, // Регламент, подписи нет, текста поручения нет
  AUTO = 7, // Автоисполнение, подписи нет, текста поручения нет
  RG2 = 8, // Покупка по реестру (Регламент), подписи нет, текста поручения нет
}

export enum OrderChannels {
  AA = 1, // Альфа Админ
  AD4DT = 2, // Альфа-Директ 4.0
  AD35 = 3, // Альфа-Директ 3.5
  AD4MT = 4, // Альфа-Директ МТ
  AD4LK = 5, // Личный Кабинет
  MBL = 6, // Альфа-Мобайл
  CLCK = 7, // Альфа-Клик
  SFA = 8, // SFA
  AI = 9, // Альфа Инвестиции
  TM = 10, // Терминал MOEX
  PHONE = 11, // Phone
  WEBDT = 18, // Web-приложение (десктоп)
}

export enum OrderFlags {
  Unknown = 0x0000,
  MTRA = 0x0001,
  AndEnterSubOrder = 0x0002,
  AndAllSubOrders = 0x0004,
  WithoutLimitsControl = 0x0008,
  WithoutTax = 0x0010,
  ToThirdParty = 0x0020,
  AA = 0x0100,
  SFA = 0x0200,
  MarginCallCancel = 0x0400,
  SyncProblem = 0x0800,
  IsClosingPosition = 0x100000000,
  IsInEditProcess = 0x200000000,
  InconsistentRest = 0x400000000,
  InReborn = 0x800000000,
}

export enum EnterOrderFlags {
  Unknown = 0x0000,
  MTRA = 0x0001,
  AndEnterSubOrder = 0x0002,
  AA = 0x0100,
  SFA = 0x0200,
}

export enum CancelOrderFlags {
  Unknown = 0x0000,
  MTRA = 0x0001,
  AndAllSubOrders = 0x0004,
  AA = 0x0100,
  SFA = 0x0200,
  MarginCallCancel = 0x0400,
}

export enum OSType {
  Unknown = 0,
  Windows = 1,
  Linux = 2,
  MacOS = 3,
  Android = 4,
  iOS = 5,
  WindowsMobile = 6,
}

export enum ClientType {
  IOSPhone = 1,
  IOSTablet = 2,
  AndroidPhone = 3,
  AndroidTablet = 4,
  Web = 5,
  ADir4 = 6,
  ADir3 = 7,
  Quick = 8,
  InternalRobot = 9,
  ExternalRobot = 10,
  Exchange = 11,
  PhoneSession = 12,
  ForcedClosure = 13,
}

export enum EnvironmentType {
  Prod = 1,
  Preprod = 2,
  Dev = 3,
  Integr = 4,
}

export enum UserCredentialsOptions {
  CertificateIssuingProhibited = 1,
}

export enum ServicesMainSystemType {
  AlfaDirect = 1,
  GoInvest = 2,
}

export enum PassportTokenType {
  AuthorizationCode = 1,
  AccessToken = 2,
  RefreshToken = 3,
  CheckToken = 4,
  CheckOneTimeToken = 5,
}

export enum UniversalExchangeCode {
  SPBEX = 'SPBEX', // Спб биржа
  SPBR = 'SPBR', // Спб биржа RUB
  MICEX = 'MICEX', // Московская биржа
  SELT = 'SELT', // Система электронных лотовых торгов MБ
  ECL = 'ECL', // Euroclear
  OTC = 'OTC', // OTC
}

export enum BrokerRecommendationFlags {
  None = 0,
  OpenPosition = 1, // Открытие позиции
  ClosePosition = 2, // Закрытие позиции
}

export enum ChatUserRoleType {
  None = 0,
  Client = 1,
  Stuff = 2,
}

export enum ChatGroupState {
  None = 0,
  Active = 1,
}

export abstract class Entity {
  [key: string]: any;
}

export type MessageObjectPayload = {
  type: string;
  data: object;
};

export type MessageArrayPayload = {
  type: string;
  data: object[];
}[];

export type MessagePayload = MessageObjectPayload | MessageArrayPayload;

export type MessageUnitedType<T = object> = {
  type: string;
  data: T[];
  frontend?: FrontEndType;
};

export type Message = {
  frontend: FrontEndType;
  isArray: boolean;
  payload: MessagePayload;
};

export enum MarketType {
  OTC = 0,
  GKO = 1,
  MICEX = 2,
  FMICEX = 3,
  RTSEX = 4,
  FORTS = 5,
  STD = 6,
  RTSCL = 7,
  LSE = 8,
  CME = 9,
}

export enum MarketBoardType {
  Regular = 0,
  OTC = 1,
  IPO = 2,
  RPS = 4,
  REPO = 8,
}

export enum AccountOptions {
  ConditionalOrdersProhibited = 0x00000001,
  OrdersEnabled = 0x00000002,
  WithdrawMoneyForbidden = 0x00000004,
  TransferMoneyForbidden = 0x00000008,
  WithdrawMoneyForbiddenFriends = 0x00000010,
  TransferMoneyForbiddenFriends = 0x00000020,
  LegalEntity = 0x00000040,
  UnfriendlyNonResident = 0x00000080,
  RepoInvest = 0x00000100,
  FortsInEFR = 0x00000200,
  Underage = 0x00002000,
}

export enum AccountStatus {
  Unknown,

  // Счет блокирован - но не закрыт. Движения по счету запрещены. Остатки - допускаются. Обычная причина - смерть владельца.
  Blocked,

  // Счет открыт. Оригиналы документов приняты от клиента.
  Open,

  //Все операции по счету разрещены. Сверка с внешними системами проводится. Обычная причина - все ok.
  Active,

  PreReg,

  //Счет закрыт. Остатков - нет. Сверка не проводится. Счета во всех внешних системах так же закрыты. Обычная причина - заявление или смерть владельца.
  Closed,

  //Документы на открытие счета находятся у клиента на подписи.
  NotSigned,

  //Счет блокирован - для списаний и торговых операций. Разрешены только зачисления на счет. Обычная причина - необеспеченная задолженность на счете.
  Malicious,
}

export enum NotificationActionFlags {
  None,
  Subscribe,
  Unsubscribe,
}

export enum NotificationType {
  None,
  FinancialAdviser,
  PersonalBrokerToClients,
  PersonalBrokerToStaff,
  OrderStatus,
  TrackPrice,
  IPO,
  Custom,
  Refill,
  Withdrawls,
  IssueCertificate,
  SignCertificate,
}

export enum NotificationSubscriptionState {
  None,
  Enabled,
  Disabled,
  EnabledAlways,
}

// Отрицательные индексы используются для добавления классов в схему сериализации
// чтобы не пересекаться с сообщениями протокола и при этом метаданные нормально работали

// это просто int32 здесь он нужен чтобы добавить его в сериализуемые массивы
// если пондобиться массив из каких-то других элементов, то следует их также добавить
@ADEntity(-1, EntityType.Int32Element)
export class Int32Element extends Entity {
  @Int32Field()
  Int32Element: number;
}

@ADEntity(-2, EntityType.OrderBookLevelEntity)
export class OrderBookLevelEntity extends Entity {
  @Int32Field()
  LineId: number;
  @DoubleField()
  Price: number;
  @Int32Field()
  BuyQty: number;
  @Int32Field()
  SellQty: number;
  @Int64Field()
  Revision: BigInt;
  @DoubleField()
  OldPrice: number;
}

@ADEntity(-3, EntityType.IntervalInfoEntity)
export class IntervalInfoEntity extends Entity {
  @DateTimeField()
  Date: Date;
  @Int64Field()
  Hash: BigInt;
}

@ADEntity(-4, EntityType.ADField)
export class ADField extends Entity {
  @StringField(50)
  Name: string;
  @EnumField(1)
  Type: number; //ADFieldType
  @StringField(50)
  StructTypeName: string;
  @BooleanField()
  Array: boolean;
  @Int32Field()
  Size: number;
  @BooleanField()
  Nullable: boolean;
}

@ADEntity(-5, EntityType.ADMessage)
export class ADMessage extends Entity {
  @EnumField(2)
  ID: number; //EntityType
  @StringField(50)
  Name: string;
  @EnumField(1)
  Mask: number; // ADMessageMaskType
  @EnumField(1)
  Direction: number; // ADMessageDirection
  @ArrayField(-1, 255, 'ADField')
  Fields: ADField[];
}

@ADEntity(-6, EntityType.VariantTypeElement)
export class VariantTypeElement extends Entity {
  @VariantField()
  VariantElement: any;
}

@ADEntity(-7, EntityType.ZoneConnectionInfo)
export class ZoneConnectionInfo extends Entity {
  @Int32Field()
  FrontEndType: number;
  @Int64Field()
  ConnectionKey: BigInt;
}

@ADEntity(-8, EntityType.VolumeForPrice)
export class VolumeForPrice extends Entity {
  @DoubleField()
  Price: number;
  @Int64Field()
  Volume: BigInt;
  @Int64Field()
  VolumeAsk: BigInt;
}

@ADEntity(-9, EntityType.OrderBookLevelWithYieldEntity)
export class OrderBookLevelWithYieldEntity extends Entity {
  @Int32Field()
  LineId: number;
  @DoubleField()
  Price: number;
  @Int32Field()
  BuyQty: number;
  @Int32Field()
  SellQty: number;
  @Int64Field()
  Revision: BigInt;
  @DoubleField()
  OldPrice: number;
  @DoubleField()
  Yield: number;
}

/*

  СООБЩЕНИЯ ПРОТОКОЛА

*/

@ADEntity(1, EntityType.AllowedOrderParamEntity)
export class AllowedOrderParamEntity extends Entity {
  @Int32Field()
  IdAllowedOrderParams: number;
  @EnumField(4)
  IdObjectGroup: ObjectGroup;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(1)
  IdDocumentType: DocumentType;
  @EnumField(1)
  IdOrderType: OrderType;
  @EnumField(1)
  IdQuantityType: QuantityType;
  @EnumField(1)
  IdPriceType: PriceType;
  @EnumField(1)
  IdLifeTime: LifeTime;
  @EnumField(1)
  IdExecutionType: ExecutionType;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(14, EntityType.ObjectEntity)
export class ObjectEntity extends Entity {
  @Int32Field()
  IdObject: number;
  @EnumField(4)
  IdObjectType: ObjectTypes;
  @Int32Field()
  IdObjectFaceUnit: number;
  @Int32Field()
  IdObjectBase: number;
  @Int32Field()
  IdIndustry: number;
  @StringField(18)
  SymbolObject: string;
  @StringField(245)
  NameObject: string;
  @StringField(1024)
  DescObject: string;
  @StringField(256)
  RegCodeObject: string;
  @DoubleField()
  Nominal: number;
  @DoubleField()
  Mult: number;
  @DateTimeField()
  MatDateObject: Date;
  @UnknownField(2)
  Expired: number;
  @BooleanField()
  IsEnabledObject: boolean;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @StringField(30)
  Isin: string;
}

@ADEntity(17, EntityType.ObjectTypeEntity)
export class ObjectTypeEntity extends Entity {
  @Int32Field()
  IdObjectType: number;
  @StringField(256)
  CodeObjectType: string;
  @StringField(256)
  NameObjectType: string;
  @EnumField(4)
  IdObjectGroup: ObjectGroup;
  @StringField(256)
  ShortNameObjectType: string;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADMaskedEntity(18, EntityType.OrderEntity)
export class OrderEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  OrderStatusRevision: number;
  @Int32Field()
  Rest: number;
  @Int32Field()
  EntryRest: number;
  @DoubleField()
  Price: number;
  @Int32Field()
  ExtSessId: number;
  @Int64Field()
  ExtOrderNum: BigInt;
  @Int32Field()
  SessionFilled: number;
  @Int64Field()
  OrderUpdateTime: BigInt;
  @DoubleField()
  FilledPrice: number;
  @StringField(400)
  BrokerComment: string;
  @EnumField(4)
  BrokerErrorCode: Messages;
  @Int64Field()
  BATime: BigInt;
  @Int32Field()
  ConfirmedQuantity: number;
  @Int64Field()
  KillNumEDocument: BigInt;
  @Int64Field()
  SentTime: BigInt;
  @Int64Field()
  SendCompletedTime: BigInt;
  @Int64Field()
  KillTime: BigInt;
  @Int64Field()
  TerminatedTime: BigInt;
  @Int64Field()
  ExchangeTime: BigInt;
  @Int64Field()
  ExchangeTerminatedTime: BigInt;
  @Int64Field()
  RepoDate: BigInt;
  @Int32Field()
  ClientNumStrategy: number;
  @EnumField(1)
  IdOrderType: OrderType;
  @EnumField(1)
  IdQuantityType: QuantityType;
  @EnumField(1)
  IdPriceType: PriceType;
  @EnumField(1)
  IdLifeTime: LifeTime;
  @EnumField(1)
  IdExecutionType: ExecutionType;
  @EnumField(1)
  IdPriceControlType: PriceControlType;
  @Int32Field()
  IdSettleCode: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(4)
  IdOrderStatus: OrderStatus;
  @Int32Field()
  IdRazdel: number;
  @DoubleField()
  LimitPrice: number;
  @DoubleField()
  StopPrice: number;
  @DoubleField()
  LimitLevelAlternative: number;
  @DateTimeField()
  SignTime: Date;
  @EnumField(2)
  BuySell: OrderDirection;
  @StringField(50)
  Contragent: string;
  @StringField(100)
  ContragentRef: string;
  @Int32Field()
  Quantity: number;
  @Int32Field()
  OpenQuantity: number;
  @DoubleField()
  RefundRate: number;
  @DoubleField()
  SDiscount: number;
  @DoubleField()
  LowerDiscount: number;
  @DoubleField()
  UpperDiscount: number;
  @DateTimeField()
  ActivationTime: Date;
  @Int64Field()
  OCOGroup: BigInt;
  @DateTimeField()
  WithdrawTime: Date;
  @Int32Field()
  IdFIActivate: number;
  @EnumField(2)
  ActivationPriceDirection: OrderStopPriceDirection;
  @DoubleField()
  Value: number;
  @StringField(50)
  BIC: string;
  @StringField(1024)
  Comment: string;
  @StringField(32)
  AccountContragent: string;
  @Int32Field()
  IdAccountTypeContragent: number;
  @Int64Field()
  TradeNo: BigInt;
  @StringField(256)
  Reason: string;
  @EnumField(1)
  IdCommandType: CommandType;
  @EnumField(1)
  IdDocumentType: DocumentType;
  @StringField(50)
  Login: string;
  @UInt32Field()
  ClientOrderNum: number;
  @StringField(128)
  ClientNumEDocument: string;
  @Int64Field()
  NumEDocument: BigInt;
  @DateTimeField()
  AcceptTime: Date;
  @Int64Field()
  NumEDocumentBase: BigInt;
  @EnumField(1)
  Operation: Operation;
  @EnumField(8)
  Flags: OrderFlags;
  @Int32Field()
  IdFI: number;
}

@ADEntity(19, EntityType.ClientRestrictions)
export class ClientRestrictions extends Entity {
  @EnumField(2)
  CommandType: number;
  @Int64Field()
  DT: BigInt;
  @Int32Field()
  TotalAttemptsMAX: number;
  @Int32Field()
  CountMaxDt: number;
  @Int32Field()
  InnerCountMac: number;
}

@ADEntity(20, EntityType.ChatGroupEntity)
export class ChatGroupEntity extends Entity {
  @Int32Field()
  IdChatGroup: number;
  @StringField()
  NameChatGroup: string;
  @Int32Field()
  ifEnable: number;
  @Int32Field()
  IdLoginChatGroup: number;
  @BooleanField()
  ConfirmReading: boolean;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @EnumField(1)
  IdRole: ChatUserRoleType;
  @Int32Field()
  IdClientStrategy: number;
  @StringField()
  NameClientStrategy: string;
  @StringField()
  Message: string;
  @EnumField(4)
  IdState: ChatGroupState;
  @DateTimeField()
  NextActiveDate: Date;
}

@ADEntity(22, EntityType.ChatMessageEntity)
export class ChatMessageEntity extends Entity {
  @Int32Field()
  IdChatMessage: number;
  @Int32Field()
  IdSender: number;
  @StringField()
  SenderNickname: string;
  @Int32Field()
  IdRecipient: number;
  @StringField()
  RecipientNickname: string;
  @Int32Field()
  IdChatGroup: number;
  @DateTimeField()
  DtChatMessage: Date;
  @StringField()
  Text: string;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  IdBrokerRecommendation: number;
  @ArrayField(-1, 1, EntityType.BrokerRecommendationEntity)
  BrokerRecommendation: BrokerRecommendationEntity[];
}

@ADEntity(26, EntityType.ClientMessageEntity)
export class ClientMessageEntity extends Entity {
  @Int32Field()
  IdLogin: number;
  @Int32Field()
  MessageId: number;
  @ArrayField(-1, -1, EntityType.VariantTypeElement, true)
  Objects: any[];
  @Int32Field()
  ClientMessageFlags: number;
}

@ADEntity(39, EntityType.HeartbeatEntity)
export class HeartbeatEntity extends Entity {
  @DateTimeField()
  SendTime: Date;
  @DateTimeField()
  ReceieveTime: Date;
  @DateTimeField()
  ReplyTime: Date;
}

@ADMaskedEntity(31, EntityType.FinInfoEntity)
export class FinInfoEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @EnumField(4)
  IdTradePeriodStatus: TradePeriodType;
  @DoubleField()
  Last: number;
  @Int32Field()
  LastQty: number;
  @DateTimeField()
  LastTime: Date;
  @DoubleField()
  Bid: number;
  @DoubleField()
  Ask: number;
  @DoubleField()
  Yield: number;
  @DoubleField()
  Duration: number;
  @Int32Field()
  BidQty: number;
  @Int32Field()
  AskQty: number;
  @Int32Field()
  SumBid: number;
  @Int32Field()
  SumAsk: number;
  @Int32Field()
  NumBids: number;
  @Int32Field()
  NumAsks: number;
  @DoubleField()
  High: number;
  @DoubleField()
  Low: number;
  @DoubleField()
  WAPrice: number;
  @DoubleField()
  YieldWAPrice: number;
  @Int64Field()
  NumTrades: BigInt;
  @Int64Field()
  VolToday: BigInt;
  @DoubleField()
  ValToday: number;
  @Int32Field()
  Pos: number;
  @DoubleField()
  HighBid: number;
  @DoubleField()
  LowOffer: number;
  @DoubleField()
  Quote: number;
  @DateTimeField()
  QuoteTime: Date;
  @DateTimeField()
  ExTime: Date;
  @Int32Field()
  IdSession: number;
  @Int32Field()
  IdBoard: number;
  @Int32Field()
  IdGate: number;
  @Int64Field()
  Revision: BigInt;
  @EnumField(1)
  Flags: number;
  @DoubleField()
  MinCurLast: number;
  @DoubleField()
  Open: number;
  @DoubleField()
  PrevCloseYield: number;
  @DoubleField()
  PrevPos: number;
  @DoubleField()
  PrevWAPrice: number;
}

@ADEntity(32, EntityType.FinInfoExtEntity)
export class FinInfoExtEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @Int32Field()
  IdGate: number;
  @Int32Field()
  IdMarket: number;
  @Int32Field()
  IdBoard: number;
  @EnumField(4)
  IdTradePeriodStatus: TradePeriodType;
  @Int32Field()
  IdAllowOrderStatus: number;
  @Int32Field()
  IdSession: number;
  @DateTimeField()
  SessionDate: Date;
  @Int32Field()
  Lot: number;
  @DoubleField()
  PriceStep: number;
  @DoubleField()
  PriceStepCost: number;
  @Int32Field()
  IdObjectCurrency: number;
  @DoubleField()
  Rate: number;
  @DoubleField()
  PSTNKD: number;
  @DoubleField()
  UpPrice: number;
  @DoubleField()
  DownPrice: number;
  @DoubleField()
  GtBuy: number;
  @DoubleField()
  GtSell: number;
  @StringField(256)
  AgentCode: string;
  @DateTimeField()
  AuctActivationDate: Date;
  @DoubleField()
  CounterPrice: number;
  @Int32Field()
  PrevSessionId: number;
  @DateTimeField()
  PrevSessionDate: Date;
  @DoubleField()
  PrevQuote: number;
  @DateTimeField()
  PrevLastDate: Date;
  @DoubleField()
  PrevLast: number;
  @DateTimeField()
  NextCoupon: Date;
  @DateTimeField()
  BuyBackDate: Date;
  @Int32Field()
  CouponPeriod: number;
  @DoubleField()
  Duration: number;
  @DoubleField()
  AccruedInt: number;
  @DoubleField()
  CouponValue: number;
  @DoubleField()
  CloseYield: number;
  @DoubleField()
  YieldAtPrevWaPrice: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @DoubleField()
  FairPrice: number;
  @DoubleField()
  PrevFairPrice: number;
  @DateTimeField()
  PrevFairPriceDate: Date;
  @Int32Field()
  IcebergMinQuantity: number;
  @Int32Field()
  IcebergMinOpenQuantity: number;
}

@ADEntity(34, EntityType.MarketBoardEntity)
export class MarketBoardEntity extends Entity {
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(4)
  IdMarketType: MarketType;
  @Int32Field()
  IdGate: number;
  @EnumField(4)
  IdBoardType: number;
  @StringField()
  CodeMarketBoard: string;
  @StringField(256)
  NameMarketBoard: string;
  @StringField(256)
  DescMargetBoard: string;
  @Int32Field()
  IdSettleCode: number;
  @StringField()
  RCode: string;
  @Int32Field()
  IdObjectCurrency: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  IdRazdelTypeCurrency: number;
  @Int32Field()
  IdObjectFee: number;
  @Int32Field()
  IdRazdelTypeFee: number;
  @BooleanField()
  IsWarrantySupply: boolean;
  @Int32Field()
  IdGateManual: number;
  @EnumField(8)
  Type: MarketBoardType;
  @StringField(256)
  PlaceCode: string;
  @StringField(63)
  UniversalMarketCode: string;
}

@ADEntity(35, EntityType.MarketEntity)
export class MarketEntity extends Entity {
  @Int32Field()
  IdMarket: number;
  @StringField()
  CodeMarket: string;
  @StringField()
  NameMarket: string;
  @Int32Field()
  IdExchange: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(30, EntityType.ExchangeEntity)
export class ExchangeEntity extends Entity {
  @Int32Field()
  IdExchange: number;
  @StringField()
  CodeExchange: string;
  @StringField()
  NameExchange: string;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(33, EntityType.FinInstrumentEntity)
export class FinInstrumentEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @Int32Field()
  IdGate: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADMaskedEntity(36, EntityType.OrderBookEntity)
export class OrderBookEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @Int32Field()
  IdBoard: number;
  @Int32Field()
  IdGate: number;
  @Int64Field()
  Revision: BigInt;
  @EnumField(1)
  Flags: InitFlagType;
  @DateTimeField()
  BATime: Date;
  @ArrayField(-1, 40, EntityType.OrderBookLevelEntity)
  Lines: OrderBookLevelEntity[];
}

@ADEntity(43, EntityType.ClientSubAccountEntity)
export class ClientSubAccountEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdSubAccount: number;
  @DateTimeField()
  BackDate: Date;
  @DoubleField()
  BackBal: number;
  @DoubleField()
  BackPL: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(45, EntityType.ClientGTSubAccountEntity)
export class ClientGTSubAccountEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdSubAccount: number;
  @DoubleField()
  PortfolioCost: number;
  @DoubleField()
  MarginInitial: number;
  @DoubleField()
  MarginRequirement: number;
  @Int32Field()
  IdMarginRequirement: number;
  @EnumField(4)
  IdRazdelGroup: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(48, EntityType.ClientAccountEntity)
export class ClientAccountEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @DoubleField()
  MargKoef: number;
  @Int32Field()
  IdTarif: number;
  @DoubleField()
  TaxRate: number;
  @DoubleField()
  BackTaxSaldo: number;
  @Int32Field()
  IdScheme: number;
  @DateTimeField()
  BackDate: Date;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  QLevel: number;
  @EnumField(1)
  IIAType: IIAType;
  @DoubleField()
  MICEX_FeeRate: number; //ставка по операциям с ЦБ на МБ
  @DoubleField()
  SELT_FeeRate: number; // ставка по операциям с иностранной валютой на биржевом рынке Московской биржи
  @DoubleField()
  SPBEX_RPSFeeRate: number; // ставка по операциям с ЦБ в РПС (режим переговорных сделок) на Санкт-Петербургской бирже
  @DoubleField()
  SPBEX_FeeRate: number; // ставка по операциям с ЦБ на СПБ
  @BooleanField()
  SPBEX_OneCentFeeFlag: boolean; // признак 'не менее одного цента за каждую ЦБ в сделке'
  @DoubleField()
  OTC_FeeRate: number; // ставка по операциям с ЦБ на внебиржевом рынке
  @BooleanField()
  EFR: boolean; // признак перевода счета на ЕФР
  @BooleanField()
  MicroInvest: boolean;
  @Int64Field()
  Options: BigInt;
  @EnumField(1)
  IdAccountStatus: AccountStatus;
}

@ADEntity(55, EntityType.AllTradeEntity)
export class AllTradeEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @Int32Field()
  IdSession: number;
  @Int64Field()
  TradeNo: BigInt;
  @EnumField(4)
  IdTradePeriodStatus: TradePeriodType;
  @EnumField(4)
  IdTradeType: TradeType;
  @DateTimeField()
  TradeTime: Date;
  @DoubleField()
  Price: number;
  @Int16Field()
  BuySell: number; // buy = 1 sell = -1
  @DoubleField()
  Yield: number;
  @Int64Field()
  Qty: BigInt;
  @DoubleField()
  Value: number;
  @DoubleField()
  RepoRate: number;
  @Int32Field()
  RepoDays: number;
  @Int32Field()
  Pos: number;
  @Int64Field()
  Revision: BigInt;
  @Int32Field()
  IdGate: number;
  @Int32Field()
  IdBoard: number;
  @StringField()
  SettleCode: string;
}

@ADEntity(57, EntityType.UserCredentialsEntity)
export class UserCredentialsEntity extends Entity {
  @StringField(50)
  Login: string;
  @StringField(256)
  FullName: string;
  @Int32Field()
  MessageId: number;
  @StringField(32)
  ProtocolVersion: string;
  @StringField(128)
  OrderPrefix: string;
  @ArrayField(5, 5, EntityType.ZoneConnectionInfo)
  ZoneConnectionInfos: ZoneConnectionInfo[];
  @BooleanField()
  Demo: boolean;
  @StringField(50)
  AccessToken: string;
  @StringField(50)
  RefreshToken: string;
  @Int32Field()
  IdAccount: number;
  @StringField(50)
  Pin: string;
  @Int32Field()
  IdLogin: number;
  @Int64Field()
  SessionID: BigInt;
  @EnumField(2)
  EnvironmentType: EnvironmentType;
  @EnumField(8)
  Options: UserCredentialsOptions;
  @EnumField(1)
  SystemType: ServicesMainSystemType;
}

@ADEntity(58, EntityType.AuthorizeWithConnectionKeyEntity)
export class AuthorizeWithConnectionKeyEntity extends Entity {
  @StringField(50)
  Login: string;
  @Int64Field()
  ConnectionKey: BigInt;
  @StringField(32)
  ProtocolVersion: string;
  @StringField(38)
  IdDevice: string;
}

@ADEntity(40, EntityType.AuthenticationRequestEntity)
export class AuthenticationRequestEntity extends Entity {
  @StringField(50)
  Login: string;
  @StringField(50)
  Password: string;
  @StringField(30)
  ClientVersion: string;
  @StringField(50)
  DeveloperCode: string;
  @StringField(32)
  ProtocolVersion: string;
  @StringField(256)
  OSVersion: string;
  @EnumField(1)
  OSType: OSType;
  @StringField(256)
  DeviceModel: string;
  @StringField(30)
  MacAdress: string;
  @StringField(38)
  IdDevice: string;
  @EnumField(1)
  ClientType: ClientType;
  @DateTimeField()
  LocalTime: Date;
  @StringField(256)
  HomeDir: string;
  @StringField(256)
  FilesDir: string;
  @StringField(256)
  UserName: string;
  @StringField(40)
  ConnectionIP: string;
}

@ADEntity(321, EntityType.BrokerRecommendationEntity)
export class BrokerRecommendationEntity extends Entity {
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  IdBrokerRecommendation: number;
  @DateTimeField()
  AcceptTime: Date;
  @Int32Field()
  IdClientStrategy: number;
  @Int32Field()
  IdFi: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(2)
  BuySell: OrderDirection;
  @EnumField(1)
  IdOrderType: OrderType;
  @DoubleField()
  Price: number;
  @Int32Field()
  Quantity: number;
  @EnumField(1)
  IdLifeTime: LifeTime;
  @StringField()
  Comment: string;
  @EnumField(1)
  Flags: BrokerRecommendationFlags;
  @Int32Field()
  IdChatGroup: number;
  @EnumField(1)
  IdQuantityType: QuantityType;
  @Int32Field()
  IdSubAccount: number;
  @DoubleField()
  StopPrice: number;
  @DoubleField()
  LimitLevelAlternative: number;
}

@ADEntity(327, EntityType.ResetDataRequestEntity)
export class ResetDataRequestEntity extends Entity {
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  Id: number;
  @EnumField(2)
  DataType: number; //EntityType
  @BooleanField()
  IsDataId: boolean;
  @ConditionField((entity: ResetDataRequestEntity) =>
    entity.IsDataId
      ? {
          type: FieldType.Int64Field,
        }
      : {
          type: FieldType.UnknownField,
        }
  )
  DataId: BigInt;
}

@ADEntity(347, EntityType.PassportAuthenticationRequestEntity)
export class PassportAuthenticationRequestEntity extends Entity {
  @StringField(50)
  Token: string;
  @EnumField(1)
  TokenType: PassportTokenType;
  @StringField(30)
  ClientVersion: string;
  @StringField(50)
  DeveloperCode: string;
  @StringField(32)
  ProtocolVersion: string;
  @StringField(256)
  OSVersion: string;
  @EnumField(1)
  OSType: OSType;
  @StringField(256)
  DeviceModel: string;
  @StringField(30)
  MacAdress: string;
  @StringField(38)
  IdDevice: string;
  @EnumField(1)
  ClientType: ClientType;
  @DateTimeField()
  LocalTime: Date;
  @StringField(256)
  HomeDir: string;
  @StringField(256)
  FilesDir: string;
  @StringField(256)
  UserName: string;
  @StringField(40)
  ConnectionIP: string;
}

@ADEntity(63, EntityType.SubAccountRazdelEntity)
export class SubAccountRazdelEntity extends Entity {
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdRazdel: number;
  @Int32Field()
  IdRazdelType: number;
  @Int32Field()
  IdDepoAccount: number;
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdGate: number;
  @EnumField(4)
  IdRazdelGroup: RazdelGroupType;
  @StringField(18)
  CodeRazdelType: string;
  @StringField(12)
  TradeAccount: string;
  @StringField(16)
  ClientCode: string;
  @StringField(14)
  CodeSubAccount: string;
  @StringField(30)
  CodeDepoAccount: string;
  @StringField(10)
  CorCodeRazdel: string;
  @StringField(512)
  NameClient: string;
  @StringField(512)
  NameDeponent: string;
  @StringField(256)
  NameCustodian: string;
  @StringField(256)
  NameCorCustodian: string;
  @StringField(9)
  RCode: string;
  @StringField(40)
  NameSubAccount: string;
  @StringField(40)
  NameRazdelType: string;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @EnumField(4)
  IdAccessType: AccessType;
  @EnumField(1)
  FrontID: FrontType;
  @Int32Field()
  IdRazdelBalance: number;
  @Int32Field()
  IdRazdelTypeBalance: number;
  @StringField(15)
  UniversalExchangeCode: string;
}

@ADEntity(65, EntityType.ClientOrderEntity)
export class ClientOrderEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @UInt32Field()
  ClientOrderNum: number;
  @Int32Field()
  IdSubAccount: number;
  @EnumField(1)
  IdPriceControlType: PriceControlType;
  @Int32Field()
  IdSettleCode: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdRazdel: number;
  @DoubleField()
  LimitPrice: number;
  @DoubleField()
  StopPrice: number;
  @DoubleField()
  LimitLevelAlternative: number;
  @Int64Field()
  SignTime: BigInt;
  @EnumField(2)
  BuySell: OrderDirection;
  @StringField(50)
  Contragent: string;
  @StringField(100)
  ContragentRef: string;
  @Int32Field()
  Quantity: number;
  @Int32Field()
  OpenQuantity: number;
  @DoubleField()
  RefundRate: number;
  @DoubleField()
  SDiscount: number;
  @DoubleField()
  LowerDiscount: number;
  @DoubleField()
  UpperDiscount: number;
  @Int64Field()
  ActivationTime: BigInt;
  @Int64Field()
  // 1000000 * (int) (IdCommandType from kill order - if need) + 100000 * (int)(признак автоматического заведения подзаявок - 1 или 0) + 10000 * (int)alfaadmin (1 если поручение или снятие поручения пришло из АА или  0) + 100 * (int)OrderChannels + (int)signType
  OCOGroup: BigInt;
  @Int64Field()
  WithdrawTime: BigInt;
  @Int32Field()
  IdFIActivate: number;
  @EnumField(2)
  ActivationPriceDirection: OrderStopPriceDirection;
  @DoubleField()
  Value: number;
  @StringField(256)
  BIC: string;
  @StringField(256)
  Comment: string;
  @StringField(256)
  AccountContragent: string;
  @Int32Field()
  IdAccountTypeContragent: number;
  @StringField(256)
  Reason: string;
  @EnumField(1)
  IdCommandType: CommandType;
  @Int32Field()
  IdAllowedOrderParams: number;
  @UnknownField(4096)
  BinaryEDocument: any;
  @Int64Field()
  IdCertificate: BigInt;
  @Int64Field()
  NumEDocumentBase: BigInt;
  @Int64Field()
  RepoDate: BigInt;
  @Int32Field()
  ClientNumStrategy: number;
}

@ADEntity(74, EntityType.ClientOperationEntity)
export class ClientOperationEntity extends Entity {
  @Int32Field()
  IdOperation: number;
  @DateTimeField()
  TimeOperation: Date;
  @Int64Field()
  ExtOperNo: BigInt;
  @Int64Field()
  NumEDocument: BigInt;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(4)
  IdOperationType: OperationType;
  @EnumField(2)
  BuySell: OrderDirection;
  @DoubleField()
  Quantity: number;
  @DoubleField()
  Price: number;
  @DoubleField()
  Value: number;
  @Int32Field()
  IdObjectCurrency: number;
  @DoubleField()
  Yield: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdAccount: number;
  @StringField(2048)
  Description: string;
  @EnumField(4)
  IdOperationStatus: OperationStatus;
  @DateTimeField()
  SettleDate: Date;
  @DateTimeField()
  DeliveryDate: Date;
  @Int32Field()
  RepoTerm: number;
  @DateTimeField()
  RepoDate: Date;
  @Int32Field()
  IdRazdel: number;
  @DoubleField()
  AccruedInt: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @EnumField(4)
  IdOperationGroup: OperationGroup;
}

@ADEntity(75, EntityType.LimitsOverflowEntity)
export class LimitsOverflowEntity extends Entity {
  @EnumField(4)
  Messages: Messages;
  @EnumField(1)
  CommandType: CommandType;
  @ArrayField(4, 500, 'Int32Element', true)
  FlowKeys: number[];
  @Int64Field()
  DtToWait: number;
  @EnumField(1)
  SubscribeType: SubscribeType;
}

@ADEntity(76, EntityType.ArchiveRequestEntity)
export class ArchiveRequestEntity extends Entity {
  @EnumField(1)
  CandleType: CandleType;
  @Int32Field()
  IdRequest: number;
  @Int32Field()
  IdFI: number;
  @EnumField(1)
  TimeFrame: BaseTimeFrame;
  // На самом деле это дата с которой мы получаем историю
  // ака "название дурацкое"
  @DateTimeField()
  MaximumDate: Date;
  // кол-во дней от MaximumDate
  @Int32Field()
  DaysCount: number;
  @ArrayField(-1, 720, EntityType.IntervalInfoEntity)
  IntervalInfo: IntervalInfoEntity[];
}

@ADEntity(77, EntityType.BaseOperationEntity)
export class BaseOperationEntity extends Entity {
  @Int32Field()
  IdOperation: number;
  @DateTimeField()
  TimeOperation: Date;
  @Int64Field()
  ExtOperNo: BigInt;
  @Int64Field()
  NumEDocument: BigInt;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(4)
  IdOperationType: OperationType;
  @EnumField(2)
  BuySell: OrderDirection;
  @DoubleField()
  Quantity: number;
  @DoubleField()
  Price: number;
  @DoubleField()
  Value: number;
  @Int32Field()
  IdObjectCurrency: number;
  @DoubleField()
  Yield: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdAccount: number;
  @StringField(2048)
  Description: string;
  @EnumField(4)
  IdOperationStatus: OperationStatus;
  @DateTimeField()
  SettleDate: Date;
  @DateTimeField()
  DeliveryDate: Date;
  @Int32Field()
  RepoTerm: number;
  @DateTimeField()
  RepoDate: Date;
  @Int32Field()
  IdRazdel: number;
  @DoubleField()
  AccruedInt: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(78, EntityType.ClientSubAccPositionEntity)
export class ClientSubAccPositionEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdPosition: number;
  @Int32Field()
  IdFIBalance: number;
  @DateTimeField()
  BackDate: Date;
  @DoubleField()
  BackUchPriceRur: number;
  @DoubleField()
  BackPos: number;
  @DoubleField()
  AwPriceBuy: number;
  @DoubleField()
  AwPriceSell: number;
  @DoubleField()
  CurRate: number;
  @DoubleField()
  PSTNKD: number;
  @DoubleField()
  TrnIn: number;
  @DoubleField()
  TrnOut: number;
  @DoubleField()
  BuyQty: number;
  @DoubleField()
  SellQty: number;
  @DoubleField()
  TrdPL: number;
  @DoubleField()
  UchPriceRur: number;
  @DoubleField()
  UchPrice: number;
  @Int32Field()
  IdRazdel: number;
  @DoubleField()
  MarginDiscountBuyD0: number;
  @DoubleField()
  MarginDiscountSellD0: number;
  @DoubleField()
  MarginDiscountBuyDX: number;
  @DoubleField()
  MarginDiscountSellDX: number;
  @DoubleField()
  VariationMargin: number;
  @DoubleField()
  SessionBuyQty: number;
  @DoubleField()
  SessionBuyVal: number;
  @DoubleField()
  SessionSellQty: number;
  @DoubleField()
  SessionSellVal: number;
  @DoubleField()
  SubAccNalPos: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @Int32Field()
  IdObjectOfUchPrice: number;
  @DoubleField()
  UchPriceUSD: number;
  @DoubleField()
  UchPriceEUR: number;
  @DoubleField()
  UchPriceGBP: number;
  @DoubleField()
  UchPriceCHF: number;
}

@ADEntity(79, EntityType.DataFlowSubscribeEntity)
export class DataFlowSubscribeEntity extends Entity {
  // здесь id типа энтити в протоколе
  @EnumField(2)
  DataFlowType: number;
  @ArrayField(4, 500, 'Int32Element', true)
  FlowKeys: number[];
  @EnumField(1)
  SubscribeType: SubscribeType;
  @Int32Field()
  Delay: number;
  @Int64Field()
  Version: BigInt;
}

@ADEntity(80, EntityType.ChartArchiveEntity)
export class ChartArchiveEntity extends Entity {
  @Int32Field()
  IdRequest: number;
  @EnumField(1)
  CandleType: CandleType;
  @Int32Field()
  IdFI: number;
  @EnumField(1)
  TimeFrame: BaseTimeFrame;
  @DateTimeField()
  Date: Date;
  @BooleanField()
  FullDay: boolean;
  @Int64Field()
  LastTradeNo: BigInt;
  @Int32Field()
  LastTradesCount: number;
  @Int64Field()
  Hash: BigInt;
  // тут может быть несколько разных энтити
  // зависит от параметра CandleType
  @ConditionField((entity: ChartArchiveEntity) => {
    return getArrayFieldMeta(
      -1,
      -1,
      entity.CandleType === CandleType.Standard
        ? EntityType.OhlcvDataPointEntity
        : EntityType.MpvDataPointEntity
    );
  })
  Candles: (MpvDataPointEntity | OhlcvDataPointEntity)[];
}

@ADEntity(82, EntityType.TradeLimitRequestEntity)
export class TradeLimitRequestEntity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdRazdel: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(1)
  IdDocumentType: DocumentType;
  @DoubleField()
  RepoRate: number;
  @Int32Field()
  RepoDays: number;
  @EnumField(2)
  BuySell: OrderDirection;
  @DoubleField()
  Price: number;
  @EnumField(1)
  IdOrderType: OrderType;
  @Int32Field()
  IdRequest: number;
}

@ADEntity(83, EntityType.TradeLimitResponseEntity)
export class TradeLimitResponseEntity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdRazdel: number;
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdMarketBoard: number;
  @EnumField(1)
  IdDocumentType: DocumentType;
  @DoubleField()
  RepoRate: number;
  @Int32Field()
  RepoDays: number;
  @EnumField(2)
  BuySell: OrderDirection;
  @DoubleField()
  Price: number;
  @EnumField(1)
  IdOrderType: OrderType;
  @Int32Field()
  IdRequest: number;
  @Int32Field()
  Quantity: number; // Лимитирующее количество на покупку данного инструмента
  @Int32Field()
  QuantityForOwnAssets: number; // Лимтирующее количество на покупку данного инструмента за свои
  @DoubleField()
  Amount: number; // лимитурующее количество денежных средств на операцию с инструментом
  @DoubleField()
  FreeMoney: number; // лимтирующее количество денег на свои( у Белова amountForOwnAssets)
}

@ADEntity(86, EntityType.ClientCertificateEntity)
export class ClientCertificateEntity extends Entity {
  @Int32Field()
  IdLogin: number;
  @Int64Field()
  IdCertificate: BigInt;
  @EnumField(1)
  State: CertificateStateType;
  @EnumField(1)
  Type: CertificateType;
  @StringField()
  Alias: string;
  @StringField()
  Description: string;
  @ByteArray()
  Certificate: ArrayBuffer;
  @StringField()
  IdDevice: string;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(87, EntityType.InitFinishedEntity)
export class InitFinishedEntity extends Entity {
  @Int16Field()
  Command: number;
  @Int32Field()
  Id: number;
}

@ADEntity(135, EntityType.CandleStandardDbEntity)
export class CandleStandardDbEntity extends Entity {
  @EnumField(1)
  CandleType: CandleType;
  @Int32Field()
  IdFI: number;
  @EnumField(1)
  TimeFrame: BaseTimeFrame;
  @DoubleField()
  Open: number;
  @DoubleField()
  High: number;
  @DoubleField()
  Low: number;
  @DoubleField()
  Close: number;
  @Int64Field()
  Volume: BigInt;
  @Int64Field()
  OpenInt: BigInt;
  @DateTimeField()
  Time: Date;
  @Int64Field()
  VolAsk: BigInt;
  @Int32Field()
  IdCandle: number;
}

@ADEntity(261, EntityType.OhlcvDataPointEntity)
export class OhlcvDataPointEntity extends Entity {
  @UnknownField(4)
  WTF: number;
  @DateTimeField()
  DateTime: Date;
  @DoubleField()
  Open: number;
  @DoubleField()
  High: number;
  @DoubleField()
  Low: number;
  @DoubleField()
  Close: number;
  @Int64Field()
  Volume: BigInt;
  @Int64Field()
  OpenInt: BigInt;
  @Int64Field()
  VolumeAsk: BigInt;
}

@ADEntity(266, EntityType.MpvDataPointEntity)
export class MpvDataPointEntity extends Entity {
  @UnknownField(4)
  WTF: number;
  @DateTimeField()
  DateTime: Date;
  @DoubleField()
  OpenPrice: number;
  @DoubleField()
  ClosePrice: number;
  @ArrayField(-1, -1, 'VolumeForPrice')
  Volumes: VolumeForPrice[];
  @Int64Field()
  TotalVolumeAsk: BigInt;
  @Int64Field()
  OpenInt: BigInt;
}

@ADEntity(267, EntityType.ProtocolInfoEntity)
export class ProtocolInfoEntity extends Entity {
  @StringField(32)
  ProtocolVersion: string;
  @ArrayField(-1, 500, 'ADMessage')
  Messages: ADMessage[];
}

@ADEntity(354, EntityType.CertificateRequestEntity)
export class CertificateRequestEntity extends Entity {
  @Int32Field()
  IdRequest: number;
  @Int32Field()
  IdAccount: number;
  @ByteArray()
  CertificateRequest: ArrayBuffer;
  @StringField()
  Alias: string;
  @StringField()
  Description: string;
}

@ADEntity(355, EntityType.CertificateResponseEntity)
export class CertificateResponseEntity extends Entity {
  @Int32Field()
  IdRequest: number;
  @ByteArray()
  Certificate: ArrayBuffer;
}

@ADEntity(356, EntityType.RequestForCertificateActionSMSEntity)
export class RequestForCertificateActionSMSEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @Int32Field()
  IdRequest: number;
  @UInt32Field()
  ClientOrderNum: number;
  @Int64Field()
  IdCertificate: BigInt;
  @EnumField(1)
  Action: CertificateActionType;
}

@ADEntity(357, EntityType.ResponseForCertificateActionSMSEntity)
export class ResponseForCertificateActionSMSEntity extends Entity {
  @Int32Field()
  IdRequest: number;
  @StringField()
  Referece: string;
  @EnumField(4)
  ErrorCode: Messages;
  @StringField()
  ErrorText: string;
  @ByteArray()
  OrderTextTemplate: ArrayBuffer;
  @DateTimeField()
  SignTime: Date;
}

@ADEntity(358, EntityType.RequestForCertificateActionWithSMSEntity)
export class RequestForCertificateActionWithSMSEntity extends Entity {
  @Int32Field()
  IdAccount: number;
  @UInt32Field()
  ClientOrderNum: number;
  @ByteArray()
  BinaryEDocument: ArrayBuffer;
  @Int64Field()
  IdCertificate: BigInt;
  @DateTimeField()
  SignTime: Date;
  @StringField(20)
  Reference: string;
  @StringField(16)
  SMSCode: string;
  @EnumField(1)
  Action: CertificateActionType;
}

@ADEntity(359, EntityType.ResponseForCertificateActionWithSMSEntity)
export class ResponseForCertificateActionWithSMSEntity extends Entity {
  @Int32Field()
  ClientOrderNum: number;
  @StringField(128)
  ClientNumEDocument: string;
  @EnumField(4)
  ErrorCode: Messages;
  @StringField(1024)
  ErrorText: string;
}

@ADEntity(542, EntityType.ArchiveRequest3Entity)
export class ArchiveRequest3Entity extends Entity {
  @EnumField(1)
  CandleType: CandleType;
  @Int32Field()
  IdRequest: number;
  @Int32Field()
  IdFI: number;
  @EnumField(1)
  TimeFrame: BaseTimeFrame;
  // На самом деле это дата с которой мы получаем историю
  // ака "название дурацкое"
  @DateTimeField()
  MaximumDate: Date;
  // кол-во дней от MaximumDate
  @Int32Field()
  DaysCount: number;
  @ArrayField(-1, 720, EntityType.IntervalInfoEntity)
  IntervalInfo: IntervalInfoEntity[];
}

@ADEntity(544, EntityType.ChartSequenceBegin)
export class ChartSequenceBegin extends Entity {
  @Int32Field()
  IdRequest: number;
  @DateTimeField()
  RequestReceiveTime: Date;
  @DateTimeField()
  StartReplyTime: Date;
}

@ADEntity(501, EntityType.ClientEnterOrderReq)
export class ClientEnterOrderReq extends Entity {
  @Int32Field()
  IdAccount: number;
  @UInt32Field()
  ClientOrderNum: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdRazdel: number;
  @Int64Field()
  SignTime: BigInt;
  @EnumField(1)
  SignType: SignType;
  @EnumField(4)
  OrderChannel: OrderChannels;
  @Int32Field()
  IdFIActivate: number;
  @ByteArray()
  BinaryEDocument: ArrayBuffer;
  @Int64Field()
  IdCertificate: BigInt;
  @Int64Field()
  NumEDocumentBase: BigInt;
  @Int32Field()
  ClientNumStrategy: number;
  // конец ClientOrderReqBase
  @EnumField(1)
  IdPriceControlType: PriceControlType;
  @Int32Field()
  IdSettleCode: number;
  @Int32Field()
  IdObject: number;
  @DoubleField()
  LimitPrice: number;
  @DoubleField()
  StopPrice: number;
  @DoubleField()
  LimitLevelAlternative: number;
  @EnumField(2)
  BuySell: OrderDirection;
  @StringField()
  Contragent: string;
  @StringField()
  ContragentRef: string;
  @Int32Field()
  Quantity: number;
  @Int32Field()
  OpenQuantity: number;
  @Int64Field()
  ActivationTime: BigInt;
  @Int64Field()
  WithdrawTime: BigInt;
  @DoubleField()
  Value: number;
  @StringField()
  Comment: string;
  @Int32Field()
  IdAllowedOrderParams: number;
  @EnumField(4)
  Flags: EnterOrderFlags;
  @EnumField(2)
  ActivationPriceDirection: OrderStopPriceDirection;
}

@ADEntity(502, EntityType.ClientCancelOrderReq)
export class ClientCancelOrderReq extends Entity {
  @Int32Field()
  IdAccount: number;
  @UInt32Field()
  ClientOrderNum: number;
  @Int32Field()
  IdSubAccount: number;
  @Int32Field()
  IdRazdel: number;
  @Int64Field()
  SignTime: BigInt;
  @EnumField(1)
  SignType: SignType;
  @EnumField(4)
  OrderChannel: OrderChannels;
  @Int32Field()
  IdFIActivate: number;
  @ByteArray()
  BinaryEDocument: ArrayBuffer;
  @Int64Field()
  IdCertificate: BigInt;
  @Int64Field()
  NumEDocumentBase: BigInt;
  @Int32Field()
  ClientNumStrategy: number;
  @EnumField(4)
  Flags: CancelOrderFlags;
}

@ADEntity(504, EntityType.ClientEnterOrderRsp)
export class ClientEnterOrderRsp extends Entity {
  @UInt32Field()
  ClientOrderNum: number;
  @Int64Field()
  NumEDocument: number;
  @Int32Field()
  IdLogin: number;
  @EnumField(4)
  ErrorCode: Messages;
  @StringField()
  ErrorText: string;
}

@ADEntity(505, EntityType.ClientCancelOrderRsp)
export class ClientCancelOrderRsp extends Entity {
  @UInt32Field()
  ClientOrderNum: number;
  @Int64Field()
  NumEDocument: number;
  @Int32Field()
  IdLogin: number;
  @EnumField(4)
  ErrorCode: Messages;
  @StringField()
  ErrorText: string;
}

@ADEntity(545, EntityType.ChartSequenceEnd)
export class ChartSequenceEnd extends Entity {
  @Int32Field()
  IdRequest: number;
}

@ADEntity(395, EntityType.ObjectExtEntity)
export class ObjectExtEntity extends Entity {
  @Int32Field()
  IdObject: number;
  @Int32Field()
  IdFIBalance: number;
  @Int32Field()
  IdMarketBoardBalance: number;
  @DoubleField()
  AccruedIntT0: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}

@ADEntity(367, EntityType.TrackPriceEntity)
export class TrackPriceEntity extends Entity {
  @Int64Field()
  IdTrackPrice: BigInt;
  @EnumField(1)
  IdType: TrackPriceType;
  @EnumField(1)
  IdState: TrackPriceState;
  @Int32Field()
  IdFI: number;
  @StringField(38)
  IdDevice: number;
  @DoubleField()
  InitialPrice: number;
  @DoubleField()
  TargetPrice: number;
  @DateTimeField()
  ExpirationDate: number;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
  @DateTimeField()
  LastUpdate: number;
  @Int64Field()
  TrackNum: BigInt;
  @StringField(280)
  SymbolSpec: string;
  @StringField(80)
  Caption: string;
  @StringField(256)
  Text: string;
}

@ADMaskedEntity(1451, EntityType.OrderBookWithYieldEntity)
export class OrderBookWithYieldEntity extends Entity {
  @Int32Field()
  IdFI: number;
  @Int32Field()
  IdBoard: number;
  @Int32Field()
  IdGate: number;
  @Int64Field()
  Revision: BigInt;
  @EnumField(1)
  Flags: InitFlagType;
  @DateTimeField()
  BATime: Date;
  @ArrayField(-1, 40, EntityType.OrderBookLevelWithYieldEntity)
  Lines: OrderBookLevelWithYieldEntity[];
}

/**
 * Доступный стакан. При подписке на эту сущность начинает приходить
 * `OrderBookEntity` или `OrderBookWithYieldEntity` в зависимости от того,
 * какой стакан доступен для инструмента.
 * В протоколе сущность с таким именем отсутствует.
 * */
@ADEntity(1452, EntityType.AvailableOrderBookEntity)
export class AvailableOrderBookEntity extends Entity {
  @UnknownField(0)
  private fakeField: unknown;
}

@ADEntity(345, EntityType.NotificationSubscriptionEntity)
export class NotificationSubscriptionEntity extends Entity {
  @Int32Field()
  IdRequest: number;
  @StringField(38)
  IdDevice: string;
  @StringField(4096)
  Token: string;
  @EnumField(1)
  Action: NotificationActionFlags;
  @StringField(100)
  DeviceAddressMFM: string;
}

@ADEntity(348, EntityType.NotificationSubscriptionStateEntity)
export class NotificationSubscriptionStateEntity extends Entity {
  @Int32Field()
  IdSubscription: number;
  @EnumField(4)
  IdNotification: NotificationType;
  @StringField(38)
  IdDevice: string;
  @EnumField(1)
  IdState: NotificationSubscriptionState;
  @Int64Field()
  Version: BigInt;
  @EnumField(1)
  Operation: Operation;
}
