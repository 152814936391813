import { format, startOfDay, startOfYear, subMonths, subYears } from 'date-fns';

import { getStringDate } from '@terminal/core/lib/format';
import { getServerTime } from '@terminal/core/lib/services/time';
import {
  DateFilterType,
  DateFilterValue,
} from '@terminal/core/types/operation';

import { START_ANALYTICS_DATE } from './const';

export const calculateFilterDates = (
  type: DateFilterType,
  dateFrom?: number | null,
  dateTo?: number | null,
  from?: string,
  to?: string
): DateFilterValue | null => {
  // Всегда берем Московское время, чтобы сервисы корректно возвращали данные
  const TODAY = startOfDay(getServerTime());

  if (type === DateFilterType.NONE) {
    return {
      type,
      valueFrom: {
        value: format(START_ANALYTICS_DATE, 'dd.MM.yyyy'),
        date: START_ANALYTICS_DATE.getTime(),
      },
      valueTo: { value: format(TODAY, 'dd.MM.yyyy'), date: TODAY.getTime() },
    };
  }

  if (type === DateFilterType.RANGE) {
    return {
      type,
      valueFrom: {
        value: from ?? format(START_ANALYTICS_DATE, 'dd.MM.yyyy'),
        date: dateFrom ?? START_ANALYTICS_DATE.getTime(),
      },
      valueTo: {
        value: to ?? format(TODAY, 'dd.MM.yyyy'),
        date: dateTo ?? TODAY.getTime(),
      },
    };
  }

  let fromDate = TODAY;

  switch (type) {
    case DateFilterType['30DAYS']:
      fromDate = startOfDay(subMonths(TODAY, 1));
      break;

    case DateFilterType['3MONTHS']:
      fromDate = startOfDay(subMonths(TODAY, 3));
      break;

    case DateFilterType.YEAR:
      fromDate = startOfDay(subYears(TODAY, 1));
      break;

    case DateFilterType.THIS_YEAR:
      fromDate = startOfYear(TODAY);
      break;
  }

  return {
    type,
    valueFrom: {
      value: getStringDate(fromDate),
      date: fromDate.getTime(),
    },
    valueTo: {
      value: getStringDate(TODAY),
      date: TODAY.getTime(),
    },
  };
};
