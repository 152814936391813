import { useCallback, useEffect, useMemo } from 'react';
import { Button } from '@alfalab/core-components/button/modern';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { dateToRequestFormat } from '@terminal/core/lib/rest/lkApi';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import {
  DocumentDateType,
  DocumentDepoAccount,
  DocumentReceivingType,
  DocumentTheme,
  OrderDocumentsParams,
  ReportTheme,
  Treaty,
  useDocumentsOrder,
} from '@terminal/lk-core';
import {
  ActiveFormType,
  HardcopyFields,
  isCountValid,
  isPeriodValid,
  obtainRename,
  OrderPeriod,
  OrderYear,
  ReceiveByMail,
  ReceiveEmail,
  ReceiveInOffice,
  sortObtain,
  useDocumentOrderForm,
} from '@terminal/lk-desktop-common';

interface DocumentOrderFormProps {
  treaty: Treaty | null;
  theme: string;
  documentsTheme?: DocumentTheme[] | ReportTheme[];
  documentsThemePending: boolean;
  depoAccounts?: DocumentDepoAccount[];
  address?: string;
  email?: string;
  emailConfirmed: boolean;
  onFormApplied?: (state: boolean) => void;
  formType?: ActiveFormType;
  getObtainHint?: (
    type: DocumentReceivingType,
    formType?: ActiveFormType
  ) => string | undefined;
}

export const DocumentOrderForm = ({
  treaty,
  theme,
  documentsTheme,
  documentsThemePending,
  depoAccounts,
  address,
  email,
  emailConfirmed,
  onFormApplied,
  formType,
  getObtainHint,
}: DocumentOrderFormProps) => {
  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    year,
    setYear,
    selectedDepoAccount,
    setSelectedDepoAccount,
    obtaining,
    setObtaining,
    city,
    setCity,
    office,
    setOffice,
    count,
    setCount,
    comment,
    setComment,
    setDepoTouched,
    selectedDocumentTheme,
    depoaccOptions,
  } = useDocumentOrderForm(theme, documentsTheme, depoAccounts);

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const { isLoading: documentOrderPending, mutateAsync: documentOrder } =
    useDocumentsOrder();

  const obtainingOptions = useMemo(() => {
    if (selectedDocumentTheme) {
      return selectedDocumentTheme.allowedReceivingTypes
        .sort(sortObtain)
        .map(obtainRename)
        .map((ob) => ({
          key: ob.type as string,
          content: ob.description,
        }));
    }

    return [];
  }, [selectedDocumentTheme]);

  useEffect(() => {
    if (obtainingOptions.length) {
      const filtred = obtainingOptions.filter(
        (obt) => obt.key && obt.key === obtaining
      );
      const obtainingList = filtred.length > 0 ? filtred : obtainingOptions;

      setObtaining(obtainingList[0].key as DocumentReceivingType);
    }
  }, [obtainingOptions, obtaining, setObtaining]);

  const handleDepoAccountChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    setSelectedDepoAccount(selected?.key || '');
  };

  const handleObtainingChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    setObtaining((selected?.key || '') as DocumentReceivingType);
  };

  const handleChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const isReadyToOrder = useMemo(() => {
    const depoAccPass: boolean = selectedDocumentTheme
      ? !selectedDocumentTheme.needDepoAccount ||
        (selectedDocumentTheme.needDepoAccount && Boolean(selectedDepoAccount))
      : false;
    const dateValid = selectedDocumentTheme
      ? isPeriodValid(
          selectedDocumentTheme.dateType,
          periodStart,
          periodEnd,
          year,
          selectedDocumentTheme.startDate,
          selectedDocumentTheme.endDate
        )
      : false;

    switch (obtaining) {
      case DocumentReceivingType.Electronic: {
        return Boolean(selectedDocumentTheme) && dateValid && depoAccPass;
      }
      case DocumentReceivingType.InBank: {
        return (
          Boolean(selectedDocumentTheme) &&
          dateValid &&
          Boolean(city) &&
          Boolean(office) &&
          isCountValid(count) &&
          depoAccPass
        );
      }
      case DocumentReceivingType.ByMail: {
        return (
          Boolean(selectedDocumentTheme) &&
          dateValid &&
          Boolean(address) &&
          isCountValid(count) &&
          depoAccPass
        );
      }
      case DocumentReceivingType.InLK: {
        return Boolean(selectedDocumentTheme) && dateValid;
      }
    }

    return false;
  }, [
    selectedDocumentTheme,
    selectedDepoAccount,
    periodStart,
    periodEnd,
    year,
    city,
    office,
    count,
    address,
    obtaining,
  ]);

  const orderDocument = () => {
    if (treaty && selectedDocumentTheme) {
      let data: OrderDocumentsParams = {
        treaty: treaty.treaty,
        dateType: selectedDocumentTheme.dateType,
        themeId: parseInt(theme),
        receivingType: obtaining,
      };

      if (selectedDocumentTheme.dateType === DocumentDateType.Year) {
        data = {
          ...data,
          year: year,
        };
      } else if (selectedDocumentTheme.dateType === DocumentDateType.Date) {
        data = {
          ...data,
          startDate: dateToRequestFormat(periodStart),
        };
      } else if (selectedDocumentTheme.dateType === DocumentDateType.Period) {
        data = {
          ...data,
          startDate: dateToRequestFormat(periodStart),
          endDate: dateToRequestFormat(periodEnd),
        };
      }

      const depoAccPass: boolean =
        Boolean(selectedDocumentTheme) && selectedDocumentTheme.needDepoAccount;
      const selectedDepoAccountObj = depoAccounts?.find(
        (doc) => doc.depoAccount === selectedDepoAccount
      );

      if (depoAccPass && selectedDepoAccountObj) {
        data = {
          ...data,
          depoAccount: selectedDepoAccountObj.depoAccount,
          placeName: selectedDepoAccountObj.placeName,
        };
      }

      if (obtaining === DocumentReceivingType.ByMail) {
        data = {
          ...data,
          count: parseInt(count),
          address: address,
          extraInfo: comment,
        };
      } else if (obtaining === DocumentReceivingType.InBank) {
        data = {
          ...data,
          count: parseInt(count),
          officeCity: city,
          office: office,
          extraInfo: comment,
        };
      } else if (obtaining === DocumentReceivingType.Electronic) {
        data = {
          ...data,
          email: email,
        };
      }

      documentOrder({
        params: data,
        signOptions: {
          successTitle: !onFormApplied ? 'Документ заказан' : undefined,
          successCallback: () => {
            onFormApplied && onFormApplied(true);
          },
        },
      });
    }
  };

  return (
    <div>
      <Space direction="vertical" fullWidth>
        {selectedDocumentTheme?.dateType === DocumentDateType.Year && (
          <OrderYear
            documentTheme={selectedDocumentTheme}
            year={year}
            onYearChange={(year) => setYear(year)}
          />
        )}
        {(selectedDocumentTheme?.dateType === DocumentDateType.Date ||
          selectedDocumentTheme?.dateType === DocumentDateType.Period) && (
          <OrderPeriod
            documentTheme={selectedDocumentTheme}
            periodStart={periodStart}
            periodEnd={periodEnd}
            onPeriodStartChange={(date) => setPeriodStart(date)}
            onPeriodEndChange={(date) => setPeriodEnd(date)}
          />
        )}

        {selectedDocumentTheme && selectedDocumentTheme.needDepoAccount && (
          <Row>
            <Col width={6}>
              <SelectDesktop
                label="Депо счет"
                labelView="outer"
                placeholder="Выберите депо счет"
                size="s"
                block
                options={depoaccOptions}
                selected={selectedDepoAccount}
                onChange={handleDepoAccountChange}
                onBlur={() => setDepoTouched(true)}
                Field={(props: FieldProps) => (
                  <Field
                    {...props}
                    leftAddons={documentsThemePending && <Loader />}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col width={12}>
            <SelectDesktop
              label="Способ получения"
              labelView="outer"
              placeholder="Выберите из списка"
              size="s"
              block
              options={obtainingOptions}
              selected={obtaining}
              onChange={handleObtainingChange}
              hint={getObtainHint?.(obtaining, formType)}
              error={
                !emailConfirmed &&
                obtaining === DocumentReceivingType.Electronic
                  ? 'У вас нет подтверждённого адреса электронной почты. Укажите его в Настройках профиля'
                  : ''
              }
            />
          </Col>
        </Row>

        {obtaining === DocumentReceivingType.InBank && (
          <ReceiveInOffice
            city={city}
            onCityChange={(city) => setCity(city)}
            office={office}
            onOfficeChange={(office) => setOffice(office)}
          />
        )}
        {obtaining === DocumentReceivingType.ByMail && (
          <ReceiveByMail
            address={address}
            hint="Для изменения почтового адреса, пожалуйста, обратитесь в службу поддержки"
          />
        )}
        {obtaining === DocumentReceivingType.Electronic && (
          <ReceiveEmail
            email={email}
            emailConfirmed={emailConfirmed}
            onEmailChange={handleChangeEmail}
          />
        )}

        {(obtaining === DocumentReceivingType.InBank ||
          obtaining === DocumentReceivingType.ByMail) && (
          <HardcopyFields
            count={count}
            onCountChange={(count) => setCount(count)}
            comment={comment}
            onCommentChange={(comment) => setComment(comment)}
            hideCount={
              formType === 'depoReport' &&
              obtaining === DocumentReceivingType.ByMail
            }
            hideComment={
              formType === 'depoReport' &&
              obtaining === DocumentReceivingType.ByMail
            }
          />
        )}
      </Space>

      <TradingCertificateLock>
        <Gap size="l" />
        <Row>
          <Col width={6}>
            {!emailConfirmed &&
            obtaining === DocumentReceivingType.Electronic ? (
              <Button
                size="s"
                block
                leftAddons={
                  <MailMIcon
                    width={18}
                    height={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
                onClick={handleChangeEmail}
              >
                Указать email
              </Button>
            ) : (
              <Button
                size="s"
                block
                view="accent"
                leftAddons={<CheckmarkMIcon width={18} height={18} />}
                loading={documentOrderPending}
                disabled={!isReadyToOrder}
                onClick={orderDocument}
              >
                Заказать
              </Button>
            )}
          </Col>
        </Row>
      </TradingCertificateLock>
    </div>
  );
};
