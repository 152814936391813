import { defaultWatchList } from '../../../constants/WatchList';
import { WatchListsTypeLast } from '../../../lib/db/watchLists';
import log from '../../../lib/loglevel-mobile-debug';

import { WatchList } from '../../../types/watchList';

const keyLS = 'watchLists';

export const getWatchListsFromLS = () => {
  const stringConfig = localStorage.getItem(keyLS);

  if (stringConfig) {
    try {
      const parsedLS = JSON.parse(stringConfig) as WatchList[];

      return parsedLS;
    } catch (e) {
      log.error('WatchLists is not valid object string');
    }
  }

  return [defaultWatchList];
};

export const saveWatchListsToLS = (watchListsTypeLast: WatchListsTypeLast) => {
  window?.requestAnimationFrame(() => {
    const watchLists = watchListsTypeLast.lists;

    localStorage.setItem(keyLS, JSON.stringify(watchLists));
  });
};
