import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Прибыль/убыток по позиции с момента ее открытия в процентах
 * */
export function getNPLPercent(
  TorgPosUchCost: number | null = null,
  NPLtoMarketCurPrice: number | null = null,
  idObject?: number
): number | null {
  if (TorgPosUchCost === 0 || idObject === ECurrencyId.RUR) {
    return 0;
  }

  if (TorgPosUchCost === null || NPLtoMarketCurPrice === null) {
    return null;
  }

  return (100 * NPLtoMarketCurPrice) / Math.abs(TorgPosUchCost);
}
