import { useMemo } from 'react';

import { PortfolioAnalyticsResult } from '@terminal/core/types/account';

import { PAYMENTS_EXPENSES } from '../lib/const';
import { mapReportChapterKeyToName } from '../lib/mapReportChapterKeyToName';
import { PaymentsAndExpensesTableRow } from '../model/FinResultTable';

export const usePaymentsAndExpensesTable = (
  analytics?: PortfolioAnalyticsResult
): PaymentsAndExpensesTableRow[] => {
  return useMemo(() => {
    if (!analytics) {
      return [];
    }

    return PAYMENTS_EXPENSES.reduce<PaymentsAndExpensesTableRow[]>(
      (acc, chapter) => {
        if (analytics[chapter.key]) {
          acc.push({
            key: chapter.key,
            Icon: chapter.Icon,
            name: mapReportChapterKeyToName(chapter.key),
            income: analytics[chapter.key]?.value || 0,
            currency: analytics[chapter.key]?.currency || 'RUR',
            hasHistory: chapter.hasHistory ?? true,
          });
        }

        return acc;
      },
      []
    )
      .sort((a, b) => b.income - a.income)
      .filter((r) => r.income !== 0);
  }, [analytics]);
};
