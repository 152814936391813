import React from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightSquareMIcon } from '@alfalab/icons-glyph/ArrowRightSquareMIcon';
import { CloudMIcon } from '@alfalab/icons-glyph/CloudMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { MenuGroup } from '@terminal/common/components/Menu';

import { PickerButton } from '../../../../shared/ui/PickerButton';

import styles from './WorkspaceConfigurationCustomListItem.module.css';

export interface WorkspaceConfigurationCustomListItemProps {
  id: string;
  key: string;
  name: string;
  savedDate: string;
  updatedDate: string;
  onAcceptConfigClick: () => void;
  dropdownGroups: MenuGroup[];
  isCloud?: boolean;
  isAccepting?: boolean;
}

export const WorkspaceConfigurationCustomListItem = (
  props: WorkspaceConfigurationCustomListItemProps
) => {
  const {
    name,
    updatedDate,
    savedDate,
    onAcceptConfigClick,
    dropdownGroups,
    isCloud,
    isAccepting,
  } = props;

  const isChanged = updatedDate !== savedDate;

  return (
    <div className={styles.otherConfigurationsRow}>
      <div>
        <Typography.Text
          view="secondary-large"
          color="primary"
          tag="p"
          defaultMargins={false}
          className={styles.flexCentered}
        >
          {name}
          {isCloud && (
            <CloudMIcon
              width={16}
              height={16}
              color="var(--color-light-text-secondary)"
            />
          )}
        </Typography.Text>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          tag="p"
          defaultMargins={false}
        >
          Сохранено {savedDate}
          {isChanged && <>, изменено {updatedDate}</>}
        </Typography.Text>
      </div>
      <div className={styles.otherConfigurationsButtons}>
        <TooltipDesktop
          position="top"
          trigger="hover"
          content="Применить"
          fallbackPlacements={['left', 'right']}
        >
          <IconButton
            size="xs"
            view="secondary"
            icon={ArrowRightSquareMIcon}
            onClick={onAcceptConfigClick}
            loading={isAccepting}
          />
        </TooltipDesktop>

        <DropdownMenu groups={dropdownGroups}>
          {(ref) => (
            <PickerButton
              ref={ref}
              size="xs"
              view="link"
              className={styles.moreButton}
            >
              <IconButton
                size="xs"
                view="secondary"
                icon={DotsHorizontalMIcon}
              />
            </PickerButton>
          )}
        </DropdownMenu>
      </div>
    </div>
  );
};
