import qs from 'query-string';

import { callLkApi } from '@terminal/core/lib/rest/lkApi';

import { CloudConfigUpdateParams } from '../model/types/cloudConfigUpdate';

export async function updateCloudConfig(params: CloudConfigUpdateParams) {
  const { config: newConfig, id, name } = params;

  return callLkApi(
    qs.stringifyUrl({
      url: `/api/terminal-settings/webterminal/${id}/update`,
      query: { configName: name },
    }),
    'PUT',
    newConfig
  );
}
