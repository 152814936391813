import { Spinner as SpinnerCore } from '@alfalab/core-components/spinner';

import styles from './Spinner.module.css';

export const Spinner = () => {
  return (
    <div className={styles.container}>
      <SpinnerCore visible size="m" />
    </div>
  );
};
