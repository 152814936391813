import { TableColumnKey } from '../types/tableColumn';

export const mapColumnToName: Record<TableColumnKey, string> = {
  // Common
  [TableColumnKey.NameObject]: 'Наименование',
  [TableColumnKey.SymbolObject]: 'Тикер',
  [TableColumnKey.ShortDescription]: 'Описание',
  [TableColumnKey.IsSelected]: 'Действия',
  [TableColumnKey.ReleaseNameObject]: 'Выпуск',
  [TableColumnKey.Yield]: 'Доходн. к погашению',
  [TableColumnKey.CodeSubAccount]: 'Субсчёт',
  [TableColumnKey.MarketNameMarketBoard]: 'Рынок',
  [TableColumnKey.OrderLogin]: 'Инициатор',
  [TableColumnKey.OrderClientNumEDocument]: 'ВхНомер',
  [TableColumnKey.NameBalanceGroup]: 'Рынок',
  [TableColumnKey.MatDate]: 'Погашение',

  // Watchlist
  [TableColumnKey.CodeMarketBoard]: 'Рынок',
  [TableColumnKey.ISIN]: 'ISIN',
  [TableColumnKey.Open]: 'Откр',
  [TableColumnKey.Close]: 'Закр.',
  [TableColumnKey.LastPrice]: 'Цена',
  [TableColumnKey.Change]: 'Изм., 1д',
  [TableColumnKey.VolToday]: 'Объем',
  [TableColumnKey.ValToday]: 'Оборот',
  [TableColumnKey.NameObjectType]: 'Тип',
  [TableColumnKey.PriceStep]: 'Шаг цены',
  [TableColumnKey.PriceStepCost]: 'Стоимость шага',
  [TableColumnKey.Lot]: 'Лот',
  [TableColumnKey.GtBuy]: 'ГО',
  [TableColumnKey.PSTNKD]: 'НКД',
  [TableColumnKey.NextCoupon]: 'Дата купона',
  [TableColumnKey.CouponRate]: 'Ставка купона',
  [TableColumnKey.CouponValue]: 'Размер купона',
  [TableColumnKey.BuyBackDate]: 'Дата оферты',
  [TableColumnKey.DaysToBuyBack]: 'Срок до оферты',
  [TableColumnKey.DaysToMat]: 'Дней до погашения',
  [TableColumnKey.IdFi]: 'Код',

  // FiModal
  [TableColumnKey.DescObject]: 'Описание',
  [TableColumnKey.Currency]: 'Валюта',
  [TableColumnKey.NameMarketBoard]: 'Ликвидный рынок',
  [TableColumnKey.AvailableMarketBoards]: 'Доступные рынки',

  // Balance
  [TableColumnKey.IdAccount]: 'Счёт',
  [TableColumnKey.Balance]: 'Баланс',
  [TableColumnKey.PrevBalance]: 'Баланс нач. дня',
  [TableColumnKey.DailyPLRur]: 'Приб/Уб, 1д',
  [TableColumnKey.DailyPLRurPrecent]: 'Приб/Уб, 1д, %',
  [TableColumnKey.NplRur]: 'Приб/Уб',
  [TableColumnKey.NplRurPercent]: 'Приб/Уб, %',
  [TableColumnKey.LongBalance]: 'Лонг',
  [TableColumnKey.ShortBalance]: 'Шорт',
  [TableColumnKey.InitialMargin]: 'Нач. маржа',
  [TableColumnKey.MinimumMargin]: 'Мин. маржа',
  [TableColumnKey.Requirements]: 'Требования',
  [TableColumnKey.ImmediateRequirements]: 'Срочн. требования',
  [TableColumnKey.LiquedBalance]: 'Ликв. стоимость',
  [TableColumnKey.PortfolioValueWithOrders]: 'Скор. стоимость',
  [TableColumnKey.InitialMarginWithOrders]: 'Скор. маржа',
  [TableColumnKey.Money]: 'Рубли',
  [TableColumnKey.InitialMoney]: 'Рубли, нач. дня',
  [TableColumnKey.NameClient]: 'Владелец счёта',

  // AlertsModal
  [TableColumnKey.TargetPrice]: 'Цена',
  [TableColumnKey.Caption]: 'Сообщение',
  [TableColumnKey.IdState]: 'Статус',

  // Orders
  [TableColumnKey.Direction]: 'Направление',
  [TableColumnKey.AcceptTime]: 'Время подачи',
  [TableColumnKey.AcceptDate]: 'Дата подачи',
  [TableColumnKey.Quantity]: 'Кол-во',
  [TableColumnKey.Executed]: 'Исполнено',
  [TableColumnKey.Rest]: 'Неисп. остаток',
  [TableColumnKey.DisplayPrice]: 'Цена',
  [TableColumnKey.Type]: 'Тип',
  [TableColumnKey.DisplayStatus]: 'Статус',
  [TableColumnKey.WithdrawTime]: 'Исполнить не позд.',
  [TableColumnKey.LifeTime]: 'Срок исп.',
  [TableColumnKey.OrderNumEDocument]: '№ заявки',
  [TableColumnKey.StopPrice]: 'Цена стоп',
  [TableColumnKey.LimitPrice]: 'Цена лимит',

  // Operations
  [TableColumnKey.OperationTime]: 'Время',
  [TableColumnKey.OperationDate]: 'Дата',
  [TableColumnKey.OperationBuySell]: 'Направление',
  [TableColumnKey.OperationQuantity]: 'Кол-во',
  [TableColumnKey.OperationValue]: 'Стоимость',
  [TableColumnKey.OperationCurrency]: 'Валюта',
  [TableColumnKey.OperationStatus]: 'Статус',
  [TableColumnKey.OperationAccount]: 'Счёт',
  [TableColumnKey.OperationNumEDocument]: '№ заявки',
  [TableColumnKey.OrderComment]: 'Комментарий',
  [TableColumnKey.OperationPrice]: 'Цена',
  [TableColumnKey.OperationYield]: 'Доходн. к погашению',
  [TableColumnKey.OperationAccruedInt]: 'НКД',
  [TableColumnKey.OperationSettleDate]: 'Дата расч.',
  [TableColumnKey.OperationDeliveryDate]: 'Дата поставки',
  [TableColumnKey.OperationTypeId]: 'Тип операции',

  // OperationHistory
  [TableColumnKey.OperationIsSettled]: 'Урегулирована',

  // Positions
  [TableColumnKey.PositionNameType]: 'Раздел',
  [TableColumnKey.PositionTorgPos]: 'Позиция',
  [TableColumnKey.PositionConvertedPrice]: 'Средняя',
  [TableColumnKey.PositionLiquidCost]: 'Ликв. стоимость',
  [TableColumnKey.PositionPrice]: 'Цена',
  [TableColumnKey.PositionNplPrice]: 'Приб/Уб',
  [TableColumnKey.PositionNplPercent]: 'Приб/Уб, %',
  [TableColumnKey.PositionDailyPrice]: 'Приб/Уб, 1д',
  [TableColumnKey.PositionDailyPercent]: 'Приб/Уб, 1д, %',
  [TableColumnKey.PositionAssetsPart]: 'Доля',
  [TableColumnKey.PositionInPrice]: 'Цена, нач. дня',
  [TableColumnKey.PositionCurSymbol]: 'Вал. расчетов',
  [TableColumnKey.PositionPriceSymbol]: 'Вал. цены',
  [TableColumnKey.PositionVariationMargin]: 'Вар. маржа',
  [TableColumnKey.PositionDeposits]: 'Вводы',
  [TableColumnKey.PositionBackPos]: 'Позиция, нач. дня',
  [TableColumnKey.PositionBackPosCost]: 'Стоим., нач. дня',
  [TableColumnKey.PositionWithdrawal]: 'Выводы',
  [TableColumnKey.PositionIsMoney]: 'Деньги',
  [TableColumnKey.PositionSessionBuyQty]: 'Куплено, 1д',
  [TableColumnKey.PositionSessionBuyVal]: 'Куплено, 1д, (руб)',
  [TableColumnKey.PositionPSTNKD]: 'НКД, %',
  [TableColumnKey.PositionNKD]: 'НКД',
  [TableColumnKey.PositionLongOrders]: 'На пок. (лот)',
  [TableColumnKey.PositionPlanLong]: 'На пок. (шт)',
  [TableColumnKey.PositionShortOrders]: 'На прод. (лот)',
  [TableColumnKey.PositionPlanShort]: 'На прод. (шт)',
  [TableColumnKey.PositionSessionVal]: 'Оборот, 1д (руб)',
  [TableColumnKey.PositionSessionQty]: 'Оборот, 1д (шт)',
  [TableColumnKey.PositionSessionSellQty]: 'Продано, 1д',
  [TableColumnKey.PositionSessionSellVal]: 'Продано, 1д (руб)',
  [TableColumnKey.PositionTorgPosCost]: 'Стоимость',
  [TableColumnKey.PositionSubAccNalPos]: 'ТекПоз',
  [TableColumnKey.PositionTorgPosUchCost]: 'УчСтоимость',
  [TableColumnKey.PositionGO]: 'ГО',

  // TradesFeed
  [TableColumnKey.TradePrice]: 'Цена',
  [TableColumnKey.TradeQty]: 'Кол-во',
  [TableColumnKey.TradeBuySell]: 'К/П',
  [TableColumnKey.TradeTime]: 'Время',
};
