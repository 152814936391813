import { useMutation, useQueryClient } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { uploadConfigToCloud } from '../api/uploadConfigToCloud';

export function useUploadCloudConfigMutation() {
  const client = useQueryClient();
  const addNotification = useNotification();

  return useMutation({
    mutationFn: uploadConfigToCloud,
    onSuccess: () => client.invalidateQueries([LkQueryKeys.CloudConfigList]),
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При загрузке конфигурации произошла ошибка',
      });
    },
  });
}
