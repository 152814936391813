import { useCallback } from 'react';

import {
  ObjectGroup,
  RazdelGroupType,
} from '@terminal/core/lib/client/entities';
import { PositionItem } from '@terminal/core/types/position';

import { ASSETS_COLORS_MAPPED } from '../lib/const';

import { useSelectedDisplayInstrumentType } from '../../../shared/hooks/useSelectedDisplayInstrumentType';

import { Asset } from '../model/types/PortfolioComposition';

export const useFilteredAssets = (
  positions: PositionItem[],
  assetId?: string
) => {
  const isNameDisplayType = useSelectedDisplayInstrumentType() === 'name';

  return useCallback(
    (baseAssetId?: string) => {
      const items: Asset[] = [];
      const isFuture = assetId === ObjectGroup.Futures.toString();

      positions.forEach((position, index) => {
        if (
          position.objectType.idObjectGroup === ObjectGroup.Currency &&
          position.idBalanceGroup === RazdelGroupType.DerivativesMarket
        ) {
          return;
        }

        const filterStocks =
          assetId === ObjectGroup.Stocks.toString()
            ? assetId === position.objectType.idObjectGroup.toString() ||
              ObjectGroup.Receipts === position.objectType.idObjectGroup
            : assetId === position.objectType.idObjectGroup.toString();

        const filterAssets = isFuture
          ? position.object?.idObjectBase.toString() === baseAssetId
          : filterStocks;

        const isCurrency = assetId === ObjectGroup.Currency.toString();

        if (isCurrency && position.torgPosCostRur! < 0) {
          return;
        }

        if (filterAssets) {
          const name =
            position.object?.[
              isNameDisplayType ? 'nameObject' : 'symbolObject'
            ];

          let price: number | null = null;

          if (isFuture) {
            price = position.NPLtoMarketCurPrice;
          } else if (position.torgPosCostRur !== null) {
            price =
              position.objectType.idObjectGroup === ObjectGroup.Bonds
                ? position.torgPosCostRur + position.NKDRur
                : position.torgPosCostRur;
          }

          items.push({
            id: position.object?.idObject ?? index,
            assetId: position.object?.idObject?.toString() || '',
            idObject: position.object?.idObject,
            symbolObject: position.object?.symbolObject,
            name: name ?? '',
            price: price || 0,
            quantity: position.backPos,
            lot: position.finInfoExt?.lot || 1,
            percent: 0,
            color: ASSETS_COLORS_MAPPED.get(index),
          });
        }
      });

      return items;
    },
    [assetId, isNameDisplayType, positions]
  );
};
