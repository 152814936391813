import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getCommonInstrumentsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeChartConfig,
  getTradeHybridOrderConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHART_RIGHT_CHARTIQ_SETTINGS,
  getChartWatchlistConfig,
  TRADE_CHART_SETTINGS,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const swingMedium = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const instrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
    );
    const instrumentTabset = new TabSet(50);

    instrumentTabset.addChildren([instrumentsTab]);
    const ordersTab = new Tab(Widget.ORDERS, getCommonOrdersConfig());
    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const positionsTabset = new TabSet(50);

    positionsTabset.addChildren([positionsTab, ordersTab]);

    const leftRow = new Row(30);

    leftRow.addChildren([instrumentTabset, positionsTabset]);

    // средняя колонка

    const orderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const tradeTab = new Tab(
      Widget.HYBRIDORDER,
      getTradeHybridOrderConfig(blueLink.name)
    );
    const orderbookTabset = new TabSet(50);

    orderbookTabset.addChildren([orderbookTab, tradeTab]);
    const middleRow = new Row(20);

    middleRow.addChildren([orderbookTabset]);

    // правая колонка
    const chartiqTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(blueLink.name),
      TRADE_CHART_SETTINGS
    );
    const chartiqTabSet = new TabSet(50);

    chartiqTabSet.addChildren([chartiqTab]);

    const layoutRow = new Row(3);

    layoutRow.addChildren([leftRow, middleRow, chartiqTabSet]);

    return new Layout('Торговля', layoutRow, [blueLink]);
  };

  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const greenLink = new Link({
      name: LinkName.GREEN,
      idFi: 144950,
      symbol: 'SBER',
    });

    const instrumentsChartTab = new Tab(
      Widget.WATCHLIST,
      getChartWatchlistConfig(greenLink.name)
    );
    const instrumentChartTabset = new TabSet(20);

    instrumentChartTabset.addChildren([instrumentsChartTab]);

    const leftChartTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(greenLink.name),
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftChartTabset = new TabSet(40);

    leftChartTabset.addChildren([leftChartTab]);

    const rightChartTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(greenLink.name),
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(40);

    rightChartTabset.addChildren([rightChartTab]);

    const chartRow = new Row(3);

    chartRow.addChildren([
      instrumentChartTabset,
      leftChartTabset,
      rightChartTabset,
    ]);

    return new Layout('Графики', chartRow, [greenLink]);
  };

  const configuration = new DefaultConfiguration('Среднесрочная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
