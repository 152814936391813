import cn from 'classnames';
import React, { FC, HTMLAttributes, MouseEventHandler } from 'react';
import {
  IconButton,
  IconButtonProps,
} from '@alfalab/core-components/icon-button';
import { TextProps, Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './ModalHeader.module.css';

export type ModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Обработчик нажатия на кнопку «Закрыть»
   * */
  onClose?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Пропсы, которые будут переданы в компонент заголовка
   * @default { view: 'secondary-large', weight: 'bold' }
   * */
  titleProps?: Partial<TextProps>;
  iconProps?: Partial<IconButtonProps>;
};

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { children, onClose, className, titleProps, iconProps, ...restProps } =
    props;
  const rootClassName = cn(className, styles.root);

  return (
    <header className={rootClassName} {...restProps}>
      {typeof children === 'string' ? (
        <Typography.Text view="secondary-large" weight="bold" {...titleProps}>
          {children}
        </Typography.Text>
      ) : (
        children
      )}
      <IconButton
        size="xs"
        view="secondary"
        icon={CrossMIcon}
        onClick={onClose}
        {...iconProps}
      />
    </header>
  );
};
