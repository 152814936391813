import { ActGroupType } from '../model/FinResult';

export const mapActGroupToIncomeTitle = (
  actGroup: ActGroupType,
  single?: boolean
): string => {
  const basePart = 'Доход по ';

  switch (actGroup) {
    case ActGroupType.STOCKS: {
      return basePart + (single ? 'акции' : 'акциям');
    }

    case ActGroupType.BONDS: {
      return basePart + (single ? 'облигации' : 'облигациям');
    }

    case ActGroupType.CURRENCY: {
      return basePart + (single ? 'валюте' : 'валютам');
    }

    case ActGroupType.FUNDS: {
      return basePart + (single ? 'фонду' : 'фондам');
    }

    case ActGroupType.FUTURES: {
      return basePart + (single ? 'фьючерсу' : 'фьючерсам');
    }

    case ActGroupType.METALLS: {
      return basePart + (single ? 'металлу' : 'металлам');
    }
  }
};
