import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';

import { PortfolioAnalyticsResult } from '@terminal/core/types/account';

import { ASSETS } from '../lib/const';
import { mapActGroupToBanchmarkName } from '../lib/mapActGroupToBenchmarkName';
import { IncomeByAssetsTableRow } from '../model/FinResultTable';

export const useIncomeByAssetsTable = (
  analytics?: PortfolioAnalyticsResult
): IncomeByAssetsTableRow[] => {
  return useMemo(() => {
    if (!analytics) {
      return [];
    }

    return ASSETS.reduce<IncomeByAssetsTableRow[]>((acc, asset) => {
      if (asset.groups.some((group) => group in analytics)) {
        acc.push({
          key: asset.key,
          actGroup: asset.actGroup,
          name: mapActGroupToBanchmarkName(asset.actGroup),
          income: sumBy(asset.groups, (g) => analytics[g]?.value),
          progressBarColor: asset.color,
        });
      }

      return acc;
    }, [])
      .sort((a, b) => b.income - a.income)
      .filter((r) => r.income !== 0);
  }, [analytics]);
};
