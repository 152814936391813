import React from 'react';
import {
  TagDesktop,
  TagDesktopProps,
} from '@alfalab/core-components/tag/desktop';

import styles from './ButtonMenu.module.css';

export interface ButtonMenuItemProps {
  id?: string;
  type: string;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface ButtonMenuProps {
  active: string[];
  items: ButtonMenuItemProps[];
  size?: TagDesktopProps['size'];
  margin?: 's' | 'm';
}

export const ButtonMenu = ({ active, items }: ButtonMenuProps) => (
  <div className={styles.container}>
    {items.map((item: ButtonMenuItemProps) => (
      <div key={`button-menu-item-${item.type}`} className={styles.item}>
        <TagDesktop
          size="xs"
          view="filled"
          onClick={item.onClick}
          disabled={Boolean(item.disabled)}
          checked={active.includes(item.type)}
        >
          {item.name}
        </TagDesktop>
      </div>
    ))}
  </div>
);
