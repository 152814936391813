import { FC, useRef, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { WalletMIcon } from '@alfalab/icons-glyph/WalletMIcon';

import {
  ColumnSettingModal,
  DoubleColumnSettingModal,
} from '@terminal/common/ui/Table';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import {
  BalanceSettingsProps,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { PositionItem } from '@terminal/core/types/position';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';
import { CommonSettings } from '@terminal/core/types/ui';

import { AccountFilters } from '../../../../features/AccountFilters';
import { PortfolioAnalyticsButton } from '../../../../features/PortfolioAnalyticsButton';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '../../../../features/WidgetServiceNotification';
import { useWidgetContext } from '../../../../shared';
import {
  PortfolioBalance,
  PortfolioBalanceSettings,
} from '../../features/PortfolioBalance';
import { PositionTypeFilters } from '../../features/PositionTypeFilters';

import { useSettings as useTableSettings } from '../../hooks/useSettings';

interface Props {
  nodeId?: string;
  isOpenColumnSettings: boolean;
  setIsOpenColumnSettings: (open: boolean) => void;
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
  columnsSetting: TableColumnSetting[];
  selectedSubAccounts: string[];
  balanceSettingsProps?: BalanceSettingsProps;
  positionTypes: ObjectGroup[];
  positions: PositionItem[];
  togglePortfolioModalOpen: () => void;
  selectedPUMode: PUShowMode;
  defaultPositionColumnSetting: TableColumnSetting[];
  selectedDisplayInstrumentType: CommonSettings['defaultValues']['selectedDisplayInstrumentType'];
}

export const Header: FC<Props> = ({
  nodeId,
  isOpenColumnSettings,
  setIsOpenColumnSettings,
  isDoubleRowMode,
  switchDoubleRowMode,
  columnsSetting,
  selectedSubAccounts,
  balanceSettingsProps,
  positionTypes,
  positions,
  togglePortfolioModalOpen,
  selectedPUMode,
  defaultPositionColumnSetting,
  selectedDisplayInstrumentType,
}) => {
  const [isOpenBalanceSettings, setIsOpenBalanceSettings] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { updateNode, openTopUpModal } = useWidgetContext();

  const widgetSettings = useTableSettings({
    isDoubleRowMode,
    setIsOpenColumnSettings,
    switchDoubleRowMode,
    setIsOpenBalanceSettings,
  });

  const allowShowPortfolioBalance =
    (balanceSettingsProps?.showBalance ?? true) ||
    (balanceSettingsProps?.showPU ?? true) ||
    (balanceSettingsProps?.showGO ?? true);

  return (
    <>
      <WidgetHeader
        inputs={
          <AccountFilters
            selectedSubAccounts={selectedSubAccounts}
            updateNode={updateNode}
            nodeId={nodeId}
          />
        }
        filters={
          <PositionTypeFilters
            nodeId={nodeId}
            updateNode={updateNode}
            selectedTypes={positionTypes}
            selectedPUMode={selectedPUMode}
          />
        }
        widgetSettings={widgetSettings}
        controls={
          <>
            <IconButton
              size="xs"
              view="secondary"
              icon={WalletMIcon}
              onClick={() =>
                openTopUpModal({
                  selectedSubAccounts,
                })
              }
            />
            <PortfolioAnalyticsButton onClick={togglePortfolioModalOpen} />
          </>
        }
      />
      {!isDoubleRowMode && (
        <ColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.POSITIONS}
        />
      )}
      {isDoubleRowMode && (
        <DoubleColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          defaultColumnSetting={defaultPositionColumnSetting}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.POSITIONS}
          selectedDisplayInstrumentType={selectedDisplayInstrumentType}
        />
      )}

      {allowShowPortfolioBalance && (
        <PortfolioBalance
          nodeId={nodeId}
          positions={positions}
          selectedSubAccounts={selectedSubAccounts}
          settings={balanceSettingsProps}
        />
      )}

      <PortfolioBalanceSettings
        nodeId={nodeId}
        balanceSettingsProps={balanceSettingsProps}
        open={isOpenBalanceSettings}
        onClose={() => setIsOpenBalanceSettings(false)}
        container={
          containerRef.current && containerRef.current.clientWidth >= 290
            ? () => containerRef.current as HTMLDivElement
            : undefined
        }
      />
      <WidgetServiceNotification
        code={TECH_MESSAGE_CODE.POSITIONS_BALANCES}
        variant="line"
        canBeShort
      />
    </>
  );
};
