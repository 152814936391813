import cn from 'classnames';
import React, { FC } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { roundPrice } from '@terminal/core/lib/format';
import { PositionItem } from '@terminal/core/types/position';

import styles from './PortfolioPosition.module.css';

interface Props {
  position: Pick<
    PositionItem,
    | 'NPLtoMarketCurPrice'
    | 'torgPos'
    | 'marketBoard'
    | 'NKDRur'
    | 'idObjectGroup'
  >;
}

export const PortfolioPosition: FC<Props> = ({ position }) => {
  return (
    <div className={styles.card}>
      <Typography.Text
        view="secondary-large"
        weight="bold"
        className={styles.tertiary}
      >
        Открытые позиции
      </Typography.Text>

      <Gap size="xs" />

      <Typography.Text view="secondary-small" color="secondary">
        Данные считаются за всё время и не учитываются в расчёте общего дохода
        по активу
      </Typography.Text>

      <Gap size="m" />

      <div className={styles.row}>
        <Typography.Text view="secondary-large" tag="div">
          {roundPrice(position.torgPos, 1)}&nbsp;шт.&nbsp;
          <Typography.Text view="secondary-large" tag="span" color="secondary">
            {position.marketBoard.nameMarketBoard}
          </Typography.Text>
        </Typography.Text>

        <div>
          <Typography.Text
            view="secondary-large"
            tag="div"
            color="secondary"
            className={cn({
              [styles.positive]: position.NPLtoMarketCurPrice! >= 0,
              [styles.negative]: position.NPLtoMarketCurPrice! < 0,
            })}
          >
            {position.NPLtoMarketCurPrice === null ? (
              '-'
            ) : (
              <>
                {position.NPLtoMarketCurPrice >= 0 ? '+' : '-'}
                {
                  formatAmount({
                    value: Math.abs(position.NPLtoMarketCurPrice) * MINORITY,
                    currency: 'RUR' as CurrencyCodes,
                    minority: MINORITY,
                    view: 'withZeroMinorPart',
                  }).formattedWithCurrency
                }
              </>
            )}
          </Typography.Text>

          {position.idObjectGroup === ObjectGroup.Bonds ? (
            <Typography.Text view="secondary-large" tag="div" color="secondary">
              {position.NKDRur}
            </Typography.Text>
          ) : null}
        </div>
      </div>
    </div>
  );
};
