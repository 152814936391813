import {
  addDays,
  endOfYear,
  format,
  parse,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@alfalab/core-components/button/modern';
import { DATE_FORMAT } from '@alfalab/core-components/calendar-input/utils';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { Space } from '@alfalab/core-components/space';
import { Switch, SwitchProps } from '@alfalab/core-components/switch';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { DocumentExcelMIcon } from '@alfalab/icons-glyph/DocumentExcelMIcon';
import { DocumentMIcon } from '@alfalab/icons-glyph/DocumentMIcon';
import { DocumentPdfMIcon } from '@alfalab/icons-glyph/DocumentPdfMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
  DownloadedFile,
} from '@terminal/core/lib/rest/lkApi';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { useDownloadFile } from '@terminal/lk/legacy/hooks';
import { FeatureSetting, isFeatureHidden } from '@terminal/lk/shared';
import {
  DocumentDateType,
  DocumentReceivingType,
  OrderDocumentsParams,
  prepareMultiplyReportsParams,
  ReportFormat,
  ReportTheme,
  ReportType,
  Treaty,
  useDocumentsOrder,
  useMultiplyReportsGenerator,
} from '@terminal/lk-core';

import {
  ActiveFormType,
  generateReportUrl,
  getPeriodFromYear,
  isCountValid,
  isPeriodValid,
  obtainRename,
  sortObtain,
} from '../../../../entities/FinancialDocuments';
import { HardcopyFields } from '../HardcopyFields';
import { OrderPeriod } from '../OrderPeriod';
import { OrderYear } from '../OrderYear';
import { ReceiveByMail } from '../ReceiveByMail';
import { ReceiveEmail } from '../ReceiveEmail';
import { ReceiveInOffice } from '../ReceiveInOffice';

import styles from './ReportsForm.module.css';

interface ReportsFormProps {
  treaty: Treaty | null;
  theme: string;
  reportsTheme?: ReportTheme[];
  address?: string;
  email?: string;
  emailConfirmed: boolean;
  featureSettings?: FeatureSetting[];
  getObtainHint?: (
    type: DocumentReceivingType,
    formType?: ActiveFormType
  ) => string | undefined;
}

export const ReportsForm = ({
  treaty,
  theme,
  reportsTheme,
  address,
  email,
  emailConfirmed,
  featureSettings,
  getObtainHint,
}: ReportsFormProps) => {
  let startDate = format(
    startOfMonth(addDays(new Date(), -1)),
    DEFAULT_VIEW_DATE_FORMAT
  );
  let endDate = format(addDays(new Date(), -1), DEFAULT_VIEW_DATE_FORMAT);

  const [periodStart, setPeriodStart] = useState<string>(startDate);
  const [periodEnd, setPeriodEnd] = useState<string>(endDate);
  const [year, setYear] = useState<string>('');
  const [obtaining, setObtaining] = useState<DocumentReceivingType>(
    DocumentReceivingType.Online
  );
  const [city, setCity] = useState<string>('');
  const [office, setOffice] = useState<string>('');
  const [count, setCount] = useState<string>('1');
  const [comment, setComment] = useState<string>('');
  const [multiply, setMultiply] = useState(false);

  const saveOptionsRef = useRef<HTMLDivElement>(null);
  const saveOptionsPopupRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [saveOpen, setSaveOpen] = useState(false);
  const [reportFile, setReportFile] = useState<DownloadedFile | null>();

  useOnClickOutside(saveOptionsPopupRef, (e) => {
    if (
      e.target !== buttonRef.current &&
      !buttonRef.current?.contains(e.target as Node)
    ) {
      setSaveOpen(false);
    }
  });

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const { mutateAsync: downloadFile, isLoading: filePending } =
    useDownloadFile();

  const { isLoading: reportOrderPending, mutateAsync: reportOrder } =
    useDocumentsOrder();

  const {
    create: generateMultiplyReports,
    initPending: multiplyReportsInitPending,
    status: multiplyReportsStatus,
    cancel: cancelMultiplyReports,
    file: multiplyReportsFile,
    filePending: multiplyReportsFilePending,
    processPending: multiplyProcessPending,
    reset: resetMultiplyReportsGeneration,
  } = useMultiplyReportsGenerator();

  const setLastDayPeriod = () => {
    setPeriodStart(format(addDays(new Date(), -1), DEFAULT_VIEW_DATE_FORMAT));
    setPeriodEnd(format(addDays(new Date(), -1), DEFAULT_VIEW_DATE_FORMAT));
  };

  const selectedReportTheme = useMemo<ReportTheme | undefined>(() => {
    if (reportsTheme && theme) {
      const selected = reportsTheme.filter(
        (report) => report.id.toString() === theme
      );

      return selected.length > 0 ? selected[0] : undefined;
    }

    return undefined;
  }, [reportsTheme, theme]);

  // При смене темы
  useEffect(() => {
    const themeId = parseInt(theme);

    // Множественный отчет нельзя сформировать для "Справки расчета" и "Отчета о движении денежных средств"
    if (themeId === ReportType.Taxes || themeId === ReportType.MyMoneyMove) {
      setMultiply(false);
    }

    // Для "Отчета о движении денежных средств" выбираем период в 1 день
    if (themeId === ReportType.MyMoneyMove) {
      setLastDayPeriod();
    }
  }, [theme]);

  // При смене параметров очищаем файл
  useEffect(() => {
    setReportFile(null);
  }, [theme, periodStart, periodEnd, year, obtaining]);

  const obtainingOptions = useMemo(() => {
    if (selectedReportTheme) {
      return selectedReportTheme.allowedReceivingTypes
        .sort(sortObtain)
        .map(obtainRename)
        .map((ob) => ({
          key: ob.type as string,
          content: ob.description,
        }));
    }

    return [];
  }, [selectedReportTheme]);

  useEffect(() => {
    if (obtainingOptions.length) {
      const filtred = obtainingOptions.filter(
        (obt) => obt.key && obt.key === obtaining
      );
      const obtainingList = filtred.length > 0 ? filtred : obtainingOptions;

      setObtaining(obtainingList[0].key as DocumentReceivingType);
    }
  }, [obtainingOptions, obtaining]);

  const handleObtainingChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    setObtaining((selected?.key || '') as DocumentReceivingType);
  };

  const handleChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const handleYearChange = (year: string) => {
    setYear(year);
    const start = startOfYear(parse(year, 'yyyy', new Date()));
    const end = endOfYear(parse(year, 'yyyy', new Date()));

    setPeriodStart(format(start, DATE_FORMAT));
    setPeriodEnd(format(end, DATE_FORMAT));
  };

  const handleMultiplyChange: SwitchProps['onChange'] = (e, payload) => {
    const multiply = payload?.checked || false;

    setMultiply(multiply);

    if (multiply) {
      setLastDayPeriod();
    }
  };

  const requestReportFile = async (format: ReportFormat) => {
    setReportFile(null);
    resetMultiplyReportsGeneration();
    setSaveOpen(false);

    if (multiply && format !== ReportFormat.XmlImport) {
      const params = prepareMultiplyReportsParams({
        themeId: theme,
        periodStart,
        periodEnd,
        format,
      });

      await generateMultiplyReports(params);
    } else {
      const url = generateReportUrl({
        report: parseInt(theme),
        format,
        start: periodStart,
        end: periodEnd,
        treaty: treaty?.treaty.toString(),
        multiple: multiply,
      });

      const file = await downloadFile(url);

      if (file) {
        setReportFile(file);
      }
    }
  };

  const isReadyToOrder = useMemo(() => {
    const dateValid = selectedReportTheme
      ? isPeriodValid(
          selectedReportTheme.dateType,
          periodStart,
          periodEnd,
          year,
          selectedReportTheme.startDate,
          selectedReportTheme.endDate
        )
      : false;

    switch (obtaining) {
      case DocumentReceivingType.Electronic: {
        return Boolean(selectedReportTheme) && dateValid;
      }
      case DocumentReceivingType.InBank: {
        return (
          Boolean(selectedReportTheme) &&
          dateValid &&
          Boolean(city) &&
          Boolean(office) &&
          isCountValid(count)
        );
      }
      case DocumentReceivingType.ByMail: {
        return (
          Boolean(selectedReportTheme) &&
          dateValid &&
          Boolean(address) &&
          isCountValid(count)
        );
      }
      case DocumentReceivingType.Online:
      case DocumentReceivingType.InLK: {
        return Boolean(selectedReportTheme) && dateValid;
      }
    }
  }, [
    selectedReportTheme,
    periodStart,
    periodEnd,
    year,
    city,
    office,
    count,
    address,
    obtaining,
  ]);

  const requestReportOrder = async () => {
    if (treaty && selectedReportTheme) {
      const { periodStart: dateFrom, periodEnd: dateTo } =
        selectedReportTheme.dateType === DocumentDateType.Year
          ? getPeriodFromYear(year)
          : { periodStart, periodEnd };

      let data: OrderDocumentsParams = {
        treaty: treaty.treaty,
        dateType: DocumentDateType.Period,
        themeId: parseInt(theme),
        receivingType: obtaining,
        startDate: dateToRequestFormat(dateFrom),
        endDate: dateToRequestFormat(dateTo),
      };

      if (obtaining === DocumentReceivingType.ByMail) {
        data = {
          ...data,
          count: parseInt(count),
          address: address,
          extraInfo: comment,
        };
      } else if (obtaining === DocumentReceivingType.InBank) {
        data = {
          ...data,
          count: parseInt(count),
          officeCity: city,
          office: office,
          extraInfo: comment,
        };
      } else if (obtaining === DocumentReceivingType.Electronic) {
        data = {
          ...data,
          email: email,
        };
      }

      reportOrder({
        params: data,
        signOptions: {
          successTitle: 'Отчет заказан',
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      <Space direction="vertical" fullWidth>
        {Boolean(theme) && (
          <Space direction="vertical" fullWidth>
            {selectedReportTheme?.dateType === DocumentDateType.Year && (
              <OrderYear
                documentTheme={selectedReportTheme}
                year={year}
                onYearChange={handleYearChange}
              />
            )}
            {(selectedReportTheme?.dateType === DocumentDateType.Date ||
              selectedReportTheme?.dateType === DocumentDateType.Period) && (
              <OrderPeriod
                documentTheme={selectedReportTheme}
                periodStart={periodStart}
                periodEnd={periodEnd}
                onPeriodStartChange={(date) => setPeriodStart(date)}
                onPeriodEndChange={(date) => setPeriodEnd(date)}
              />
            )}

            <Row>
              <Col width={12}>
                <SelectDesktop
                  label="Способ получения"
                  labelView="outer"
                  placeholder="Выберите из списка"
                  size="s"
                  block
                  options={obtainingOptions}
                  selected={obtaining}
                  onChange={handleObtainingChange}
                  hint={getObtainHint?.(obtaining)}
                  error={
                    !emailConfirmed &&
                    obtaining === DocumentReceivingType.Electronic
                      ? 'У вас нет подтверждённого адреса электронной почты. Укажите его в Настройках профиля'
                      : ''
                  }
                />
              </Col>
            </Row>

            {obtaining === DocumentReceivingType.InBank && (
              <ReceiveInOffice
                city={city}
                onCityChange={(city) => setCity(city)}
                office={office}
                onOfficeChange={(office) => setOffice(office)}
              />
            )}
            {obtaining === DocumentReceivingType.ByMail && (
              <ReceiveByMail address={address} />
            )}
            {obtaining === DocumentReceivingType.Electronic && (
              <ReceiveEmail
                email={email}
                emailConfirmed={emailConfirmed}
                onEmailChange={handleChangeEmail}
              />
            )}
            {(obtaining === DocumentReceivingType.InBank ||
              obtaining === DocumentReceivingType.ByMail) && (
              <HardcopyFields
                count={count}
                onCountChange={(count) => setCount(count)}
                comment={comment}
                onCommentChange={(comment) => setComment(comment)}
              />
            )}

            {obtaining === DocumentReceivingType.Online &&
              !(
                parseInt(theme) === ReportType.Taxes ||
                parseInt(theme) === ReportType.MyMoneyMove
              ) && (
                <div className={styles.multiply}>
                  <Switch
                    checked={multiply}
                    label="Отчеты для всех счетов"
                    onChange={handleMultiplyChange}
                  />
                  <TooltipDesktop
                    targetClassName={styles.tooltipTrigger}
                    contentClassName={styles.tooltipContent}
                    position="top"
                    trigger="hover"
                    content="В отчет будут добавлены все счета, к которым у вас есть доступ."
                    fallbackPlacements={['left', 'right']}
                  >
                    <InformationCircleLineSIcon
                      height={16}
                      width={16}
                      color="var(--color-light-graphic-secondary)"
                    />
                  </TooltipDesktop>
                </div>
              )}

            <TradingCertificateLock>
              <Space direction="vertical" fullWidth>
                {obtaining === DocumentReceivingType.Online && (
                  <Row>
                    <Col width={6}>
                      <div ref={saveOptionsRef}>
                        <Button
                          block
                          size="s"
                          onClick={() => setSaveOpen(true)}
                          disabled={!isReadyToOrder}
                          loading={
                            filePending ||
                            multiplyReportsInitPending ||
                            multiplyProcessPending ||
                            multiplyReportsFilePending
                          }
                          ref={buttonRef}
                        >
                          Загрузить
                        </Button>
                        <Dropdown
                          anchorElement={saveOptionsRef.current}
                          open={saveOpen}
                          position="top-start"
                          offset={[0, 4]}
                          useAnchorWidth={true}
                          view="select"
                        >
                          <div ref={saveOptionsPopupRef}>
                            <Dropdown.Item
                              content="В формате XLS"
                              leftIcon={
                                <DocumentExcelMIcon height={18} width={18} />
                              }
                              onClick={() =>
                                requestReportFile(ReportFormat.Excel)
                              }
                            />
                            <Dropdown.Item
                              content="В формате PDF"
                              leftIcon={
                                <DocumentPdfMIcon height={18} width={18} />
                              }
                              onClick={() =>
                                requestReportFile(ReportFormat.Pdf)
                              }
                            />
                            {parseInt(theme) !== ReportType.MyMoneyMove && (
                              <Dropdown.Item
                                content="В формате XML"
                                leftIcon={
                                  <DocumentMIcon height={18} width={18} />
                                }
                                onClick={() =>
                                  requestReportFile(ReportFormat.Xml)
                                }
                              />
                            )}
                            {parseInt(theme) === ReportType.MyBroker &&
                              !isFeatureHidden(
                                'reports-broker-xml-import',
                                featureSettings || []
                              ) && (
                                <Dropdown.Item
                                  content="В формате XML (для импорта)"
                                  leftIcon={
                                    <DocumentMIcon height={18} width={18} />
                                  }
                                  onClick={() =>
                                    requestReportFile(ReportFormat.XmlImport)
                                  }
                                />
                              )}
                          </div>
                        </Dropdown>
                      </div>
                    </Col>
                    {(Boolean(reportFile) || Boolean(multiplyReportsFile)) && (
                      <Col width={6}>
                        <Button
                          block
                          view="tertiary"
                          size="s"
                          leftAddons={<OutsideMIcon width={18} height={18} />}
                          href={reportFile?.url || multiplyReportsFile?.url}
                          download={
                            reportFile?.filename ||
                            multiplyReportsFile?.filename
                          }
                          target="_blank"
                        >
                          {reportFile ? 'Открыть' : 'Скачать'}
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}

                {obtaining !== DocumentReceivingType.Online && (
                  <Row>
                    <Col width={6}>
                      <Button
                        size="s"
                        block
                        view="accent"
                        leftAddons={<CheckmarkMIcon width={18} height={18} />}
                        loading={reportOrderPending}
                        disabled={!isReadyToOrder}
                        onClick={requestReportOrder}
                      >
                        Заказать
                      </Button>
                    </Col>
                  </Row>
                )}

                {multiplyReportsStatus ? (
                  <div className={styles.multiplyProgress}>
                    <Typography.Text
                      view="secondary-large"
                      color="secondary"
                      tag="p"
                      defaultMargins={false}
                    >
                      Подготовка отчетов: {multiplyReportsStatus.currentItem} из{' '}
                      {multiplyReportsStatus.count}
                    </Typography.Text>
                    <Gap size="2xs" />
                    <ProgressBar
                      view="accent"
                      value={Math.ceil(
                        (multiplyReportsStatus.currentItem /
                          multiplyReportsStatus.count) *
                          100
                      )}
                    />
                    <Row className={styles.multiplyCancel}>
                      <Col width={6}>
                        <Button
                          block
                          view="tertiary"
                          size="s"
                          onClick={() => cancelMultiplyReports()}
                        >
                          Отмена
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </Space>
            </TradingCertificateLock>
          </Space>
        )}
      </Space>
    </div>
  );
};
