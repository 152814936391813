import cn from 'classnames';
import React, { FC } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DotMediumMIcon } from '@alfalab/icons-glyph/DotMediumMIcon';

import { ChartIndex } from '../../model/types';

import styles from './IndexChip.module.css';

interface Props {
  index?: ChartIndex;
  color: string;
  name: string;
  onClose?: (index: ChartIndex) => void;
}

export const IndexChip: FC<Props> = ({ index, color, name, onClose }) => {
  return (
    <div
      className={cn(styles.chip, {
        [styles.withClose]: Boolean(onClose),
      })}
    >
      <DotMediumMIcon
        width={16}
        height={16}
        style={{
          color,
        }}
      />
      <Typography.Text
        tag="div"
        view="secondary-large"
        className={styles.tertiary}
      >
        {name}
      </Typography.Text>
      {index && onClose && (
        <IconButton
          view="secondary"
          size="xxs"
          icon={CrossMIcon}
          onClick={() => onClose(index)}
        />
      )}
    </div>
  );
};
