import { featureFlags } from '@terminal/core/featureFlags';
import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { balanceLayout } from '../common/balanceLayout';
import {
  getBalancesBalancesConfig,
  getBaseChartConfig,
  getCommonInstrumentsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
  getTradeChartConfig,
  getTradeOrderbookConfig,
} from '../common/configs';
import {
  CHARTS__10MIN_CHARTIQ_SETTINGS,
  CHARTS__HOUR_CHARTIQ_SETTINGS,
  CHARTS_DAY_SETTINGS,
  CHARTS_USD_CHARTIQ_SETTINGS,
  CHARTS_USD_MOEX_SETTINGS,
  TRADE_BOTTOM_CHART_SETTING,
  TRADE_TOP_CHART_SETTING,
} from './configs';

import { ConfigTemplate } from '../../../../model/types/configTemplate';
import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const daytradeLarge = () => {
  /**
   * Возвращает layout Торговля
   * */
  const makeTradeLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const leftRow = new Row(50);
    const rightRow = new Row(50);

    const ordersTab = new Tab(Widget.ORDERS, getCommonOrdersConfig());
    const positionsTab = new Tab(
      Widget.POSITIONS,
      getPositionsCommonConfig(blueLink.name)
    );
    const balancesTab = new Tab(Widget.BALANCE, getBalancesBalancesConfig());
    const orderTabset = new TabSet(30);

    orderTabset.addChildren([ordersTab, positionsTab, balancesTab]);

    const watchLists: Tab[] = [];

    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.iMOEXList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.MOEX10List)
      )
    );

    if (featureFlags.AMERICAN_INDICIES_ENABLED) {
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.NDXList)
        )
      );
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.DJIList)
        )
      );
      watchLists.push(
        new Tab(
          Widget.WATCHLIST,
          getCommonInstrumentsConfig(blueLink.name, WatchlistIds.SP500List)
        )
      );
    }

    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.RGBITRList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.RUCBITRList)
      )
    );
    watchLists.push(
      new Tab(
        Widget.WATCHLIST,
        getCommonInstrumentsConfig(blueLink.name, WatchlistIds.featuresList)
      )
    );

    const instrumentsTabSet = new TabSet(40);

    instrumentsTabSet.addChildren(watchLists);

    const trades1Tab = new Tab(Widget.TRADES, { link: blueLink.name });
    const trades2Tab = new Tab(Widget.TRADES, { link: blueLink.name });
    const trades1TabSet = new TabSet(20);

    trades1TabSet.addChildren([trades1Tab]);
    const trades2TabSet = new TabSet(20);

    trades2TabSet.addChildren([trades2Tab]);

    const orderbookTab = new Tab(
      Widget.ORDERBOOK,
      getTradeOrderbookConfig(blueLink.name)
    );
    const orderbookTabset = new TabSet(20);

    orderbookTabset.addChildren([orderbookTab]);

    const bottomRow = new Row(70);

    bottomRow.addChildren([
      instrumentsTabSet,
      trades1TabSet,
      trades2TabSet,
      orderbookTabset,
    ]);

    leftRow.addChildren([orderTabset, bottomRow]);

    const chartiqTopTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_TOP_CHART_SETTING
    );
    const chartiqTopTabSet = new TabSet(100);

    chartiqTopTabSet.addChildren([chartiqTopTab]);

    const chartiqBottomTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      TRADE_BOTTOM_CHART_SETTING
    );
    const chartiqBottomTabSet = new TabSet(100);

    chartiqBottomTabSet.addChildren([chartiqBottomTab]);

    rightRow.addChildren([chartiqTopTabSet, chartiqBottomTabSet]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, rightRow]);

    return new Layout('Торговля', tradeRow, [blueLink]);
  };
  /**
   * Возвращает layout Графики
   * */
  const makeChartLayout = () => {
    const blueLink = new Link({
      name: LinkName.BLUE,
      idFi: 144950,
      symbol: 'SBER',
    });

    const leftRow = new Row(20);
    const leftMiddleRow = new Row(40);
    const rightRow = new Row(60);

    const instrumentLeftTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig()
    );
    const instrumentLeftTabset = new TabSet(50);

    instrumentLeftTabset.addChildren([instrumentLeftTab]);

    leftRow.addChildren([instrumentLeftTabset]);

    const chartiqLeftTopTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      CHARTS_DAY_SETTINGS
    );
    const chartiqLeftTopTabSet = new TabSet(40);

    chartiqLeftTopTabSet.addChildren([chartiqLeftTopTab]);

    const chartiqLeftBottomTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      CHARTS__10MIN_CHARTIQ_SETTINGS
    );
    const chartiqLeftBottomTabSet = new TabSet(40);

    chartiqLeftBottomTabSet.addChildren([chartiqLeftBottomTab]);

    leftMiddleRow.addChildren([chartiqLeftTopTabSet, chartiqLeftBottomTabSet]);

    const chartiqRightTopTab = new Tab(
      Widget.CHART,
      getBaseChartConfig(blueLink.name),
      CHARTS__HOUR_CHARTIQ_SETTINGS
    );
    const chartiqRightTopTabSet = new TabSet(60);

    chartiqRightTopTabSet.addChildren([chartiqRightTopTab]);

    const chartiqRightBottomLeftTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(undefined, 149262, 'USD'),
      CHARTS_USD_CHARTIQ_SETTINGS
    );
    const chartiqRightBottomLeftTabSet = new TabSet(40);

    chartiqRightBottomLeftTabSet.addChildren([chartiqRightBottomLeftTab]);

    const chartiqRightBottomRightTab = new Tab(
      Widget.CHART,
      getTradeChartConfig(undefined, 108897, 'IMOEX'),
      CHARTS_USD_MOEX_SETTINGS
    );
    const chartiqRightBottomRightTabSet = new TabSet(40);

    chartiqRightBottomRightTabSet.addChildren([chartiqRightBottomRightTab]);

    const chertiqBottomRow = new Row(40);

    chertiqBottomRow.addChildren([
      chartiqRightBottomLeftTabSet,
      chartiqRightBottomRightTabSet,
    ]);

    rightRow.addChildren([chartiqRightTopTabSet, chertiqBottomRow]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftRow, leftMiddleRow, rightRow]);

    return new Layout('Графики', chartRow, [blueLink]);
  };

  const configuration = new DefaultConfiguration('Дневная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
