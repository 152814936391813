import noop from 'lodash/noop';
import { useCallback } from 'react';

import { DIMENSIONS_SCHEMAS } from '@terminal/core/constants/analytics';
import { DateFilterType } from '@terminal/core/types/operation';

import { AnalyticsTrack, useWidgetContext } from '../../../shared';
import { PortfolioChart } from '../model';
import { FiltersNameMap, PortfolioChartNameMap } from './const';

const CATEGORY_NAME = 'Analytics report';

const enum EVENT_NAME {
  OPEN = 'open',
  APPLY = 'apply',
  CLICK = 'click',
  HOVER = 'hover',
}

const enum EVENT_LABELS {
  CHIPS = 'Chips',
  CUSTOM_DATE = 'Custom date',
  BANNER = 'Banner',
  GRAPH_CHIPS = 'Graph chips',
  TOOLTIP = 'Tooltip',
  ELEMENT = 'Element',
  WINDOW = 'Window',
  CARD = 'Card',
}

export const usePortfolioReportsMetrics = () => {
  const {
    analytics: { track = noop as AnalyticsTrack } = {
      track: noop as AnalyticsTrack,
    },
  } = useWidgetContext();

  return {
    trackClickPortfolioReportDateChip: useCallback(
      (dateFilterType: DateFilterType) => {
        track(CATEGORY_NAME, EVENT_NAME.CLICK, EVENT_LABELS.CHIPS, null, null, [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              1: FiltersNameMap.get(dateFilterType),
              2: 'Вкл',
            },
          },
        ]);
      },
      [track]
    ),

    trackApplyPortfolioReportCustomDate: useCallback(
      (dateFrom: string, dateTo: string) => {
        track(
          CATEGORY_NAME,
          EVENT_NAME.APPLY,
          EVENT_LABELS.CUSTOM_DATE,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                3: dateFrom,
                4: dateTo,
              },
            },
          ]
        );
      },
      [track]
    ),

    trackClickPortfolioReportMarginalBanner: useCallback(() => {
      track(CATEGORY_NAME, EVENT_NAME.CLICK, EVENT_LABELS.BANNER, null, null, [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            5: 'С плечом можно заработать ещё больше',
          },
        },
      ]);
    }, [track]),

    trackClickPortfolioReportGraphChip: useCallback(
      (chartType: PortfolioChart) => {
        track(
          CATEGORY_NAME,
          EVENT_NAME.CLICK,
          EVENT_LABELS.GRAPH_CHIPS,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                1: PortfolioChartNameMap[chartType],
              },
            },
          ]
        );
      },
      [track]
    ),

    trackHoverPortfolioReportTooltip: useCallback(
      (tooltipTitle: string) => {
        track(
          CATEGORY_NAME,
          EVENT_NAME.HOVER,
          EVENT_LABELS.TOOLTIP,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                8: tooltipTitle,
              },
            },
          ]
        );
      },
      [track]
    ),

    trackClickPortfolioReportElement: useCallback(
      (blockName: string, rowName: string) => {
        track(
          CATEGORY_NAME,
          EVENT_NAME.CLICK,
          EVENT_LABELS.ELEMENT,
          null,
          null,
          [
            {
              schema: DIMENSIONS_SCHEMAS.CUSTOM,
              data: {
                8: blockName,
                9: rowName,
              },
            },
          ]
        );
      },
      [track]
    ),

    trackOpenPortfolioReportDetails: useCallback(
      (actGroupName: string) => {
        track(CATEGORY_NAME, EVENT_NAME.OPEN, EVENT_LABELS.WINDOW, null, null, [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              1: actGroupName,
            },
          },
        ]);
      },
      [track]
    ),

    trackOpenPortfolioReportFIDetails: useCallback(
      (assetType: string, ticker: string, isin: string) => {
        track(CATEGORY_NAME, EVENT_NAME.OPEN, EVENT_LABELS.CARD, null, null, [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              1: ticker,
              2: isin,
              3: assetType,
            },
          },
        ]);
      },
      [track]
    ),
  };
};
