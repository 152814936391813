import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { FC, useRef } from 'react';
import {
  Area,
  AreaChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';
import { NetValuePoint } from '@terminal/core/lib/rest/investApi';

import { CurrentPriceLabel } from '../../../../shared';
import { calculateBalanceChartLabels } from '../../lib/calculateBalanceChartLabels';
import { BalanceChartTooltip } from '../BalanceChartTooltip';

import { useBalanceChartDots } from '../../hooks/useBalanceChartDots';

import styles from './PortfolioBalanceChart.module.css';

interface Props {
  portfolioGraph: NetValuePoint[];
}

export const PortfolioBalanceChart: FC<Props> = ({ portfolioGraph }) => {
  const areaRef = useRef<Area | null>(null);

  const dots = useBalanceChartDots(portfolioGraph);

  const minBalance = minBy(dots, (d) => d.balance)?.balance as number;
  const maxBalance = maxBy(dots, (d) => d.balance)?.balance as number;
  const currentBalance = Number(dots.at(-1)?.balance as number);

  const { labels, minMaxTicks } = calculateBalanceChartLabels(
    dots,
    areaRef.current?.props.height || 200
  );

  const yAxisWidth = Math.max(
    ...[currentBalance, minBalance, maxBalance].map((num) =>
      getPriceLabelWidth(num)
    )
  );

  return (
    <ResponsiveContainer width="100%" height={242}>
      {/* @ts-expect-error cursor=pointer валидно */}
      <AreaChart cursor="pointer" data={dots} className={styles.chart}>
        <defs>
          <linearGradient id="colorFill" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor="var(--color-light-text-primary)"
              stopOpacity={1}
            />
            <stop
              offset="100%"
              stopColor="var(--color-light-text-primary)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>

        {labels.map((label) => (
          <ReferenceLine
            key={`${label.type}-line`}
            y={label.y}
            stroke={label.stroke}
            label={
              label.hideLabel ? undefined : (
                <CurrentPriceLabel
                  price={label.price}
                  fill={label.color}
                  yAxisWidth={yAxisWidth}
                />
              )
            }
          />
        ))}

        {minMaxTicks.map((label) => (
          <ReferenceLine
            key={`minmax-${label.price}`}
            y={label.y}
            stroke="none"
            label={
              <CurrentPriceLabel
                price={label.price}
                fill="var(--color-light-text-secondary)"
                yAxisWidth={yAxisWidth}
              />
            }
          />
        ))}

        <Area
          ref={(area) => {
            areaRef.current = area;
          }}
          isAnimationActive={false}
          dot={false}
          activeDot={false}
          type="linear"
          dataKey="balance"
          fill="url(#colorFill)"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="var(--color-light-text-primary)"
          strokeWidth={2}
        />
        <ReferenceDot
          x={dots.at(-1)?.date?.getTime()}
          y={dots.at(-1)?.balance}
          r={3}
          fill="var(--color-light-text-primary)"
          stroke="none"
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tick={{
            fill: 'var(--color-light-text-secondary)',
            fontSize: 11,
          }}
          tickFormatter={(timestamp) =>
            format(timestamp, 'dd MMM', { locale: ru })
          }
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          width={yAxisWidth}
          orientation="right"
          tick={<div />}
          ticks={[]}
          domain={([min, max]) => {
            return [min - (max - min) / 10, max + (max - min) / 10];
          }}
        />
        <Tooltip content={<BalanceChartTooltip />} cursor={{}} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const SYMBOL_WIDTH = 6;

function getPriceLabelWidth(price: number) {
  const decimalsNumber = countDecimals(price);
  const minority = Math.pow(10, decimalsNumber);

  return (
    formatAmount({
      value: price * minority,
      currency: 'RUR' as CurrencyCodes,
      minority: minority,
      view: 'withZeroMinorPart',
    }).formatted.length * SYMBOL_WIDTH
  );
}
