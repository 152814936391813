// Вкладка Баланс
import { LinkName, Widget } from '@terminal/core/types/layout';

import {
  getBalancesBalancesConfig,
  getBalancesOperationsConfig,
  getCommonOrdersConfig,
  getPositionsCommonConfig,
} from './configs';

import { Layout } from '../../../../model/types/layout';
import { Link } from '../../../../model/types/layoutLinks';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

const blueLink = new Link({
  name: LinkName.BLUE,
  idFi: 144950,
  symbol: 'SBER',
});

const balancesTab = new Tab(Widget.BALANCE, getBalancesBalancesConfig());
const balancesTabSet = new TabSet(50);
const positionsTab = new Tab(
  Widget.POSITIONS,
  getPositionsCommonConfig(blueLink.name)
);

balancesTabSet.addChildren([balancesTab, positionsTab]);

const operationsTab = new Tab(Widget.OPERATIONS, getBalancesOperationsConfig());

const ordersTab = new Tab(Widget.ORDERS, getCommonOrdersConfig());

const ordersBalanceTabSet = new TabSet(50);

ordersBalanceTabSet.addChildren([ordersTab, operationsTab]);

const topBalanceRow = new Row(50);

topBalanceRow.addChildren([balancesTabSet]);
const bottomBalanceRow = new Row(50);

bottomBalanceRow.addChildren([ordersBalanceTabSet]);

const verticalRow = new Row(100);

verticalRow.addChildren([topBalanceRow, bottomBalanceRow]);

const balanceRow = new Row(100);

balanceRow.addChildren([verticalRow]);

export const balanceLayout = new Layout('Баланс', balanceRow, [blueLink]);
