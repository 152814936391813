import { useQuery } from 'react-query';

import {
  BankCardsResponse,
  getBankCards,
} from '@terminal/core/lib/rest/lkBankCard';

export const useCardList = (treaty?: number) => {
  return useQuery<BankCardsResponse | null, Error>(
    ['lk', 'transfer', 'cardList', treaty],
    async () => {
      if (!treaty) {
        return null;
      }

      return await getBankCards({ treaty });
    },
    {
      enabled: Boolean(treaty),
      staleTime: 30 * 1000,
    }
  );
};
